import PropTypes from 'prop-types'
import React, { Component, useCallback, useEffect, useState } from 'react'

import { getCurrentLng } from '../i18next_fun';
import { useTranslation } from 'react-i18next';
import CustomPopup from '../Components/CustomPopup';

import leavePageBlockerStyles from "./leave-page-blocker.module.scss";

const LeavePageBlocker = (props) => {
  const { t } = useTranslation();
  const message = t('page_has_unsaved_changes');

  const [when, setWhen] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };

  const resetHandler = (e) => {
    setShow(false);
    props.onReset();
  };

  const saveHandler = (e) => {
    setShow(false);
    props.onSave();
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  //useEffect(() => {
  //  setWhen(props.when);
  //}, [props.when]);

  //useEffect(() => {
  //  if (!when) return (() => { });

  //  const beforeUnloadCallback = (event) => {
  //    event.preventDefault();
  //    //event.returnValue = message
  //    //return message
  //    //setShow(true);
  //    debugger;
  //    console.log('now');
  //  }

  //  window.addEventListener('beforeunload', beforeUnloadCallback)
  //  return () => {
  //    window.removeEventListener('beforeunload', beforeUnloadCallback)
  //  }
  //}, [when, title])

  return (
    <CustomPopup className={leavePageBlockerStyles.leavePageBlockerMain} show={show} onClose={(e) => { closeHandler(e); }} title={""} >
      <div className={leavePageBlockerStyles.leavePageBlockerWrapper}><img src="/assets/alert_exclamation.svg" alt="" /></div>
      <div className={leavePageBlockerStyles.leavePageBlockerMessageWrapper + " " + (getCurrentLng() === 'en' ? "" : leavePageBlockerStyles.errorAlertMainActionsWrapperRTL)}>
        <b>{(title !== '') ? title : t('leavePageBlocker.messageText')}</b>
      </div>
      <div>
        {t('leavePageBlocker.explanationText')}
      </div>
      <div className={leavePageBlockerStyles.leavePageBlockerActionsWrapper}>
        <button color="link" className={leavePageBlockerStyles.leavePageBlockerActionBtn} onClick={(e) => { saveHandler(); }} >{t('leavePageBlocker.saveText')}</button>
        <button color="link" className={leavePageBlockerStyles.leavePageBlockerActionBtn} onClick={(e) => { resetHandler(); }} >{t('leavePageBlocker.resetText')}</button>
        {/*<button color="link" className={leavePageBlockerStyles.leavePageBlockerActionBtn} onClick={(e) => { console.log(e); }} >{t('leavePageBlocker.cancelText')}</button>*/}
      </div>
    </CustomPopup>
  );

}

LeavePageBlocker.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default LeavePageBlocker