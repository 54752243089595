import { getCurrentLng } from '../../i18next_fun';
import React, { Component, useCallback, useEffect, useState } from 'react';
import { Card, CardImg, Button, ButtonGroup, CardTitle, CardText, CardHeader, CardBody, Collapse, Row, Col, FormGroup, Input, Label, NavLink, Tooltip } from 'reactstrap';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Accordion } from "../../Components/Accordion";
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import 'moment/locale/he';

import * as Icon from 'react-bootstrap-icons';
import styles from './order-card.module.scss';

const OrderCard = (props) => {
  const { order } = props;
  const { onHandleFreezeOrder } = props;

  const [orderTimeLeft, setOrderTimeLeft] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [isCallToolTipOpen, setIsCallToolTipOpen] = useState(false);

  if (getCurrentLng() === 'he') { moment.locale('he'); } else { moment.locale('en'); };

  let history = useHistory();

  useEffect(() => {
    let dateString = null;
    if (order.ShippingMethod == 1) {
      dateString = String(order.ShipTimeFrom);
    }
    else {
      dateString = String(order.PickupTimeFrom);

    }
    setOrderTimeLeft(moment(dateString, "DD/MM/YYYY hh:mm"));

    //setOrderTimeLeft(moment(order.DeliveryDate, "DD/MM/YYYY hh:mm"));
  }, [order]);

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  const toggleCard = (e) => {
    setIsOpen(!isOpen);
  };

  const toggleCallToolTip = (e) => {
    setIsCallToolTipOpen(!isCallToolTipOpen);
  };

  return (
    <Card className={styles.orderCard + " " + (getCurrentLng() === 'en' ? "" : styles.orderRtl)}>
      <Card body className={styles.cardBody}>
        <CardHeader className={styles.cardHeader} onClick={(e) => { toggleCard(e) }}>
          <CardTitle className={styles.cardTitle}>
            <div className={styles.cardTitleName}>
              <div className={styles.cardTitleId}>{order.IDinShop}</div>
              {order.FullName}
            </div>
            <div className={styles.cardTitleHour}>
              <div className={styles.cardTitleTimeCaption}>{t('orderCard.timeToCollect')}</div>
              {/*<Moment date={orderTimeLeft} format="hh:mm" durationFromNow />*/}
              {moment(orderTimeLeft).fromNow(true) }
            </div>
            <div className={styles.cardTitleIcon + " " + ((getCurrentLng() === 'en') ? null : styles.cardTitleIconRTL)}>
              {(order.ShippingMethod === 1) ?
                <img src="/assets/orders/delivery.svg" title={t('orderCard.deliveryDate')} />
                :
                <img src="/assets/orders/pickup.svg" title={t('orderCard.pickupDate')} />
              }
            </div>
          </CardTitle>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className={styles.cardBody}>
            {(order.Status > 3 && order.isCharged === true) ?
              <div className={styles.cardOrderCharged}>
                {t("orderCard.orderCharged")}
              </div>
              : null
            }
            <div className={styles.cardText}>
              <Row className={styles.cardRow}>
                <div className={styles.cardFirstRowSpacer}>
                </div>
                <Col className={styles.cardCol} lg="12" xs="12">
                  <b>{order.FullName}</b>
                </Col>
              </Row>
              <Row className={styles.cardRow}>
                <Col className={styles.cardCol} lg="12" xs="12">
                  {/*<Label>{t('orderCard.shipAddressStreet')}: </Label>*/}{order.ShipAddressStreet}
                </Col>
              </Row>
              <Row className={styles.cardRow}>
                <Col className={styles.cardCol} lg="12" xs="12">
                  <b><Label>{t('orderCard.pickupBranch')}: </Label></b> {(order.ShoookChosenShop > 0) ? order.ShopName + " - " + order.ShoookChosenShopName : order.ShopName} - {order.PickupBranchName}<br />
                </Col>
              </Row>
              <Row className={styles.cardRow}>
                <Col className={styles.cardCol} lg="12" xs="12">
                  {/*{order.DeliveryDate}*/}
                  {(order.ShippingMethod === 1) ?
                    order.DeliveryDate
                    :
                    order.PickupTimeFrom
                  }
                </Col>
              </Row>
              <Row className={styles.cardRow}>
                <Col className={styles.cardCol} lg="12" xs="12">
                  <b>
                    {/*{order.ItemsCount} {t('orderCard.products')}*/}
                    {(order.PackagesCount !== 0) ?
                      <Label> {order.PackagesCount} {t('orderCard.packages')}</Label>
                      :
                      <Label>{order.ItemsCount} {t('orderCard.products')}</Label>
                    }
                  </b>
                {/*</Col>*/}
                {/*<Col className={styles.cardCol + " " + (getCurrentLng() === 'en' ? styles.alignRight : styles.alignLeft)} lg="6" xs="6">*/}
                  <b> | </b>
                  <b>{((order.Status < 3) ?
                    order.SubTotal
                    :
                    order.Total
                  )} &#8362;</b>
                  &#160;
                  {((order.PaymentMethod != 3) ?
                    <img src={"/assets/orders/payment_method_" + order.PaymentMethod + ".svg"} title={t('orderCard.paymentMethod' + order.PaymentMethod)} alt={t('orderCard.paymentMethod' + order.PaymentMethod)} /> :
                    <Icon.CashCoin title={t('orderCard.paymentMethod' + order.PaymentMethod)} alt={t('orderCard.paymentMethod' + order.PaymentMethod)}/>
                  )}
                </Col>
              </Row>
              {order.UserEditingOrder ? 
                <Row className={styles.cardRow}>
                  <Col className={styles.cardCol + " " + styles.cardEditingByUserText} lg="12" xs="12">
                    {t('orderCard.EditingByUser')}<br />
                  </Col>
                </Row>
              : null}
              {order.MemberEditingOrder ? 
                <Row className={styles.cardRow}>
                  <Col className={styles.cardCol + " " + styles.cardEditingByUserText} lg="12" xs="12">
                    {t('orderCard.EditingByMember') + " " + order.MemberNameEditingOrder}<br />
                  </Col>
                </Row>
              : null}
              <Row className={styles.cardRow}>
                <Col className={styles.cardCol + " " + styles.cardComment} lg="12" xs="12">
                  {/*{*/}
                  {/*  (order.ShippingMethod == 1) ?*/}
                  {/*    <React.Fragment><Label>{t('orderCard.deliveryDate')}:</Label><Label>{order.DeliveryDate}</Label></React.Fragment> :*/}
                  {/*    <React.Fragment><Label>{t('orderCard.pickupDate')}:</Label><Label>{order.DeliveryDate}</Label></React.Fragment>*/}
                  {/*}*/}
                  {((order.Comment && order.Comment.length > 0) ?
                    <img src="/assets/orders/edit_icon_purple.svg" /> :
                    null
                  )}
                  &#160;
                  {order.Comment}
                </Col>
                {/*<Col className={styles.cardCol + " " + (getCurrentLng() === 'en' ? styles.alignRight : styles.alignLeft)} lg="6" xs="6">*/}
                  {/*<div className={styles.timeLeftBox}>*/}
                  {/*  <h2><Moment date={orderTimeLeft} format="hh:mm" durationFromNow /></h2>*/}
                  {/*  <Label>{t('orderCard.hoursLeft')} {(order.ShippingMethod == 1) ? t('orderCard.forDelivery') : t('orderCard.forPickup')}</Label>*/}
                  {/*</div>*/}
                {/*</Col>*/}
              </Row>               
              <Row className={styles.cardRow + " " + styles.cardAdvancementRow}>
                <Col className={styles.cardCol + " " + styles.cardAdvancementCol} lg="4" xs="4">
                  {t('orderCard.collected')} {order.CollectedItemsCount}/{order.ItemsCount}
                </Col>
                <Col className={styles.cardCol + " " + styles.cardAdvancementCol} lg="4" xs="4">

                </Col>
                <Col className={styles.cardCol + " " + styles.cardAdvancementCol} lg="4" xs="4">

                </Col>
              </Row>                          
              <Row className={styles.cardRow + " " + styles.cardActionsRow}>
                <Col className={styles.cardCol + " " + styles.cardActionsCol} lg="4" xs="4">
                  <Button color="link" href="" className={styles.cardNavLink + " " + styles.cardActionLink} id={'CallTooltip-' + order.ID}>
                    <img src="/assets/orders/phone.svg" /> {t('orderCard.call')}
                  </Button>
                  <Tooltip placement={'top'} isOpen={isCallToolTipOpen} trigger="click" target={'CallTooltip-' + order.ID} toggle={toggleCallToolTip} className={styles.cardCallToolTipBg}>
                    <Button color="link" href={"tel:" + order.Phone} className={styles.cardCallToolTipNavLink + " " + styles.cardCallToolTipActionLink}>
                      <img src="/assets/orders/phone.svg" /> {order.Phone}
                    </Button>
                    {(order.UserUserName && String(order.UserUserName).indexOf("@") != 0) ? 
                      <Button color="link" href={"mailto:" + order.UserUserName} className={styles.cardCallToolTipNavLink + " " + styles.cardCallToolTipActionLink}>
                        <Icon.Envelope /> {order.UserUserName}
                      </Button>
                      : null} 
                    <Button color="link" href={"https://wa.me/" + order.Phone} target={'blank'} className={styles.cardCallToolTipNavLink + " " + styles.cardCallToolTipActionLink}>
                      <img src="/assets/orders/phone.svg" /> WhatsApp
                    </Button>
                  </Tooltip>
                </Col>
                <Col className={styles.cardCol + " " + styles.cardActionsCol} lg="4" xs="4">
                  <Button color="link" onClick={(e) => { if (order.Status == 1) onHandleFreezeOrder(order.ID); }} data-orderid={order.ID} disabled={order.Status != 1 || order.UserEditingOrder || order.MemberEditingOrder} className={styles.cardNavLink + " " + styles.cardActionLink}>
                    <img src="/assets/orders/freeze.svg" /> {t('orderCard.freeze')}
                  </Button>
                </Col>
                <Col className={styles.cardCol + " " + styles.cardActionsCol} lg="4" xs="4">
                  <Button color={'Link'} onClick={(e) => { history.push(`${path}order/${order.ID}`); }} disabled={order.UserEditingOrder || order.MemberEditingOrder} className={styles.cardNavLink + " " + styles.cardCollectionLink}>
                    <img src="/assets/orders/basket.svg" /> {t('orderCard.collect')}
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    </Card>
  );
}

export default OrderCard