import React, { useRef } from "react";

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapLocationAndRadius = (props) => {
  const circleRef = useRef(null);

  const {GoogleMap, Circle} = props

  const onCircleChangeCenter = (center) => {   
    props.onCircleCenterChange(center);
  };

  const onCircleChangeRadius = (radius) => {   
    props.onCircleRadiusChange(radius);
  };

  const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: true,
    editable: true,
    visible: true,    
    center: {
      lat: parseFloat(props.center.lat),
      lng: parseFloat(props.center.lng)
    },
    radius: props.distance,
    zIndex: 1
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: parseFloat(props.mapCenter.lat),
        lng: parseFloat(props.mapCenter.lng)
      }}
      zoom={props.zoom}
    >
      <Circle
        options={circleOptions}              
        onLoad={(circle) => {
          circleRef.current = circle
        }}
        onCenterChanged={(e) => {          
          circleRef.current && onCircleChangeCenter({ lat: circleRef.current.center.lat(), lng: circleRef.current.center.lng() });
        }}
        onRadiusChanged={() => {
          circleRef.current && onCircleChangeRadius(parseInt(circleRef.current['radius']));
        }}
      />
    </GoogleMap>
  )
};

export default MapLocationAndRadius;