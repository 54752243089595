import React, { Component, useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import ReactDOM from "react-dom";
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import CustomPopup from '../../Components/CustomPopup';
import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';
import ToggleSwitch from '../../Components/ToggleSwitch';

import { DatePicker } from 'reactstrap-date-picker'
import { dateAdd, getFullDateInUTC } from '../../dateAdd';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import styles from './shippingtime-edit.module.scss';
import ShippingTimeSchdule from './ShippingTimeSchedule';

const ShippingTimeEdit = (props) => {
  const [user, setUser] = useState({});
  const [shippingTimeShopId, setShippingTimeShopId] = useState(0);
  const [shippingTime, setShippingTime] = useState({});

  const [shippingTimeId, setShippingTimeId] = useState(0);
  
  const [shippingTimeTitle, setShippingTimeTitle] = useState('');
  const [shippingTimeTitleValid, setShippingTimeTitleValid] = useState(false);
  const [shippingTimeTitleCheck, setShippingTimeTitleCheck] = useState(false);

  const [shippingTimeDeliveryInterval, setShippingTimeDeliveryInterval] = useState(0);

  const [shippingTimeShipLimitPerInterval, setShippingTimeShipLimitPerInterval] = useState(0);

  const [shippingTimeDatesValid, setShippingTimeDatesValid] = useState(false);
  const [shippingTimeDatesValueCheck, setShippingTimeDatesValueCheck] = useState(false);

  const [shippingTimeSchedule, setShippingTimeSchedule] = useState([]);

  const [errorShippingTimesEmpty, setErrorShippingTimesEmpty] = useState(false);
  //const [errorNoCondition, setErrorNoCondition] = useState(false);
  //const [errorNoReward, setErrorNoReward] = useState(false);
  //const [errorConditionWithoutSet, setErrorConditionWithoutSet] = useState(false);
  //const [errorRewardWithoutSet, setErrorRewardWithoutSet] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseDataFetched, setBaseDataFetched] = useState(false);

  const [shippingTimeEditError, setShippingTimeEditError] = useState(false);
  const [shippingTimeEditErrorMessage, setShippingTimeEditErrorMessage] = useState('');

  const { t, i18n } = useTranslation('common');

  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    if (shippingTimeId == undefined || (loading)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    document.title = t('nav.shippingTimeEdit');
    getShippingTime();

  }, [user, shippingTimeShopId]);

  useEffect(() => {
    if (shippingTimeTitle !== null && shippingTimeTitle !== undefined) {
      if (String(shippingTimeTitle) !== '') {
        setShippingTimeTitleCheck(true);
      }
      if (String(shippingTimeTitle).trim().length > 3) {
        setShippingTimeTitleValid(true);
      }
      else {
        setShippingTimeTitleValid(false);
      }
    }
  }, [shippingTimeTitle]);


  //useEffect(() => {
  //  if (specialOfferDateStartValue !== null && specialOfferDateStartValue !== undefined &&
  //    specialOfferDateEndValue !== null && specialOfferDateEndValue !== undefined
  //  ) {
  //    //if (String(productSKU) !== '') {
  //    setSpecialOfferDatesValueCheck(true);
  //    //}

  //    let startDate = new Date(specialOfferDateStartValue);
      
  //    let endDate = new Date(specialOfferDateEndValue);
      
  //    //if (startDate > new Date() && endDate > startDate) {
  //    if (endDate > startDate) {
  //      setSpecialOfferDatesValid(true);
  //    }
  //    else {
  //      setSpecialOfferDatesValid(false);
  //    }
  //  }
  //}, [specialOfferDateStartValue, specialOfferDateEndValue]);

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  async function getShippingTime() {
    if ((user
      && Object.keys(user).length === 0
      && Object.getPrototypeOf(user) === Object.prototype)
      || shippingTimeShopId === 0
      || baseDataFetched
      || (id === null || id === undefined)) {

      return;
    }
    else if (id === 'new') {
      let startSchedule = [];

      [...Array(7)].map((x, i) => {
        let active = true;
        let timeFrom = new Date('0001-01-01T11:00:00.000Z');
        let timeTo = new Date('0001-01-01T17:00:00.000Z');
        let timeLimit = new Date('0001-01-01T15:00:00.000Z');

        if (i == 5) {
          timeFrom = new Date('0001-01-01T09:00:00.000Z');
          timeTo = new Date('0001-01-01T15:00:00.000Z');
          timeLimit = new Date('0001-01-01T11:00:00.000Z');
        }
        if (i == 6) {
          active = false;
          timeFrom = new Date('0001-01-01T18:00:00.000Z');
          timeTo = new Date('0001-01-01T21:00:00.000Z');
          timeLimit = new Date('0001-01-01T15:00:00.000Z');
        }

        let shippingTime = {
          shippingTimeId: i,
          shippingTimeActive: active,
          shippingTimeDay: i,
          shippingTimeTimeFrom: timeFrom,
          shippingTimeTimeTo: timeTo,
          shippingTimeTimeLimit: timeLimit,
          shippingTimeNoSameDayDelivery: false
        };

        startSchedule.push(shippingTime);
      });

      setShippingTimeShipLimitPerInterval(10);
      setShippingTimeDeliveryInterval(120);
      setShippingTimeSchedule(startSchedule);

      return;
    }

    try {
      setBaseDataFetched(true);
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: id,
          qShopID: shippingTimeShopId
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/ShopSchedule/`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        setShippingTime(data);

        setShippingTimeId(data.data.ID);

        setShippingTimeTitle(data.data.Name);
        setShippingTimeShipLimitPerInterval(data.data.HourlyShipsLimit);
        setShippingTimeDeliveryInterval(data.data.DeliveryInterval);

        //setShippingTimeDateStartValue(data.data.StartDate);
        //setShippingTimeDateEndValue(data.data.EndDate);

        let currentShippingTimesToLoad = [];

        if (data.data.ShopShipTimes && data.data.ShopShipTimes.length > 0) {
          data.data.ShopShipTimes.map((e, index) => {
            let shippingTime = {
              shippingTimeId: e.ID,
              shippingTimeActive: e.Active,
              shippingTimeDay: e.Day,
              shippingTimeTimeFrom: e.TimeFrom,
              shippingTimeTimeTo: e.TimeTo,
              shippingTimeTimeLimit: e.TimeLimit,
              shippingTimeNoSameDayDelivery: e.NoSameDayDelivery
            };
            
            currentShippingTimesToLoad.push(shippingTime);
          });
        }
        setShippingTimeSchedule(currentShippingTimesToLoad);

      }
      else {
        setShippingTimeSchedule([]);
      }

      setLoading(false);
    }
    catch {
      //debugger;
      setShippingTime([]);
      setLoading(false);
      setShowLoader(false);
    }
  }

  async function saveShippingTime() {
    try {
      if (shippingTimeTitle !== null && shippingTimeTitle !== undefined) {
        setShippingTimeTitleCheck(true);

        if (String(shippingTimeTitle).trim().length > 3) {
          setShippingTimeTitleValid(true);
        }
        else {
          setShippingTimeTitleValid(false);
          return;
        }
      }

      setLoading(true);

      let shippingTimeEmpty = false;

      //build ProductSet
      let currentShippingTimesToSave = [];

      if (shippingTimeSchedule === null || shippingTimeSchedule === undefined || shippingTimeSchedule.length === 0) {
        shippingTimeEmpty = true;
      }
      else {
        shippingTimeSchedule.map((e, index) => {
          let ProductsSets = {
            Day: e.shippingTimeDay,
            Active: e.shippingTimeActive,
            TimeFrom: e.shippingTimeTimeFrom,
            TimeTo: e.shippingTimeTimeTo,
            TimeLimit: e.shippingTimeTimeLimit,
            NoSameDayDelivery: e.shippingTimeNoSameDayDelivery
          };

          currentShippingTimesToSave.push(ProductsSets);
        });
      }

      if (shippingTimeEmpty) {
        setErrorShippingTimesEmpty(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorShippingTimesEmpty(false);
      }

      //let startDate = shippingTimeDateStartValue
      let currentIdForSaving = (id == 'new') ? null : id;

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: currentIdForSaving,
          qShopID: shippingTimeShopId,
          qName: shippingTimeTitle,
          qHourlyShipsLimit: shippingTimeShipLimitPerInterval,
          qDeliveryInterval: shippingTimeDeliveryInterval,
          qShopShipTimes: currentShippingTimesToSave,
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/SaveShopShipTime`, requestOptions);

        const data = await response.json();

        if (data.success == true) {
          history.push('/shippingtimes');
        }
      }
      else {
        setShippingTime({});
      }

      setLoading(false);
    }
    catch {
      //debugger;
      setShippingTime({});
      setLoading(false);
      setShowLoader(false);
    }
  }

  const handleShippingTimeChangeSchedule = (shippingTimes) => {
    //  console.log(shippingTimes);
    setShippingTimeSchedule(shippingTimes);
  };

  const renderSchedule = () => {
    //console.log(shippingTimeSchedule);
    return (
      <ShippingTimeSchdule
        key={shippingTimeSchedule.shippingTimeId}
        currentSchduleId={shippingTimeSchedule.shippingTimeId}
        shippingTimeSchdule={shippingTimeSchedule}
        handleSchduleChange={handleShippingTimeChangeSchedule}
      />
    );
  };

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setShippingTimeShopId(currentShop);
              //setShippingTimeString(currentSearchString);

              return (
                <div className={styles.shippingTimeEditContainer}>
                  <div className={styles.shippingTimeEditTitleText + " " + (getCurrentLng() === 'en' ? "" : styles.shippingTimeEditTitleTextRtl)}>
                    {t('shippingTimeEdit.editTitle')}
                    <button type="button" className={"btn-close " + (getCurrentLng() === 'en' ? styles.topCloseButton : styles.topCloseButtonRtl)} aria-label="Close"
                      onClick={(e) => {
                        history.push('/shippingTimes');
                      }}></button>
                  </div>

                  <div className={styles.shippingTimeEditTitle}>
                  </div>
                  <Container className={styles.shippingTimeEdit + " " + (getCurrentLng() === 'en' ? "" : styles.shippingTimeEditRtl)}>
                    <Row className={styles.shippingTimeEditExplanationRow}>
                      <Col lg="12" xs="12">
                        {t('shippingTimeEdit.topExplanation')}
                      </Col>
                    </Row>

                    <Row className={styles.shippingTimeEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('shippingTimeEdit.title')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.shippingTimeEditControlsCol : styles.shippingTimeEditControlsColRtl)}>
                        <input maxLength="250" className={styles.shippingTimeEditInputField + " " + (getCurrentLng() === 'en' ? null : styles.shippingTimeEditInputFieldRtl)} value={shippingTimeTitle} onChange={(e) => {
                          setShippingTimeTitle(e.target.value);
                        }} />
                        {shippingTimeTitleCheck && ((shippingTimeTitleValid) ? null :
                          <div className={styles.shippingTimeEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.shippingTimeEditInputValidationMessageRtl)}>{t("shippingTimeEdit.validationMessageTitle")}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className={styles.shippingTimeEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('shippingTimeEdit.deliveryInterval')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.shippingTimeEditControlsCol : styles.shippingTimeEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"deliveryInterval"}
                          title={t('shippingTimeEdit.deliveryIntervalPlaceHolder')}
                          data-productid={shippingTimeId}
                          name="deliveryInterval"
                          placeholder={t('shippingTimeEdit.deliveryIntervalPlaceHolder')}
                          onChange={(value) => {
                            setShippingTimeDeliveryInterval(Number(value));
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={shippingTimeDeliveryInterval}
                          pattern='[0-9]{0,5}'
                          itemId={shippingTimeId}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.shippingTimeEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('shippingTimeEdit.shipLimitPerInterval')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.shippingTimeEditControlsCol : styles.shippingTimeEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"shipLimitPerInterval"}
                          title={t('shippingTimeEdit.shipLimitPerIntervalPlaceHolder')}
                          data-productid={shippingTimeId}
                          name="shipLimitPerInterval"
                          placeholder={t('shippingTimeEdit.shipLimitPerIntervalPlaceHolder')}
                          onChange={(value) => {
                            setShippingTimeShipLimitPerInterval(Number(value));
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={shippingTimeShipLimitPerInterval}
                          pattern='[0-9]{0,5}'
                          itemId={shippingTimeId}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.shippingTimeEditRow}>
                      {shippingTimeSchedule && shippingTimeSchedule.length > 0 && renderSchedule()}
                    </Row>

                    <Row className={styles.lastShippingTimeEditRow}>
                      {/*{specialOfferDatesValueCheck && ((specialOfferDatesValid) ? null :*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>{t("specialOffersEdit.validationMessageSpecialOfferDates")}</div>*/}
                      {/*)}*/}
                      {/*{errorProductSetEmpty ?*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>*/}
                      {/*    {t("specialOffersEdit.errorProductSetEmpty")}*/}
                      {/*  </div>*/}
                      {/*  : null*/}
                      {/*}*/}
                      {/*{errorNoCondition ?*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>*/}
                      {/*    {t("specialOffersEdit.errorNoCondition")}*/}
                      {/*  </div>*/}
                      {/*  : null*/}
                      {/*}*/}
                      {/*{errorNoReward ?*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>*/}
                      {/*    {t("specialOffersEdit.errorNoReward")}*/}
                      {/*  </div>*/}
                      {/*  : null*/}
                      {/*}*/}
                      {/*{errorConditionWithoutSet ?*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>*/}
                      {/*    {t("specialOffersEdit.errorConditionWithoutSet")}*/}
                      {/*  </div>*/}
                      {/*  : null*/}
                      {/*}*/}
                      {/*{errorRewardWithoutSet ?*/}
                      {/*  <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>*/}
                      {/*    {t("specialOffersEdit.errorRewardWithoutSet")}*/}
                      {/*  </div>*/}
                      {/*  : null*/}
                      {/*}*/}
                      <Col lg="12" sm="12" xs="12">
                        <Button
                          color="link"
                          className={styles.shippingTimeEditBtn}
                          data-productid={shippingTimeId}
                          name="Edit"
                          value={shippingTimeId}
                          disabled={shippingTime == null} //|| productPrice === 0
                          onClick={(e) => { saveShippingTime(); }}
                        >{(shippingTimeId == 0) ? t('shippingTimeEdit.addShippingTimeToShopBtn') : t('shippingTimeEdit.saveShippingTimeToShopBtn')}</Button>
                      </Col>
                    </Row>
                  </Container>

                  <ErrorMessageCustomPopup show={shippingTimeEditError} title={shippingTimeEditErrorMessage} onClose={(e) => { setShippingTimeEditError(false); }} />
                  <LoaderAnimation show={showLoader} allowClose={false} />
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}
    </userContext.Consumer>
  )
}
export default ShippingTimeEdit