import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import ProductImageListItem from './ProductImageListItem';
import styles from './product-imagelist.module.scss';

const ProductImageList = (props) => {
  const { t, i18n } = useTranslation('common');
  const [images, setImages] = useState(props.images)

  const moveUp = index => {

  }

  const moveDown = index => {

  }

  return (
    <Container className={styles.ProductImageList + " " + (getCurrentLng() === 'en' ? "" : styles.ProductImageListRtl)}>
      {props.images.map((image, index) => {
        return (<ProductImageListItem image={image} index={index} onUp={moveUp} onDown={moveDown} key={index} />)
      })}
    </Container>
  )
}

export default ProductImageList