import React, { useState, useEffect } from 'react';
import { userContext } from '../../Context/userContext';
import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { getCurrentLng } from '../../i18next_fun';

import { NavLink, Button, ButtonGroup, Form, FormGroup, Input, Label, Container, Row, Col, Card, CardImg, CardTitle, CardText, CardFooter, ModalHeader, ModalBody } from "reactstrap";
import styles from './login.module.scss';

function Login(props) {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  //const [user, setUser] = useState({ user: {} });
  let loading = false;
  const [loginError, setLoginError] = useState(0);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    document.title = t('nav.login');
  }, []);

  function onKeyUp(event) {
    if (event.charCode === 13) {
      
    }
  }

  return (
    <userContext.Consumer>

      {({ user, loginUser }) => {


        function onKeyUp(event) {
          if (event.charCode === 13) {
            handleLogin(event, emailOrPhone, password);
          }
        }

        const handleLogin = async (e, emailOrPhone, password) => {
          if (e && e != null)
            e.preventDefault();

          loginUser(e, emailOrPhone, password).then((loginResults) => {
            //console.log(loginResults);
            setLoginError(loginResults);

            if (loginResults === 0) {
              const history = props;
              if (history) props.history.push('/orders');
            }
          }).catch((err) => { console.log('error: ' + err); });
        }
        return (
          <div className={styles.container + " " + styles.gradientBackground}>

            <div className={styles.tomatos}>
              <img src="/assets/home_svg/tomatos.svg" />
            </div>

            <div className={styles.carrots}>
              <img src="/assets/home_svg/carrots.svg" />
            </div>

            <div className={styles.eggplants}>
              <img src="/assets/home_svg/eggplants.svg" />
            </div>

            <div className={styles.mushroom}>
              <img src="/assets/home_svg/mushroom.svg" />
            </div>

            <div className={styles.lettuce}>
              <img src="/assets/home_svg/lettuce.svg" />
            </div>

            <div className={styles.butternut}>
              <img src="/assets/home_svg/butternut.svg" />
            </div>

            <div className={styles.bellpepper}>
              <img src="/assets/home_svg/bellpepper.svg" />
            </div>

            <div className={styles.artichoke}>
              <img src="/assets/home_svg/artichoke.svg" />
            </div>

            <div className={styles.grapes}>
              <img src="/assets/home_svg/grapes.svg" />
            </div>

            <div className={styles.cherries}>
              <img src="/assets/home_svg/cherries.svg" />
            </div>

            <div className={styles.melon}>
              <img src="/assets/home_svg/melon.svg" />
            </div>

            <div className={styles.corn}>
              <img src="/assets/home_svg/corn.svg" />
            </div>

            <div className={styles.loginContainer + " " + (getCurrentLng() === 'en' ? "" : styles.loginRtl)}>
              {/*{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}*/}
              <form className={styles.loginFormContainer} onKeyPress={onKeyUp}>
                <h1>
                  {t('loginForm.title')}
                </h1>
                <div className={styles.loginForm}>
                  <div className={styles.loginFormItem}>
                    <input
                      type='text'
                      id='emailOrPhone'
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                      disabled={loading}
                      placeholder={t('loginForm.userName')}
                    />
                  </div>
                  <div className={styles.loginFormItem}>
                    <input
                      type='password'
                      id='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={loading}
                      placeholder={t('loginForm.password')}
                    />
                  </div>
                </div>
                <div className={styles.error}>
                  <span className={((loginError !== 1) ? styles.hidden : '')}>{t('errors.user_or_pass_incorrect')}</span>
                </div>
                <Button className={styles.loginFormBtn} onClick={(e) => handleLogin(e, emailOrPhone, password)} disabled={loading}>
                  {t('loginForm.loginBtnText')}
                </Button>
                <div className={styles.forgotPassword}>
                  <NavLink tag={Link} to="/forgot">{t('loginForm.forgotPassword')}</NavLink>
                </div>
              </form>
              <div className={styles.loginFormBottomText + " " + (getCurrentLng() === 'en' ? "" : styles.loginRtl)}>
                <div className={styles.loginFormBottomTextTitle}>
                  {t('loginForm.bottomTextTitle')}
                </div>
                <span className={styles.loginFormBottomTextLink}>
                  <a href="https://wa.me/+972523566096">
                    {t('loginForm.bottomTextLink')} <img src="/assets/home_svg/wa_logo.svg" />
                  </a>
                </span>
              </div>
              <div className={styles.loginFormLangSwitch + ' ' + (getCurrentLng() === 'en' ? null : styles.loginRtl)}>
                <ButtonGroup className={styles.btnGroup}>
                  <Button
                    data-filtervalue={1}
                    name="selectLanguage"
                    value="en"
                    className={styles.btn + " " + (getCurrentLng() === 'en' ? 'active' : '')}
                    onClick={() => { i18n.changeLanguage('en'); window.localStorage.setItem('i18nextLng', 'en'); }}
                  >{t('nav.en')}</Button>
                  <Button
                    data-filtervalue={2}
                    name="selectLanguage"
                    value="he"
                    className={styles.btn + " " + (getCurrentLng() === 'he' ? 'active' : '')}
                    onClick={() => { i18n.changeLanguage('he'); window.localStorage.setItem('i18nextLng', 'he'); }}
                  >{t('nav.he')}</Button>
                </ButtonGroup>
              </div>
            </div>

          </div>
        )
        //{(user && user.currentUser !== undefined) ? user.currentUser : ''}
      }}

    </userContext.Consumer>
  );
}

export default Login;
