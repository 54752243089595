import React, { Component, useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import { getCurrentLng } from '../i18next_fun';
import { useTranslation } from "react-i18next";

import styles from "./numericinputwithbuttons.module.scss";
import { propTypes } from "react-keyboard-event-handler";

const NumericInputWithButtons = (props) => {
  const handleFocus = (event) => {
    setEditingValue(true);
    if (event && event.target) { // && event.target.select
      //event.target.value = '';
      event.target.select();
    }
  }

  const handleKeyDown = event => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      if (props.disabled) {
        return false;
      }

      //console.log(event.target.dataset);
      //debugger;
      props.onChange(Number(event.target.value));
      props.onSubmit(event);
    }
  };

  const [editingValue, setEditingValue] = useState(false);

  const { t, i18n } = useTranslation('common');
  const defaultYesLabel = t('toggleSwitch.defaultYes');
  const defaultNoLabel = t('toggleSwitch.defaultNo');

  //if (!props.optionLabels || props.optionLabels.length == 0) {
  //  //optionLabels = {
  //  //  optionLabels: [defaultYesLabel, defaultNoLabel]
  //  //};
  //  props.optionLabels = [defaultYesLabel, defaultNoLabel];
  //}

  //onChange={(e) => onChange(e)}
  return (
    <React.Fragment>
      {((props.itemId !== undefined) ?
        <div className={styles.numericInputWithButtons + " " + (getCurrentLng() === 'en' ? "" : styles.numericInputWithButtonsRtl)}>
          <Button
            className={styles.numericInputFieldButton + " " + ((editingValue) ? styles.numericInputFieldButtonShown : null)}
            onClick={() => {
              //debugger;
              let currentValue = Number(props.value + 1).toFixed(2);
              if (currentValue < 0) currentValue = 0;
              props.onChange(currentValue);
            }}><Icon.Plus /></Button>
          <input
            id={props.id + "numeric" + props.itemId}
            title={props.title}
            name={props.id + "numeric" + props.itemId}
            type='number'
            className={styles.numericInputField}
            min={0}
            pattern={props.pattern}
            onInput={(e) => {
              //debugger;
              if (Number(e.target.value) !== NaN) {
                props.onChange(Number(e.target.value));
              }
            }}
            onFocus={(e) => { handleFocus(e); }}
            onKeyDown={(e) => { handleKeyDown(e); }}
            onChange={(e) => { }}
            value={props.value}
            data-itemid={props.itemId}
            disabled={props.disabled}
          />
          <Button
            className={styles.numericInputFieldButton + " " + ((editingValue) ? styles.numericInputFieldButtonShown : null)}
            onClick={() => {
              //debugger;
              let currentValue = Number(props.value - 1).toFixed(2);
              if (currentValue < 0) currentValue = 0;
              props.onChange(currentValue);
            }}><Icon.Dash /></Button>
        </div>
        :
        null
      )}
    </React.Fragment>
  );
};

// Set optionLabels for rendering.
//ToggleSwitch.defaultProps = {
//  optionLabels: ["Yes", "No"]
//};

NumericInputWithButtons.propTypes = {
  id: PropTypes.string.isRequired,
  //name: PropTypes.string,
  title: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  //onSubmit: PropTypes.func.isRequired,
  //optionLabels: PropTypes.array,
  //pattern: propTypes.string,
  disabled: PropTypes.bool
};

export default NumericInputWithButtons;