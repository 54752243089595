import React, { Component, useCallback, useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import ProductImageList from '../Pages/Products/ProductImageList';

import styles from './ImageUpload.module.scss';
import * as Icon from 'react-bootstrap-icons';

// for profile picture
const ImageUpload = (props) => {
  const { addFile } = props;
  const { files } = props;

  const [images, setImages] = useState(files)
  const [fileState, setFileState] = useState({ warningMsg: "" });

  const onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setFileState({ warningMsg: message });
    } else {
      addFile(accepted);
      setFileState({ warningMsg: "" });

      setImages(current => [...current, ...accepted.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )])

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then(value => {
        // console.log(value);
      });
    }
  };

  const thumbs = (files.length > 0) ? files.map(file => (
    <img className={styles.thumbsContainer} src={file.preview} alt="profile" />
  )) : '';

  //console.log(thumbs);

  const dropZoneArea = () => {
    const keysLength = Object.keys(files).length;
    //console.log(keysLength);
    return (
      //((keysLength && files && files.files.length > 0) > 0 ? (
      //  files.files.map(file =>
      //    <aside key={file.name}>
      //    {/*{thumbs}*/}
      //    {/*<img className={styles.thumbsContainer} src={file.preview} alt="profile" />*/}
      //    {file.type.includes('image') ? <img className={styles.thumbsContainer} src={file.preview} alt="profile" /> :
      //    <video className={styles.thumbsContainer} src={file.preview} autoPlay="autoplay" loop="loop" controls></video>}
      //  </aside>)
      //) : (
          <div className={styles.dropZone}>
            <div className={styles.dropZoneBtn}>העלה תמונה <img src="/assets/products/upload.svg" /></div>
            <span className={styles.dropZoneText}>או גרור מתיקייה</span>
          </div>
      //))
    );
  };

  return (
    <div className={props.className}>
      <p>{fileState.warningMsg}</p>
      
      {/*<Dropzone*/}
      {/*  className={styles.dropZone}*/}
      {/*  multiple={false}*/}
      {/*  accept="image/*"*/}
      {/*  onDrop={(accepted, rejected) => onDrop(accepted, rejected)}*/}
      {/*>*/}
      {/*  {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {*/}
      {/*    // for drag and drop warning statement*/}
      {/*    if (isDragReject) return "Please submit a valid file";*/}
      {/*    { return dropZoneArea() }*/}
      {/*  }}*/}
      {/*</Dropzone>*/}
      <ProductImageList images={images} />
      <Dropzone accept="image/*,video/*" multiple={true} onDrop={(accepted, rejected) => onDrop(accepted, rejected)}>
        {({ getRootProps, getInputProps }) => (
          <section className={styles.dropZoneContainer}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}
              {dropZoneArea()}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default ImageUpload;
