import React, { Component, useCallback, useEffect, useState } from 'react';
//import { Route } from 'react-router';
import Layout from './Components/Layout';
import packageJson from '../package.json';

import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from 'react-router-dom';
import routes from './Config/routes.js';

import { getShopsList } from './API/shop';

import { userContext } from './Context/userContext';
import { layoutContext } from './Context/layoutContext';
import AppRoute from './Components/AppRoute';
import { loginUser, logoutUser, isLoggedIn, sendForgotPassword, resetPassword } from './Context/loginActions';
import { I18nextProvider, useTranslation } from "react-i18next";
//import i18next from "i18next";
import i18next from "./i18n";

import styles from './custom.scss';

function App() {
  //console.log(props);
  const [appUser, setAppUser] = useState({
    currentUser: '',
    token: null,
  });

  const [appShop, setAppShop] = useState({
    currentShop: null,
  });

  const [currentSearchString, setCurrentSearchString] = useState("");

  useEffect(() => {
    handleIsLoggedIn().then((results) => {
      if (results) {

      }
    });
  }, []);

  useEffect(() => {
    caching();
  }, []);

  useEffect(() => {
    setDefaultShop();

    return function clearShop() {
      setAppShop({ currentShop: null });
    }
  }, [appUser]);

  const setDefaultShop = async () => {
    let shopsList = await getShopsList(appUser);
    if (shopsList.length == 0) {
      console.log('no shops available!');
      return;
    }

    if (window !== 'undefined' && window.localStorage.getItem('currentshop')) {
      const localShop = localStorage.getItem('currentshop');
      if (localShop != null && localShop != undefined) {
        const localSelected = shopsList.filter(e => e.id == Number(localShop));
        if (localSelected && localSelected.length > 0) {
          console.log('Selected Shop From LocalStorage ID: ' + localShop);
          handleShopChange(Number(localShop));
          return;
        }
      }
    }

    //console.log(shopsList);
    if (shopsList && shopsList.length == 1) {
      console.log('Selected Shop ID: ' + shopsList[0].id + ', Name: ' + shopsList[0].name);
      handleShopChange(shopsList[0].id);
    }
    else {
      console.log('Selected All Shops');
      handleShopChange(0);
    }
  }

  const handleIsLoggedIn = async () => {
    let response = await isLoggedIn();
    //console.log(response);
    if (response !== undefined && response.success !== undefined && response.success !== false && response.token.length > 0) {
      setAppUser(response);
    }
    else {
      setAppUser({
        currentUser: '',
        token: '',
      });
    }
  }

  const handleLogout = async () => {
    await logoutUser();
    setAppUser({
      currentUser: '',
      token: '',
    });
  }

  const handleLogin = async (e, emailOrPhone, password) => {
    if (e && e != null)
      e.preventDefault();

    try {
      let response = await loginUser({ emailOrPhone, password, 'rememberMe': true });
      if (response.success == false) {
        return 1;
      }
      if (!response.token) return 1;

      //console.log(response);
      setAppUser(response);
      return 0;

    } catch (error) {
      //console.log(error);
      return 1;
    }
  };

  const handleSendForgotPassword = async (e, Email) => {
    if (e && e != null)
      e.preventDefault();

    try {
      let response = await sendForgotPassword({ Email });
      if (response.success == false) {
        return 1;
      }
      //if (!response.token) return 1;

      //console.log(response);
      return 0;

    } catch (error) {
      //console.log(error);
      return 1;
    }
  };

  const handleResetPassword = async (e, Email, Password, ConfirmPassword, Code) => {
    if (e && e != null)
      e.preventDefault();

    try {
      let response = await resetPassword({ Email, Password, ConfirmPassword, Code });
      if (response.success == false) {
        return 1;
      }
      //if (!response.token) return 1;

      //console.log(response);
      return 0;

    } catch (error) {
      //console.log(error);
      return 1;
    }
  };

  const handleShopChange = (value) => {
    //console.log(value);
    if (window !== 'undefined' && window.localStorage) {
      localStorage.setItem('currentshop', value);
    }
    setAppShop({
      currentShop: value
    });
  };

  const handleSearchInputChange = (value) => {
    //e.preventDefault();
    setCurrentSearchString(value);
  };

  const handleSearchSubmit = (value) => {
    //e.preventDefault();
    setCurrentSearchString(value);
  };

  const userValue = {
    user: appUser,
    setUser: setAppUser,
    logoutUser: handleLogout,
    loginUser: handleLogin,
    isLoggedIn: handleIsLoggedIn,
    sendForgotPassword: handleSendForgotPassword,
    resetPassword: handleResetPassword
  };

  const layoutValue = {
    onHandleShopChange: handleSearchInputChange,
    onHandleSearchInputChange: handleSearchInputChange,
    onHandleSearchSubmit: handleSearchSubmit,
    currentSearchString: currentSearchString,
    currentShop: appShop.currentShop,
    onHandleShopChange: handleShopChange
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log('Complete Cache Cleared');
  };

  const caching = () => {
    let version = localStorage.getItem('version');
    console.log("current: " + packageJson.version + ", local: " + version);

    if (version != packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  return (
    <I18nextProvider i18n={i18next}>
      <userContext.Provider value={userValue}>
        <layoutContext.Provider value={layoutValue}>
          <Router>
            <Layout>
              <Switch>
                {(appUser && appUser.token !== null) && routes.map((route) => {
                  return (<AppRoute
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    isPrivate={route.isPrivate}
                  />)
                })}
              </Switch>
            </Layout>
          </Router>
        </layoutContext.Provider>
      </userContext.Provider>
    </I18nextProvider>
  );
}
export default App;