import { post } from 'jquery';
import React, { Component, useCallback, useEffect, useState } from 'react';
import { Row, Container } from 'reactstrap';

import useInfiniteScroll from '../../Components/InfiniteScroll';
import ProductCard from './ProductCard';
import ProductStockFilters from './ProductStockFilters';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './product-replacement.module.css';

const ProductReplacement = ({ onHandleReplacement, shopId, chosenShopId, initialSearchText, initialReplaceProductId }) => {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useInfiniteScroll(populateProductsData, '.modal-body', '#infiniteScrollQueue');
  //const [loading, setLoading] = useInfiniteScroll(populateProductsData);
  const [productSearchString, setProductSearchString] = useState('');
  const [user, setUser] = useState({});

  const [productsReachedEnd, setProductsReachedEnd] = useState(false);

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    if (initialSearchText && initialSearchText.length > 0) {
      setProductSearchString(initialSearchText);
    }
  }, []);

  useEffect(() => {
    setProductsReachedEnd(false);
    setProducts([]);
    optimizedGetProducts();
    return function cleanUp() {
      setProducts([]);
    };
  }, [shopId, productSearchString]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProducts([]);
    populateProductsData();
    //const { productName, SKU } = searchState;
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setProductSearchString(value);
  };

  const handleReplaceProduct = (newProdPID, quantityOrdered, unitMeasureId) => {
    let thisProduct = products.filter(p => p.ID == newProdPID);
    if (thisProduct.length > 0) {
      //thisProduct[0].Quantity = newStockValue;

      //setProducts(
      //  //products.map(el => (el.ID === newPricePID ? Object.assign({}, thisProduct[0]) : el))
      //  products.map(el => (el.ID === newStockPID ? Object.assign({}, el, { Quantity: newStockValue }) : el))
      //);

      //updateProductValue("Quantity", newStockValue, newStockPID);

      //console.log(thisProduct[0]);
      //console.log(initialSearchSKU);
      //console.log(initialReplaceProductId);
      onHandleReplacement(initialReplaceProductId, newProdPID, quantityOrdered, unitMeasureId); //unitMeasure
    }
  };

  async function populateProductsData() {
    //console.log('fetching');

    if (!productsReachedEnd) {
      setLoading(true);

      let currentShopId = shopId;
      if (chosenShopId && chosenShopId > 0) {
        currentShopId = chosenShopId;;
      }
      try {

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: products.length, pageSize: 20, qString: productSearchString, qShopID: currentShopId, qStockFilter: 2 })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/ListProducts`, requestOptions);

          const data = await response.json();

          if (data.data.length === 0) {
            setProductsReachedEnd(true);
            setLoading(false);
          }

          const fullProductsArr = [...products, ...data.data];

          //setProducts(prevState => ([...prevState, ...data.data]));
          setProducts(fullProductsArr);
        }
        else {
          setProducts([]);
        }
        setLoading(false);
      }
      catch {
        setLoading(false);
      }
    }
  }

  const optimizedGetProducts = useCallback(debounce(populateProductsData, null), []);
  const optimizedReplaceProduct = useCallback(debounce(handleReplaceProduct, null), [products]);


  const renderProductsTable = () => {
    return (
      <div className={"container " + styles.modalProductContainer}>
        <Row xs={1} md={1} className={"g-4 " + styles.modalProductRow}>
          {products.map(product => (product.ID !== undefined) ?
            <ProductCard
              onHandleReplacement={(e, addedQuantityOrdered, addedUnitMeasureId) => optimizedReplaceProduct(Number(e.target.getAttribute('data-itemid')), Number(addedQuantityOrdered), Number(addedUnitMeasureId))}
              isReplacement={true}
              isAddProduct={false}
              product={product}
              key={"replacer_" + product.ID}
            /> : null)}
        </Row>
      </div>
    );
  }

  let contents = loading && !productsReachedEnd
    ? <p><em>Loading...</em></p>
    : '';

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              //setProductSearchString(currentSearchString);

              return (
                <div id="infiniteList">
                  <div className={styles.modalSearchContainer}>
                    <input value={productSearchString} onChange={(e) => { setProductSearchString(e.target.value); }} className={styles.modalSearchInput + " " + (getCurrentLng() === 'en' ? "" : styles.modalSearchInputRtl)} />
                    <div className={styles.modalSearchIcon + " " + (getCurrentLng() === 'en' ? "" : styles.modalSearchIconRtl)}></div>
                  </div>
                  <div className={styles.modalSearchResultsTitle}>
                    {t('productReplacement.searchResults')}
                  </div>
                  {/*<h1 id="tabelLabel" >{t('nav.products')}</h1>*/}

                  {/*<ProductStockFilters*/}
                  {/*  {...searchState}*/}
                  {/*  shops={null}*/}
                  {/*  shopId={shopId}*/}
                  {/*  chosenShopId={chosenShopId}*/}
                  {/*  onHandleInputChange={(e) => handleInputChange(e, user)}*/}
                  {/*  onHandleSubmit={(e) => handleSubmit(e, user)}*/}
                  {/*/>*/}

                  {products && products.length > 0 && renderProductsTable()}
                  {contents}
                  <div className={styles.infiniteScrollQueue} id="infiniteScrollQueue"></div>
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );

}
export default ProductReplacement
