import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { layoutContext } from '../Context/layoutContext';

import 'bootstrap/scss/bootstrap.scss';

import styles from './layout.module.scss';
import NavMenu from './NavMenu';

const Layout = (props) => {
  //const { onHandleSearchInputChange, onHandleSearchSubmit, currentSearchString } = value;
  const displayName = Layout.name;
  const { t, i18n } = useTranslation('common');

  return (
    <div>
      <NavMenu {...props} />
      <div className={styles.wrapper}>
        {props.children}
      </div>
    </div>
  );
}
export default Layout;