import React, { Component, useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import ProductSetSearch from './ProductSetSearch';
import ProductInShopSearch from '../Products/ProductInShopSearch';
import BrandSearch from '../Products/BrandSearch';

import NumericInput from 'react-numeric-input';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './specialoffer-reward.module.scss';
import * as Icon from 'react-bootstrap-icons';

const SpecialOfferReward = (props) => {
  const { currentRewardId } = props;

  const { handleRewardRemove } = props;
  const { handleRewardChange } = props;

  const { specialOfferReward } = props;
  const { specialOfferProductSets } = props;

  const [user, setUser] = useState({});
  const { t, i18n } = useTranslation('common');

  const [currentRewardIdInSpecialOffer, setCurrentRewardIdInSpecialOffer] = useState(0);

  const [currentRewardPropertiesForEdit, setCurrentRewardPropertiesForEdit] = useState({});

  //const [currentRewardType, setCurrentRewardType] = useState(-1);
  const [currentRewardTypeName, setCurrentRewardTypeName] = useState('');

  const [currentRewardProductSets, setCurrentRewardProductSets] = useState([]);
  const [currentRewardProductSetsOpen, setCurrentRewardProductSetsOpen] = useState(false);

  const [specialOfferRewardProductSetId, setSpecialOfferRewardProductSetId] = useState(0);
  const [specialOfferRewardProductSetName, setSpecialOfferRewardProductSetName] = useState('');
  const [specialOfferRewardProductSetDescription, setSpecialOfferRewardProductSetDescription] = useState('');
  //const [specialOfferRewardProductSetSuggestion, setSpecialOfferRewardProductSetSuggestion] = useState([]);

  const [specialOfferRewardDiscount, setSpecialOfferRewardDiscount] = useState(0);
  const [specialOfferRewardNewPrice, setSpecialOfferRewardNewPrice] = useState(0);
  const [specialOfferRewardAmount, setSpecialOfferRewardAmount] = useState(0);

  const [currentRewardCardOpen, setCurrentRewardCardOpen] = useState(false);

  const [currentRewardCardShowProductSet, setCurrentRewardCardShowProductSet] = useState(false);
  const [currentRewardCardShowDiscount, setCurrentRewardCardShowDiscount] = useState(false);
  const [currentRewardCardShowNewPrice, setCurrentRewardCardShowNewPrice] = useState(false);
  const [currentRewardCardShowAmount, setCurrentRewardCardShowAmount] = useState(false);
  const [currentRewardCardShowBundle, setCurrentRewardCardShowBundle] = useState(false);

  const [allRewardTypes, setAllRewardTypes] = useState([]);
  const [allRewardTypesDropDownOpen, setAllRewardTypesDropDownOpen] = useState(false);

  useEffect(() => {
    //document.title = t('nav.specialOfferEdit');
    getSpecialOfferRewardTypes();

    return function clear() {
      setAllRewardTypes([]);
    }
  }, [user]);

  useEffect(() => {
    if (currentRewardId && currentRewardId !== 0) {
      setCurrentRewardIdInSpecialOffer(currentRewardId);

      setCurrentRewardPropertiesForEdit(specialOfferReward);
      //console.log(currentRewardId);
    }

    return function clear() {
      setCurrentRewardIdInSpecialOffer(0);
      setCurrentRewardPropertiesForEdit({});
    }
  }, [currentRewardId]);

  useEffect(() => {
    if (specialOfferReward === undefined ||
      (specialOfferReward
      && Object.keys(specialOfferReward).length === 0
      && Object.getPrototypeOf(specialOfferReward) === Object.prototype)) {
      //return;
      //do nothing;
    }
    else {
      //console.log(specialOfferReward);
      setCurrentRewardTypeName(specialOfferReward.type);
      //let currentType = allRewardTypes.indexOf(specialOfferReward.type + "ViewModel");
      //setCurrentRewardType(currentType);
      setSpecialOfferRewardProductSetId(specialOfferReward.productSetId);
      setSpecialOfferRewardProductSetName(specialOfferReward.productSetName);
      setSpecialOfferRewardProductSetDescription(specialOfferReward.productSetDescription);

      //let currentSet = {
      //  id: specialOfferReward.productSetId,
      //  name: specialOfferReward.productSetName,
      //  text: specialOfferReward.productSetDescription
      //}

      //let currentRewardProductSetSuggestion = [];
      //currentRewardProductSetSuggestion.push(currentSet);
      //setSpecialOfferRewardProductSetSuggestion(currentRewardProductSetSuggestion);

      setSpecialOfferRewardDiscount(specialOfferReward.discount);

      setSpecialOfferRewardNewPrice(specialOfferReward.newPrice);

      setSpecialOfferRewardAmount(specialOfferReward.amount);
    }

    return function clear() {
      //setCurrentRewardType(-1);
      setCurrentRewardTypeName('');
    }
  }, [currentRewardPropertiesForEdit, allRewardTypes]);

  useEffect(() => {
    if (specialOfferProductSets === undefined ||
      (specialOfferProductSets && specialOfferProductSets.length === 0)) {
      //return;
      //do nothing;
    }
    else {
      //console.log(specialOfferProductSets);
      setCurrentRewardProductSets(specialOfferProductSets);

      if (specialOfferRewardProductSetId != 0) {
        let currentSet = specialOfferProductSets.find(e => e.productSetId == specialOfferRewardProductSetId);
        //console.log(currentSet);
        if (currentSet) {
          setSpecialOfferRewardProductSetName(currentSet.productSetName);
        }
      }
    }

  }, [specialOfferProductSets]);

  useEffect(() => {
    //if (currentRewardType !== null && currentRewardType !== undefined && currentRewardType !== -1) {
      //switch (currentRewardType) {
    if (currentRewardTypeName !== null && currentRewardTypeName !== undefined && currentRewardTypeName !== '') {
      switch (currentRewardTypeName) {
        case 'RewardAbsoluteDiscountForAnyProductFromProductSet':
        default:
          {
            setCurrentRewardCardShowProductSet(true);
            setCurrentRewardCardShowDiscount(true);
            setCurrentRewardCardShowNewPrice(false);
            setCurrentRewardCardShowAmount(false);
            setCurrentRewardCardShowBundle(false);

            //setSpecialOfferRewardProductSetId(0);
            //setSpecialOfferRewardProductSetName('');
            //setSpecialOfferRewardDiscount(0);
            setSpecialOfferRewardNewPrice(0);
            setSpecialOfferRewardAmount(0);
          }
          break;
        case 'RewardAbsoluteDiscountForWholeCart':
          {
            setCurrentRewardCardShowProductSet(false);
            setCurrentRewardCardShowDiscount(true);
            setCurrentRewardCardShowNewPrice(false);
            setCurrentRewardCardShowAmount(false);
            setCurrentRewardCardShowBundle(false);

            setSpecialOfferRewardProductSetId(0);
            setSpecialOfferRewardProductSetName('');
            //setSpecialOfferRewardDiscount(0);
            setSpecialOfferRewardNewPrice(0);
            setSpecialOfferRewardAmount(0);
          }
          break;
        case 'RewardGetAnyProductFromProductSetForNewPrice':
          {
            setCurrentRewardCardShowProductSet(true);
            setCurrentRewardCardShowDiscount(false);
            setCurrentRewardCardShowNewPrice(true);
            setCurrentRewardCardShowAmount(false);
            setCurrentRewardCardShowBundle(false);

            //setSpecialOfferRewardProductSetId(0);
            //setSpecialOfferRewardProductSetName('');
            setSpecialOfferRewardDiscount(0);
            //setSpecialOfferRewardNewPrice(0);
            setSpecialOfferRewardAmount(0);
          }
          break;
        case 'RewardGetExactAmountProductsFromProductSetForNewPrice':
          {
            setCurrentRewardCardShowProductSet(true);
            setCurrentRewardCardShowDiscount(false);
            setCurrentRewardCardShowNewPrice(true);
            setCurrentRewardCardShowAmount(true);
            setCurrentRewardCardShowBundle(false);

            //setSpecialOfferRewardProductSetId(0);
            //setSpecialOfferRewardProductSetName('');
            setSpecialOfferRewardDiscount(0);
            //setSpecialOfferRewardNewPrice(0);
            //setSpecialOfferRewardAmount(0);
          }
          break;
        //case 'RewardGetProductBundleForFree':
        //  {
        //    setCurrentRewardCardShowProductSet(false);
        //    setCurrentRewardCardShowDiscount(false);
        //    setCurrentRewardCardShowNewPrice(false);
        //    setCurrentRewardCardShowAmount(false);
        //    setCurrentRewardCardShowBundle(true);

        //    //setSpecialOfferRewardProductSetId(0);
        //    //setSpecialOfferRewardProductSetName('');
        //    setSpecialOfferRewardDiscount(0);
        //    setSpecialOfferRewardNewPrice(0);
        //    //setSpecialOfferRewardAmount(0);
        //  }
        //  break;
        //case 'RewardGetProductBundleForNewPrice':
        //  {
        //    setCurrentRewardCardShowProductSet(false);
        //    setCurrentRewardCardShowDiscount(false);
        //    setCurrentRewardCardShowNewPrice(true);
        //    setCurrentRewardCardShowAmount(false);
        //    setCurrentRewardCardShowBundle(true);

        //    //setSpecialOfferRewardProductSetId(0);
        //    //setSpecialOfferRewardProductSetName('');
        //    setSpecialOfferRewardDiscount(0);
        //    setSpecialOfferRewardNewPrice(0);
        //    //setSpecialOfferRewardAmount(0);
        //  }
        //  break;
        case 'RewardGetYProductsForFreeFromProductSet':
          {
            setCurrentRewardCardShowProductSet(true);
            setCurrentRewardCardShowDiscount(false);
            setCurrentRewardCardShowNewPrice(false);
            setCurrentRewardCardShowAmount(true);
            setCurrentRewardCardShowBundle(false);

            //setSpecialOfferRewardProductSetId(0);
            //setSpecialOfferRewardProductSetName('');
            setSpecialOfferRewardDiscount(0);
            setSpecialOfferRewardNewPrice(0);
            //setSpecialOfferRewardAmount(0);
          }
          break;
        case 'RewardPercentDiscountForAnyProductFromProductSet':
          {
            setCurrentRewardCardShowProductSet(true);
            setCurrentRewardCardShowDiscount(true);
            setCurrentRewardCardShowNewPrice(false);
            setCurrentRewardCardShowAmount(false);
            setCurrentRewardCardShowBundle(false);

            //setSpecialOfferRewardProductSetId(0);
            //setSpecialOfferRewardProductSetName('');
            setSpecialOfferRewardDiscount(0);
            setSpecialOfferRewardNewPrice(0);
            setSpecialOfferRewardAmount(0);
          }
          break;
        case 'RewardPercentDiscountForWholeCart':
          {
            setCurrentRewardCardShowProductSet(false);
            setCurrentRewardCardShowDiscount(true);
            setCurrentRewardCardShowNewPrice(false);
            setCurrentRewardCardShowAmount(false);
            setCurrentRewardCardShowBundle(false);

            setSpecialOfferRewardProductSetId(0);
            setSpecialOfferRewardProductSetName('');
            //setSpecialOfferRewardDiscount(0);
            setSpecialOfferRewardNewPrice(0);
            setSpecialOfferRewardAmount(0);
          }
          break;
      }
    }
  }, [currentRewardTypeName]);

  useEffect(() => {
    handleCurrentRewardChange();
  }, [currentRewardIdInSpecialOffer,
    specialOfferRewardProductSetId,
    currentRewardTypeName,
    specialOfferRewardAmount,
    specialOfferRewardNewPrice,
    specialOfferRewardDiscount
  ]);

  //const handleProductSetSuggestionSelect = (event, name, id, text) => {
  //  //debugger;
  //  if (id && id !== 0) {
  //    //setSpecialOfferProductSetId(id);
  //    setSpecialOfferRewardProductSetId(id);
  //    setSpecialOfferRewardProductSetName(name);
  //    setSpecialOfferRewardProductSetDescription(text);
  //  }

  //  //if (id === 0) {
  //  //  setProductEnableOverrideGlobal(false);
  //  //}
  //};

  const toggleRewardsOpen = (e) => { setAllRewardTypesDropDownOpen(!allRewardTypesDropDownOpen); };

  const toggleCurrentRewardProductSets = (e) => { setCurrentRewardProductSetsOpen(!currentRewardProductSetsOpen); };
  
  const toggleSpecialOfferRewardCard = (e) => { setCurrentRewardCardOpen(!currentRewardCardOpen); };

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  function priceFormat(num) {
    //var nis = '\U020AA';
    //const nis = '&#8362;';
    //const nis = '\20AA';
    return String(num) + " ₪"; // + " " + { nis };
  }

  async function getSpecialOfferRewardTypes() {
    try {
      //setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/RewardTypesList`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        //debugger;
        if (data && data.data && data.data.length > 0) {
          setAllRewardTypes(data.data);
        }
      }
      else {
        //setSpecialOffer({});
      }

      //setLoading(false);
    }
    catch {
      //debugger;
      //setSpecialOffer({});
      //setLoading(false);
      //setShowLoader(false);
    }
  }

  const handleCurrentRewardChange = () => {

    let thisReward = {
      rewardId: currentRewardIdInSpecialOffer,
      productSetId: specialOfferRewardProductSetId,
      productSetName: specialOfferRewardProductSetName,
      productSetDescription: specialOfferRewardProductSetDescription,
      type: currentRewardTypeName,
      discount: specialOfferRewardDiscount,
      newPrice: specialOfferRewardNewPrice,
      amount: specialOfferRewardAmount
    };

    handleRewardChange(thisReward);
  };

  const renderAllRewardDropDown = () => {
    return (
      <DropdownMenu>
        {allRewardTypes.map((item, index) => {
          return (
            <DropdownItem value={index} key={"rewardType" + index} className={((currentRewardTypeName == String(item).split('ViewModel')[0]) ? "active" : "") + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferRewardsRtl)}
              onClick={(e) => {
                //setCurrentRewardType(index);
                let itemWithoutViewModel = item.split('ViewModel')[0]
                setCurrentRewardTypeName(itemWithoutViewModel);
              }}>
              {t('specialOffersEditReward.' + String(item).split('ViewModel')[0])}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    )
  }

  const renderCurrentRewardProductSetsDropDown = () => {
    //console.log(currentRewardProductSets);
    return (
      <DropdownMenu>
        {currentRewardProductSets.map((item, index) => {
          if (item !== 'RewardEmptyViewModel') {
            return (
              <DropdownItem value={index} key={"rewardProductSet" + index} className={((specialOfferRewardProductSetId == item.productSetId) ? "active" : "") + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferRewardsRtl)}
                onClick={(e) => {
                  setSpecialOfferRewardProductSetId(item.productSetId);
                  setSpecialOfferRewardProductSetName(item.productSetName);
                }}>
                {item.productSetName}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    )
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <Card className={styles.specialOfferCard + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL)}>
            <CardHeader className={styles.cardHeader}>
              <Row className={styles.cardRow}>
                <Col lg="2" xs="2" className={styles.cardRemoveIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardRemoveIconRTL)} onClick={(e) => {
                  if (e) {
                    //debugger;
                    handleRewardRemove(currentRewardIdInSpecialOffer);
                  }
                }}>
                  <Button
                    onClick={(e) => {
                      if (e) {
                        handleRewardRemove(currentRewardIdInSpecialOffer);
                      }
                    }}
                    color="link"
                    className={styles.productTrashBtn}
                    name="Edit"
                    value={currentRewardId}>
                    <Icon.Trash />
                  </Button>
                </Col>
                <Col lg="8" xs="8" className={styles.cardColumn + " " + styles.cardInfoContainer} onClick={(e) => { toggleSpecialOfferRewardCard(e) }} >
                  <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
                    {(currentRewardTypeName !== undefined && currentRewardTypeName !== '') ? t('specialOffersEditReward.' + currentRewardTypeName) : t('specialOffersEditReward.newReward')}
                  </CardTitle>
                </Col>
                <Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)} onClick={(e) => { toggleSpecialOfferRewardCard(e) }} >
                  {(currentRewardCardOpen) ?
                    <Icon.ChevronUp />
                    :
                    <Icon.ChevronDown />}
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={currentRewardCardOpen}>
              <CardBody className={styles.cardEditSectionWrapper}>
                <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL)}>
                  <Row className={styles.cardEditRow}>
                    <Col lg="12" xs="12" className={styles.cardEditCol}>

                      <Dropdown
                        isOpen={allRewardTypesDropDownOpen}
                        toggle={toggleRewardsOpen}
                        name={"productEditCategories" + currentRewardId}
                        placeholder={t('productEdit.categories')}
                        className={styles.RewardEditTypes}
                      >
                        <DropdownToggle caret
                          onClick={toggleRewardsOpen}
                          data-toggle={"allRewardTypesDropDownOpen"} aria-expanded={allRewardTypesDropDownOpen}
                          className={styles.specialOfferRewardsDropDown + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferRewardsRtl)}
                        >
                          {(currentRewardTypeName !== undefined && currentRewardTypeName !== '') ? t('specialOffersEditReward.' + currentRewardTypeName) : t('specialOffersEditReward.chooseReward')}
                        </DropdownToggle>

                        {allRewardTypes && allRewardTypes.length > 0 && renderAllRewardDropDown()}
                      </Dropdown>
                    </Col>
                  </Row>
                  {currentRewardCardShowProductSet ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditReward.editProductSetLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<ProductSetSearch defaultSuggestions={specialOfferRewardProductSetSuggestion} onHandleSelect={handleProductSetSuggestionSelect} controlId={"specialOfferRewardProductSet" + + currentRewardId} />*/}
                        <Dropdown
                          isOpen={currentRewardProductSetsOpen}
                          toggle={toggleCurrentRewardProductSets}
                          name={"currentRewardProductSets" + currentRewardId}
                          placeholder={t('productEdit.categories')}
                          className={styles.rewardEditTypes}
                        >
                          <DropdownToggle caret
                            onClick={toggleCurrentRewardProductSets}
                            data-toggle={"toggleCurrentRewardProductSets"} aria-expanded={currentRewardProductSetsOpen}
                            className={styles.specialOfferRewardsDropDown + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferRewardsRtl)}
                          >
                            {(specialOfferRewardProductSetId !== undefined && specialOfferRewardProductSetId !== 0) ?
                              specialOfferRewardProductSetName : t('specialOffersEdit.chooseProductSet')}
                          </DropdownToggle>

                          {renderCurrentRewardProductSetsDropDown()}
                        </Dropdown>
                      </Col>
                    </Row>
                    : null}
                  {currentRewardCardShowDiscount ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditReward.editDiscountLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        <input
                          name={"specialOfferRewardDiscount" + + currentRewardId}
                          value={specialOfferRewardDiscount}
                          onChange={(e) => {
                            setSpecialOfferRewardDiscount(e.target.value);
                          }} />
                      </Col>
                    </Row>
                    : null}
                  {currentRewardCardShowNewPrice ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditReward.editNewPriceLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<input*/}
                        {/*  name={"specialOfferRewardNewPrice" + + currentRewardId}*/}
                        {/*  value={specialOfferRewardNewPrice}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    setSpecialOfferRewardNewPrice(e.target.value);*/}
                        {/*  }} />*/}
                        <span className={styles.productEditPriceInputField}>
                          <NumericInput type="number"
                            title={t('specialOffersEditReward.editNewPriceLabel')}
                            name={"specialOfferRewardNewPrice" + + currentRewardId}
                            data-rewardid={currentRewardId}
                            id={"specialOfferRewardNewPrice" + + currentRewardId}
                            className={styles.productEditFormPriceField}
                            minimum="0.00"
                            step={1}
                            //precision={2}
                            value={specialOfferRewardNewPrice}
                            format={priceFormat}
                            mobile={"auto"}
                            //onFocus={handlePriceFieldFocus}
                            onChange={(e) => {
                              //if (Number(e) !== 0) {
                              setSpecialOfferRewardNewPrice(Number(e).toFixed(2));
                              //}
                            }}
                            onFocus={handleFocus}
                          />
                        </span>
                      </Col>
                    </Row>
                    : null}
                  {currentRewardCardShowAmount ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditReward.editAmountLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<input*/}
                        {/*  name={"specialOfferRewardAmount" + + currentRewardId}*/}
                        {/*  value={specialOfferRewardAmount}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    setSpecialOfferRewardAmount(e.target.value);*/}
                        {/*  }} />*/}

                        <NumericInputWithButtons
                          name={"specialOfferRewardAmount" + + currentRewardId}
                          id={"specialOfferRewardAmount" + + currentRewardId}
                          title={t('specialOffersEditReward.editAmountLabel')}
                          data-rewardid={currentRewardId}
                          placeholder={t('specialOffersEditReward.editAmountLabel')}
                          onChange={(value) => {
                            //if (Number(value) !== 0) {
                            //console.log(value);
                            setSpecialOfferRewardAmount(Number(value));
                            //}
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={specialOfferRewardAmount}
                          pattern='[0-9]{0,5}'
                          itemId={currentRewardId}
                        />
                      </Col>
                    </Row>
                    : null}
                  {currentRewardCardShowBundle ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditReward.editBundleLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>

                      </Col>
                    </Row>
                    : null}
                  

                  {/*<Row className={styles.cardEditRow}>*/}
                  {/*  <Col lg="6" xs="6" className={styles.cardEditCol}>*/}
                  {/*    {t('specialOffersEditReward.editProductSetLabel')}*/}
                  {/*  </Col>*/}
                  {/*  <Col lg="6" xs="6" className={styles.cardEditCol}>*/}

                  {/*  </Col>*/}
                  {/*</Row>*/}

                  {/*{currentRewardType}, {currentRewardTypeName}, {currentRewardIdInSpecialOffer}*/}
                </div>
              </CardBody>
            </Collapse>
          </Card>

        )
      }}
    </userContext.Consumer >
  );
}

export default SpecialOfferReward