import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink } from 'reactstrap';

import CustomPopup from '../../Components/CustomPopup';
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';
import ToggleSwitch from '../../Components/ToggleSwitch';

import ShippingTimeCard from './ShippingTimeCard';
import DeliveryZoneCard from './DeliveryZoneCard';
import useInfiniteScroll from '../../Components/InfiniteScroll';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './shippingtimes.module.scss';

const ShippingTimesList = (props) => {
  const [user, setUser] = useState({});
  const [shippingTimesShop, setShippingTimesShop] = useState(null);
  const [shippingTimesSearchString, setShippingTimesSearchString] = useState('');

  //const [shippingTimesReachedEnd, setShippingTimesReachedEnd] = useState(false);

  const [total, setTotal] = useState(0);

  const [isShippingEnabled, setIsShippingEnabled] = useState(null);
  const [isShippingEnabledUpdated, setIsShippingEnabledUpdated] = useState(null);

  const [shippingTimes, setShippingTimes] = useState(null);

  const [deliveryZones, setDeliveryZones] = useState(null);

  const [loading, setLoading] = useInfiniteScroll(populateShippingTimesData, null, '#infiniteScrollQueue');

  const [showLoader, setShowLoader] = useState(false);

  const [customModalMultipleShopsAlert, setCustomModalMultipleShopsAlert] = useState(false);

  const [shippingTimesError, setShippingTimesError] = useState(false);
  const [shippingTimesErrorMessage, setShippingTimesErrorMessage] = useState('');

  const [deliveryZonesError, setDeliveryZonesError] = useState(false);
  const [deliveryZonesErrorMessage, setDeliveryZonesErrorMessage] = useState('');

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  useEffect(() => {
    document.title = t('nav.shippingTimes');
  }, []);

  //useEffect(() => {
  //  if (shippingTimes == null || (loading && !shippingTimesReachedEnd)) {
  //    setShowLoader(true);
  //  }
  //  else {
  //    setShowLoader(false);
  //  }
  //}, [loading]);

  useEffect(() => {
    if (shippingTimesErrorMessage && shippingTimesErrorMessage.length > 0) {
      setShippingTimesError(true);
    }
  }, [shippingTimesErrorMessage]);

  useEffect(() => {
    setShippingTimes([]);
    //setShippingTimesReachedEnd(false);
    //optimizedGetShippingTimes();
    populateShippingSettingsData();
    populateShippingTimesData();
    populateDeliveryZonesData();

    return function cleanUp() {
      setShippingTimes([]);
      //setShippingTimesReachedEnd(false);
    };
  }, [shippingTimesShop, shippingTimesSearchString]);

  useEffect(() => {
    //console.log(productShop);
    if (shippingTimesShop != null) {
      if (Number(shippingTimesShop) == 0) { //layoutDataInitialized
        toggleMultipleShopsCustomModal();
      }
    }

  }, [shippingTimesShop]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const toggleMultipleShopsCustomModal = (e) => {
    setCustomModalMultipleShopsAlert(!customModalMultipleShopsAlert);
  }

  async function populateShippingSettingsData() {
    setShowLoader(true);
    try {
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qShopID: shippingTimesShop })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/IsShipping`, requestOptions);

        const data = await response.json();

        if (data.data && data.data.isShipping !== null && data.data.isShipping !== undefined) {
          setIsShippingEnabled(data.data.isShipping);
        }
      }
      else {
        setIsShippingEnabled(null);
      }
      setShowLoader(false);
    }
    catch {
      setIsShippingEnabled(null);
      setShowLoader(false);
    }
  }

  async function saveShippingSettingsData(isShippingEnabled) {
    setShowLoader(true);
    try {
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qShopID: shippingTimesShop, qIsShipping: isShippingEnabled })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/SaveIsShipping`, requestOptions);

        const data = await response.json();

        if (data.success !== undefined && data.success !== null) {
          setIsShippingEnabled(isShippingEnabled);
        }
      }
      else {
        //setIsShippingEnabled(null);
      }
      setShowLoader(false);
    }
    catch {
      //setIsShippingEnabled(null);
      setShowLoader(false);
    }
  }

  async function populateShippingTimesData() {
    //if (!shippingTimesReachedEnd) {
      //setLoading(true);
      setShowLoader(true);
      try {
        // const response = await fetch('weatherforecast');

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: shippingTimes.length, pageSize: 100, qString: shippingTimesSearchString, qShopID: shippingTimesShop })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/ListShopShipTimes`, requestOptions);

          const data = await response.json();

          setTotal(data.total);

          if (data.data.length === 0) {
            //setShippingTimesReachedEnd(true);
            //setLoading(false);
            setShowLoader(false);
          }

          //const fullShippingTimesArr = [...shippingTimes, ...data.data];
          //setShippingTimes(fullShippingTimesArr);

          setShippingTimes(data.data);
        }
        else {
          setShippingTimes([]);
        }
        //setLoading(false);
        setShowLoader(false);
      }
      catch {
        //setLoading(false);
        setShippingTimes([]);
        setShowLoader(false);
      }
    //}
    //else {
    //  //setLoading(false);
    //  setShowLoader(false);
    //}
  }

  async function populateDeliveryZonesData() {
    //if (!shippingTimesReachedEnd) {
      //setLoading(true);
      setShowLoader(true);
      try {
        // const response = await fetch('weatherforecast');

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: shippingTimes.length, pageSize: 100, qString: shippingTimesSearchString, qShopID: shippingTimesShop })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/ListMainShopDeliveryZones`, requestOptions);

          const data = await response.json();

          setTotal(data.total);

          if (data.data.length === 0) {
            //setShippingTimesReachedEnd(true);
            //setLoading(false);
            setShowLoader(false);
          }

          //const fullShippingTimesArr = [...shippingTimes, ...data.data];
          //setShippingTimes(fullShippingTimesArr);

          setDeliveryZones(data.data);
        }
        else {
          setDeliveryZones([]);
        }
        //setLoading(false);
        setShowLoader(false);
      }
      catch {
        //setLoading(false);
        setDeliveryZones([]);
        setShowLoader(false);
      }
    //}
    //else {
    //  //setLoading(false);
    //  setShowLoader(false);
    //}
  }

  const optimizedGetShippingTimes = useCallback(debounce(populateShippingTimesData, null), []);

  const renderShippingTimesTable = () => {
    return (
      <Container className={styles.container}>
        <Row xs={1} md={1} className={"g-4 " + styles.cardsWrapper}>
          {shippingTimes && shippingTimes.map(shippingTime => {
            return (
              <ShippingTimeCard
                shippingTime={shippingTime}
                key={shippingTime.ScheduleID}
              >{shippingTime}</ShippingTimeCard>
            );
          })}
        </Row>
      </Container>
    );
  }

  const renderDeliveryZonesTable = () => {
    return (
      <Container className={styles.container}>
        <Row xs={1} md={1} className={"g-4 " + styles.cardsWrapper}>
          {deliveryZones && deliveryZones.map(deliveryZone => {
            return (
              <DeliveryZoneCard
                deliveryZone={deliveryZone}
                key={deliveryZone.DeliveryZoneID}
              >{deliveryZone}</DeliveryZoneCard>
            );
          })}
        </Row>
      </Container>
    );
  }

  //let contents = loading && !shippingTimesReachedEnd
  //  ? <Container><em>Loading...</em></Container>
  //  : '';

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setShippingTimesShop(currentShop);
              setShippingTimesSearchString(currentSearchString);

              return (
                <div id="infiniteList">

                  <Container className={styles.container + " " + ((getCurrentLng() == 'en') ? null : styles.shippingTimeRtl)}>
                    <Row className={"g-4 " + styles.cardsWrapper}>
                      <Col lg={3} md={6} xs={6} className={styles.shippingSettingsCol}>
                        {t('shippingTimes.isShippingEnabled') }
                      </Col>
                      <Col lg={3} md={6} xs={6} >
                        {(isShippingEnabled === null || Number(shippingTimesShop) == 0) ?
                          <NavLink 
                            tag={Link}
                            to="/preferences" 
                            name="goToPerferences"
                          >{t('shippingTimes.pleaseChooseAShop')}</NavLink>
                          :
                          <ToggleSwitch
                            id={'editIsShippingEnabled'}
                            name={'editIsShippingEnabled'}
                            optionLabels={[]}
                            checked={isShippingEnabled}
                            itemId={shippingTimesShop}
                            data-id={shippingTimesShop}
                            onChange={(e, itemId) => {
                              saveShippingSettingsData(e);
                            }} />
                        } 
                      </Col>
                    </Row>
                    {renderShippingTimesTable()}
                    <Row>
                      {(shippingTimesShop && shippingTimesShop !== 0 && Number(shippingTimesShop) !== 0) ?
                        <Col lg="12">
                          <Button
                            tag={Link}
                            to={`${path}edit/new`}
                            className={styles.shippingTimeEditBtn}
                            name="addShippingTime"
                          >
                            {t('shippingTimes.addNewShippingTime')}
                          </Button>
                        </Col>
                        : null}
                    </Row>
                  </Container>

                  {/*{contents}*/}

                  <Container className={styles.container + " " + ((getCurrentLng() == 'en') ? null : styles.shippingTimeRtl)}>
                    {renderDeliveryZonesTable()}
                    <Row>
                      {(shippingTimesShop && shippingTimesShop !== 0 && Number(shippingTimesShop) !== 0) ?
                        <Col lg="12">
                          <Button
                            tag={Link}
                            to={`${path}deliveryzone/new`}
                            className={styles.shippingTimeEditBtn}
                            name="addDeliveryZone"
                          >
                            {t('shippingTimes.addNewDeliveryZone')}
                          </Button>
                        </Col>
                        : null}
                    </Row>
                  </Container>

                  <CustomPopup className={styles.shippingTimesAlertMain} show={customModalMultipleShopsAlert} onClose={(e) => { toggleMultipleShopsCustomModal(); }} title={""} >
                    <div className={styles.shippingTimesAlertBellWrapper}><img src="/assets/products/products_alert_bell.svg" alt="" /></div>
                    <h2>{t('products.noShopSelected')}</h2>
                    <div></div>
                    {/*<div>*/}
                    {/*  {t('products.noShopSelectedText')}*/}
                    {/*</div>*/}
                    <div className={styles.shippingTimesAlertActionsWrapper}>
                      <button color="link" className={styles.shippingTimesAlertActionBtn} onClick={(e) => { toggleMultipleShopsCustomModal(); }} >{t('products.alertCancelText')}</button>
                      <NavLink tag={Link} to="/preferences" className={styles.shippingTimesAlertActionBtn}>{t('products.goToPreferences')}</NavLink>
                    </div>
                  </CustomPopup>

                  <ErrorMessageCustomPopup show={shippingTimesError} title={shippingTimesErrorMessage} onClose={(e) => { setShippingTimesError(false); }} />

                  <LoaderAnimation show={showLoader} allowClose={false} />

                  {/*<div className={styles.infiniteScrollQueue} id="infiniteScrollQueue"></div>*/}
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );
}

export default ShippingTimesList