import React, { Component, useCallback, useEffect, useState, useRef } from 'react';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './product-search.module.scss';
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, Form, FormGroup, Input, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';

const ProductSearch = ({ defaultSuggestions, onHandleSelect }) => {
  const [user, setUser] = useState({});

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState('');
  const inputRef = useRef();

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (String(inputRef.current).trim().length > 3) {
      optimizedGetAutoComplete(input.current);
    }
    else {
      onHandleSelect(null, '', '', 0);
    }
  }, [input]);

  useEffect(() => {
    if (defaultSuggestions && defaultSuggestions.length > 0) {
      setSuggestions(defaultSuggestions);
      inputRef.current = String(defaultSuggestions[0].text);
      setInput(String(defaultSuggestions[0].text).replace('\n', ' '));
    }

    return function cleanUp() {
      setSuggestions();
    };
  }, [defaultSuggestions]);

  //useEffect(() => {
  //  if (suggestions && suggestions?.length > 0) {
  //    //// Filter our suggestions that don't contain the user's input
  //    //const unLinked = suggestions.filter(
  //    //  (suggestion) =>
  //    //    suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
  //    //);
  //    //setFilteredSuggestions(unLinked);
  //    setFilteredSuggestions(suggestions);
  //    setActiveSuggestionIndex(0);
  //    setShowSuggestions(true);
  //  }
  //}, [suggestions]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  async function getProductAutoComplete(input) {
    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
        const ts = Date.now();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/Autocomplete?q=${inputRef.current}&ts=${ts}`, requestOptions);
        //console.log('fetching suggestions');

        const data = await response.json();
        //console.log(data.items);

        if (data.items.length === 0) {
          setFilteredSuggestions([]);
          setActiveSuggestionIndex(0);
          setShowSuggestions(false);
        }
        else {
          //setSuggestions(data.items);
          setFilteredSuggestions(data.items);
          setActiveSuggestionIndex(0);
        }
      }
      else {
        setSuggestions([]);
      }
    }
    catch {

    }
  }

  const onChange = (e) => {
    inputRef.current = e.target.value;
    setInput(e.target.value);
    setShowSuggestions(true);
  };

  const onClick = (e, sku, name, id) => {
    setFilteredSuggestions([]);
    inputRef.current = e.target.innerText;
    setInput(e.target.innerText);
    onHandleSelect(e, sku, name, id);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {

  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className={styles.suggestions}>
        {filteredSuggestions.map((suggestion, index) => {
          return (
            <li className={((index === activeSuggestionIndex) ? styles.suggestionActive : null)} key={suggestion.intId} onClick={(e) => { onClick(e, suggestion.id, String(suggestion.text).split('\n')[0], suggestion.intId); }}>
              {/*<img src={"https://aviram.blob.core.windows.net/product-thumbnails/50x35/" + suggestion.id + ".png"} />*/}
              {suggestion.text}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className={styles.noSuggestions}>
          <em>{t("productSearch.noReasults")}</em>
      </div>
    );
  };

  const optimizedGetAutoComplete = useCallback(debounce(getProductAutoComplete, null), [suggestions]);

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              onChange={onChange}
              //onBlur={setShowSuggestions(false)}
              onKeyDown={onKeyDown}
              value={input}
            />
            {showSuggestions && input && input.length > 3 && <SuggestionsListComponent />}
          </div>
        )
      }}
    </userContext.Consumer>
  );
}
export default ProductSearch