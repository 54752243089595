import React, { useState, useEffect } from "react"
import { Multiselect } from 'multiselect-react-dropdown'
import { useTranslation } from "react-i18next";

const SelectCities = (props) => {    
    const [options, setOptions] = useState([])
    const [input, setInput] = useState('')

    const { t, i18n } = useTranslation('common');

    useEffect(() => {
        var request = {
            input: input,
            componentRestrictions: { country: "il" },
            types: ["locality", "administrative_area_level_3"]
        };

        var service = new window.google.maps.places.AutocompleteService();

        service.getPlacePredictions(request, function (results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                let opt = results.map(item => { return { text: item.description, id: item.place_id } });
                console.log(opt)
                setOptions(opt)
            }
        });
    }, [input])

    const onSearch = (filter) => {
      setInput(filter)
    }

    const onSelect = (selectedList) => {
      setOptions([])
      props.onCitiesListChanged(selectedList)
    }

    const onRemove = (selectedList) => {        
        props.onCitiesListChanged(selectedList)
    }

    return (
      <Multiselect 
        options={options} 
        selectedValues={props.cities}
        onSearch={onSearch} 
        onSelect={onSelect}
        onRemove={onRemove}
        placeholder={t('deliveryZoneEdit.selectCity')}
        emptyRecordMsg={t('deliveryZoneEdit.enterOneOrMore')}
        closeOnSelect={true}
        style={{background: '#6c757d'}}
        displayValue="text" />
    )
}

export default SelectCities