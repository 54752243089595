import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label } from 'reactstrap';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import ToggleSwitch from '../../Components/ToggleSwitch';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';

import styles from './product-card.module.scss';
import * as Icon from 'react-bootstrap-icons';

const ProductCard = (props) => {
  const { product } = props;
  const { onHandlePriceChange } = props;
  const { onHandleShowInStockChange } = props;
  const { onHandleUnlimitedQuantityChange } = props;
  const { onHandleStockQuantityChange } = props;
  const { onHandlePrivateTextChange } = props;
  const { onHandleCommentsForAdminChange } = props;
  const { onHandleReplacement } = props;
  const { onHandleAdd } = props;
  const { onHandleProductImageUploadShow } = props;
  const { onHandleMoveProductToTop } = props;
  const { isReplacement } = props;
  const { isAddProduct } = props;
  const { showProductShopName } = props;
  const { onHandleProductInShopEditShow } = props;

  const [productCardOpen, setProductCardOpen] = useState(false);

  const [productId, setProductId] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productUShowInStock, setProductUShowInStock] = useState(false);
  const [productUnlimitedQuantity, setProductUnlimitedQuantity] = useState(false);
  const [productStockQuantity, setProductStockQuantity] = useState(0);
  const [productPrivateText, setProductPrivateText] = useState('');
  const [productCommentsForAdmin, setProductCommentsForAdmin] = useState('');

  const [modalProdAddQuantityAdded, setModalProdAddQuantityAdded] = useState(0);
  const [modalProdAddUnitMeasure, setModalProdAddUnitMeasure] = useState(1);

  const [modalProdReplaceQuantityAdded, setModalProdReplaceQuantityAdded] = useState(0);
  const [modalProdReplaceUnitMeasure, setModalProdReplaceUnitMeasure] = useState(0);


  const { t, i18n } = useTranslation('common');

  //const imgUrl = "https://aviram.blob.core.windows.net/product-thumbnails/50x35/" + product.ImageToShow;
  const imgUrl = (product.ImageToShow === '' || product.ImageToShow === null) ? "https://aviram.blob.core.windows.net/shop-images/תמונת-אופנוען-למוצר-ללא-תמונה.jpg" :
    ((product.Image === '' || product.Image === null) ? "https://aviram.blob.core.windows.net/product-thumbnails/170x161/" + product.ProductImage : `https://aviram.blob.core.windows.net/private-product-thumbnails/shop${product.ShopID}/170x161/${product.Image}`);

  useEffect(() => {
    setProductPrice(product.Price);
    setProductStockQuantity(product.Quantity)
    setProductUnlimitedQuantity(product.UnlimitedQuantity);

    //setProductUShowInStock(product.ShowInStock);
    setProductUShowInStock(((product.Quantity > 0) || (product.UnlimitedQuantity)));
    setProductPrivateText(product.PrivateText);
    setProductCommentsForAdmin(product.CommentsForAdmin);

    setModalProdAddUnitMeasure((product.FirstUnitMeasureID !== null) ? product.FirstUnitMeasureID : 1);
    setModalProdReplaceUnitMeasure((product.FirstUnitMeasureID !== null) ? product.FirstUnitMeasureID : 1);

    setProductId(product.ID);
  }, []);

  //useEffect(() => {
  //  if (onHandlePriceChange && productId !== 0) {
  //    onHandlePriceChange(productPrice, productId);
  //  }
  //}, [productPrice]);

  //useEffect(() => {
  //  if (onHandleStockQuantityChange && productId !== 0) {
  //    onHandleStockQuantityChange(productStockQuantity, productId);
  //  }
  //}, [productStockQuantity]);

  const handlePriceChange = (price, id) => {
    onHandlePriceChange(price, id);
  };

  const handleStockQuantityChange = (qty, id) => {
    onHandleStockQuantityChange(qty, id);
  };

  const handlePrivateTextChange = (privateText, id) => {
    onHandlePrivateTextChange(privateText, id);
  };

  const handleCommentsForAdminChange = (privateText, id) => {
    onHandleCommentsForAdminChange(privateText, id);
  };

  const toggleProductCard = (e) => {
    if (!productCardOpen == true && onHandleMoveProductToTop) {
      onHandleMoveProductToTop(productId);
    }
    setProductCardOpen(!productCardOpen);
  }

  const tLength = JSON.parse(product.Tags).length;

  const handleFocus = (event) => {
    if (event && event.target) { // && event.target.select
      event.target.value = '';
      event.target.select();
    }
  }

  function priceFormat(num) {
    //var nis = '\U020AA';
    //const nis = '&#8362;';
    //const nis = '\20AA';
    return String(num) + " ₪"; // + " " + { nis };
  }

  function unitMeasureFormat(num) {
    switch (modalProdAddUnitMeasure) {
      case 4:
      default:
        return String(num) + ' ' + t('orderCollection.collectInputUnitMeasureUnit');
      case 1:
        return String(num) + ' ' + t('orderCollection.collectInputUnitMeasureWeight');

    }
  }

  //const handlePriceFieldFocus = (event) => {
  //  if (event)
  //    event.target.select();
  //}
  //const handleQuantityFieldFocus = (event) => {
  //  if (event)
  //    event.target.select();
  //}

  return (
    <Card className={styles.productCard + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL) + " " + ((product.Updated) ? 'border-success' : '')}>
      <CardHeader onClick={(e) => { toggleProductCard(e) }} className={styles.cardHeader}>
        <Row className={styles.cardRow}>
          <Col lg="5" xs="5" className={styles.cardColumn + " " + styles.cardImgContainer}>
            <div className={styles.cardImgCenter}>
              <CardImg top alt={product.Name} variant="top" className={"img-responsive center-block d-block mx-auto " + styles.cardImg} src={imgUrl} />
            </div>
          </Col>
          <Col lg="7" xs="7" className={styles.cardColumn + " " + styles.cardInfoContainer}>
            <div>
              <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>{(product.Name && product.Name.length > 0) ? product.Name : product.ProductName}</CardTitle>
            </div>
            {(showProductShopName) ?
              <div>
                {product.ShopName}
              </div>
              : null
            }
            <div className={styles.cardTagsContainer}>
              {
                JSON.parse(product.Tags).map((e, index) => {
                  return (<span key={e.name + product.ID}><span className={styles.cardTags}>{e.name}</span> {((index + 1 < tLength) ? ' >> ' : '')}</span>);
                })
              }
            </div>
            <Row className={styles.cardRow}>
              <Col lg="7" xs="7" className={styles.cardColumn}>
                {product.SKU}
              </Col>
              <Col lg="5" xs="5" className={styles.cardColumn}>
                {((productUnlimitedQuantity && !product.Ignored) || (product.Quantity > 0 && !product.Ignored) || product.ShowInStock) ?
                  <span><Icon.CheckCircle /> {t('productCard.inStock')}</span> :
                  <span><Icon.DashCircle /> {t('productCard.notInStock')}</span>}
              </Col>
            </Row>
            <Row className={styles.cardRow}>
              <Col lg="7" xs="7" className={styles.cardColumn + " " + styles.ManufacturerBrand + " " + (getCurrentLng() === 'en' ? '' : styles.ManufacturerBrandRtl)}>
                {product.FirstUnitWeight} {(getCurrentLng() === 'en' ? product.FirstUnitMeasureName : product.FirstUnitMeasureDisplayName)}
                {(((product.ProductBrandName != null && product.ProductBrandName.length > 0) || (product.ProductManufacturerName != null && product.ProductManufacturerName.length > 0)) ?
                  " | " + ((product.ProductBrandName != null && product.ProductBrandName.length > 0) ? product.ProductBrandName : product.ProductManufacturerName)
                  : "")}
              </Col>
              <Col lg="5" xs="5" className={styles.cardColumn}>
                <span className={styles.productCardPrice + " " + (getCurrentLng() === 'en' ? "" : styles.productCardPriceRtl)}>{product.Price}</span>{/*&#8362;*/}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={productCardOpen}>
        <CardBody className={styles.cardEditSectionWrapper}>
          {(!isReplacement && !isAddProduct) ?
            <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
              <Row className={styles.cardEditRow}>
                <Col lg="8" xs="8" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                  <span className={styles.cardFormEditIconWrapper}
                    onClick={(e) => {
                      //onHandleProductImageUploadShow(product.ID);
                      onHandleProductInShopEditShow(product.ID);
                    }}
                  ><img src="/assets/products/edit_icon.svg" /></span>
                  <span className={styles.cardPriceInputField}>
                    <input
                      title={t('productCard.productPrice')}
                      data-productid={product.ID}
                      id={"productPrice" + product.ID}
                      type='number'
                      min={0}
                      className={styles.cardFormPriceField}
                      pattern='[0-9]{0,5}'
                      onInput={(e) => {
                        setProductPrice(Number(e.target.value)); //.toFixed(2)
                        //if (Number(e.target.value) !== 0) {
                          handlePriceChange(Number(e.target.value), productId); //.toFixed(2)
                        //}
                      }}
                      onChange={(e) => { }}
                      value={productPrice}
                      onFocus={handleFocus}
                    />
                    &#8362; \ {(getCurrentLng() === 'en' ? product.FirstUnitMeasureName : product.FirstUnitMeasureDisplayName)}
                  </span>
                  <span className={styles.cardFormCameraIconWrapper}
                    onClick={(e) => {
                      onHandleProductImageUploadShow(product.ID);
                    }}>
                    <Icon.Camera />
                  </span>
                  {/*{(getCurrentLng() === 'en' ? product.FirstUnitMeasureName : product.FirstUnitMeasureDisplayName)}*/}
                </Col>
                <Col lg="4" xs="4" className={styles.cardColumn + " " + styles.lowestInShoookContainer + " " + (getCurrentLng() === 'en' ? "" : styles.lowestInShoookContainerRtl)}>
                  {t('productCard.lowestInShoook')} <br />
                  <span className={styles.lowestInShoook + " " + (getCurrentLng() === 'en' ? "" : styles.lowestInShoookRtl)}>{product.LowestPriceInShoook}</span>
                </Col>
              </Row>
              <Row className={styles.cardEditRow + " " + styles.cardEditRowHidden}>
                <Col lg="12" xs="12" className={styles.cardColumn} title={t('productCard.privateText')}>
                  <span>{t('productCard.privateText')}</span>
                  <Input
                    type="textarea"
                    data-productid={product.ID}
                    value={(productPrivateText !== null) ? productPrivateText : ''}
                    onChange={(e) => {
                      setProductPrivateText(e.target.value);
                      handlePrivateTextChange(e.target.value, productId);
                    }} />

                </Col>
              </Row>
              <Row className={styles.cardEditRow + " " + styles.cardEditRowHidden}>
                <Col lg="12" xs="12" className={styles.cardColumn} title={t('productCard.commentsForAdmin')}>
                  <span>{t('productCard.commentsForAdmin')}</span>
                  <Input
                    type="textarea"
                    data-productid={product.ID}
                    value={(productCommentsForAdmin !== null) ? productCommentsForAdmin : ''}
                    onChange={(e) => {
                      setProductCommentsForAdmin(e.target.value);
                      handleCommentsForAdminChange(e.target.value, productId);
                    }} />

                </Col>
              </Row>
              <Row className={styles.cardEditRow}>
                <Col lg="12" xs="12" className={styles.cardColumn} title={t('productCard.unlimitedQuantity')}>
                  <span className={styles.cardFormStockIconWrapper}><img src="/assets/products/instock_icon.svg" /></span>
                  {t('productCard.inStock')}
                  <ToggleSwitch id="ShowInStock" name="ShowInStock" optionLabels={["", ""]} checked={productUShowInStock} itemId={productId} onChange={(e, itemId) => { setProductUnlimitedQuantity(e); setProductUShowInStock(e, setProductStockQuantity((e ? productStockQuantity : 0), onHandleShowInStockChange(e, itemId))); }} />
                  &#160;{t('productCard.notInStock')}
                </Col>
              </Row>
              <Row className={styles.cardEditRow + " " + styles.cardEditLastRow + " " + ((productUShowInStock) ? '' : styles.hiddenElement)}>
                <Col lg="12" xs="12" className={styles.cardColumn} title={t('productCard.unlimitedQuantity')}>
                  {t('productCard.unlimitedQuantity')}
                  <ToggleSwitch id="UnlimitedQuantity" name="UnlimitedQuantity" optionLabels={["", ""]} checked={productUnlimitedQuantity} itemId={productId} onChange={(e, itemId) => { setProductUnlimitedQuantity(e, onHandleUnlimitedQuantityChange(e, itemId)); }} />
                  &#160;{t('productCard.stockQuantity')}&#160;&#160;
                  <span className={((productUnlimitedQuantity) ? styles.hiddenElement : '')}>
                    <input
                      title={t('productCard.productStock')}
                      placeholder={t('productCard.productStockTextInput')}
                      data-productid={product.ID}
                      id={"productStock" + product.ID}
                      type='number'
                      min={0}
                      className={styles.cardFormStockQuantityField}
                      pattern='[0-9]{0,5}'
                      onInput={(e) => {
                        //console.log(e.target.value);
                        if (Number(e.target.value) !== 0) {
                          const currentQuantityValue = Number(e.target.value);
                          setProductStockQuantity(((currentQuantityValue.length === 0) ? 0 : currentQuantityValue));
                          handleStockQuantityChange(((currentQuantityValue.length === 0) ? 0 : currentQuantityValue), productId);
                        }
                      }}
                      onChange={(e) => { }}
                      value={productStockQuantity}
                      onFocus={handleFocus}
                    />
                  </span>
                </Col>
              </Row>
            </div>
            :
            ((isReplacement) ?
              <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
                {(product.FirstUnitMeasureID != null) ?
                  <div style={{ display: 'none' }}>
                    <input
                      key={product.ID + product.FirstUnitMeasureID}
                      type="radio"
                      value={product.FirstUnitMeasureID}
                      checked={product.FirstUnitMeasureID === modalProdAddUnitMeasure}
                      onClick={(event) => setModalProdReplaceUnitMeasure(product.FirstUnitMeasureID)}
                      onChange={(e) => { }}
                    />
                    <label className='label-brand'>
                      <span>{product.FirstUnitMeasureDisplayName}</span>
                    </label>
                  </div>
                  :
                  null
                }
                {(product.SecondUnitMeasureID != null) ?
                  <div style={{ display: 'none' }}>
                    <input
                      key={product.ID + product.SecondUnitMeasureID}
                      type="radio"
                      value={product.SecondUnitMeasureID}
                      checked={product.SecondUnitMeasureID === modalProdAddUnitMeasure}
                      onClick={(event) => setModalProdReplaceUnitMeasure(product.SecondUnitMeasureID)}
                      onChange={(e) => { }}
                    />
                    <label>
                      <span>{product.SecondUnitMeasureDisplayName}</span>
                    </label>
                  </div>
                  :
                  null
                }
                {/*<input*/}
                {/*  title={t('orderCollection.collectInputTitle')}*/}
                {/*  placeholder={t('productCard.productStockTextInput')}*/}
                {/*  data-productid={product.ID}*/}
                {/*  id={"quantityPick" + product.ID}*/}
                {/*  type='number'*/}
                {/*  min={0}*/}
                {/*  className={styles.orderItemCardAddQuantityPicked}*/}
                {/*  pattern='[0-9]{0,5}'*/}
                {/*  onInput={(e) => {*/}
                {/*    //console.log(e.target.value);*/}
                {/*    setModalProdReplaceQuantityAdded(Number(e.target.value));*/}
                {/*    //if (Number(e.target.value) !== 0) {*/}
                {/*    //}*/}
                {/*  }}*/}
                {/*  onChange={(e) => { }}*/}
                {/*  value={modalProdReplaceQuantityAdded}*/}
                {/*  onFocus={handleFocus}*/}
                {/*/>*/}
                <Button
                  color="link"
                  className={styles.productCardReplaceBtn}
                  data-itemid={product.ID}
                  name="Replace"
                  value={product.ID}
                  disabled={Number(modalProdReplaceQuantityAdded) === 0}
                  onClick={(e) => { onHandleReplacement(e, modalProdReplaceQuantityAdded, modalProdReplaceUnitMeasure) }}>
                  {t('productCard.replace')}
                </Button>
                <div className={styles.orderItemCardAddQuantityPicked}>
                  <NumericInputWithButtons
                    id={"quantityPick"}
                    title={t('orderCollection.collectInputTitle')}
                    onChange={(value) => {
                      console.log(value);
                      setModalProdReplaceQuantityAdded(Number(value));
                    }}
                    onSubmit={(e) => {
                      if (Number(modalProdReplaceQuantityAdded) !== NaN && Number(modalProdReplaceQuantityAdded) > 0) {
                        onHandleReplacement(e, modalProdReplaceQuantityAdded, modalProdReplaceUnitMeasure)
                      }
                    }}
                    value={modalProdReplaceQuantityAdded}
                    pattern='[0-9]{0,5}'
                    itemId={product.ID}
                  />
                  {/*onSubmit={(e) => { handleProdCollectQuantity(e); }}*/}
                </div>
              </div>
              :
              <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
                {(product.FirstUnitMeasureID != null) ? 
                  <div style={{ display: 'none' }}>
                    <input
                      key={product.ID + product.FirstUnitMeasureID}
                      type="radio"
                      value={product.FirstUnitMeasureID}
                      checked={product.FirstUnitMeasureID === modalProdAddUnitMeasure}
                      onClick={(event) => setModalProdAddUnitMeasure(product.FirstUnitMeasureID)}
                      onChange={(e) => { }}
                    />
                    <label className='label-brand'>
                      <span>{product.FirstUnitMeasureDisplayName}</span>
                    </label>
                  </div>
                  :
                  null
                }
                {(product.SecondUnitMeasureID != null) ?
                  <div style={{ display: 'none'}}>
                    <input
                      key={product.ID + product.SecondUnitMeasureID}
                      type="radio"
                      value={product.SecondUnitMeasureID}
                      checked={product.SecondUnitMeasureID === modalProdAddUnitMeasure}
                      onClick={(event) => setModalProdAddUnitMeasure(product.SecondUnitMeasureID)}
                      onChange={(e) => { }}
                    />
                    <label>
                      <span>{product.SecondUnitMeasureDisplayName}</span>
                    </label>
                  </div>
                  :
                  null
                }
                {/*<input*/}
                {/*  title={t('orderCollection.collectInputTitle')}*/}
                {/*  placeholder={t('productCard.productStockTextInput')}*/}
                {/*  data-productid={product.ID}*/}
                {/*  id={"quantityPick" + product.ID}*/}
                {/*  type='number'*/}
                {/*  min={0}*/}
                {/*  className={styles.orderItemCardAddQuantityPicked}*/}
                {/*  pattern='[0-9]{0,5}'*/}
                {/*  onInput={(e) => {*/}
                {/*    //console.log(e.target.value);*/}
                {/*    setModalProdAddQuantityAdded(Number(e.target.value));*/}
                {/*    //if (Number(e.target.value) !== 0) {*/}
                {/*    //}*/}
                {/*  }}*/}
                {/*  onChange={(e) => { }}*/}
                {/*  value={modalProdAddQuantityAdded}*/}
                {/*  onFocus={handleFocus}*/}
                {/*/>*/}
                <Button
                  color="link"
                  className={styles.productCardReplaceBtn}
                  data-itemid={product.ID}
                  name="Add"
                  value={product.ID}
                  disabled={Number(modalProdAddQuantityAdded) == 0}
                  onClick={(e) => { onHandleAdd(e, modalProdAddQuantityAdded, modalProdAddUnitMeasure) }}
                >{t('productCard.addToOrder')}</Button>
                <div className={styles.orderItemCardAddQuantityPicked}>
                  <NumericInputWithButtons
                    id={"quantityPick"}
                    title={t('orderCollection.collectInputTitle')}
                    onChange={(value) => {
                      console.log(value);
                      setModalProdAddQuantityAdded(Number(value));
                    }}
                    onSubmit={(e) => {
                      if (Number(modalProdAddQuantityAdded) !== NaN && Number(modalProdAddQuantityAdded) > 0) {
                        onHandleAdd(e, modalProdAddQuantityAdded, modalProdAddUnitMeasure)
                      }
                    }}
                    value={modalProdAddQuantityAdded}
                    pattern='[0-9]{0,5}'
                    itemId={product.ID}
                  />
                  {/*onSubmit={(e) => { handleProdCollectQuantity(e); }}*/}
                </div>
              </div>
            )
          }
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default ProductCard