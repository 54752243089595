import { getCurrentLng } from '../../i18next_fun';
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardBody } from "reactstrap";

import { useTranslation } from "react-i18next";

import CustomCollapse from '../../Components/CustomCollapse'
import * as Icon from 'react-bootstrap-icons';

import styles from './order-statusFilter.module.scss';
 
const OrderStatusFilter = ({
  onHandleOrderStatusFilterChange, ...props
}) => {
  const [currentOrderFilter, setCurrentOrderFilter] = useState('1');

  const { totalNew } = props;
  const { totalPreparation } = props;
  const { totalReady } = props;

  const { t, i18n } = useTranslation('common');

  useEffect(() => {

  }, []);

  const handleOrderstatusFilterChange = (e) => {
    setCurrentOrderFilter(e.target.value);
    onHandleOrderStatusFilterChange(e.target.value);
  };

  return (
    <div className={styles.searchWrpperFixed + " " + (getCurrentLng() === 'en' ? "" : styles.searchFormRtl)}>
      <ButtonGroup className={styles.btnGroup}>
        <Button
          data-filtervalue={1}
          name="stockFilter"
          value="1"
          className={styles.btn + " " + (currentOrderFilter === '1' ? styles.btnActive : '')}
          onClick={(e) => { handleOrderstatusFilterChange(e); }}
        >{t('orders.orderStatusNew')} ({totalNew})</Button>
        <Button
          data-filtervalue={2}
          name="stockFilter"
          value="2"
          className={styles.btn + " " + (currentOrderFilter === '2' ? styles.btnActive : '')}
          onClick={(e) => { handleOrderstatusFilterChange(e); }}
        >{t('orders.orderStatusPreparation')} ({totalPreparation})</Button>
        <Button
          data-filtervalue={3}
          name="stockFilter"
          value="3"
          className={styles.btn + " " + (currentOrderFilter === '3' ? styles.btnActive : '')}
          onClick={(e) => { handleOrderstatusFilterChange(e); }}
        >{t('orders.orderStatusReady')} ({totalReady})</Button>
      </ButtonGroup>
    </div>
  )
};

export default OrderStatusFilter;