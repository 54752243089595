import { getCurrentLng } from '../../i18next_fun';
import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation } from "react-i18next";

import { Accordion } from "../../Components/Accordion";
import OrderCard from './OrderCard';

import { Card, CardImg, Button, ButtonGroup, CardTitle, CardText, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import styles from './order-group.module.css';

const OrderGroup = (props) => {
  const { orders } = props;
  const { onHandleFreezeOrder } = props;

  const { t, i18n } = useTranslation('common');
  const { path } = useRouteMatch();

  return (
    orders.map(order => {
      return (
        <OrderCard onHandleFreezeOrder={(e) => onHandleFreezeOrder(e)} order={order} key={order.ID} />
      )
    })
  );
}

export default OrderGroup