import React, { useState, useEffect } from 'react';
import { userContext } from '../../Context/userContext';
import { Link } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";

import styles from './reset-password.module.scss';
import { Button, NavLink, ButtonGroup, Form, FormGroup, Input, Label, Container, Row, Col, Card, CardImg, CardTitle, CardText, CardFooter, ModalHeader, ModalBody } from "reactstrap";
import * as Icon from 'react-bootstrap-icons';

function ResetPassword(props) {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  //const [user, setUser] = useState({ user: {} });
  let loading = false;
  const [resetError, setResetError] = useState(0);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const qCode = query.get('code')
    setCode(qCode);
    const userId = query.get('userId')
    setEmail(userId);

    document.title = t('nav.resetPassword');
  }, []);

  return (
    <userContext.Consumer>

      {({ resetPassword }) => {


        function onKeyUp(event) {
          if (event.charCode === 13) {
            handleReset(null, email, password, rePassword, code);
          }
        }

        const handleReset = async (e, email, password, rePassword, code) => {
          if (e && e != null)
            e.preventDefault();

          if (rePassword !== password) {
            setResetError(2);
            return;
          }
          resetPassword(e, email, password, rePassword, code).then((resetResults) => {
            //console.log(loginResults);
            setResetError(resetResults);

            if (resetResults === 0) {
              const history = props;
              if (history) props.history.push('/login');
            }
          }).catch((err) => {
            //console.log('error: ' + err);
          });
        }

        return (
          <div className={styles.container + " " + styles.gradientBackground}>

            <div className={styles.tomatos}>
              <img src="/assets/home_svg/tomatos.svg" />
            </div>

            <div className={styles.carrots}>
              <img src="/assets/home_svg/carrots.svg" />
            </div>

            <div className={styles.eggplants}>
              <img src="/assets/home_svg/eggplants.svg" />
            </div>

            <div className={styles.mushroom}>
              <img src="/assets/home_svg/mushroom.svg" />
            </div>

            <div className={styles.lettuce}>
              <img src="/assets/home_svg/lettuce.svg" />
            </div>

            <div className={styles.butternut}>
              <img src="/assets/home_svg/butternut.svg" />
            </div>

            <div className={styles.bellpepper}>
              <img src="/assets/home_svg/bellpepper.svg" />
            </div>

            <div className={styles.artichoke}>
              <img src="/assets/home_svg/artichoke.svg" />
            </div>

            <div className={styles.grapes}>
              <img src="/assets/home_svg/grapes.svg" />
            </div>

            <div className={styles.cherries}>
              <img src="/assets/home_svg/cherries.svg" />
            </div>

            <div className={styles.melon}>
              <img src="/assets/home_svg/melon.svg" />
            </div>

            <div className={styles.corn}>
              <img src="/assets/home_svg/corn.svg" />
            </div>

            <div className={styles.loginContainer + " " + (getCurrentLng() === 'en' ? "" : styles.loginRtl)}>
              {/*{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}*/}
              <form className={styles.loginFormContainer} onKeyPress={onKeyUp}>
                <h1>
                  {t('resetPassword.title')}
                </h1>
                <input
                  type='hidden'
                  id='email'
                  value={email}
                //onChange={(e) => setEmail(e.target.value)}
                //disabled={loading}
                //placeholder={t('forgotPassword.email')}
                />
                <input
                  type='hidden'
                  id='code'
                  value={code}
                //onChange={(e) => setEmail(e.target.value)}
                //disabled={loading}
                //placeholder={t('forgotPassword.email')}
                />
                <div className={styles.loginForm}>
                  <div className={styles.loginFormItem}>
                    <input
                      type='password'
                      id='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={loading}
                      placeholder={t('resetPassword.password')}
                    />
                  </div>
                  <div className={styles.loginFormItem}>
                    <input
                      type='password'
                      id='repassword'
                      value={rePassword}
                      onChange={(e) => setRePassword(e.target.value)}
                      disabled={loading}
                      placeholder={t('resetPassword.rePassword')}
                    />
                  </div>
                </div>
                <div className={styles.error}>
                  <span className={((resetError !== 1) ? styles.hidden : '')}>{t('errors.reset_error')}</span>
                  <span className={((resetError !== 2) ? styles.hidden : '')}>{t('errors.passwords_dont_match')}</span>
                </div>
                <Button className={styles.loginFormBtn} onClick={(e) => handleReset(e, email, password, rePassword, code)} disabled={loading}>
                  {t('resetPassword.resetPassword')}
                </Button>
                <div className={styles.forgotPassword}>
                  <NavLink tag={Link} to="/login">{t('resetPassword.backToLogin')}{(getCurrentLng() === 'en' ? <Icon.ChevronRight /> : <Icon.ChevronLeft />)} </NavLink>
                </div>
              </form>
            </div>
          </div>
        )
        //{(user && user.currentUser !== undefined) ? user.currentUser : ''}
      }}

    </userContext.Consumer>
  );
}

export default ResetPassword;
