import React, { Component, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
//import { logout } from '../Context/loginActions';
import { userContext } from '../Context/userContext';
import { useTranslation } from "react-i18next";
import { getCurrentLng } from '../i18next_fun';
import { layoutContext } from '../Context/layoutContext';

import { Button, Form, FormGroup, Input, InputGroup, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import styles from './navmenu.module.scss';
import * as Icon from 'react-bootstrap-icons';

const NavMenu = (props) => {
  //const { onHandleSearchInputChange, onHandleSearchSubmit, currentSearchString, currentShop } = props;
  const [collapsed, setCollapsed] = useState(false);
  const { t, i18n } = useTranslation('common');
  let history = useHistory();

  const [currentSearchInputValue, setCurrentSearchInputValue] = useState("");
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(true);

  const displayName = NavMenu.name;

  useEffect(() => {
    setCollapsed(true);
    //toggleSearchPanel();
  }, []);

  const toggleNavbar = function (e, url) {
    //e.preventDefault();
    setCollapsed(!collapsed);

    if (url.length > 0) {
      if (history) history.push(url);
    }
  }

  // <NavItem>
  // <NavLink tag={Link} className="text-dark" to="/dashboard">{t('nav.dashboard')}</NavLink>
  // </NavItem>
  //{t('welcome.title', { appName: 'SLK' })}

  function toggleSearchPanel() {
    const searchFrame = document.querySelectorAll("#searchForm")[0];
    const brandElement = document.querySelectorAll(".navbar-brand")[0];
    const magnifier = document.querySelectorAll("#magnifier")[0];
    const navSearchBtnIcon = document.querySelectorAll("#navSearchBtnIcon")[0];

    const windowWidth = window.outerWidth;

    if (isSearchPanelOpen) {
      setIsSearchPanelOpen(!isSearchPanelOpen);

      if (windowWidth <= 769) {
        brandElement.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
      }
      setTimeout((e) => {
        magnifier.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
        navSearchBtnIcon.style.display = (isSearchPanelOpen) ? "inline-block" : "none";
      }, 1000);
    }

    setTimeout((e) => {

      //if (windowWidth <= 769) {
      //  //brandElement.style.visibility = (isSearchPanelOpen) ? "hidden" : "visible";
      //  brandElement.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
      //}

      if (isSearchPanelOpen) {
        searchFrame.classList.add(styles.searchFrameOpen);
      }
      else {
        searchFrame.classList.remove(styles.searchFrameOpen);
      }

      if (!isSearchPanelOpen) {

        setTimeout((e) => {
          //if (windowWidth <= 769) {
          //  brandElement.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
          //}

          setIsSearchPanelOpen(!isSearchPanelOpen);

          setTimeout((e) => {
            if (windowWidth <= 769) {
              brandElement.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
            }
            magnifier.style.display = (isSearchPanelOpen) ? "none" : "inline-block";
            navSearchBtnIcon.style.display = (isSearchPanelOpen) ? "inline-block" : "none";
          });


        }, 500);
      }

    }, 200);
  }


  // fixed="top"
  return (
    <layoutContext.Consumer>
      {({ onHandleSearchInputChange, onHandleSearchSubmit, currentShop }) => {


        function onKeyUp(e) {
          if (e.charCode === 13) {
            e.preventDefault();
            //debugger;
            //return false;
            //handleSearchInputChange(null);
            //onHandleSearchInputChange(currentSearchInputValue);
            document.querySelector('#searchBtn').focus();
          }
        }

        const handleSearchInputChange = (e) => {
          //this.setState({
          //  searchText: event.target.value
          //});
          e.preventDefault();

          let value = e.target.value;
          setCurrentSearchInputValue(value);
          onHandleSearchInputChange(value);
        };

        const handleSearchSubmit = (e) => {
          //if (this.state.searchText) {
          //  this.props.history.push({
          //    pathname: "/results",
          //    state: {
          //      searchText: this.state.searchText
          //    }
          //  });
          //} else {
          //  alert("Please enter some search text!");
          //}
          e.preventDefault();

          onHandleSearchSubmit(currentSearchInputValue);

          //console.log(e);
          //console.log(currentSearchInputValue);
          
        };

        return (
          <header>
            <Navbar className={"navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 " + styles.navbar} fixed="top" light>
              <Container className={styles.flexContainer}>

                <NavbarBrand tag={Link} to="/">
                  {/*{t('app.title')}*/}
                  <img src="/assets/logo.svg" />
                </NavbarBrand>

                <div className={styles.navSearchContainer} >
                  <Form id="searchForm" inline className={styles.searchFrame} onKeyPress={onKeyUp}>
                    <Icon.Search id="magnifier" onClick={() => { toggleSearchPanel() }} className={styles.navMenuMagnifier} /> {/* + " " + (isSearchPanelOpen ? styles.navSearchFieldOpen : styles.navSearchFieldClosed)*/}
                    <InputGroup className={styles.inputGroup}>
                      <Button
                        onClick={(e) => { handleSearchSubmit(e) }}
                        variant="outline-info"
                        id="searchBtn"
                        className={"mr-sm-2 " + styles.navSearchBtn + " " + (isSearchPanelOpen ? styles.navSearchFieldClosed : styles.navSearchFieldOpen)}
                      >
                        <Icon.Search id="navSearchBtnIcon" />
                      </Button>
                      <Input
                        onChange={(e) => { handleSearchInputChange(e) }}
                        value={currentSearchInputValue}
                        placeholder="Search"
                        className={"mr-sm-2 " + styles.navSearchInput + " " + (isSearchPanelOpen ? styles.navSearchFieldClosed : styles.navSearchFieldOpen)}
                      />
                      <Button
                        onClick={(e) => { toggleSearchPanel(e) }}
                        className={"mr-sm-2 " + styles.navSearchCloseBtn + " " + (isSearchPanelOpen ? styles.navSearchFieldClosed : styles.navSearchFieldOpen)}
                      >
                        <Icon.X />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>

                <NavbarToggler onClick={(e) => { toggleNavbar(e, ""); }} className="mr-2" />
                
              </Container>
              <Collapse className={"d-sm-inline-flex flex-sm-row-reverse " + styles.navBarLinksWrap} isOpen={!collapsed} navbar>
                <ul className={"navbar-nav flex-grow " + ((getCurrentLng() === 'en') ? styles.navList : styles.navListRtl)}>
                  <NavItem>
                    <NavLink tag={Link} className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} to="/" onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, ""); }}>{t('nav.home')}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, "/products/"); }}>{t('nav.products')}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, "/orders/"); }}>{t('nav.orders')}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, "/specialoffers/"); }}>{t('nav.specialOffers')}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, "/shippingtimes/"); }}>{t('nav.shippingTimes')}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { setCurrentSearchInputValue(''); onHandleSearchInputChange(); toggleNavbar(e, "/preferences/"); }}>{t('nav.preferences')}</NavLink>
                  </NavItem>


                  <span className={styles.navSeparator}> | </span>

                  {/*<span>{currentShop}</span>*/}
                  <NavItem>

                    <userContext.Consumer>

                      {({ user, logoutUser }) => {
                        const handleLogout = async (e) => {
                          e.preventDefault();

                          logoutUser().then(() => {
                            //if (history) props.history.push('/login');
                            if (history) history.push('/login');
                          });
                        }
                        //console.log(user);
                        //{(user && user.currentUser !== undefined) ? user.currentUser : ''}
                        //<NavItem><Button className="text-dark" onClick={(e) => { handleLogout(e); toggleNavbar() }}>{t('nav.logout')}</Button></NavItem> :
                        return (
                          user && user.token ?
                            <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { handleLogout(e); toggleNavbar(e, ""); }}>{t('nav.logout')}</NavLink> :
                            <NavLink className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)} onClick={(e) => { toggleNavbar(e, "/login"); }}>{t('nav.login')}</NavLink>
                        )
                      }}

                    </userContext.Consumer>

                  </NavItem>

                </ul>
              </Collapse>

            </Navbar>
          </header>
        )
      }}
    </layoutContext.Consumer>
  );
}

export default NavMenu;