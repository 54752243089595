import React, { useState, useEffect } from 'react';
import { userContext } from '../../Context/userContext';
import { Link } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";

import styles from './forgot-password.module.scss';
import { Button, NavLink, ButtonGroup, Form, FormGroup, Input, Label, Container, Row, Col, Card, CardImg, CardTitle, CardText, CardFooter, ModalHeader, ModalBody } from "reactstrap";
import * as Icon from 'react-bootstrap-icons';

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  let loading = false;
  const [forgotError, setForgotError] = useState(0);
  const { t, i18n } = useTranslation('common');

  const [mailSent, setMailSent] = useState(false);

  useEffect(() => {
    document.title = t('nav.forgotPassword');
  }, []);

  return (
    <userContext.Consumer>

      {({ sendForgotPassword }) => {


        function onKeyUp(event) {
          if (event.charCode === 13) {
            handleForgot(event, email);
          }
        }

        const handleForgot = async (e, email) => {
          if (e && e != null)
            e.preventDefault();

          if (email === '') {
            setForgotError(1);
            return;
          }

          sendForgotPassword(e, email).then((forgotResults) => {
            //console.log(forgotResults);
            setForgotError(forgotResults);

            if (forgotResults === 0) {
              setMailSent(true);
              //const history = props;
              //if (history) props.history.push('/login');
            }
          }).catch((err) => {
            //console.log('error: ' + err);
          });
        }

        return (
          <div className={styles.container + " " + styles.gradientBackground}>

            <div className={styles.tomatos}>
              <img src="/assets/home_svg/tomatos.svg" />
            </div>

            <div className={styles.carrots}>
              <img src="/assets/home_svg/carrots.svg" />
            </div>

            <div className={styles.eggplants}>
              <img src="/assets/home_svg/eggplants.svg" />
            </div>

            <div className={styles.mushroom}>
              <img src="/assets/home_svg/mushroom.svg" />
            </div>

            <div className={styles.lettuce}>
              <img src="/assets/home_svg/lettuce.svg" />
            </div>

            <div className={styles.butternut}>
              <img src="/assets/home_svg/butternut.svg" />
            </div>

            <div className={styles.bellpepper}>
              <img src="/assets/home_svg/bellpepper.svg" />
            </div>

            <div className={styles.artichoke}>
              <img src="/assets/home_svg/artichoke.svg" />
            </div>

            <div className={styles.grapes}>
              <img src="/assets/home_svg/grapes.svg" />
            </div>

            <div className={styles.cherries}>
              <img src="/assets/home_svg/cherries.svg" />
            </div>

            <div className={styles.melon}>
              <img src="/assets/home_svg/melon.svg" />
            </div>

            <div className={styles.corn}>
              <img src="/assets/home_svg/corn.svg" />
            </div>

            {(mailSent) ?
              <div className={(getCurrentLng() === 'en' ? "" : styles.loginRtl)}>
                {/*{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}*/}
                <form className={styles.loginFormContainer} onKeyPress={onKeyUp}>
                  <h1>
                    {t('forgotPassword.thankYou')}
                  </h1>
                  <div>
                    {t('forgotPassword.mailWasSent')}
                  </div>
                  <NavLink tag={Link} className={styles.loginFormBtn + " " + styles.linkBtn} to="/login">
                    {t('forgotPassword.toLogin')}
                  </NavLink>
                  <div className={styles.forgotPassword}>
                    <NavLink tag={Link} onClick={(e) => handleForgot(e, email)} disabled={loading}>{t('forgotPassword.sendAgain')}</NavLink>
                  </div>
                </form>
              </div>
              :
              <div className={styles.loginContainer + " " + (getCurrentLng() === 'en' ? "" : styles.loginRtl)}>
                {/*{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}*/}
                <form className={styles.loginFormContainer} onKeyPress={onKeyUp}>
                  <h1>
                    {t('forgotPassword.title')}
                  </h1>
                  <div className={styles.loginForm}>
                    <div className={styles.loginFormItem}>
                      <input
                        type='text'
                        id='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                        placeholder={t('forgotPassword.email')}
                      />
                    </div>
                  </div>
                  <div className={styles.error}>
                    <span className={((forgotError !== 1) ? styles.hidden : '')}>{t('errors.forgot_error')}</span>
                  </div>
                  <Button className={styles.loginFormBtn} onClick={(e) => handleForgot(e, email)} disabled={loading}>
                    {t('forgotPassword.sendForgotPassMailBtnText')}
                  </Button>
                  <div className={styles.forgotPassword}>
                    <NavLink tag={Link} to="/login">{t('forgotPassword.backToLogin')}{(getCurrentLng() === 'en' ? <Icon.ChevronRight /> : <Icon.ChevronLeft />)} </NavLink>
                  </div>
                </form>
              </div>
            }
          </div>
        )
        //{(user && user.currentUser !== undefined) ? user.currentUser : ''}
      }}

    </userContext.Consumer>
  );
}

export default ForgotPassword;
