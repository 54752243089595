import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';
import { useTranslation } from "react-i18next";
import { getCurrentLng } from '../../i18next_fun';

import { getShopsList } from '../../API/shop';

import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, Form, FormGroup, Input, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';

import ToggleSwitch from '../../Components/ToggleSwitch';

import 'bootstrap/scss/bootstrap.scss';
import styles from './preferences.module.scss';

const Preferences = (props) => {
  const [user, setUser] = useState({});
  const [shops, setShops] = useState([]);
  const { currentShop } = props;
  const [currentSelectedShop, setCurrentSelectedShop] = useState(currentShop);
  const [currentSelectedShopName, setCurrentSelectedShopName] = useState('');
  const { t, i18n } = useTranslation('common');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  let [useStickersPrinter, setUseStickersPrinter] = useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const { path } = useRouteMatch();

  const onUseStickerPrinterChange = (checked) => {
    setUseStickersPrinter(checked);
  }

  //async function getShopsList() {
  //  try {
  //    const requestOptions = {
  //      //credentials: 'same-origin',
  //      //credentials: 'include',
  //      method: 'POST',
  //      mode: 'cors',
  //      headers: {
  //        'Accept': 'application/json',
  //        'Content-Type': 'application/json',
  //      },
  //      //body: JSON.stringify({  })
  //    };

  //    if (user.token) {
  //      requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

  //      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/ListShops`, requestOptions);

  //      const data = await response.json();
  //      if (data.length > 1) {
  //        //data.push({ id: 0, name: 'All Shops' });
  //        setShops([{ id: 0, name: 'All Shops' }, ...data]);
  //      }
  //      else {
  //        setShops(data);
  //      }
  //    }
  //    else {
  //      setShops([]);
  //    }
  //  }
  //  catch {
  //    setShops([]);
  //    //setLoading(false);
  //  }
  //}

  const populateShops = async () => {
    const shopsList = await getShopsList(user);
    setShops(shopsList);
  }

  useEffect(() => {
    //getShopsList();
    populateShops();

    return function clear() {
      setShops([]);
    }
  }, [user]);

  useEffect(() => {
    if (currentSelectedShop != 0) {
      //console.log(currentSelectedShop);
      let selectedShop = shops.filter(e => Number(e.id) == currentSelectedShop);
      if (selectedShop.length > 0) {
        const shopName = selectedShop[0].name;
        setCurrentSelectedShopName(shopName);
      }
    }
    else {
      setCurrentSelectedShopName('All Shops');
    }

    return function clear() {
      setCurrentSelectedShop(0);
      setCurrentSelectedShopName('');
    }
  }, [shops]);

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <layoutContext.Consumer>
            {({ onHandleShopChange, currentShop }) => {

              const handleShopChange = (e) => {
                setCurrentSelectedShop(Number(e.target.value));
                onHandleShopChange(Number(e.target.value));
              }

              setCurrentSelectedShop(currentShop);

              return (
                <div>
                  <h1 id="tabelLabel" >{t('nav.preferences')}</h1>

                  <Container className={(getCurrentLng() === 'en' ? "" : styles.preferencesRtl)}>

                    <Row className={styles.preferencesRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('preferences.useStickerPrinter')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                        <ToggleSwitch id="stickerPrinterToggle" checked={useStickersPrinter} onChange={onUseStickerPrinterChange} />
                      </Col>
                    </Row>
                    <Row className={styles.preferencesRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('preferences.language')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                        <ButtonGroup className={styles.btnGroup}>
                          <Button
                            data-filtervalue={1}
                            name="selectLanguage"
                            value="en"
                            className={styles.btn + " " + (getCurrentLng() === 'en' ? 'active' : '')}
                            onClick={() => { i18n.changeLanguage('en'); window.localStorage.setItem('i18nextLng', 'en'); }}
                          >{t('nav.en')}</Button>
                          <Button
                            data-filtervalue={2}
                            name="selectLanguage"
                            value="he"
                            className={styles.btn + " " + (getCurrentLng() === 'he' ? 'active' : '')}
                            onClick={() => { i18n.changeLanguage('he'); window.localStorage.setItem('i18nextLng', 'he'); }}
                          >{t('nav.he')}</Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Row className={styles.preferencesRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('preferences.shopName')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                        <FormGroup>
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            name="shopName"
                            placeholder={t('preferences.shopName')}
                          >
                            <DropdownToggle caret onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen}>
                              {((currentSelectedShop !== 0) ? currentSelectedShopName : t('preferences.shopNameDropDown'))}
                            </DropdownToggle>
                            <DropdownMenu className={styles.dropdownWindow}>
                              {shops.map(s => <DropdownItem value={s.id} data-shop-id={s.id} key={s.id} className={(currentSelectedShop == Number(s.id)) ? "active" : ""}
                                onClick={(e) => {
                                  setCurrentSelectedShop(s.id);
                                  setCurrentSelectedShopName(s.name);
                                  handleShopChange(e);
                                }}>
                                {s.name}
                              </DropdownItem>)}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className={styles.preferencesRow}>
                      {/*<Col lg="6" sm="8" xs="8">*/}
                      {/*  <Label>*/}
                      {/*    {t('preferences.shopSpecialTimes')}*/}
                      {/*  </Label>*/}
                      {/*</Col>*/}
                      <Col lg="12" sm="12" xs="12"> {/* < className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)} /> */ }
                        <NavLink tag={Link} to={`${path}specialworktimes/`} className={"text-dark " + styles.navLink + " " + (getCurrentLng() === 'en' ? "" : styles.navLinkRtl)}>
                          {t('preferences.setSpecialWorkTimes')}
                        </NavLink>
                      </Col>
                    </Row>

                    <Row className={styles.preferencesRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('preferences.ordersOrderingBy')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                          {t('preferences.ordersOrderingByDate')}
                      </Col>
                    </Row>


                    <Row className={styles.preferencesRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('preferences.filterOrdersBy')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                          {t('preferences.filterOrdersByNone')}
                      </Col>
                    </Row>


                  </Container>
                </div>
              );
            }}
          </layoutContext.Consumer>
        )

      }}

    </userContext.Consumer>
  )
}

export default Preferences