import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink } from 'reactstrap';

import CustomPopup from '../../Components/CustomPopup';
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';

import SpecialOfferCard from './SpecialOfferCard';
import useInfiniteScroll from '../../Components/InfiniteScroll';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './specialoffers.module.scss';

const SpecialOffersList = (props) => {
  const [user, setUser] = useState({});
  const [specialOfferShop, setSpecialOfferShop] = useState(null);
  const [specialOfferSearchString, setSpecialOfferSearchString] = useState('');

  const [specialOffersReachedEnd, setSpecialOffersReachedEnd] = useState(false);

  const [total, setTotal] = useState(0);

  const [specialOffers, setSpecialOffers] = useState(null);
  const [loading, setLoading] = useInfiniteScroll(populateSpecialOffersData, null, '#infiniteScrollQueue');

  const [showLoader, setShowLoader] = useState(false);

  const [customModalMultipleShopsAlert, setCustomModalMultipleShopsAlert] = useState(false);

  const [specialOffersError, setSpecialOffersError] = useState(false);
  const [specialOffersErrorMessage, setSpecialOffersErrorMessage] = useState('');

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  useEffect(() => {
    document.title = t('nav.specialOffers');
  }, []);

  useEffect(() => {
    if (specialOffers == null || (loading && !specialOffersReachedEnd)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    if (specialOffersErrorMessage && specialOffersErrorMessage.length > 0) {
      setSpecialOffersError(true);
    }
  }, [specialOffersErrorMessage]);

  useEffect(() => {
    setSpecialOffers([]);
    setSpecialOffersReachedEnd(false);
    optimizedGetSpecialOffers();

    return function cleanUp() {
      setSpecialOffers([]);
      setSpecialOffersReachedEnd(false);
    };
  }, [specialOfferShop, specialOfferSearchString]);

  useEffect(() => {
    //console.log(productShop);
    if (specialOfferShop != null) {
      if (Number(specialOfferShop) == 0) { //layoutDataInitialized
        toggleMultipleShopsCustomModal();
      }
    }

  }, [specialOfferShop]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const toggleMultipleShopsCustomModal = (e) => {
    setCustomModalMultipleShopsAlert(!customModalMultipleShopsAlert);
  }

  async function populateSpecialOffersData() {
    if (!specialOffersReachedEnd) {
      setLoading(true);
      try {
        // const response = await fetch('weatherforecast');

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: specialOffers.length, pageSize: 20, qString: specialOfferSearchString, qShopID: specialOfferShop })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/ListSpecialOffers`, requestOptions);

          const data = await response.json();

          setTotal(data.total);

          if (data.data.length === 0) {
            setSpecialOffersReachedEnd(true);
            setLoading(false);
          }

          const fullSpecialOffersArr = [...specialOffers, ...data.data];

          setSpecialOffers(fullSpecialOffersArr);
        }
        else {
          setSpecialOffers([]);
        }
        setLoading(false);
      }
      catch {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  }

  const optimizedGetSpecialOffers = useCallback(debounce(populateSpecialOffersData, null), []);

  const renderSpecialOffersTable = () => {
    return (
      <Container className={styles.container}>
        <Row xs={1} md={1} className={"g-4 " + styles.cardsWrapper}>
          {specialOffers && specialOffers.map(specialOffer => {
            return (
              <SpecialOfferCard
                specialOffer={specialOffer}
                key={specialOffer.ID}
              >{specialOffer}</SpecialOfferCard>
            );
          })}
        </Row>
      </Container>
    );
  }

  let contents = loading && !specialOffersReachedEnd
    ? <Container><em>Loading...</em></Container>
    : '';

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setSpecialOfferShop(currentShop);
              setSpecialOfferSearchString(currentSearchString);

              return (
                <div id="infiniteList">

                  {renderSpecialOffersTable()}
                  {contents}

                  {(specialOfferShop && specialOfferShop !== 0 && Number(specialOfferShop) !== 0) ?
                    <div className={styles.specialOffersAddCircle}>
                      <div className={styles.specialOffersAddPlus}>
                        <NavLink tag={Link} to={`${path}edit/new`} className={styles.addPlusNavLink}>
                          <img src="/assets/products/products_add_item.svg" alt="" />
                        </NavLink>
                      </div>
                    </div>
                    : null}

                  <CustomPopup className={styles.specialOffersAlertMain} show={customModalMultipleShopsAlert} onClose={(e) => { toggleMultipleShopsCustomModal(); }} title={""} >
                    <div className={styles.specialOffersAlertBellWrapper}><img src="/assets/products/products_alert_bell.svg" alt="" /></div>
                    <h2>{t('products.noShopSelected')}</h2>
                    <div></div>
                    {/*<div>*/}
                    {/*  {t('products.noShopSelectedText')}*/}
                    {/*</div>*/}
                    <div className={styles.specialOffersAlertActionsWrapper}>
                      <button color="link" className={styles.specialOffersAlertActionBtn} onClick={(e) => { toggleMultipleShopsCustomModal(); }} >{t('products.alertCancelText')}</button>
                      <NavLink tag={Link} to="/preferences" className={styles.specialOffersAlertActionBtn}>{t('products.goToPreferences')}</NavLink>
                    </div>
                  </CustomPopup>

                  <ErrorMessageCustomPopup show={specialOffersError} title={specialOffersErrorMessage} onClose={(e) => { setSpecialOffersError(false); }} />

                  <LoaderAnimation show={showLoader} allowClose={false} />

                  <div className={styles.infiniteScrollQueue} id="infiniteScrollQueue"></div>
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );
}

export default SpecialOffersList