import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './specialoffer-card.module.scss';
import * as Icon from 'react-bootstrap-icons';

const SpecialOfferCard = (props) => {
  const { specialOffer } = props;

  const [specialOfferCardOpen, setSpecialOfferCardOpen] = useState(false);

  const [specialOfferId, setSpecialOfferId] = useState(0);
  const [specialOfferPrice, setSpecialOfferPrice] = useState(0);

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  useEffect(() => {
    setSpecialOfferPrice(specialOffer.Price);

    setSpecialOfferId(specialOffer.ID);
  }, []);

  const toggleSpecialOfferCard = (e) => {
    if (!specialOfferCardOpen == true) {
      //onHandleMoveSpecialOfferCardOpenToTop(productId);
    }
    setSpecialOfferCardOpen(!specialOfferCardOpen);
  }

  return (
    <Card className={styles.specialOfferCard + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL) + " " + ((specialOffer.Updated) ? 'border-success' : '')}>
      <CardHeader onClick={(e) => { toggleSpecialOfferCard(e) }} className={styles.cardHeader}>
        <Row className={styles.cardRow}>
          <Col lg="10" xs="10" className={styles.cardColumn + " " + styles.cardInfoContainer}>
            <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
              <NavLink tag={Link} to={`${path}edit/${specialOffer.ID}`} className={styles.cardNavLink + " " + styles.cardCollectionLink}>
                {specialOffer.Title}
              </NavLink>
            </CardTitle>
          </Col>
          <Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)}>
            {(specialOfferCardOpen) ?
              <Icon.ChevronUp />
              :
              <Icon.ChevronDown />}
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={specialOfferCardOpen}>
        <CardBody className={styles.cardEditSectionWrapper}>
          <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
            <Row className={styles.cardEditRow}>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {t('specialOffers.dateStart')}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {specialOffer.StartDate}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {t('specialOffers.dateEnd')}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {specialOffer.EndDate}
              </Col>
            </Row>
            <Row className={styles.cardEditRow}>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {t('specialOffers.priority')}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {specialOffer.Priority}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {t('specialOffers.updatedOn')}
              </Col>
              <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                {specialOffer.UpdatedOn}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default SpecialOfferCard