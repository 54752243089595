import React from 'react';
import styles from './notfound.module.css';
 
function NotFound(props) {
  const hOneStyle = { color: '#000000' };
 return (
 <div className={styles.container}>
     <h1 style={hOneStyle}>Page not found</h1>
 </div>
 );
}
 
export default NotFound;