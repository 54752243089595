import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import common_he from "./translations/he/common.json";
//import common_en from "./translations/en/common.json";
//import { I18nextProvider } from "react-i18next";
//import i18next from "i18next";
import i18next from "./i18n";

//import '../node_modules/bootstrap/scss/bootstrap.scss';
//import "bootstrap/dist/css/bootstrap.min.css";
//import 'bootstrap/dist/css/bootstrap.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

//const languageSelected = (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'he';

//i18next.init({
//  interpolation: { escapeValue: false },
//  lng: languageSelected,
//  resources: {
//    en: {
//      common: common_en
//    },
//    he: {
//      common: common_he
//    },
//  },
//});

ReactDOM.render(
  <App />,
  rootElement);

registerServiceWorker();

