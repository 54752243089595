import React, { Component, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, CardBody
} from "reactstrap";

import styles from './order-charge.module.scss';

const OrderCharge = ({ onHandleCharge, shopId, chosenShopId, orderData, orderItemsData}) => {
  const [user, setUser] = useState({});

  const { t, i18n } = useTranslation('common');

  const [orderChargeCustomerDirection, setOrderChargeCustomerDirection] = useState(0);
  const [orderChargeCustomerName, setOrderChargeCustomerName] = useState('');
  const [orderChargeCustomerPrimaryPhone, setOrderChargeCustomerPrimaryPhone] = useState('');
  const [orderChargeCustomerSecondaryPhone, setOrderChargeCustomerSecondaryPhone] = useState('');
  const [orderChargeCustomerAddress, setOrderChargeCustomerAddress] = useState('');
  const [orderChargeCustomerPickupOrDelivery, setOrderChargeCustomerPickupOrDelivery] = useState('');
  const [orderChargeCustomerDeliveryWindow, setOrderChargeCustomerDeliveryWindow] = useState('');
  const [orderChargeCustomerComments, setOrderChargeCustomerComments] = useState('');
  const [orderChargeCustomerItemsOrdered, setOrderChargeCustomerItemsOrdered] = useState(0);
  const [orderChargeCustomerItemsCollected, setOrderChargeCustomerItemsCollected] = useState(0);
  const [orderChargeCustomerSubTotal, setOrderChargeCustomerSubTotal] = useState(0);
  const [orderChargeCustomerShipCost, setOrderChargeCustomerShipCost] = useState(0);
  const [orderChargeCustomerTotal, setOrderChargeCustomerTotal] = useState(0);

  useEffect(() => {
    //console.log(orderData);

    let deliveryWindowText = '';

    if (orderData.ShippingMethod == 1) {
      let thisDate = moment(orderData.ShipTimeFrom, "DD/MM/YYYY hh:mm");
      deliveryWindowText = t('orders.isoDay' + (Number(thisDate.day() + 1))) + ', ' + thisDate.format("MMM D") + ' ' + thisDate.format('HH:mm') + ' - ' + moment(orderData.ShipTimeTo, "DD/MM/YYYY hh:mm").format('HH:mm')
    }
    else {
      let thisDate = moment(orderData.PickupTimeFrom, "DD/MM/YYYY hh:mm");
      deliveryWindowText = t('orders.isoDay' + (Number(thisDate.day() + 1))) + ', ' + thisDate.format("MMM D") + ' ' + thisDate.format('HH:mm') + ' - ' + moment(orderData.PickupTimeTo, "DD/MM/YYYY hh:mm").format('HH:mm');
    }

    setOrderChargeCustomerDirection(orderData.MissingProductAction);
    setOrderChargeCustomerName(orderData.FullName);
    setOrderChargeCustomerPrimaryPhone(orderData.Phone);
    setOrderChargeCustomerSecondaryPhone(orderData.ShipAddressAddressPhoneNumber);
    setOrderChargeCustomerAddress(orderData.ShipAddressStreet);
    setOrderChargeCustomerPickupOrDelivery(orderData.ShippingMethod);
    setOrderChargeCustomerDeliveryWindow(deliveryWindowText);
    setOrderChargeCustomerComments(orderData.Comment);
    setOrderChargeCustomerSubTotal(orderData.OriginalTotal);
    setOrderChargeCustomerShipCost(orderData.ShipCost);
    //console.log(orderData.Total);
    setOrderChargeCustomerTotal(orderData.Total);

    setOrderChargeCustomerItemsOrdered(orderItemsData.filter(oi => !oi.Deleted && !oi.Replaced).length);
    setOrderChargeCustomerItemsCollected(orderItemsData.filter(oi => oi.QuantityPicked > 0 && !oi.Deleted && !oi.Replaced).length);
  }, [orderData, orderItemsData]);

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              //setProductSearchString(currentSearchString);

              return (

                <div className={styles.modalOrderChargeContainer + " " + (getCurrentLng() === 'en' ? "" : styles.OrderChargeRtl)}>
                  <div className={styles.modalOrderChargeTitle}>
                    {t('orderCharge.orderChargeTitle')}
                  </div>
                  <div className={styles.modalOrderChargeCustomerDirection}>
                    <b>{t('orderCharge.orderChargeCustomerDirection')}</b>
                    { ": " +
                      ((orderChargeCustomerDirection == 1) ? t('orderCollection.missingProductActionNothing') :
                        ((orderChargeCustomerDirection == 2) ? t('orderCollection.missingProductActionReplace') : t('orderCollection.missingProductActionCall'))
                      )
                    }
                  </div>
                  <Card className={ styles.modalOrderChargeDataCard}>
                    <CardBody>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerDetails')}
                        </Col>
                        <Col lg="5" xs="5">

                        </Col>
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerName')}
                        </Col>
                        <Col lg="5" xs="5">
                          {orderChargeCustomerName}
                        </Col>
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerPrimaryPhone')}
                        </Col>
                        <Col lg="5" xs="5">
                          <a className={styles.phoneLink} href={"tel:" + orderChargeCustomerPrimaryPhone} title={t('orderCollection.call')}>{orderChargeCustomerPrimaryPhone}</a>
                        </Col>
                        <Col lg="2" xs="2">
                          <img src="/assets/collection/order_collection_charge_phone.svg" alt="" title="" />
                        </Col>
                      </Row>
                      {(orderChargeCustomerSecondaryPhone != null && orderChargeCustomerSecondaryPhone.length > 0) ?
                        <Row>
                          <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                            {t('orderCharge.orderChargeCustomerSecondaryPhone')}
                          </Col>
                          <Col lg="5" xs="5">
                            <a className={styles.phoneLinkSecondary} href={"tel:" + orderChargeCustomerSecondaryPhone} title={t('orderCollection.call')}>{orderChargeCustomerSecondaryPhone}</a>
                          </Col>
                          <Col lg="2" xs="2">

                          </Col>
                        </Row>
                          :
                          null
                      }
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerAddress')}
                        </Col>
                        <Col lg="5" xs="5">
                          {orderChargeCustomerAddress}
                        </Col>
                        <Col lg="2" xs="2">
                          <img src="/assets/collection/order_collection_charge_edit_address.svg" alt="" title="" />
                        </Col>
                      </Row>
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="5" xs="10">
                          {((orderChargeCustomerPickupOrDelivery == 2) ?
                            <img src="/assets/collection/order_collection_charge_chk_filled.svg" alt="" title="" /> :
                            <img src="/assets/collection/order_collection_charge_chk_empty.svg" alt="" title="" />)}&#160;
                          {t('orderCharge.orderChargeCustomerIsPickup')}
                          &#160;&#160;&#160;
                          {((orderChargeCustomerPickupOrDelivery == 1) ?
                            <img src="/assets/collection/order_collection_charge_chk_filled.svg" alt="" title="" /> :
                            <img src="/assets/collection/order_collection_charge_chk_empty.svg" alt="" title="" />)}&#160;
                          {t('orderCharge.orderChargeCustomerIsDelivery')}
                        </Col>
                        
                          {/*{orderChargeCustomerPickupOrDelivery}*/}
                        
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerDeliveryWindow')}
                        </Col>
                        <Col lg="5" xs="5">
                          <b>{orderChargeCustomerDeliveryWindow}</b>
                        </Col>
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      {(orderChargeCustomerShipCost && orderChargeCustomerShipCost > 0) ?
                        <div className={styles.modalOrderChargeDataCardDivider}></div>
                        : null
                      }
                      {(orderChargeCustomerShipCost && orderChargeCustomerShipCost > 0) ?
                        <Row>
                          <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                            {t('orderCharge.orderChargeCustomerDeliveryCost')}
                          </Col>
                          <Col lg="5" xs="5">
                            {orderChargeCustomerShipCost}
                          </Col>
                          <Col lg="2" xs="2">

                          </Col>
                        </Row>
                        : null
                      }
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerItemsOrdered')}
                        </Col>
                        <Col lg="5" xs="5">
                          {orderChargeCustomerItemsOrdered}
                        </Col>
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      <Row>
                        <Col lg="5" xs="5" className={styles.modalOrderChargeDataCardFieldName}>
                          {t('orderCharge.orderChargeCustomerItemsCollected')}
                        </Col>
                        <Col lg="5" xs="5">
                          {orderChargeCustomerItemsCollected}
                        </Col>
                        <Col lg="2" xs="2">

                        </Col>
                      </Row>
                      <div className={styles.modalOrderChargeDataCardDivider}></div>
                      <Row>
                        <Col lg="12" xs="5">
                          <img src="/assets/collection/order_collection_charge_notes.svg" alt="" title="" />&#160;
                          {orderChargeCustomerComments}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className={styles.modalOrderChargeBottomPurple}>
                    <Row>
                      <Col lg="6" xs="6">
                        {t('orderCharge.orderChargeEstimatedTotal')}
                        <div className={styles.modalOrderChargeBottomPurpleSubTotal}>
                          <span className={styles.modalOrderChargePrice + " " + ((getCurrentLng() == 'en') ? null : styles.modalOrderChargePriceRtl)}>{orderChargeCustomerSubTotal}</span>
                        </div>
                      </Col>
                      <Col lg="6" xs="6">
                        <div>{t('orderCharge.orderChargeTotal')}</div>
                        {((orderChargeCustomerShipCost && orderChargeCustomerShipCost !== 0) ?
                          <div className={styles.modalOrderChargeBottomPurpleShipCost}>
                            <span className={styles.modalOrderChargePrice + " " + ((getCurrentLng() == 'en') ? null : styles.modalOrderChargePriceRtl)}>{orderChargeCustomerShipCost}</span>
                            {t('orderCharge.orderChargeIncludingShipCost')}
                          </div>
                          : null
                        )}
                        <div className={styles.modalOrderChargeBottomPurpleTotal}>
                          <span className={styles.modalOrderChargePrice + " " + ((getCurrentLng() == 'en') ? null : styles.modalOrderChargePriceRtl)}>{orderChargeCustomerTotal}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12">
                        <Button color="link" className={styles.modalOrderChargeBtn} onClick={onHandleCharge}><img src="/assets/collection/order_collection_charge_cc.svg" alt="" title="" /> {t('orderCharge.orderChargeBtnText')}</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            }}
          </layoutContext.Consumer >
        )
      }}

    </userContext.Consumer >
  );

}
export default OrderCharge