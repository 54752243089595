import React from "react";
import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';
import NotFound from '../Pages/NotFound';
import Home from '../Pages/Home';
import ProductsList from "../Pages/Products";
import Orders from '../Pages/Orders';
import OrderCollection from '../Pages/OrderCollection';
import ForgotPassword from "../Pages/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword";
import Preferences from "../Pages/Preferences";
import SpecialWorkTimes from "../Pages/Preferences/SpecialWorkTimes";
import SpecialOffersList from "../Pages/SpecialOffers/index";
import SpecialOffersEdit from "../Pages/SpecialOffers/SpecialOfferEdit";
import ShippingTimesList from "../Pages/ShippingTimes/index";
import ShippingTimeEdit from "../Pages/ShippingTimes/ShippingTimeEdit";
import DeliveryZoneEdit from "../Pages/ShippingTimes/DeliveryZoneEdit";

const routes = [
  {
    path: '/',
    component: Home,
    isPrivate: true,
    //isPrivate: false,
    exact: "exact"
  },
  {
    path: '/home',
    component: Home,
    isPrivate: true,
    //isPrivate: false,
    exact: "exact"
  },
  //{
  //  path: '/dashboard',
  //  component: Dashboard,
  //  isPrivate: true,
  //},
  {
    path: '/products/',
    component: ProductsList,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/orders/',
    component: Orders,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/orders/order/:id',
    component: OrderCollection,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/preferences/',
    component: Preferences,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/preferences/specialworktimes/',
    component: SpecialWorkTimes,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/specialoffers/',
    component: SpecialOffersList,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/specialoffers/edit/:id',
    component: SpecialOffersEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/specialoffers/edit/new/',
    component: SpecialOffersEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/shippingtimes/',
    component: ShippingTimesList,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/shippingtimes/edit/:id',
    component: ShippingTimeEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/shippingtimes/edit/new/',
    component: ShippingTimeEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/shippingtimes/deliveryzone/:id',
    component: DeliveryZoneEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/shippingtimes/deliveryzone/new/',
    component: DeliveryZoneEdit,
    isPrivate: true,
    exact: "exact"
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    exact: "exact"
  },
  {
    path: '/forgot',
    component: ForgotPassword,
    isPrivate: false,
    exact: "exact"
  },
  {
    path: '/reset',
    component: ResetPassword,
    isPrivate: false,
    exact: "exact"
  },
  {
    path: '/*',
    component: NotFound,
    isPrivate: false,
    exact: "exact"
  },
]
   
export default routes