import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink } from 'reactstrap';
//import useState from 'react-usestateref';
//import { debounce } from "lodash";
import CustomPopup from '../../Components/CustomPopup';
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';

import ProductCard from './ProductCard';
import useInfiniteScroll from '../../Components/InfiniteScroll';
import ProductStockFilters from './ProductStockFilters';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import ProductEdit from "./ProductEdit";

import ImageUpload from "../../Components/ImageUpload";
import Camera from '../../Components/Camera';

import styles from './products.module.scss';

const ProductsList = (props) => {
  const [user, setUser] = useState({});
  const [productShop, setProductShop] = useState(null);
  const [productSearchString, setProductSearchString] = useState('');

  //const [layoutDataInitialized, setLayoutDataInitialized] = useState(false);

  const [productsReachedEnd, setProductsReachedEnd] = useState(false);

  const [stockFilter, setStockFilter] = useState('2');
  const [total, setTotal] = useState(0);
  const [totalInStock, setTotalInStock] = useState(0);
  const [totalOutOfStock, setTotalOutOfStock] = useState(0);

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useInfiniteScroll(populateProductsData, null, '#infiniteScrollQueue');

  const [showLoader, setShowLoader] = useState(false);

  const [modalProdImg, setModalProdImg] = useState(false);
  const [prodImgId, setProdImgId] = useState(0);

  const [productImageFiles, setProductImageFiles] = useState([]);
  const [productPictures, setProductPictures] = useState(null);
  const [takingPituresModes, setTakingPicturesMode] = useState(false);

  const [modalProductEdit, setModalProductEdit] = useState(false);

  const [customModalMultipleShopsAlert, setCustomModalMultipleShopsAlert] = useState(false);

  const [customModalImagesUploadedAlert, setCustomModalImagesUploadedAlert] = useState(false);

  const [productEditError, setProductEditError] = useState(false);
  const [productEditErrorMessage, setProductEditErrorMessage] = useState('');

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    document.title = t('nav.products');
  }, []);

  useEffect(() => {
    if (products == null || (loading && !productsReachedEnd)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    if (productEditErrorMessage && productEditErrorMessage.length > 0) {
      setProductEditError(true);
    }
  }, [productEditErrorMessage]);

  useEffect(() => {
    setProducts([]);
    setProductsReachedEnd(false);
    optimizedGetProducts();

    return function cleanUp() {
      setProducts([]);
      setProductsReachedEnd(false);
    };
  }, [productShop, productSearchString]);

  useEffect(() => {
    //console.log(productShop);
    if (productShop != null) {
      if (Number(productShop) == 0) { //layoutDataInitialized
        toggleMultipleShopsCustomModal();
      }
    }

  }, [productShop]);

  useEffect(() => {
    setProducts([]);
    setProductsReachedEnd(false);
    optimizedGetProducts();

    return function cleanUp() {
      setProducts([]);
      setProductsReachedEnd(false);
    };
  }, [stockFilter]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const toggleProdImgModal = (e) => {
    //document.body.style.overflow = (!modalProdImg) ? 'hidden' : 'unset';
    let filesObject = {};
    if (prodImgId != 0) {      
      const product = products.filter(p => p.ID == prodImgId)[0]
      if (product.LinkedImages && product.LinkedImages.length > 0) {
        filesObject = {
          files: product.LinkedImages.map(file =>
            Object.assign(file, {
              preview: file.Image,
              name: file.Image,
              path: file.Image,
              type: 'img/png'
            })
          )
        };
      }
      //console.log(filesObject);
    }
    else {      
      setProductImageFiles(filesObject);
    }
    setProductImageFiles([]);
    setProductPictures(null);
    setModalProdImg(!modalProdImg);
    setTakingPicturesMode(false);
  };

  useEffect(() => {
    return function cleanUp() {
      if (productImageFiles.length > 0) productImageFiles.forEach(file => URL.revokeObjectURL(file.preview));
    }
  });

  const toggleProductEditModal = (e) => {
    //document.body.style.overflow = (!modalProductEdit) ? 'hidden' : 'unset';
    if (modalProductEdit) {
      setProdImgId(0);
    }
    setModalProductEdit(!modalProductEdit);
  }

  const addFile = (files) => {
    //console.log(files);
    let filesObject = {};
    if (files && files.length > 0) {
      filesObject = {
        files: files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      };
    }
    //console.log(filesObject);
    setProductImageFiles(filesObject);
  };

  const addPictureFromCanvas = (canvasInfo) => {
    if (canvasInfo) {
      setProductPictures(canvasInfo);
      console.log(canvasInfo);
    }
  };

  const handleSubmit = (e) => {
    setProducts([]);

    e.preventDefault();
    optimizedGetProducts();
  };

  const handleStockFilterChange = (value) => {
    setStockFilter(value);
    setProducts([]);
  };

  const handleSearchStringChange = (value) => {
    setProducts([]);
    setProductSearchString(value);
  };

  const handleUploadImageShow = (productId) => {
    setProdImgId(productId);
    toggleProdImgModal();
  };

  const handleProductInShopEditShow = (productId) => {
    setProdImgId(productId);
    toggleProductEditModal();
  };

  const toggleMultipleShopsCustomModal = (e) => {
    //document.body.style.overflow = (!customModalMultipleShopsAlert) ? 'hidden' : 'unset';
    setCustomModalMultipleShopsAlert(!customModalMultipleShopsAlert);
  }

  const toggleImagesUploadedCustomModal = (e) => {
    //document.body.style.overflow = (!customModalImagesUploadedAlert) ? 'hidden' : 'unset';
    setCustomModalImagesUploadedAlert(!customModalImagesUploadedAlert);
  }

  const handlePriceChange = (value, productId) => {
    updateProductValue("Price", value, productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].Updated = true;
          thisProduct[0].Price = value;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, Price: value }) : el)));
        }
      }
    });
  };

  const handleShowInStockChange = (value, productId) => {
    //updateProductValue("ShowInStock", value, productId).then((success) => {
    //  if (success) {
    //    let thisProduct = products.filter(p => p.ID == productId);
    //    if (thisProduct.length > 0) {
    //      thisProduct[0].Updated = true;
    //      thisProduct[0].ShowInStock = value;
    //      setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, ShowInStock: value }) : el)));
    //    }
    //  }
    //});

    updateProductValue("UnlimitedQuantity", value, productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].Updated = true;
          thisProduct[0].UnlimitedQuantity = value;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, UnlimitedQuantity: value }) : el)));
          if (!value) {
            const quntityValue = 0;
            updateProductValue("Quantity", quntityValue, productId).then((success) => {
              if (success) {
                let thisProduct = products.filter(p => p.ID == productId);
                if (thisProduct.length > 0) {
                  thisProduct[0].Quantity = quntityValue;
                  setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, Quantity: quntityValue }) : el)));
                }
              }
            });
          }
        }
      }
    });
  };

  const handleUnlimitedQuantityChange = (value, productId) => {
    updateProductValue("UnlimitedQuantity", value ? "true" : "false", productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].UnlimitedQuantity = value;
          thisProduct[0].Updated = true;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, UnlimitedQuantity: value }) : el)));
        }
      }
    });
  }

  const handleStockQuantityChange = (value, productId) => {
    updateProductValue("Quantity", value, productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].Updated = true;
          thisProduct[0].Quantity = value;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, Quantity: value }) : el)));
        }
      }
    });
  };

  const handlePrivateTextChange = (value, productId) => {
    updateProductValue("PrivateText", value, productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].Updated = true;
          thisProduct[0].PrivateText = value;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, PrivateText: value }) : el)));
        }
      }
    });
  };

  const handleCommentsForAdminChange = (value, productId) => {
    updateProductValue("CommentsForAdmin", value, productId).then((success) => {
      if (success) {
        let thisProduct = products.filter(p => p.ID == productId);
        if (thisProduct.length > 0) {
          thisProduct[0].Updated = true;
          thisProduct[0].CommentsForAdmin = value;
          setProducts(products.map(el => (el.ID === productId ? Object.assign({}, el, { Updated: true, CommentsForAdmin: value }) : el)));
        }
      }
    });
  };

  const handleProductEdit = () => {

  };

  async function moveProductToTop(productId) {
    console.log('unshifting ' + productId);
    let thisProduct = products.filter(p => p.ID == productId);
    if (thisProduct.length > 0) {
      let data = products;
      data.forEach(function (item, i) {
        if (item.ID === productId) {
          data.splice(i, 1);
          data.unshift(item);
        }
      });
      setProducts(data.map(el => (el.ID === productId ? Object.assign({}, el, { Top: true }) : el)));
    }
  }

  async function populateProductsData() {
    if (!productsReachedEnd) {
      setLoading(true);
      try {
        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: products.length, pageSize: 20, qString: productSearchString, qShopID: productShop, qStockFilter: stockFilter })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/ListProducts`, requestOptions);

          const data = await response.json();

          setTotal(data.total);
          setTotalInStock(data.totalInStock);
          setTotalOutOfStock(data.totalOutOfStock);

          if (data.data.length === 0) {
            setProductsReachedEnd(true);
            setLoading(false);
          }

          const fullProductsArr = [...products, ...data.data];

          //setProducts(prevState => ([...prevState, ...data.data]));
          setProducts(fullProductsArr);
        }
        else {
          setProducts([]);
        }
        setLoading(false);
      }
      catch {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  }

  async function updateProductValue(name, value, id) {
    try {
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, productInShopId: id })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      setShowLoader(true);

      return fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/SetValue`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.success === true) {
            setShowLoader(false);
            return true;
          }
          else {
            setShowLoader(false);
            setProductEditErrorMessage(t('products.error_updating_product'));
            return false;
          }
        });
    }
    catch {
      setShowLoader(false);
      setProductEditErrorMessage(t('errors.connection_error'));
      return false;
    }
  }

  async function uploadImage() {
    //var data = new FormData()
    //data.append('file', input.files[0])
    //console.log(productImageFiles);

    const formData = new FormData();

    //for (const f of file) {
    //  formData.append('image', f);
    //}
    //formData.append('Image', productImageFiles.files[0]);
    productImageFiles.files.forEach((file, i) => formData.append(`Image`, file))
    formData.append('productInShopId', prodImgId);

    try {
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: formData,
        //type: 'uploadAll',
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      setShowLoader(true);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/SetProductImage`, requestOptions);
      const data = await response.json();


      if (data.success == true) {
        setShowLoader(false);
        toggleProdImgModal();
        toggleImagesUploadedCustomModal();
        return true;
      }
      else {
        toggleProdImgModal();
        setShowLoader(false);

        if (data.error === "no_image") {
          setProductEditErrorMessage(t('products.error_no_image'));
        }
        else if (data.error === "no_product_found") {
          setProductEditErrorMessage(t('products.error_no_product_found'));
        }
        else if (data.error === "image_too_large_in_mb") {
          setProductEditErrorMessage(t('products.error_image_too_large_in_mb'));
        }
        else if (data.error === "server_failed_save") {
          setProductEditErrorMessage(t('products.error_server_failed_save'));
        }
        else {
          setProductEditErrorMessage(t('products.error_image_upload'));
        }
        return false;
      }
    }
    catch {
      toggleProdImgModal();
      setShowLoader(false);
      setProductEditErrorMessage(t('products.error_image_upload'));
      return false;
    }
  }

  async function uploadPicture() {
    try {
      const formData = new FormData();
      formData.append('image', productPictures);
      formData.append('productInShopId', prodImgId);

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          //'Accept': 'image/jpeg'
          //'Content-Type': 'multipart/form-data',
        },
        body: formData,
        //type: 'uploadAll',
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      setShowLoader(true);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/SetProductPicture`, requestOptions);
      const data = await response.json();

      if (data.success == true) {
        setShowLoader(false);
        toggleProdImgModal();
        toggleImagesUploadedCustomModal();
        return true;
      }
      else {
        toggleProdImgModal();
        setShowLoader(false);

        if (data.error === "no_image") {
          setProductEditErrorMessage(t('products.error_no_image'));
        }
        else if (data.error === "no_product_found") {
          setProductEditErrorMessage(t('products.error_no_product_found'));
        }
        else if (data.error === "image_too_large_in_mb") {
          setProductEditErrorMessage(t('products.error_image_too_large_in_mb'));
        }
        else if (data.error === "server_failed_save") {
          setProductEditErrorMessage(t('products.error_server_failed_save'));
        }
        else {
          setProductEditErrorMessage(t('products.error_image_upload'));
        }
      }
    }
    catch {
      toggleProdImgModal();
      setShowLoader(false);
      setProductEditErrorMessage(t('errors.connection_error'));
      return false;
    }
  }

  const optimizedGetProducts = useCallback(debounce(populateProductsData, null), []);
  const optimizedHandlePriceChange = useCallback(debounce(handlePriceChange, null), [products]);
  const optimizedShowInStockChange = useCallback(debounce(handleShowInStockChange, null), [products]);
  const optimizedUnlimitedQuantityChange = useCallback(debounce(handleUnlimitedQuantityChange, null), [products]);
  const optimizedStockQuantityChange = useCallback(debounce(handleStockQuantityChange, null), [products]);
  const optimizedPrivateTextChange = useCallback(debounce(handlePrivateTextChange, null), [products]);
  const optimizedCommentsForAdminChange = useCallback(debounce(handleCommentsForAdminChange, null), [products]);

  const renderProductsTable = () => {
    return (
      <div className="container">
        <Row xs={1} md={1} className={"g-4 " + styles.cardsWrapper}>
          {products && products.map(product => {
            return (
              <ProductCard
                //onHandlePriceChange={(e) => { optimizedHandlePriceChange(e.target.value, e.target.getAttribute('data-productid')); }}
                onHandlePriceChange={(e, itemId) => { optimizedHandlePriceChange(e, itemId); }}
                onHandleShowInStockChange={(e, itemId) => { optimizedShowInStockChange(e, itemId); }}
                onHandleUnlimitedQuantityChange={(e, itemId) => { optimizedUnlimitedQuantityChange(e, itemId); }}
                onHandleStockQuantityChange={(e, itemId) => { optimizedStockQuantityChange(e, itemId); }}
                onHandleProductImageUploadShow={(e) => { handleUploadImageShow(e); }}
                onHandleProductInShopEditShow={(e) => { handleProductInShopEditShow(e); }}
                onHandlePrivateTextChange={(e, itemId) => { optimizedPrivateTextChange(e, itemId); }}
                onHandleCommentsForAdminChange={(e, itemId) => { optimizedCommentsForAdminChange(e, itemId); }}
                onHandleMoveProductToTop={(itemId) => { moveProductToTop(itemId); } }
                isReplacement={false}
                product={product}
                showProductShopName={(productShop == 0) ? true : false}
                key={product.ID + '_' + product.SKU + '_' + stockFilter}
              />
            );
          })}
        </Row>
      </div>
    );
  }

  let contents = loading && !productsReachedEnd
    ? <Container><em>Loading...</em></Container>
    : '';

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              //setLayoutDataInitialized(true);
              setProductShop(currentShop);
              setProductSearchString(currentSearchString);
              //handleSearchStringChange(currentSearchString);

              return (
                <div id="infiniteList">

                  <ProductStockFilters
                    onHandleStockFilterChange={(e) => {
                      handleStockFilterChange(e);
                    }}
                    total={total}
                    totalInStock={totalInStock}
                    totalOutOfStock={totalOutOfStock}
                  />

                  {renderProductsTable()}
                  {contents}
                  
                  {(productShop && productShop !== 0 && Number(productShop) !== 0) ?
                    <div className={styles.productsAddCircle} onClick={(e) => {
                      toggleProductEditModal(e)
                    }}>
                      <div className={styles.productsAddPlus}>
                        <img src="/assets/products/products_add_item.svg" alt="" />
                      </div>
                    </div>
                    : null}

                  <Modal isOpen={modalProdImg} toggle={toggleProdImgModal} scrollable={true} fullscreen={true} backdrop="static">
                    <ModalHeader toggle={toggleProdImgModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}></ModalHeader>
                    <ModalBody className={styles.modalProductContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>

                      <ImageUpload className={styles.uploadImgContainer + " " + ((takingPituresModes) ? styles.uploadImgHidden : null)} addFile={addFile} files={productImageFiles} />
                      <Camera
                        addPictureFromCanvas={(canvasData) => { addPictureFromCanvas(canvasData); }}
                        takingPicturesModeSet={() => { setTakingPicturesMode(true); }}
                      />
                      
                    </ModalBody>
                    <ModalFooter>
                      <Button color="link" className={styles.uploadImgBtn + " " + ((productImageFiles == [] || productImageFiles.length === 0) ? styles.uploadImgBtnHide : null)} disabled={productImageFiles == [] || productImageFiles.length === 0} onClick={(e) => { uploadImage(); }}>שלח</Button>
                      <Button color="link" className={styles.uploadImgBtn + " " + ((productPictures == null) ? styles.uploadImgBtnHide : null)} disabled={productPictures == null} onClick={(e) => { uploadPicture(); }}>שלח</Button>
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={modalProductEdit} toggle={toggleProductEditModal} scrollable={true} fullscreen={true} backdrop="static">
                    <ModalHeader toggle={toggleProductEditModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>
                      {(prodImgId == 0) ? t('products.addProductModalTitle') : t('products.editProductModalTitle')}
                      {/*{t('products.addProductModalTitle')}*/}
                    </ModalHeader>
                    <ModalBody className={styles.modalProductBody + " " + styles.modalAddProductBody + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                      <ProductEdit onHandleEdit={() => { toggleProductEditModal(); handleProductEdit(); }} productInShopId={prodImgId} />
                    </ModalBody>
                  </Modal>

                  <CustomPopup className={styles.productsAlertMain} show={customModalMultipleShopsAlert} onClose={(e) => { toggleMultipleShopsCustomModal(); }} title={""} >
                    <div className={styles.productsAlertBellWrapper}><img src="/assets/products/products_alert_bell.svg" alt="" /></div>
                    <h2>{t('products.noShopSelected')}</h2>
                    <div></div>
                    <div>
                      {t('products.noShopSelectedText')}
                    </div>
                    <div className={styles.productsAlertActionsWrapper}>
                      <button color="link" className={styles.productsAlertActionBtn} onClick={(e) => { toggleMultipleShopsCustomModal(); }} >{t('products.alertCancelText')}</button>
                      <NavLink tag={Link} to="/preferences" className={styles.productsAlertActionBtn}>{t('products.goToPreferences')}</NavLink>
                    </div>
                  </CustomPopup>

                  <CustomPopup className={styles.productsAlertMain} show={customModalImagesUploadedAlert} onClose={(e) => { toggleImagesUploadedCustomModal(); }} title={""} >
                    <div className={styles.productsAlertBellWrapper}><img src="/assets/products/products_alert_bell.svg" alt="" /></div>
                    <h2>{t('products.imageUploaded')}</h2>
                    <div></div>
                    <div>
                      {t('products.imageUploadedWait2')}
                    </div>
                    <div className={styles.productsAlertActionsWrapper}>
                      <button color="link" className={styles.productsAlertActionBtn} onClick={(e) => { toggleImagesUploadedCustomModal(); }} >{t('products.alertCancelText')}</button>
                    </div>
                  </CustomPopup>

                  <ErrorMessageCustomPopup show={productEditError} title={productEditErrorMessage} onClose={(e) => { setProductEditError(false); }} />

                  <LoaderAnimation show={showLoader} allowClose={false} />

                  <div className={styles.infiniteScrollQueue} id="infiniteScrollQueue"></div>
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );
}

export default ProductsList