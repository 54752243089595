import React, { Component, useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import { getCurrentLng } from '../i18next_fun';
import { useTranslation } from "react-i18next";

import styles from "./timepicker.module.scss";
import { propTypes } from "react-keyboard-event-handler";

const TimePicker = (props) => {

  const [timeHours, setTimeHours] = useState('00');
  const [timeMinutes, setTimeMinutes] = useState('00');

  const [editingValue, setEditingValue] = useState(false);

  useEffect(() => {
    if (props.time !== null && props.time !== undefined) {
      let currentTimeHour = new Date(props.time).getUTCHours();
      setTimeHours(pad(currentTimeHour, 2));

      let currentTimeMinute = new Date(props.time).getUTCMinutes();
      setTimeMinutes(pad(currentTimeMinute, 2));

    }
  }, [props.time])

  //function handleKeyPress(e, itemId) {
  //  if (e.keyCode !== 32) return;

  //  e.preventDefault();
  //  props.onChange(props.time, props.itemId);
  //  //onChange(e);
  //}

  function generateTimeAccordingToChange(valueHour, valueMinute) {
    let returnedDate = (new Date('0001-01-01T' + valueHour + ':' + valueMinute + ':00.000Z')).toUTCString()
    //"Mon, 01 Jan 1 00:00:00 GMT"
    props.onChange(returnedDate, props.itemId);
  }

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  const handleFocus = (event) => {
    setEditingValue(true);
    if (event && event.target) { // && event.target.select
      //event.target.value = '';
      event.target.select();
    }
  }

  const handleKeyDown = event => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      //if (props.disabled) {
      //  return false;
      //}

      //console.log(event.target.dataset);
      //debugger;
      //props.onChange(Number(event.target.value));
      //onSubmit(event);

      generateTimeAccordingToChange(event.target.value);
    }
  };

  const { t, i18n } = useTranslation('common');

  return (
    <React.Fragment>
      {((props.itemId !== undefined) ?
        <div className={styles.timePickerWrapper + " " + (getCurrentLng() === 'en' ? "" : styles.timePickerWrapperRtl)}>
          <ButtonGroup>
            <Input
                type="number"
                id={props.id + 'hours' + props.itemId}
                name={props.id + 'hours' + props.itemId}
                data-itemid={props.itemId}
                value={timeHours}
                min={0}
                max={23}
                pattern='[0-9]{0,2}'
                onChange={(e) => {
                  if (Number(e.target.value) !== NaN) {
                    if (Number(e.target.value) > 23) {
                      setTimeHours(pad(0, 2));
                      generateTimeAccordingToChange(pad(0, 2), timeMinutes);
                    }
                    else {
                      setTimeHours(pad(Number(e.target.value), 2));
                      generateTimeAccordingToChange(pad(Number(e.target.value), 2), timeMinutes);
                    }
                  }
                }}
                onKeyDown={(e) => { handleKeyDown(e); }}
                onInput={(e) => { }}
                onFocus={(e) => { handleFocus(e); }}
                className={styles.timePickerHourBox}
            />
            <span className={styles.timePickerColumnBox}>:</span>
            <Input
              type="number"
              id={props.id + 'minutes' + props.itemId}
              name={props.id + 'minutes' + props.itemId}
              data-itemid={props.itemId}
              value={timeMinutes}
              min={0}
              max={59}
              pattern='[0-9]{0,2}'
              onChange={(e) => {
                if (Number(e.target.value) !== NaN) {
                  if (Number(e.target.value) > 59) {
                    setTimeMinutes(pad(0, 2));
                    generateTimeAccordingToChange(timeHours, pad(0, 2));
                  }
                  else {
                    setTimeMinutes(pad(Number(e.target.value), 2));
                    generateTimeAccordingToChange(timeHours, pad(Number(e.target.value), 2));
                  }
                }
              }}
              onKeyDown={(e) => { handleKeyDown(e); }}
              onInput={(e) => { }}
              onFocus={(e) => { handleFocus(e); }}
              className={styles.timePickerMinuteBox}
            />
          </ButtonGroup>
        </div>
        :
        null
      )}
    </React.Fragment>
  );
};

TimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  //name: PropTypes.string,
  itemId: PropTypes.number,
  //time: PropTypes.date,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default TimePicker;
