

export async function getShopsList(user) {
  try {
    const requestOptions = {
      //credentials: 'same-origin',
      //credentials: 'include',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify({  })
    };

    if (user.token) {
      requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/ListShops`, requestOptions);

      const data = await response.json();
      if (data.length > 1) {
        //data.push({ id: 0, name: 'All Shops' });
        return ([{ id: 0, name: 'All Shops' }, ...data]);
      }
      else {
        return data;
      }
    }
    else {
      return [];
    }
  }
  catch {
    return [];
  }
}