import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback, rootClassName, scrollingElement) => { //
  const [isFetching, setIsFetching] = useState(false);

  const rootObject = ((rootClassName == null) ? null : document.querySelector(rootClassName));

  const debounce = (func, delay) => {
    let inDebounceTimer;
    return function () {
      if (inDebounceTimer) clearTimeout(inDebounceTimer);
      const context = this;
      inDebounceTimer = setTimeout(() => {
        inDebounceTimer = null;
        func.apply(context, arguments);
      }, delay);
    };
  }

  useEffect(() => {
    if (rootClassName != null && document.querySelector(rootClassName) !== undefined && document.querySelector(rootClassName) !== null) {
      document.querySelector(rootClassName).addEventListener('scroll', debounce(handleScroll, 500));
    }
    else {
      window.addEventListener('scroll', debounce(handleScroll, 500));
    }

    return () => {
      if (rootClassName != null && document.querySelector(rootClassName) !== undefined && document.querySelector(rootClassName) !== null) {
        document.querySelector(rootClassName).removeEventListener('scroll', debounce(handleScroll, 500));
      }
      else {
        window.removeEventListener('scroll', debounce(handleScroll, 500));
      }
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
      //console.log('called back');
    });
  }, [isFetching]);

  function handleScroll() {
    const target = document.querySelector(scrollingElement);

    const observer = new IntersectionObserver(updateList, {
      root: rootObject, //document.body,
      threshold: 1,
      rootMargin: "0px 0px 0px 0px",
    });

    if (target) {
      //console.log('watching infinite');
      observer.observe(target);
    }

    function updateList(entries, observer) {

      entries.forEach(entry => {
        const intersecting = entry.isIntersecting
        //console.log(intersecting ? 'intersecting' : 'not intersecting');
        //entry.target.style.backgroundColor = intersecting ? "blue" : "orange"
        if (intersecting) {
          debounce(setIsFetching(true), 1500);
        }
        observer.unobserve(target);

      });
    }
  }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;