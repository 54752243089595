import React, { Component, useCallback, useEffect, useState } from 'react';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import ToggleSwitch from '../../Components/ToggleSwitch';
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';
import NumericInput from 'react-numeric-input';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';

import ProductSearch from './ProductSearch';
import BrandSearch from './BrandSearch';

import styles from './product-edit.module.scss';
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, Form, FormGroup, Input, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';
import { number } from 'prop-types';

const ProductEdit = ({ onHandleEdit, productInShopId }) => {
  const [user, setUser] = useState({});
  const [productShop, setProductShop] = useState(0);
  const [productInShop, setProductInShop] = useState({});

  const [productShopTags, setProductShopTags] = useState([]);
  const [productShopSelectedTags, setProductShopSelectedTags] = useState([]);
  const [productTagsOpen, setProductTagsOpen] = useState(false);

  const [productId, setProductId] = useState(0);
  const [productGlobalFieldSuggestion, setProductGlobalFieldSuggestion] = useState([]);

  const [productBrandSuggestion, setProductBrandSuggestion] = useState([]);

  const [productSKU, setProductSKU] = useState('');
  const [productSKUValid, setProductSKUValid] = useState(false);
  const [productSKUCheck, setProductSKUCheck] = useState(false);

  const [productName, setProductName] = useState('');
  const [productNameValid, setProductNameValid] = useState(false);
  const [productNameCheck, setProductNameCheck] = useState(false);

  const [productEnableOverrideGlobal, setProductEnableOverrideGlobal] = useState(false);

  const [productPrice, setProductPrice] = useState(0);
  const [productUShowInStock, setProductUShowInStock] = useState(true);
  const [productUnlimitedQuantity, setProductUnlimitedQuantity] = useState(true);
  const [productStockQuantity, setProductStockQuantity] = useState(0);

  const [productFirstUnitMeasure, setProductFirstUnitMeasure] = useState(false);
  const [productFirstUnitMeasureId, setProductFirstUnitMeasureId] = useState(0);
  const [productFirstUnitMeasureValue, setProductFirstUnitMeasureValue] = useState(0);

  const [productSecondUnitMeasure, setProductSecondUnitMeasure] = useState(false);
  const [productSecondUnitMeasureId, setProductSecondUnitMeasureId] = useState(0);
  const [productSecondUnitMeasureValue, setProductSecondUnitMeasureValue] = useState(0);

  const [productDisplayOrder, setProductDisplayOrder] = useState(9999);
  const [productCapacity, setProductCapacity] = useState(0);

  const [productBrandId, setProductBrandId] = useState(0);
  const [productBrandName, setProductBrandName] = useState(0);

  const [productGlobalName, setProductGlobalName] = useState('');

  const [productSoldByWeight, setProductSoldByWeight] = useState(false);

  const [productIsMeasuredBySKU, setProductIsMeasuredBySKU] = useState(true);

  const [productEditError, setProductEditError] = useState(false);
  const [productEditErrorMessage, setProductEditErrorMessage] = useState('');

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    if (productInShopId && productInShopId !== 0) {
      getProductInShopData();
    }

    getTagsData();

    //setProductInShop({});
    //optimizedGetProduct();
    //getProductInShopData();

    return function cleanUp() {
      setProductInShop({});
    };
  }, [productShop]);

  //useEffect(() => {
  //  if (productInShopId && productInShopId != 0) {
  //    getProductInShopData();
  //  }
  //  //getGlobalProductData();

  //  return function cleanUp() {
  //    setProductInShop({});
  //  };
  //}, [productInShopId]);

  useEffect(() => {
    if (productSKU !== null && productSKU !== undefined) {
      if (String(productSKU) !== '') {
        setProductSKUCheck(true);
      }
      if (String(productSKU).trim().length > 1 && Number(productSKU) > 0) {
        setProductSKUValid(true);
      }
      else {
        setProductSKUValid(false);
      }
    }
  }, [productSKU]);

  useEffect(() => {
    if (productEditErrorMessage && productEditErrorMessage.length > 0) {
      setProductEditError(true);
    }
  }, [productEditErrorMessage]);

  useEffect(() => {
    if (productName !== null && productName !== undefined) {
      if (String(productName) !== '') {
        setProductNameCheck(true);
      }
      if (String(productName).trim().length > 1) {
        setProductNameValid(true);
      }
      else {
        setProductNameValid(false);
      }
    }
  }, [productName]);

  let timer;
  const debounce = (func, ...args) => {
    if (timer) clearTimeout(timer);

    return function (...args) {
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleEditProduct = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (productInShop) {
      saveProductInShopData();
    }
  };

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  const handleSuggestionSelect = (event, sku, name, id) => {
    //debugger;
    console.log(id);
    console.log(sku);
    console.log(name);
    if (id && id !== 0) {
      setProductId(id);
      setProductSKU(sku);
      setProductName(name);
    }

    if (id === 0) {
      setProductEnableOverrideGlobal(false);
    }
  };

  const handleBrandSelect = (event, id, text) => {
    setProductBrandId(id);
    setProductBrandName(text);
  };

  function priceFormat(num) {
    //var nis = '\U020AA';
    //const nis = '&#8362;';
    //const nis = '\20AA';
    return String(num) + " ₪"; // + " " + { nis };
  }

  function isInSelectedTags(itemId) {
    return (productShopSelectedTags.find(e => e.id == itemId) != undefined) ? true : false;
  }

  function renderSelectedTagsText() {
    return productShopSelectedTags.map(e => { return (e.text + " | "); })
  }

  const toggleProductFirstUnitMeasure = (e) => { setProductFirstUnitMeasure(!productFirstUnitMeasure); };
  const toggleProductSecondUnitMeasure = (e) => { setProductSecondUnitMeasure(!productSecondUnitMeasure); };
  const toggleProductTagsOpen = (e) => { setProductTagsOpen(!productTagsOpen); };

  function unitMeasureFormat(num) {
    switch (num) {
      case 0:
        return t("productEdit.selectUnitMeasure0");
      case 1:
        return t("productEdit.selectUnitMeasure1");
      case 3:
        return t("productEdit.selectUnitMeasure3");
      case 4:
        return t("productEdit.selectUnitMeasure4");
      case 5:
        return t("productEdit.selectUnitMeasure5");
      case 8:
      default:
        return t("productEdit.selectUnitMeasure8");

    }
  }

  async function getTagsData() {
    try {

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({})
      };

      if (Boolean(user.token)) {
        //requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
        const ts = Date.now();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Tag/autocomplete?countOnPage=30&shop_id=${productShop}&ts=${ts}`);

        const data = await response.json();

        if (data.items?.length === 0) {

        }
        else {
          setProductShopTags(data.items);
        }
        console.log(data);

      }
      else {
        //setProductInShop({});
      }
    }
    catch {
      setProductEditErrorMessage('Error');
    }
  }

  async function getGlobalProductData(globalProductId) {
    if (globalProductId !== null) {
      try {

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({})
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const ts = Date.now();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/GetGlobalProduct?id=${globalProductId}&ts=${ts}`, requestOptions);

          const data = await response.json();

          if (!data.product) {

          }
          console.log(data);

          let currentGlobal = data.product;
          let currentSuggestion = [];
          currentSuggestion.push(currentGlobal);
          setProductGlobalFieldSuggestion(currentSuggestion);
          setProductGlobalName(currentSuggestion.text);

          //console.log(data);
          //setProductInShop.data);
        }
        else {
          //setProductInShop({});
        }
      }
      catch {
        setProductEditErrorMessage('Error');
      }
    }
  }
    
  async function getProductInShopData() {

    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qProductInShopID: productInShopId, qShopID: productShop, qStockFilter: 0 })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/ListProducts`, requestOptions);

        const data = await response.json();

        if (data.data?.length === 0) {

        }
        else if (data.data?.length === 1) {
          let thisPIS = data.data[0];
          setProductId(thisPIS.ProductID);
          if (thisPIS.ProductID) {
            await getGlobalProductData(thisPIS.ProductID);
          }

          //setProductSKU(thisPIS.ProductSKU);
          setProductSKU(thisPIS.SKU);

          setProductName(thisPIS.Name);
          setProductPrice(thisPIS.Price);

          let fUnit = thisPIS.FirstUnitMeasureID == null ? 0 : thisPIS.FirstUnitMeasureID;
          setProductFirstUnitMeasureId(fUnit);
          setProductFirstUnitMeasureValue(thisPIS.FirstUnitWeight);

          let sUnit = thisPIS.SecondUnitMeasureID == null ? 0 : thisPIS.SecondUnitMeasureID;
          setProductSecondUnitMeasureId(sUnit);
          setProductSecondUnitMeasureValue(thisPIS.SecondUnitWeight);

          setProductStockQuantity(thisPIS.Quantity);
          setProductUnlimitedQuantity(thisPIS.UnlimitedQuantity);
          setProductDisplayOrder(thisPIS.DisplayOrder);
          setProductSoldByWeight(thisPIS.SoldByWeight);
          setProductEnableOverrideGlobal(thisPIS.EnableOverrideGlobal);
          //setProductIsMeasuredBySKU(thisPIS.IsMeasuredBySKU)
          setProductIsMeasuredBySKU(true);
          setProductCapacity(Number(thisPIS.Capacity));

          setProductGlobalName(thisPIS.ProductName);

          //let currentProductBrandName = data.ProductBrandName;
          let currentBrandName = thisPIS.BrandName;
          let currentBrandId = thisPIS.BrandID;

          if (thisPIS.BrandID !== 0) {
            let currentBrandSuggestion = [];
            currentBrandSuggestion.push({ id: currentBrandId, text: currentBrandName });
            setProductBrandSuggestion(currentBrandSuggestion);
            setProductBrandId(currentBrandId);
            setProductBrandName(currentBrandName);
          }

          let currentProductTagsJson = thisPIS.Tags;
          let currentProductTags = JSON.parse(currentProductTagsJson);
          if (currentProductTags.length > 0) {
            setProductShopSelectedTags(currentProductTags.map(e => { return { id: e.id, text: e.name } }));
          }
        }

        setProductInShop(data.data);
        console.log(data.data);
      }
      else {
        setProductInShop({});
      }
    }
    catch {
      setProductEditErrorMessage('Error');
    }
  }

  async function saveProductInShopData() {

    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: productInShopId,
          shopId: productShop,
          globalProductId: productId,
          SKU: productSKU,
          name: productName,
          unlimitedStock: productUnlimitedQuantity,
          stockQuantity: productStockQuantity,
          price: productPrice,
          firstUnitMeasureId: productFirstUnitMeasureId,
          firstUnitWeight: Number((productFirstUnitMeasureValue !== '') ? productFirstUnitMeasureValue : '0'),
          firstUnitStep: 1,
          secondUnitMeasureId: productSecondUnitMeasureId,
          secondUnitWeight: Number((productSecondUnitMeasureValue !== '') ? productSecondUnitMeasureValue : '0'),
          secondUnitStep: 1,
          displayOrder: productDisplayOrder,
          capacity: productCapacity,
          brandId: productBrandId,
          tags: productShopSelectedTags,
          soldByWeight: productSoldByWeight,
          //isMeasuredBySKU: productIsMeasuredBySKU,
          isMeasuredBySKU: true,
          enableOverrideGlobal: productEnableOverrideGlobal
        })
      };

      //debugger;
      console.log(productShopSelectedTags);
      console.log(productBrandId);

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberProductsInShop/EditProductInShop`, requestOptions);

        const data = await response.json();

        //if (data.data.length === 0) {

        //}

        if (data.success == true) {
          //setProduct(data.data);
          onHandleEdit();
        }
        else {
          if (data.error === "SKU_exists") {
            setProductEditErrorMessage('Error Adding Product');
          }
          else if (data.error === "SKU_short") {
            setProductEditErrorMessage('Error Adding Product');
          }
          else {
            setProductEditErrorMessage('Error');
          }
        }
      }
      else {
        setProductInShop({});
      }
    }
    catch {
      setProductEditErrorMessage('Error Adding Product');
    }
  }

  //const optimizedGetProduct = useCallback(debounce(getProductInShopData, 500), []);
  //const optimizedGetBrandAutoComplete = useCallback(debounce(getBrandAutoComplete, 500), []);
  //const optimizedGetTags = useCallback(debounce(getTags, 500), []);

  const renderTags = () => {
    return (
      <DropdownMenu multiple={true}>
        {productShopTags && productShopTags.map(item => {
          return (
            <DropdownItem value={item.id} key={"productEditTag" + item.id} className={(isInSelectedTags(item.id) ? "active" : "")}
              onClick={(e) => {
                //setProductFirstUnitMeasureId(0);
                if (isInSelectedTags(item.id)) {
                  setProductShopSelectedTags(productShopSelectedTags.filter(e => e.id !== item.id));
                }
                else {
                  setProductShopSelectedTags([...productShopSelectedTags, item]);
                }
              }}>
              {item.text}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    )
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setProductShop(currentShop);

              return (
                <div id="infiniteList">
                  <div className={styles.modalProductEditTitle}>
                    
                  </div>
                  {/*{product}*/}

                  <Container className={(getCurrentLng() === 'en' ? "" : styles.productEditRtl)}>
                    <Row className={styles.productEditExplanationRow}>
                      <Col lg="12" xs="12">
                        {t("productEdit.topExplanation")}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="5" sm="5" xs="5">
                        <Label>
                          {t("productEdit.selectProduct")}
                        </Label>
                      </Col>
                      <Col lg="7" sm="7" xs="7" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <ProductSearch defaultSuggestions={productGlobalFieldSuggestion} onHandleSelect={handleSuggestionSelect} />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="5" sm="5" xs="5">
                        <Label>
                          {t("productEdit.productSKU")}*
                        </Label>
                      </Col>
                      <Col lg="7" sm="7" xs="7" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <input className={styles.productEditPriceInputField} type={"number"} value={productSKU} onChange={(e) => {
                          setProductSKU(e.target.value);
                        }} />
                        {productSKUCheck && ((productSKUValid) ? null :
                          <div className={styles.productEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.productEditInputValidationMessageRtl)}>{t("productEdit.validationMessageSKU")}</div>
                        )}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + ((productId !== 0 && productId !== null) ? null : styles.hiddenElement)}>
                      <Col lg="12" sm="12" xs="12">
                        {t('productEdit.enableOverrideGlobal')}
                        <ToggleSwitch id="editProductEnableOverrideGlobal" name="editProductEnableOverrideGlobal" optionLabels={["", ""]} checked={productEnableOverrideGlobal} itemId={productInShopId} onChange={(e, itemId) => { setProductEnableOverrideGlobal(e); }} />
                        &#160;{t('productEdit.useGlobalName')}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + (productEnableOverrideGlobal || productId === 0 || productId === null ? null : styles.hiddenElement)}>
                      <Col lg="5" sm="5" xs="5">
                        <Label>
                          {t("productEdit.productName")}*
                        </Label>
                      </Col>
                      <Col lg="7" sm="7" xs="7" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <input className={styles.productEditPriceInputField} value={productName} onChange={(e) => {
                          setProductName(e.target.value);
                        }} />
                        {productNameCheck && ((productNameValid) ? null :
                          <div className={styles.productEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.productEditInputValidationMessageRtl)}>{t("productEdit.ValidationMessageName")}</div>
                        )}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + ((!productEnableOverrideGlobal && productId !== 0 && productId !== null) ? null : styles.hiddenElement)}>
                      <Col lg="4" sm="4" xs="4">
                        {t("productEdit.productGlobalName")}
                      </Col>
                      <Col lg="8" sm="8" xs="8" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        {productGlobalName}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="5" sm="5" xs="5">
                        <Label>
                          {t("productEdit.productPrice")}
                        </Label>
                      </Col>
                      <Col lg="7" sm="7" xs="7">
                        <span className={styles.productEditPriceInputField}>
                          <NumericInput type="number"
                            title={t('productEdit.productPrice')}
                            data-productid={productInShopId}
                            id={"productEditPrice"}
                            className={styles.productEditFormPriceField}
                            minimum="0.00"
                            step={1}
                            //precision={2}
                            value={productPrice}
                            format={priceFormat}
                            mobile={'auto'}
                            //onFocus={handlePriceFieldFocus}
                            onChange={(e) => {
                              //if (Number(e) !== 0) {
                                setProductPrice(Number(e).toFixed(2));
                              //}
                            }}
                            onFocus={handleFocus}
                          /> {(getCurrentLng() === 'en' ? productInShop.FirstUnitMeasureName : productInShop.FirstUnitMeasureDisplayName)}
                        </span>
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="5" sm="5" xs="5">
                        <Label>
                          {t("productEdit.productBrand")}
                        </Label>
                      </Col>
                      <Col lg="7" sm="7" xs="7" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <BrandSearch className={styles.productEditPriceInputField} id="productEditBrandSearch" defaultBrand={productBrandSuggestion} onHandleSelect={handleBrandSelect} />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t("productEdit.productDisplayOrder")}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={styles.productEditNumeric + ' ' + (getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"displayOrder"}
                          title={t('productEdit.productDisplayOrder')}
                          data-productid={productInShopId}
                          name="displayOrder"
                          placeholder={t('productEdit.productDisplayOrder')}
                          onChange={(value) => {
                            //if (Number(value) !== 0) {
                            //console.log(value);
                            setProductDisplayOrder(Number(value));
                            //}
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={productDisplayOrder}
                          pattern='[0-9]{0,5}'
                          itemId={productInShopId}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="4" sm="4" xs="4">
                        <Label>
                          {t('productEdit.categories')}
                        </Label>
                      </Col>
                      <Col lg="8" sm="8" xs="8" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <Dropdown
                          isOpen={productTagsOpen}
                          toggle={toggleProductTagsOpen}
                          name="productEditCategories"
                          placeholder={t('productEdit.categories')}
                          className={styles.productEditCategories }
                        >
                          <DropdownToggle caret
                            onClick={toggleProductTagsOpen}
                            data-toggle={"toggleProductTagsOpen"} aria-expanded={productTagsOpen}
                            className={styles.productTagsDropDown + ' ' + (getCurrentLng() == "en" ? null : styles.productEditRtl)}
                          >
                            {renderSelectedTagsText()}
                          </DropdownToggle>
                          
                            {renderTags()}
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="12" sm="12" xs="12">
                        {t('productEdit.inStock')}
                        <ToggleSwitch id="editProductShowInStock" name="editProductShowInStock" optionLabels={["", ""]} checked={productUShowInStock} itemId={productInShopId} onChange={(e, itemId) => { setProductUnlimitedQuantity(e); setProductUShowInStock(e, setProductStockQuantity((e ? productStockQuantity : 0))); }} />
                        &#160;{t('productEdit.notInStock')}
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="12" sm="12" xs="12">
                        {t('productEdit.unlimitedQuantity')}
                        <ToggleSwitch id="editProductUnlimitedQuantity" name="editProductUnlimitedQuantity" optionLabels={["", ""]} checked={productUnlimitedQuantity} itemId={productInShopId} onChange={(e, itemId) => { setProductUnlimitedQuantity(e); }} />
                        &#160;{t('productEdit.stockQuantity')}&#160;&#160;
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + ((productUnlimitedQuantity) ? styles.hiddenElement : '')}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t('productEdit.stockQuantity')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={styles.productEditNumeric + ' ' + (getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        {/*<NumericInput*/}
                        {/*  type="number"*/}
                        {/*  title={t('productEdit.stockQuantity')}*/}
                        {/*  data-productid={product.ID}*/}
                        {/*  name="productStock"*/}
                        {/*  minimum="0"*/}
                        {/*  step={1}*/}
                        {/*  mobile={'auto'}*/}
                        {/*  placeholder={t('productEdit.productStockTextInput')}*/}
                        {/*  value={productStockQuantity}*/}
                        {/*  //className={styles.productEditFormStockQuantityField + " " + ((productUnlimitedQuantity) ? styles.hiddenElement : '')}*/}
                        {/*  //onFocus={handleQuantityFieldFocus}*/}
                        {/*  onFocus={handleFocus}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    if (Number(e) !== 0) {*/}
                        {/*      setProductStockQuantity(Number(e));*/}
                        {/*    }*/}
                        {/*  }}*/}
                        {/*/>*/}

                        <NumericInputWithButtons
                          id={"editProductProductStock"}
                          title={t('productEdit.stockQuantity')}
                          data-productid={productInShopId}
                          name="editProductProductStock"
                          placeholder={t('productEdit.productStockTextInput')}
                          onChange={(value) => {
                            //if (Number(value) !== 0) {
                              //console.log(value);
                              setProductStockQuantity(Number(value));
                            //}
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={productStockQuantity}
                          pattern='[0-9]{0,5}'
                          itemId={productInShopId}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + styles.hiddenElement}>
                      <Col lg="12" sm="12" xs="12">
                        {t('productEdit.isMeasuredBySKU')}
                        <ToggleSwitch id="editProductIsMeasuredBySKU" name="editProductIsMeasuredBySKU" optionLabels={["", ""]} checked={productIsMeasuredBySKU} itemId={productInShopId} onChange={(e, itemId) => { setProductIsMeasuredBySKU(e); }} />
                        &#160;{t('productEdit.useGloblMeasure')}&#160;&#160;
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="12" sm="12" xs="12">
                        {t('productEdit.soldByWeight')}
                        <ToggleSwitch id="editProductSoldByWeight" name="editProductSoldByWeight" optionLabels={["", ""]} checked={productSoldByWeight} itemId={productInShopId} onChange={(e, itemId) => { setProductSoldByWeight(e); if (e) { setProductCapacity(0); } }} />
                        &#160;{t('productEdit.soldByUnit')}&#160;&#160;
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + (productSoldByWeight ? styles.hiddenElement : null)}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t("productEdit.productCapacity")}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={styles.productEditNumeric + ' ' + (getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"editProductCapacity"}
                          title={t('productEdit.productCapacity')}
                          data-productid={productInShopId}
                          name="editProductCapacity"
                          placeholder={t('productEdit.productCapacity')}
                          onChange={(value) => {
                            //if (Number(value) !== 0) {
                            //console.log(value);
                            setProductCapacity(Number(value));
                            //}
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={productCapacity}
                          pattern='[0-9]{0,5}'
                          itemId={productInShopId}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow}>
                      <Col lg="8" sm="8" xs="8">
                        <Label>
                          {(productSoldByWeight) ? t('productEdit.selectUnitMeasure') : t('productEdit.productCapacityMeasuredBy')}
                        </Label>
                      </Col>
                      <Col lg="4" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <Dropdown
                          isOpen={productFirstUnitMeasure}
                          toggle={toggleProductFirstUnitMeasure}
                          name="firstUnitMeasure"
                          placeholder={t('productEdit.selectUnitMeasureTitle')}
                        >
                          <DropdownToggle caret
                            onClick={toggleProductFirstUnitMeasure}
                            data-toggle={"toggleProductFirstUnitMeasure"} aria-expanded={productFirstUnitMeasure}
                            className={styles.productEditDropDown}
                          >
                            {unitMeasureFormat(productFirstUnitMeasureId)}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem value={0} key={"fUnit0"} className={(productFirstUnitMeasureId == 0) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(0);
                              }}>
                              {t('productEdit.selectUnitMeasure0')}
                            </DropdownItem>
                            <DropdownItem value={1} key={"fUnit" + 1} className={(productFirstUnitMeasureId == 1) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(1);
                              }}>
                              {t('productEdit.selectUnitMeasure1')}
                            </DropdownItem>
                            <DropdownItem value={3} key={"fUnit" + 3} className={(productFirstUnitMeasureId == 3) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(3);
                              }}>
                              {t('productEdit.selectUnitMeasure3')}
                            </DropdownItem>
                            <DropdownItem value={4} key={"fUnit" + 4} className={(productFirstUnitMeasureId == 4) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(4);
                              }}>
                              {t('productEdit.selectUnitMeasure4')}
                            </DropdownItem>
                            <DropdownItem value={5} key={"fUnit" + 5} className={(productFirstUnitMeasureId == 5) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(5);
                              }}>
                              {t('productEdit.selectUnitMeasure5')}
                            </DropdownItem>
                            <DropdownItem value={8} key={"fUnit" + 8} className={(productFirstUnitMeasureId == 8) ? "active" : ""}
                              onClick={(e) => {
                                setProductFirstUnitMeasureId(8);
                              }}>
                              {t('productEdit.selectUnitMeasure8')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + (productSoldByWeight ? null : styles.hiddenElement)}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t('productEdit.unitMeasureValue')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={styles.productEditNumeric + ' ' + (getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <NumericInputWithButtons
                          type="number"
                          title={t('productEdit.unitMeasureValue')}
                          data-productid={productInShopId}
                          id="firstUnitWeight"
                          name="firstUnitWeight"
                          minimum="0"
                          step={1}
                          mobile={'auto'}
                          placeholder={""}
                          value={productFirstUnitMeasureValue}
                          //className={styles.productEditFormStockQuantityField + " " + ((productUnlimitedQuantity) ? styles.hiddenElement : '')}
                          //onFocus={handleQuantityFieldFocus}
                          itemId={productInShopId}
                          onFocus={handleFocus}
                          onChange={(e) => {
                            if (Number(e) !== 0) {
                              setProductFirstUnitMeasureValue(Number(e));
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + (productSoldByWeight ? null : styles.hiddenElement)}>
                      <Col lg="8" sm="8" xs="8">
                        <Label>
                          {t('productEdit.selectSecondUnitMeasure')}
                        </Label>
                      </Col>
                      <Col lg="4" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        <Dropdown
                          isOpen={productSecondUnitMeasure}
                          toggle={toggleProductSecondUnitMeasure}
                          name="secondUnitMeasure"
                          placeholder={t('productEdit.selectUnitMeasure0')}
                        >
                          <DropdownToggle caret
                            onClick={toggleProductSecondUnitMeasure}
                            data-toggle={"toggleProductFirstUnitMeasure"}
                            aria-expanded={productFirstUnitMeasure}
                            className={styles.productEditDropDown}
                          >
                            {unitMeasureFormat(productSecondUnitMeasureId)}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem value={0} key={"sUnit0"} className={(productSecondUnitMeasureId == 0) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(0);
                              }}>
                              {t('productEdit.selectUnitMeasure0')}
                            </DropdownItem>
                            <DropdownItem value={1} key={"sUnit" + 1} className={(productSecondUnitMeasureId == 1) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(1);
                              }}>
                              {t('productEdit.selectUnitMeasure1')}
                            </DropdownItem>
                            <DropdownItem value={3} key={"sUnit" + 3} className={(productSecondUnitMeasureId == 3) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(3);
                              }}>
                              {t('productEdit.selectUnitMeasure3')}
                            </DropdownItem>
                            <DropdownItem value={4} key={"sUnit" + 4} className={(productSecondUnitMeasureId == 4) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(4);
                              }}>
                              {t('productEdit.selectUnitMeasure4')}
                            </DropdownItem>
                            <DropdownItem value={5} key={"sUnit" + 5} className={(productSecondUnitMeasureId == 5) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(5);
                              }}>
                              {t('productEdit.selectUnitMeasure5')}
                            </DropdownItem>
                            <DropdownItem value={8} key={"sUnit" + 8} className={(productSecondUnitMeasureId == 8) ? "active" : ""}
                              onClick={(e) => {
                                setProductSecondUnitMeasureId(8);
                              }}>
                              {t('productEdit.selectUnitMeasure8')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row className={styles.productEditRow + " " + (productSoldByWeight ? null : styles.hiddenElement)}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t('productEdit.unitMeasureValue')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={styles.productEditNumeric + ' ' + (getCurrentLng() === 'en' ? styles.productEditControlsCol : styles.productEditControlsColRtl)}>
                        {/*<NumericInput*/}
                        {/*  type="number"*/}
                        {/*  title={t('productEdit.unitMeasureValue')}*/}
                        {/*  data-productid={productInShopId}*/}
                        {/*  name="secondUnitWeight"*/}
                        {/*  minimum="0"*/}
                        {/*  step={1}*/}
                        {/*  mobile={'auto'}*/}
                        {/*  placeholder={""}*/}
                        {/*  value={productSecondUnitMeasureValue}*/}
                        {/*  //className={styles.productEditFormStockQuantityField + " " + ((productUnlimitedQuantity) ? styles.hiddenElement : '')}*/}
                        {/*  //onFocus={handleQuantityFieldFocus}*/}
                        {/*  onFocus={handleFocus}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    if (Number(e) !== 0) {*/}
                        {/*      setProductSecondUnitMeasureValue(Number(e));*/}
                        {/*    }*/}
                        {/*  }}*/}
                        {/*/>*/}

                        <NumericInputWithButtons
                          type="number"
                          title={t('productEdit.unitMeasureValue')}
                          data-productid={productInShopId}
                          id="secondUnitWeight"
                          name="secondUnitWeight"
                          minimum="0"
                          step={1}
                          mobile={'auto'}
                          placeholder={""}
                          value={productSecondUnitMeasureValue}
                          //className={styles.productEditFormStockQuantityField + " " + ((productUnlimitedQuantity) ? styles.hiddenElement : '')}
                          //onFocus={handleQuantityFieldFocus}
                          itemId={productInShopId}
                          onFocus={handleFocus}
                          onChange={(e) => {
                            if (Number(e) !== 0) {
                              setProductSecondUnitMeasureValue(Number(e));
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={styles.lastProductEditRow}>
                      <Col lg="12" sm="12" xs="12">
                        <Button
                          color="link"
                          className={styles.productEditBtn}
                          data-productid={productInShopId}
                          name="Edit"
                          value={productInShopId}
                          disabled={productName === '' || productSKU === '' || !productNameValid || !productSKUValid} //|| productPrice === 0
                          onClick={(e) => { handleEditProduct(e) }}
                        >{(productInShopId == 0) ? t('productEdit.addProductToShopBtn') : t('productEdit.saveProductToShopBtn')}</Button>
                      </Col>
                    </Row>

                    <ErrorMessageCustomPopup show={productEditError} title={productEditErrorMessage} onClose={(e) => { setProductEditError(false); }} />
                  </Container>
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );

}
export default ProductEdit