import React, { Component, useCallback, useEffect, useState } from 'react';
import loaderStyles from "./loader-animation.module.scss";
import PropTypes from "prop-types";
import * as Icon from 'react-bootstrap-icons';

const LoaderAnimation = (props) => {
  const [show, setShow] = useState(false);
  const [allowClose, setAllowClose] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setAllowClose(props.allowClose);
  }, [props.allowClose]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={loaderStyles.overlay + " " + props.className}
      onClick={(e) => {
        if (allowClose) {
          setShow(false);
        }
      }}
    >
      <div className={loaderStyles.loader}>
        <img src="/assets/pink_fruit.svg"/>
      </div>
    </div>
  );
};

LoaderAnimation.propTypes = {
  show: PropTypes.bool.isRequired,
  allowClose: PropTypes.bool
};

export default LoaderAnimation;