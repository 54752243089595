//import i18n from 'i18next';
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import { getLocales } from 'react-native-localize';
import HttpBackend from 'i18next-http-backend';

//import i18nHttpLoader from 'i18next-http-backend';
//import HttpApi from 'i18next-http-backend';
import common_he from "./translations/he/common.json";
import common_en from "./translations/en/common.json";
//import Axios from 'axios';
//const I18NextHttpBackend = require('i18next-http-backend')

const resources = {
  he: {
    common: common_he
  },
  en: {
    common: common_en
  }
};

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    resources: {},
    interpolation: {
      escapeValue: false,
    },
    lng: i18n.language || (typeof window !== 'undefined' && window.localStorage.getItem('i18nextLng') || 'he'),
    fallbackLng: 'he',
    ns: ['common'],
    defaultNS: 'common',
    initImmediate: false,
    //keySeparator: false,
    react: {
      useSuspense: false,
      wait: true
    },
    backend: {
      //ns: ['common'],
      loadPath: `${process.env.REACT_APP_API_URL}/api/translate/?lng={{lng}}&ns={{ns}}`,
      //loadPath: `${process.env.REACT_APP_API_URL}/api/translate/`,
      allowMultiLoading: true,
      //allowMultiLoading: false,
      parse: (data, lng) => {
        //console.log(data);
        //return data;

        let jData = JSON.parse(data);
        //console.log(jData);

        //let lngData = {};
        //jData.map(el => {
        //  //console.log(el.FileContent);
        //  //lngData[String(el.FileName).substr(String(el.FileName).indexOf('.json'), String(el.FileName).length - String(el.FileName).indexOf('.json'))] = el.FileContent;
        //  lngData[String(el.FileName).substr(0, String(el.FileName).indexOf('.json'))] = { "common": JSON.parse(unescape(el.FileContent)) };
        //});

        let lngData = JSON.parse(unescape(jData.filter(i => i.FileName == lng + '.json')[0].FileContent));

        console.log(lngData);

        return lngData;
      },
      crossDomain: true,
      withCredentials: false,
      overrideMimeType: false,
      serializeConfig: false,
      //debug: true,
      //requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
      //  mode: 'cors',
      //  credentials: 'same-origin',
      //  cache: 'default'
      //},
      //request: function (options, url, payload, callback) { },
      reloadInterval: false,
      //use: [I18NextHttpBackend]

    },
    partialBundledLanguages: true

    //   resources: {
    //     en: {
    //       translation: {
    //         'Hey Yo Im at home': 'Hey Yo Im at home',
    //         'Hey Yo Im inside Room': 'Hey Yo Im inside Room',
    //       },
    //     },
    //     es: {
    //       translation: {
    //         'Hey Yo Im at home': 'Hey yo estoy en casa',
    //         'Hey Yo Im inside Room': 'Hola, yo estoy dentro de la habitación',
    //       },
    //     },
    //     de: {
    //       translation: {
    //         'Hey Yo Im at home': 'Hey Yo Ich bin zu Hause',
    //         'Hey Yo Im inside Room': 'Hey Yo Ich bin im Zimmer',
    //       },
    //     },
    //   },
  });

i18n.addResourceBundle('he', 'common', common_he);
i18n.addResourceBundle('en', 'common', common_en);

export default i18n;


//
//async function getLangFile() {
//  //`${process.env.REACT_APP_API_URL}/translations/{{lng}}`
//  const requestOptions = {
//    //credentials: 'same-origin',
//    //credentials: 'include',
//    method: 'GET',
//    mode: 'cors',
//    headers: {
//      'Accept': 'application/json',
//      'Content-Type': 'application/json',
//    },
//    //body: JSON.stringify({  })
//  };
//  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/translate/`, requestOptions);
//  const data = await response.json();
//  console.log(data);
//}

//getLangFile();