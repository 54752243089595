import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './deliveryzone-card.module.scss';
import * as Icon from 'react-bootstrap-icons';

const DeliveryZoneCard = (props) => {
  const { deliveryZone } = props;
  //console.log(deliveryZone);

  const [deliveryZoneCardOpen, setDeliveryZoneCardOpen] = useState(false);

  const [deliveryZoneId, setDeliveryZoneId] = useState(0);
  const [deliveryZonePrice, setDeliveryZonePrice] = useState(0);

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  const toggleDeliveryZoneCard = (e) => {
    if (!deliveryZoneCardOpen == true) {
    }
    setDeliveryZoneCardOpen(!deliveryZoneCardOpen);
  }

  return (
    <Card className={styles.deliveryZoneCard + " " + (getCurrentLng() === 'en' ? "" : styles.deliveryZoneCardRTL)}>
      <CardHeader onClick={(e) => {
        //toggleDeliveryZoneCard(e)
      }} className={styles.cardHeader}>
        <Row className={styles.cardRow}>
          <Col lg="3" xs="12" className={styles.cardInfoContainer}>
            <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
              <NavLink tag={Link} to={`${path}deliveryzone/${deliveryZone.DeliveryZoneID}`} className={styles.cardNavLink + " " + styles.cardCollectionLink}>
                <Icon.PencilSquare /> {t('deliveryZones.editDeliveryZone')}
              </NavLink>
            </CardTitle>
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {t('deliveryZones.deliveryZoneActive')}
          </Col>
          <Col lg="1" xs="6" className={styles.cardColumn}>
            {(deliveryZone.Active == true) ? <Icon.CheckLg /> : <Icon.XLg />}
          </Col>
          <Col lg="3" xs="6" className={styles.cardColumn}>
            {t('deliveryZones.deliveryCities')}
          </Col>
          <Col lg="3" xs="6" className={styles.cardColumn}>
            {deliveryZone.Cities}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {t('deliveryZones.deliveryShipCost')}
          </Col>
          <Col lg="1" xs="6" className={styles.cardColumn}>
            {deliveryZone.ShipCost}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {t('deliveryZones.deliveryFreeShipFrom')}
          </Col>
          <Col lg="1" xs="6" className={styles.cardColumn}>
            {deliveryZone.FreeShipFrom}
          </Col>
          <Col lg="3" xs="6" className={styles.cardColumn}>
            {t('deliveryZones.deliveryFreeShipFromEnabled')}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {(deliveryZone.FreeShipFromEnabled == true) ? <Icon.CheckLg /> : <Icon.XLg />}
          </Col>
          {/*<Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)}>*/}
          {/*  {(deliveryZoneCardOpen) ?*/}
          {/*    <Icon.ChevronUp />*/}
          {/*    :*/}
          {/*    <Icon.ChevronDown />}*/}
          {/*</Col>*/}
        </Row>
      </CardHeader>
      <Collapse isOpen={deliveryZoneCardOpen}>
        <CardBody className={styles.cardEditSectionWrapper}>
          <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
            <Row className={styles.cardEditRow}>
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {t('deliveryZones.shipAmountPerHourLimit')}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {deliveryZone.ShipAmountPerHourLimit}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {t('deliveryZones.deliveryInterval')}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {deliveryZone.DeliveryInterval}*/}
              {/*</Col>*/}
            </Row>
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default DeliveryZoneCard