import React, { Component, useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import ReactDOM from "react-dom";
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import CustomPopup from '../../Components/CustomPopup';
import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';

import { GoogleMap, useJsApiLoader, Circle, Autocomplete } from '@react-google-maps/api';

import NumericInput from 'react-numeric-input';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';
import ToggleSwitch from '../../Components/ToggleSwitch';

import MapLocationAndRadius from '../../Components/MapLocationAndRadius';

import { DatePicker } from 'reactstrap-date-picker'
import { dateAdd, getFullDateInUTC } from '../../dateAdd';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import styles from './deliveryzone-edit.module.scss';
import SelectCities from '../../Components/SelectCities';

const DeliveryZoneEdit = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_MAPSGOOGLEAPIS_KEY}`,
    libraries: ['places'],
    //language: 'he-IL'
  })

  const [user, setUser] = useState({});
  const [deliveryZoneShopId, setDeliveryZoneShopId] = useState(0);
  const [deliveryZone, setDeliveryZone] = useState({});

  const [deliveryZoneId, setDeliveryZoneId] = useState(0);
  
  //const [deliveryZoneTitle, setDeliveryZoneTitle] = useState('');
  //const [deliveryZoneTitleValid, setDeliveryZoneTitleValid] = useState(false);
  //const [deliveryZoneTitleCheck, setDeliveryZoneTitleCheck] = useState(false);

  const [deliveryZoneAcvtive, setDeliveryZoneAcvtive] = useState(false);
  const [deliveryZoneShipCost, setDeliveryZoneShipCost] = useState(0);
  const [deliveryZoneFreeShipFromEnabled, setDeliveryZoneFreeShipFromEnable] = useState(false);
  const [deliveryZoneFreeShipFrom, setDeliveryZoneFreeShipFrom] = useState(0);
  const [deliveryZoneDeliveryTime, setDeliveryZoneDeliveryTime] = useState(0);
  const [deliveryZoneDistance, setDeliveryZoneDistance] = useState(0);
  const [deliveryZoneCities, setDeliveryZoneCities] = useState([]);

  const [errorDeliveryZoneEmpty, setErrorDeliveryZonesEmpty] = useState(false);
  //const [errorNoCondition, setErrorNoCondition] = useState(false);
  //const [errorNoReward, setErrorNoReward] = useState(false);
  //const [errorConditionWithoutSet, setErrorConditionWithoutSet] = useState(false);
  //const [errorRewardWithoutSet, setErrorRewardWithoutSet] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseDataFetched, setBaseDataFetched] = useState(false);

  const [deliveryZoneEditError, setDeliveryZoneEditError] = useState(false);
  const [deliveryZoneEditErrorMessage, setDeliveryZoneEditErrorMessage] = useState('');

  const [schedules, setSchedules] = useState([]);
  const [scheduleID, setScheduleID] = useState(0);
  const [scheduleName, setScheduleName] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
  }

  const [center, setCenter] = useState(null);  
  const [mapCenter, setMapCenter] = useState({lat:32,lng:34});
  const [distance, setDistance] = useState(0);

  const [isMarkerShown, setIsMarekerShown] = useState(false);

  const { t, i18n } = useTranslation('common');

  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    delayedShowMarker();
  }, [isMarkerShown]);

    const populateSchedules = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startIndex: 0,
                    pageSize: 1000,
                    qString: '',
                    qShopID: deliveryZoneShopId
                })
            };

            if (user.token) {
                requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

                const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/ListShopShipTimes`, requestOptions);

                const data = await response.json();

                setSchedules(data.data);
            }
            else {
                setSchedules([]);
            }
        }
        catch {
            setSchedules([]);
        }
    }

  useEffect(() => {
    document.title = t('nav.deliveryZoneEdit');
    getDeliveryZone();
    if (user && deliveryZoneShopId > 0) {
      populateSchedules();
    }
  }, [user, deliveryZoneShopId]);

  //useEffect(() => {
  //  if (deliveryZoneTitle !== null && deliveryZoneTitle !== undefined) {
  //    if (String(deliveryZoneTitle) !== '') {
  //      setDeliveryZoneTitleCheck(true);
  //    }
  //    if (String(deliveryZoneTitle).trim().length > 3) {
  //      setDeliveryZoneTitleValid(true);
  //    }
  //    else {
  //      setDeliveryZoneTitleValid(false);
  //    }
  //  }
  //}, [deliveryZoneTitle]);


  //useEffect(() => {
  //  if (specialOfferDateStartValue !== null && specialOfferDateStartValue !== undefined &&
  //    specialOfferDateEndValue !== null && specialOfferDateEndValue !== undefined
  //  ) {
  //    //if (String(productSKU) !== '') {
  //    setSpecialOfferDatesValueCheck(true);
  //    //}

  //    let startDate = new Date(specialOfferDateStartValue);

  //    let endDate = new Date(specialOfferDateEndValue);

  //    //if (startDate > new Date() && endDate > startDate) {
  //    if (endDate > startDate) {
  //      setSpecialOfferDatesValid(true);
  //    }
  //    else {
  //      setSpecialOfferDatesValid(false);
  //    }
  //  }
  //}, [specialOfferDateStartValue, specialOfferDateEndValue]);



  const delayedShowMarker = () => {
    //setTimeout(() => {
    //  setIsMarekerShown(true);
    //}, 3000)
  };

  const handleMarkerClick = () => {
    setIsMarekerShown(false);
    //delayedShowMarker()
  };

  const onCircleCenterChange = (e) => {    
      if (e && JSON.stringify(center) != JSON.stringify(e)) {
        //console.log(e);
        setCenter(e);
      }
  }  

  const onCircleRadiusChange = (e) => {
    if (e && deliveryZoneDistance != e) {
      //console.log(e);
      setDeliveryZoneDistance(e);
    }    
  };

  const onCitiesListChanged = (cities) => {
    setDeliveryZoneCities(cities)   
  };

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  function priceFormat(num) {
    //var nis = '\U020AA';
    //const nis = '&#8362;';
    //const nis = '\20AA';
    return String(num) + " ₪"; // + " " + { nis };
  }

  async function getDeliveryZone() {
    if ((user
      && Object.keys(user).length === 0
      && Object.getPrototypeOf(user) === Object.prototype)
      || deliveryZoneShopId === 0
      || baseDataFetched
      || (id === null || id === undefined)) {

      return;
    }
    else if (id === 'new') {      
      setCenter({ lng: 34.78291507676921, lat: 32.008617138581165 })
      setMapCenter({ lng: 34.78291507676921, lat: 32.008617138581165 })
      setDeliveryZoneDistance(2000)
      
      //let startSchedule = [];

      //[...Array(7)].map((x, i) => {
      //  let active = true;
      //  let timeFrom = new Date('0001-01-01T11:00:00.000Z');
      //  let timeTo = new Date('0001-01-01T17:00:00.000Z');
      //  let timeLimit = new Date('0001-01-01T15:00:00.000Z');

      //  if (i == 5) {
      //    timeFrom = new Date('0001-01-01T09:00:00.000Z');
      //    timeTo = new Date('0001-01-01T15:00:00.000Z');
      //    timeLimit = new Date('0001-01-01T11:00:00.000Z');
      //  }
      //  if (i == 6) {
      //    active = false;
      //    timeFrom = new Date('0001-01-01T18:00:00.000Z');
      //    timeTo = new Date('0001-01-01T21:00:00.000Z');
      //    timeLimit = new Date('0001-01-01T15:00:00.000Z');
      //  }

      //  let deliveryZone = {
      //    shippingTimeId: i,
      //    shippingTimeActive: active,
      //    shippingTimeDay: i,
      //    shippingTimeTimeFrom: timeFrom,
      //    shippingTimeTimeTo: timeTo,
      //    shippingTimeTimeLimit: timeLimit,
      //    shippingTimeNoSameDayDelivery: false
      //  };

      //  startSchedule.push(shippingTime);
      //});

      //setShippingTimeShipLimitPerInterval(10);
      //setShippingTimeDeliveryInterval(120);
      //setShippingTimeSchedule(startSchedule);

      return;
    }

    try {
      setBaseDataFetched(true);
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: id,
          qShopID: deliveryZoneShopId
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/DeliveryZone/`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        setDeliveryZone(data);

        setDeliveryZoneId(data.data.DeliveryZoneID);

        //setDeliveryZoneTitle(data.data.Name);
        //setDeliveryZoneShipLimitPerInterval(data.data.HourlyShipsLimit);
        //setDeliveryZoneDeliveryInterval(data.data.DeliveryInterval);

        setDeliveryZoneAcvtive(data.data.Active);
        setDeliveryZoneShipCost(data.data.ShipCost);
        setDeliveryZoneFreeShipFrom(data.data.FreeShipFrom);
        setDeliveryZoneDeliveryTime(data.data.DeliveryTime);
          
        setScheduleID(data.data.ScheduleID);
        setScheduleName(data.data.ScheduleName);

        setDeliveryZoneCities(data.data.Cities);

        if (data.data.StartingPoint) {          
          setCenter({lng: data.data.StartingPoint.x, lat: data.data.StartingPoint.y})
          setMapCenter({ lng: data.data.StartingPoint.x, lat: data.data.StartingPoint.y })
          setDeliveryZoneDistance(data.data.Distance * 1000)
        }

        setDistance(data.data.Distance * 1000)

        //let currentShippingTimesToLoad = [];

        //if (data.data.ShopShipTimes && data.data.ShopShipTimes.length > 0) {
        //  data.data.ShopShipTimes.map((e, index) => {
        //    let shippingTime = {
        //      shippingTimeId: e.ID,
        //      shippingTimeActive: e.Active,
        //      shippingTimeDay: e.Day,
        //      shippingTimeTimeFrom: e.TimeFrom,
        //      shippingTimeTimeTo: e.TimeTo,
        //      shippingTimeTimeLimit: e.TimeLimit,
        //      shippingTimeNoSameDayDelivery: e.NoSameDayDelivery
        //    };

        //    currentShippingTimesToLoad.push(shippingTime);
        //  });
        //}
        //setShippingTimeSchedule(currentShippingTimesToLoad);
      }
      else {
        //setShippingTimeSchedule([]);
      }

      //setLoading(false);
      setShowLoader(false);
    }
    catch {
      //debugger;
      setDeliveryZone([]);
      //setLoading(false);
      setShowLoader(false);
    }
  }

  async function saveDeliveryZone() {
    try {
      //if (deliveryZoneTitle !== null && deliveryZoneTitle !== undefined) {
      //  setDeliveryZoneTitleCheck(true);

      //  if (String(deliveryZoneTitle).trim().length > 3) {
      //    setDeliveryZoneTitleValid(true);
      //  }
      //  else {
      //    setDeliveryZoneTitleValid(false);
      //    return;
      //  }
      //}

      setLoading(true);

      let deliveryZoneEmpty = false;

      //build ProductSet
      //let currentDeliveryZonesToSave = [];

      //if (shippingTimeSchedule === null || shippingTimeSchedule === undefined || shippingTimeSchedule.length === 0) {
      //  shippingTimeEmpty = true;
      //}
      //else {
      //  shippingTimeSchedule.map((e, index) => {
      //    let ProductsSets = {
      //      Day: e.shippingTimeDay,
      //      Active: e.shippingTimeActive,
      //      TimeFrom: e.shippingTimeTimeFrom,
      //      TimeTo: e.shippingTimeTimeTo,
      //      TimeLimit: e.shippingTimeTimeLimit,
      //      NoSameDayDelivery: e.shippingTimeNoSameDayDelivery
      //    };

      //    currentShippingTimesToSave.push(ProductsSets);
      //  });
      //}

      //if (shippingTimeEmpty) {
      //  setErrorShippingTimesEmpty(true);
      //  setLoading(false);
      //  setShowLoader(false);
      //  return;
      //}
      //else {
      //  setErrorShippingTimesEmpty(false);
      //}

      //let startDate = shippingTimeDateStartValue
      let currentIdForSaving = (id == 'new') ? null : id;     

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: currentIdForSaving,
          qShopID: deliveryZoneShopId,
          qActive: deliveryZoneAcvtive,
          qDeliveryTime: deliveryZoneDeliveryTime,
          qDistance: deliveryZoneDistance / 1000,
          qFreeShipFrom: deliveryZoneFreeShipFrom,
          qShipCost: deliveryZoneShipCost,
          qScheduleID: scheduleID,
          qCities: deliveryZoneCities,
          qStartingPoint: { X: center.lng, Y: center.lat}
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberDelivery/SaveDeliveryZone`, requestOptions);

        const data = await response.json();

        if (data.success == true) {
          history.push('/shippingtimes');
        }
      }
      else {
        setDeliveryZone({});
      }

      setLoading(false);
    }
    catch {
      //debugger;
      setDeliveryZone({});
      setLoading(false);
      setShowLoader(false);
    }
  }

  const handleDeliveryZoneChangeGeoLocation = (point) => {
    //setDeliveryZoneGeoLocation(point);
  };

  //const renderGeoLocation = () => {
  //  return (
  //    <div></div>
  //  );
  //};

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return isLoaded ? (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {
                    
              setDeliveryZoneShopId(currentShop);
              //setDeliveryZoneString(currentSearchString);

              return (
                <div className={styles.deliveryZoneEditContainer}>
                  <div className={styles.deliveryZoneEditTitleText + " " + (getCurrentLng() === 'en' ? "" : styles.deliveryZoneEditTitleTextRtl)}>
                    {t('deliveryZoneEdit.editTitle')}
                    <button type="button" className={"btn-close " + (getCurrentLng() === 'en' ? styles.topCloseButton : styles.topCloseButtonRtl)} aria-label="Close"
                      onClick={(e) => {
                        history.push('/shippingtimes');
                      }}></button>
                  </div>

                  <div className={styles.deliveryZoneEditTitle}>
                  </div>
                  <Container className={styles.deliveryZoneEdit + " " + (getCurrentLng() === 'en' ? "" : styles.deliveryZoneEditRtl)}>
                    <Row className={styles.deliveryZoneEditExplanationRow}>
                      <Col lg="12" xs="12">
                        {t('deliveryZoneEdit.topExplanation')}
                      </Col>
                    </Row>

                    {/*<Row className={styles.deliveryZoneEditRow}>*/}
                    {/*  <Col lg="6" md="6" xs="6">*/}
                    {/*    <Label>*/}
                    {/*      {t('deliveryZoneEdit.title')}*/}
                    {/*    </Label>*/}
                    {/*  </Col>*/}
                    {/*  <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>*/}
                    {/*    <input maxLength="250" className={styles.deliveryZoneEditInputField + " " + (getCurrentLng() === 'en' ? null : styles.deliveryZoneEditInputFieldRtl)} value={deliveryZoneTitle} onChange={(e) => {*/}
                    {/*      setDeliveryZoneTitle(e.target.value);*/}
                    {/*    }} />*/}
                    {/*    {deliveryZoneTitleCheck && ((deliveryZoneTitleValid) ? null :*/}
                    {/*      <div className={styles.deliveryZoneEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.deliveryZoneEditInputValidationMessageRtl)}>{t("deliveryZoneEdit.validationMessageTitle")}</div>*/}
                    {/*    )}*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}

                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        {t('deliveryZoneEdit.deliveryZoneActive')}
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                          <ToggleSwitch
                          id={'editDeliveryZoneAcvtive'}
                          name={'editDeliveryZoneAcvtive'}
                          optionLabels={[]}
                          checked={deliveryZoneAcvtive}
                          itemId={deliveryZoneId}
                          data-id={deliveryZoneShopId}
                          onChange={(e, itemId) => {
                            setDeliveryZoneAcvtive(e);
                          }} />
                      </Col>
                    </Row>
                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('deliveryZoneEdit.deliveryZoneShipCost')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <span className={styles.deliveryZoneEditPriceInputField}>
                          <NumericInput type="number"
                            title={t('deliveryZoneEdit.deliveryZoneShipCost')}
                            name={"deliveryZoneShipCost" + + deliveryZoneId}
                            data-deliveryzoneid={deliveryZoneId}
                            id={"deliveryZoneShipCost" + + deliveryZoneId}
                            className={styles.deliveryZoneEditFormPriceField}
                            minimum="0.00"
                            step={1}
                            //precision={2}
                            value={deliveryZoneShipCost}
                            format={priceFormat}
                            mobile={"auto"}
                            //onFocus={handlePriceFieldFocus}
                            onChange={(e) => {
                              //if (Number(e) !== 0) {
                              setDeliveryZoneShipCost(Number(e).toFixed(2));
                              //}
                            }}
                            onFocus={handleFocus}
                          />
                        </span>
                      </Col>
                    </Row>
                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('deliveryZoneEdit.deliveryZoneDeliveryTime')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"deliveryZoneDeliveryTime"}
                          title={t('deliveryZoneEdit.deliveryZoneDeliveryTime')}
                          data-productid={deliveryZoneId}
                          itemId={deliveryZoneId}
                          name="deliveryInterval"
                          placeholder={t('deliveryZoneEdit.deliveryZoneDeliveryTime')}
                          onChange={(value) => {
                            setDeliveryZoneDeliveryTime(Number(value));
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={deliveryZoneDeliveryTime}
                          pattern='[0-9]{0,5}'
                          itemId={deliveryZoneId}
                        />
                      </Col>
                    </Row>

                          
                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('deliveryZoneEdit.schedule')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <FormGroup>
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            name="scheduleName"
                            placeholder={t('deliveryZoneEdit.scheduleName')}
                          >
                            <DropdownToggle caret onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen}>
                              {((scheduleID !== 0) ? scheduleName : '')}
                            </DropdownToggle>
                            <DropdownMenu className={styles.dropdownWindow}>
                              {schedules.map(s => <DropdownItem value={s.ScheduleID} data-schedule-id={s.ScheduleID} key={s.ScheduleID} className={(scheduleID == Number(s.ScheduleID)) ? "active" : ""}
                                onClick={() => {
                                    setScheduleID(s.ScheduleID);
                                    setScheduleName(s.Name);
                                }}>
                                {s.Name}
                              </DropdownItem>)}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('deliveryZoneEdit.deliveryZoneFreeShipFrom')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <span className={styles.deliveryZoneEditPriceInputField}>
                          <NumericInput type="number"
                            title={t('deliveryZoneEdit.deliveryZoneFreeShipFrom')}
                            name={"deliveryZoneFreeShipFrom" + + deliveryZoneId}
                            data-deliveryzoneid={deliveryZoneId}
                            id={"deliveryZoneFreeShipFrom" + + deliveryZoneId}
                            className={styles.deliveryZoneEditFormPriceField}
                            minimum="0.00"
                            step={1}
                            //precision={2}
                            value={deliveryZoneFreeShipFrom}
                            format={priceFormat}
                            mobile={"auto"}
                            //onFocus={handlePriceFieldFocus}
                            onChange={(e) => {
                              //if (Number(e) !== 0) {
                              setDeliveryZoneFreeShipFrom(Number(e).toFixed(2));
                              //}
                            }}
                            onFocus={handleFocus}
                          />
                        </span>
                      </Col>
                    </Row>
                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('deliveryZoneEdit.deliveryZoneFreeShipFromEnabled')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <span className={styles.deliveryZoneEditPriceInputField}>
                          <ToggleSwitch
                            id={'editFreeShipFromEnabled'}
                            name={'editFreeShipFromEnabled'}
                            optionLabels={[]}
                            checked={deliveryZoneFreeShipFromEnabled}
                            itemId={deliveryZoneId}
                            data-id={deliveryZoneShopId}
                            onChange={(e, itemId) => {
                              setDeliveryZoneFreeShipFromEnable(e);
                            }} />
                        </span>
                      </Col>
                    </Row>

                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('deliveryZoneEdit.deliveryZoneRadius')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"deliveryZoneRadius"}
                          title={t('deliveryZoneEdit.deliveryZoneRadius')}
                          data-productid={deliveryZoneId}
                          itemId={deliveryZoneId}
                          name="deliveryZoneRadius"
                          placeholder={t('deliveryZoneEdit.deliveryZoneRadius')}
                          onChange={(value) => {
                              setDeliveryZoneDistance(Number(value));
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={deliveryZoneDistance}
                          pattern='[0-9]'                          
                        />
                      </Col>
                    </Row>

                    <Row className={styles.deliveryZoneEditRow}>
                      <Col lg="6" sm="8" xs="8">
                        <Label>
                          {t('deliveryZoneEdit.cities')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="4" xs="4" className={(getCurrentLng() === 'en' ? styles.deliveryZoneEditControlsCol : styles.deliveryZoneEditControlsColRtl)}>
                        <FormGroup>
                          <SelectCities cities={deliveryZoneCities} onCitiesListChanged={onCitiesListChanged}/>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className={styles.lastDeliveryZoneEditRow}>
                      <Col lg="12" sm="12" xs="12">
                        <Button
                          color="link"
                          className={styles.deliveryZoneEditBtn}
                          data-productid={deliveryZoneId}
                          name="Edit"
                          value={deliveryZoneId}
                          disabled={deliveryZone == null} //|| productPrice === 0
                          onClick={(e) => {
                            saveDeliveryZone();
                          }}
                        >{(deliveryZoneId == 0) ? t('deliveryZoneEdit.addDeliveryZoneToShopBtn') : t('deliveryZoneEdit.saveDeliveryZoneToShopBtn')}</Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12" sm="12" xs="12">

                        {center && <MapLocationAndRadius
                          GoogleMap={GoogleMap}
                          Circle={Circle}
                          isMarkerShown={isMarkerShown}
                          onMarkerClick={handleMarkerClick}
                          center={center}
                          mapCenter={mapCenter}
                          zoom={Math.round(Math.log2(20000000 / (distance == 0 ? 2000 : distance)))}
                          distance={deliveryZoneDistance}
                          onCircleCenterChange={onCircleCenterChange}
                          onCircleRadiusChange={onCircleRadiusChange}                          
                        />}

                      </Col>
                    </Row>
                  </Container>

                  <ErrorMessageCustomPopup show={deliveryZoneEditError} title={deliveryZoneEditErrorMessage} onClose={(e) => { setDeliveryZoneEditError(false); }} />
                  <LoaderAnimation show={showLoader} allowClose={false} />
                </div>
              )
            }}
          </layoutContext.Consumer>
        ) : <></>
      }}
    </userContext.Consumer>
  )
}
export default DeliveryZoneEdit