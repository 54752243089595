import React, { Component, useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import ReactDOM from "react-dom";
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import CustomPopup from '../../Components/CustomPopup';
import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';
import ToggleSwitch from '../../Components/ToggleSwitch';

import SpecialOfferProductSet from './SpecialOfferProductSet';
import SpecialOfferCondition from './SpecialOfferCondition';
import SpecialOfferReward from './SpecialOfferReward';

import { DatePicker } from 'reactstrap-date-picker'
import { dateAdd, getFullDateInUTC } from '../../dateAdd';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import styles from './specialoffer-edit.module.scss';

const SpecialOfferEdit = (props) => {
  const [user, setUser] = useState({});
  const [specialOfferShopId, setSpecialOfferShopId] = useState(0);
  const [specialOffer, setSpecialOffer] = useState({});

  const [specialOfferId, setSpecialOfferId] = useState(0);
  const [specialOfferComaxId, setSpecialOfferComaxId] = useState(0);

  const [specialOfferTitle, setSpecialOfferTitle] = useState('');
  const [specialOfferTitleValid, setSpecialOfferTitleValid] = useState(false);
  const [specialOfferTitleCheck, setSpecialOfferTitleCheck] = useState(false);

  const [specialOfferPriority, setSpecialOfferPriority] = useState(0);

  const [specialOfferDateStartValue, setSpecialOfferDateStartValue] = useState(new Date());
  const [specialOfferDateStartFormattedValue, setSpecialOfferDateStartFormattedValue] = useState(undefined);

  const [specialOfferDateEndValue, setSpecialOfferDateEndValue] = useState(new Date());
  const [specialOfferDateEndFormattedValue, setSpecialOfferDateEndFormattedValue] = useState(undefined);

  const [specialOfferDatesValid, setSpecialOfferDatesValid] = useState(false);
  const [specialOfferDatesValueCheck, setSpecialOfferDatesValueCheck] = useState(false);

  const [errorProductSetEmpty, setErrorProductSetEmpty] = useState(false);
  const [errorNoCondition, setErrorNoCondition] = useState(false);
  const [errorNoReward, setErrorNoReward] = useState(false);
  const [errorConditionWithoutSet, setErrorConditionWithoutSet] = useState(false);
  const [errorRewardWithoutSet, setErrorRewardWithoutSet] = useState(false);

  const [specialOfferShowOnHomePage, setSpecialOfferShowOnHomePage] = useState(false);

  const [shopId, setShopId] = useState(0);

  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseDataFetched, setBaseDataFetched] = useState(false);

  const [ordersEditError, setOrdersEditError] = useState(false);
  const [ordersEditErrorMessage, setOrdersEditErrorMessage] = useState('');

  const [specialOfferProductSets, setSpecialOfferProductSets] = useState([]);

  const [specialOfferConditions, setSpecialOfferConditions] = useState([]);

  const [specialOfferRewards, setSpecialOfferRewards] = useState([]);

  const [productSetId, setPproductSetId] = useState(0);

  const [productSets, setProductsSets] = useState([]);

  const { t, i18n } = useTranslation('common');

  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    if (specialOfferId == undefined || (loading)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    document.title = t('nav.specialOfferEdit');
    getSpecialOffer();

    return function clear() {
      setSpecialOffer({});
    }
  }, [user, specialOfferShopId]);

  useEffect(() => {
    //getSpecialOfferData();
    

  }, [specialOfferId]);

  useEffect(() => {
    if (specialOfferTitle !== null && specialOfferTitle !== undefined) {
      if (String(specialOfferTitle) !== '') {
        setSpecialOfferTitleCheck(true);
      }
      if (String(specialOfferTitle).trim().length > 3) {
        setSpecialOfferTitleValid(true);
      }
      else {
        setSpecialOfferTitleValid(false);
      }
    }
  }, [specialOfferTitle]);


  useEffect(() => {
    if (specialOfferDateStartValue !== null && specialOfferDateStartValue !== undefined &&
      specialOfferDateEndValue !== null && specialOfferDateEndValue !== undefined
    ) {
      //if (String(productSKU) !== '') {
      setSpecialOfferDatesValueCheck(true);
      //}

      let startDate = new Date(specialOfferDateStartValue);
      
      let endDate = new Date(specialOfferDateEndValue);
      
      //if (startDate > new Date() && endDate > startDate) {
      if (endDate > startDate) {
        setSpecialOfferDatesValid(true);
      }
      else {
        setSpecialOfferDatesValid(false);
      }
    }
  }, [specialOfferDateStartValue, specialOfferDateEndValue]);

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  const handleSpecialOfferDateStartChange = (value, formattedValue) => {
    //debugger;
    setSpecialOfferDateStartValue(value);
    setSpecialOfferDateStartFormattedValue(formattedValue);
  };

  const handleSpecialOfferDateEndChange = (value, formattedValue) => {
    //debugger;
    setSpecialOfferDateEndValue(value);
    setSpecialOfferDateEndFormattedValue(formattedValue);
  };

  async function getSpecialOffer() {
    if ((user
      && Object.keys(user).length === 0
      && Object.getPrototypeOf(user) === Object.prototype)
      || specialOfferShopId === 0
      || baseDataFetched) {

      return;
    }

    try {
      setBaseDataFetched(true);
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: id,
          qShopID: specialOfferShopId
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/SpecialOffer/${id}`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        setSpecialOffer(data);

        setSpecialOfferId(data.data.SpecialOfferID);

        setSpecialOfferComaxId(data.data.ComaxID);

        setSpecialOfferTitle(data.data.Title);
        setSpecialOfferPriority(data.data.Priority);
        setSpecialOfferDateStartValue(data.data.StartDate);
        setSpecialOfferDateEndValue(data.data.EndDate);
        setSpecialOfferShowOnHomePage(data.data.ShowOnHomepage);

        let currentProductsSetsToLoad = [];

        if (data.data.ProductsSets && data.data.ProductsSets.length > 0) {
          data.data.ProductsSets.map((e, index) => {
            let productsSet = {
              productSetId: e.ProductSetID,
              productSetName: e.Name,
              productSetDescription: e.Description,
              productSetBrand: e.Brand,
              items: []
            };

            if (e.Items && e.Items.length > 0) {
              e.Items.map((psi) => {
                let item = {
                  productInShopId: psi.ProductInShopID,
                  name: psi.Name,
                  sku: psi.SKU
                };
                productsSet.items.push(item);
              });
            }

            currentProductsSetsToLoad.push(productsSet);
          });
        }
        setSpecialOfferProductSets(currentProductsSetsToLoad);

        let currentConditionsToLoad = [];

        if (data.data.Conditions && data.data.Conditions.length > 0) {
          data.data.Conditions.map((e, index) => {
            let typeOfCondition = String(e.TypeName).split('.');
            typeOfCondition = typeOfCondition[typeOfCondition.length - 1];
            typeOfCondition = typeOfCondition.split('ViewModel')[0];

            let conditionAmount = 0;
            if (e.Amount && !Number.isNaN(e.Amount) && Number(e.Amount) > 0) {
              conditionAmount = e.Amount;
            }

            let conditionDayOfWeek = 0;
            if (e.DayOfWeek && !Number.isNaN(e.DayOfWeek) && Number(e.DayOfWeek) > 0) {
              conditionDayOfWeek = e.DayOfWeek;
            }

            let conditionHoursFrom = new Date(e.From).getUTCHours();
            if (conditionHoursFrom && !Number.isNaN(conditionHoursFrom) && Number(conditionHoursFrom) > 0) {

            }
            else {
              conditionHoursFrom = -1;
            }

            let conditionHoursTo = new Date(e.To).getUTCHours();
            if (conditionHoursTo && !Number.isNaN(conditionHoursTo) && Number(conditionHoursTo) > 0) {
              
            }
            else {
              conditionHoursTo = -1;
            }

            let conditionBonusCode = 0;
            if (e.BonusCode && e.BonusCode !== '' && e.BonusCode.length > 0) {
              conditionBonusCode = e.BonusCode;
            }

            let conditionPrice = 0;
            if (e.Price && !Number.isNaN(e.Price) && Number(e.Price) > 0) {
              conditionPrice = e.Price;
            }

            let condition = {
              conditionId: e.ConditionID,
              productSetId: e.ProductSetIdx,
              productSetName: e.Name,
              productSetDescription: e.Description,
              type: typeOfCondition,
              amount: conditionAmount,
              dayOfWeek: conditionDayOfWeek,
              hoursFrom: conditionHoursFrom,
              hoursTo: conditionHoursTo,
              bonusCode: conditionBonusCode,
              price: conditionPrice
            };

            //console.log(typeOfCondition);
            currentConditionsToLoad.push(condition);
          });
        }
        setSpecialOfferConditions(currentConditionsToLoad);

        let currentRewardsToLoad = [];

        if (data.data.Rewards && data.data.Rewards.length > 0) {
          data.data.Rewards.map((e, index) => {
            let typeOfReward = String(e.TypeName).split('.');
            typeOfReward = typeOfReward[typeOfReward.length - 1];
            typeOfReward = typeOfReward.split('ViewModel')[0];

            let rewardDiscount = 0;
            if (e.Discount && !Number.isNaN(e.Discount) && Number(e.Discount) > 0) {
              rewardDiscount = e.Discount;
            }

            let rewardNewPrice = 0;
            if (e.NewPrice && !Number.isNaN(e.NewPrice) && Number(e.NewPrice) > 0) {
              rewardNewPrice = e.NewPrice;
            }

            let rewardAmount = 0;
            if (e.Amount && !Number.isNaN(e.Amount) && Number(e.Amount) > 0) {
              rewardAmount = e.Amount;
            }

            let reward = {
              rewardId: e.RewardID,
              productSetId: e.ProductSetIdx,
              productSetName: e.Name,
              productSetDescription: e.Description,
              type: typeOfReward,
              discount: rewardDiscount,
              newPrice: rewardNewPrice,
              amount: rewardAmount
            };

            //console.log(typeOfReward);
            currentRewardsToLoad.push(reward);
          });
        }

        //console.log(currentRewardsToLoad);
        setSpecialOfferRewards(currentRewardsToLoad);

        //setShopId(data.ShopID);
      }
      else {
        setSpecialOffer({});
      }

      setLoading(false);
    }
    catch {
      //debugger;
      setSpecialOffer({});
      setLoading(false);
      setShowLoader(false);
    }
  }

  async function saveSpecialOffer() {
    try {
      setBaseDataFetched(true);
      setLoading(true);

      let productSetEmpty = false;

      //build ProductSet
      let currentProductsSetsToSave = [];

      specialOfferProductSets.map((e, index) => {
        let currentBrandIdForSaving = (!e.productSetBrand || isNaN(Number(e.productSetBrand)) || Number(e.productSetBrand) == 0) ? null : e.productSetBrand;
        let ProductsSets = {
          qID: e.productSetId,
          qProductSetIdx: e.productSetId,
          qName: e.productSetName,
          qDescription: e.productSetDescription,
          qBrandID: currentBrandIdForSaving,
          qProductInShopIDList: []
        };

        if (e.items && e.items.length > 0) {
          e.items.map((psi) => {
            ProductsSets.qProductInShopIDList.push(psi);
          });
        }
        else {
          productSetEmpty = true;
        }

        currentProductsSetsToSave.push(ProductsSets);
      });

      if (productSetEmpty) {
        setErrorProductSetEmpty(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorProductSetEmpty(false);
      }

      if (specialOfferConditions.length == 0) {
        setErrorNoCondition(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorNoCondition(false);
      }

      let condtionForSetWithNoSet = false;

      //build Conditions
      let currentConditionsToSave = [];

      specialOfferConditions.map((e, index) => {
        let typeOfCondition = 'SLK.Web.Models.SpecialOfferModels.ConditionModels.' + String(e.type) + 'ViewModel';
        debugger;

        if (e.type.indexOf('ProductSet') !== -1 && (e.productSetId == undefined || e.productSetId == 0)) {
          condtionForSetWithNoSet = true;
        }

        let conditionAmount = 0;
        if (e.amount && !Number.isNaN(e.amount) && Number(e.amount) > 0) {
          conditionAmount = e.amount;
        }

        let conditionDayOfWeek = null;
        if (e.dayOfWeek && !Number.isNaN(e.dayOfWeek) && Number(e.dayOfWeek) > 0) {
          conditionDayOfWeek = e.dayOfWeek;
        }

        let conditionHoursFrom = null
        if (e.hoursFrom && !Number.isNaN(e.hoursFrom) && Number(e.hoursFrom) > 0) {
          conditionHoursFrom = dateAdd(new Date(), 'hour', e.hoursFrom);
        }
        else {
          conditionHoursFrom = new Date();
        }

        let conditionHoursTo = null;
        if (e.To && !Number.isNaN(e.To) && Number(e.To) > 0) {
          conditionHoursTo = dateAdd(new Date(), 'hour', e.To);
        }
        else {
          conditionHoursTo = new Date();
        }

        let conditionBonusCode = null;
        if (e.bonusCode && e.bonusCode !== '' && e.bonusCode.length > 0) {
          conditionBonusCode = e.bonusCode;
        }

        let conditionPrice = 0;
        if (e.price && !Number.isNaN(e.price) && Number(e.price) > 0) {
          conditionPrice = e.price;
        }

        let condition = {
          //conditionId: e.ConditionID,
          ProductSetIdx: e.productSetId,
          //productSetName: e.Name,
          //productSetDescription: e.Description,
          TypeName: typeOfCondition,
          Amount: conditionAmount,
          DayOfWeek: conditionDayOfWeek,
          From: conditionHoursFrom,
          To: conditionHoursTo,
          BonusCode: conditionBonusCode,
          Price: conditionPrice
        };

        //console.log(typeOfCondition);
        currentConditionsToSave.push(condition);
      });

      if (condtionForSetWithNoSet) {
        setErrorConditionWithoutSet(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorConditionWithoutSet(false);
      }

      if (specialOfferRewards.length == 0) {
        setErrorNoReward(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorNoReward(false);
      }

      let rewardForSetWithNoSet = false;

      //build Rewards
      let currentRewardsToSave = [];

      specialOfferRewards.map((e, index) => {
        let typeOfReward = 'SLK.Web.Models.SpecialOfferModels.RewardModels.' + String(e.type) + 'ViewModel';
        debugger;

        if (e.type.indexOf('ProductSet') !== -1 && (e.productSetId == undefined || e.productSetId == 0)) {
          rewardForSetWithNoSet = true;
        }

        let rewardDiscount = 0;
        if (e.Discount && !Number.isNaN(e.Discount) && Number(e.Discount) > 0) {
          rewardDiscount = e.discount;
        }

        let rewardNewPrice = 0;
        if (e.newPrice && !Number.isNaN(e.newPrice) && Number(e.newPrice) > 0) {
          rewardNewPrice = e.newPrice;
        }

        let rewardAmount = 0;
        if (e.amount && !Number.isNaN(e.amount) && Number(e.amount) > 0) {
          rewardAmount = e.amount;
        }

        let reward = {
          //rewardId: e.RewardID,
          ProductSetIdx: e.productSetId,
          TypeName: typeOfReward,
          Discount: rewardDiscount,
          NewPrice: rewardNewPrice,
          Amount: rewardAmount
        };

        //console.log(typeOfReward);
        currentRewardsToSave.push(reward);
      });

      if (rewardForSetWithNoSet) {
        setErrorRewardWithoutSet(true);
        setLoading(false);
        setShowLoader(false);
        return;
      }
      else {
        setErrorRewardWithoutSet(false);
      }

      //let startDate = specialOfferDateStartValue
      let currentIdForSaving = (id == 'new') ? 0 : id;

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qID: currentIdForSaving,
          qShopID: specialOfferShopId,
          qTitle: specialOfferTitle,
          qPriority: specialOfferPriority,
          qStartDate: specialOfferDateStartValue,
          qEndDate: specialOfferDateEndValue,
          qShowOnHomepage: specialOfferShowOnHomePage,
          qProductsSets: currentProductsSetsToSave,
          qConditions: currentConditionsToSave,
          qRewards: currentRewardsToSave,
          qComaxID: specialOfferComaxId
        })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/SaveSpecialOffer`, requestOptions);

        const data = await response.json();

        if (data.success == true) {
          history.push('/specialoffers');
        }
      }
      else {
        setSpecialOffer({});
      }

      setLoading(false);
    }
    catch {
      //debugger;
      setSpecialOffer({});
      setLoading(false);
      setShowLoader(false);
    }
  }

  const handleSpecialOfferAddProductSet = (e) => {
    //debugger;
    let currentProductSetId = productSetId;

    if (!currentProductSetId || currentProductSetId == 0) {
      currentProductSetId = specialOfferProductSets.length + 1;
    }

    setSpecialOfferProductSets([...specialOfferProductSets, {
      productSetId: currentProductSetId,
      productSetName: ''
    }]);
  };

  const handleSpecialOfferRemoveProductSet = (productSetId) => {
    //debugger;
    //console.log(productSetId);
    //console.log(specialOfferProductSets);

    if (productSetId !== 0) {
      let newSpecialOfferProductSets = specialOfferProductSets.filter(e => e.productSetId !== productSetId);
      setSpecialOfferProductSets(newSpecialOfferProductSets);
    }
  };

  const handleSpecialOfferChangeProductSet = (productSet) => {
    //console.log(productSet);

    let currentProductSets = specialOfferProductSets.filter(e => e.productSetId !== productSet.productSetId);
    //console.log(currentProductSets);

    let thisProductSet = specialOfferProductSets.find(e => e.productSetId == productSet.productSetId);
    if (thisProductSet !== undefined) {

    //  console.log(thisProductSet);

      setSpecialOfferProductSets([...currentProductSets, productSet]);

    }
  };

  const handleSpecialOfferAddCondition = (e) => {
    //debugger;
    //let currentConditionId = specialOfferProductSets.length + 1;

    const conditionIds = specialOfferConditions.map(object => {
      return object.conditionId;
    });

    const nextId = (conditionIds.length > 0) ? Math.max(...conditionIds) + 1 : 1;

    setSpecialOfferConditions([...specialOfferConditions, {
      conditionId: nextId
    }]);
  };

  const handleSpecialOfferRemoveCondition = (conditionId) => {
    //debugger;
    if (conditionId !== 0) {
      let newSpecialOfferConditionsArr = specialOfferConditions.filter(e => e.conditionId !== conditionId);
      setSpecialOfferConditions(newSpecialOfferConditionsArr);
    }
  };

  const handleSpecialOfferChangeCondition = (condition) => {
    let currentConditions = specialOfferConditions.filter(e => e.conditionId !== condition.conditionId);
    let thisCondition = specialOfferConditions.find(e => e.conditionId == condition.conditionId);
    if (thisCondition !== undefined) {
      setSpecialOfferConditions([...currentConditions, condition]);
    }
  };

  const handleSpecialOfferAddReward = (e) => {
    const rewardIds = specialOfferRewards.map(object => {
      return object.rewardId;
    });

    const nextId = (rewardIds.length > 0) ? Math.max(...rewardIds) + 1 : 1;

    setSpecialOfferRewards([...specialOfferRewards, {
      rewardId: nextId
    }]);
  };

  const handleSpecialOfferRemoveReward = (rewardId) => {
    //debugger;
    if (rewardId !== 0) {
      let newSpecialOfferRewardsArr = specialOfferRewards.filter(e => e.rewardId !== rewardId);
      setSpecialOfferRewards(newSpecialOfferRewardsArr);
    }
  };

  const handleSpecialOfferChangeReward = (reward) => {
    let currentRewards = specialOfferRewards.filter(e => e.rewardId !== reward.rewardId);
    let thisReward = specialOfferRewards.find(e => e.rewardId == reward.rewardId);
    if (thisReward !== undefined) {
      setSpecialOfferRewards([...currentRewards, reward]);
    }
  };

  const renderProductSets = () => {

    return (
      specialOfferProductSets.map((e, i) => {

        //console.log(e);
        return (
          <SpecialOfferProductSet
            key={i}
            /*specialOffer={e}*/
            currentProductSetId={e.productSetId}
            specialOfferProductSet={e}
            handleProductSetChange={handleSpecialOfferChangeProductSet}
            handleProductSetRemove={handleSpecialOfferRemoveProductSet} />
        );
      }));
  };

  const renderConditions = () => {

    return (
      specialOfferConditions.map((e, i) => {

        //console.log(e);
        //console.log(specialOfferProductSets);
        return (
          <SpecialOfferCondition
            key={e.conditionId}
            currentConditionId={e.conditionId}
            specialOfferCondition={e}
            handleConditionRemove={handleSpecialOfferRemoveCondition}
            handleConditionChange={handleSpecialOfferChangeCondition}
            specialOfferProductSets={specialOfferProductSets}
          />
        );
      }));
  };

  const renderRewards = () => {

    return (
      specialOfferRewards.map((e, i) => {

        //console.log(e);
        //console.log(specialOfferProductSets);
        return (
          <SpecialOfferReward
            key={e.rewardId}
            currentRewardId={e.rewardId}
            specialOfferReward={e}
            handleRewardRemove={handleSpecialOfferRemoveReward}
            handleRewardChange={handleSpecialOfferChangeReward}
            specialOfferProductSets={specialOfferProductSets}
          />
        );
      }));
  };

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setSpecialOfferShopId(currentShop);
              //setSpecialOfferSearchString(currentSearchString);

              return (
                <div className={styles.specialOfferEditContainer}>
                  <div className={styles.specialOfferEditTitleText + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferEditTitleTextRtl)}>
                    {t('specialOffersEdit.editTitle')}
                    <button type="button" className={"btn-close " + (getCurrentLng() === 'en' ? styles.topCloseButton : styles.topCloseButtonRtl)} aria-label="Close"
                      onClick={(e) => {
                        history.push('/specialoffers');
                      }}></button>
                  </div>

                  <div className={styles.specialOfferEditTitle}>
                  </div>
                  <Container className={styles.specialOfferEdit + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferEditRtl)}>
                    <Row className={styles.specialOfferEditExplanationRow}>
                      <Col lg="12" xs="12">
                        {t("specialOffersEdit.topExplanation")}
                      </Col>
                    </Row>

                    <Row className={styles.specialOfferEditRow}>
                      <Col lg="12" md="12" xs="12">
                        <Label>
                          {t('specialOffersEdit.title')}
                        </Label>
                      </Col>
                      <Col lg="12" md="12" xs="12" className={(getCurrentLng() === 'en' ? styles.specialOfferEditControlsCol : styles.specialOfferEditControlsColRtl)}>
                        <input maxLength="250" className={styles.specialOfferEditInputField + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputFieldRtl)} value={specialOfferTitle} onChange={(e) => {
                          setSpecialOfferTitle(e.target.value);
                        }} />
                        {specialOfferTitleCheck && ((specialOfferTitleValid) ? null :
                          <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>{t("specialOffersEdit.validationMessageTitle")}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('specialOffersEdit.priority')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.specialOfferEditControlsCol : styles.specialOfferEditControlsColRtl)}>
                        <NumericInputWithButtons
                          id={"priority"}
                          title={t('specialOffersEdit.priorityPlaceHolder')}
                          data-productid={specialOfferId}
                          name="priority"
                          placeholder={t('specialOffersEdit.priorityPlaceHolder')}
                          onChange={(value) => {
                            setSpecialOfferPriority(Number(value));
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={specialOfferPriority}
                          pattern='[0-9]{0,5}'
                          itemId={specialOfferId}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('specialOffersEdit.startDate')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.specialOfferEditControlsCol : styles.specialOfferEditControlsColRtl)}>
                        <DatePicker
                          id="specialOfferDateStartValue"
                          dateFormat='DD/MM/YYYY'
                          value={specialOfferDateStartValue}
                          showClearButton={false}
                          onChange={(v, f) => handleSpecialOfferDateStartChange(v, f)} />
                      </Col>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('specialOffersEdit.endDate')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.specialOfferEditControlsCol : styles.specialOfferEditControlsColRtl)}>
                        <DatePicker
                          id="specialOfferDateEndValue"
                          dateFormat='DD/MM/YYYY'
                          value={specialOfferDateEndValue}
                          showClearButton={false}
                          onChange={(v, f) => handleSpecialOfferDateEndChange(v, f)} />
                      </Col>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      <Col lg="6" md="6" xs="6">
                        <Label>
                          {t('specialOffersEdit.showOnHomePage')}
                        </Label>
                      </Col>
                      <Col lg="6" md="6" xs="6" className={(getCurrentLng() === 'en' ? styles.specialOfferEditControlsCol : styles.specialOfferEditControlsColRtl)}>
                        <ToggleSwitch id="editProductshowOnHomePage" name="editProductshowOnHomePage" optionLabels={[t('specialOffersEdit.showOnHomePageYes'), t('specialOffersEdit.showOnHomePageNo')]} checked={specialOfferShowOnHomePage} itemId={specialOfferId} onChange={(e, itemId) => { setSpecialOfferShowOnHomePage(e); }} />
                      </Col>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                    {specialOfferProductSets && specialOfferProductSets.length > 0 && renderProductSets()}

                    <Button
                      onClick={(e) => { handleSpecialOfferAddProductSet(e); }}
                      color="link"
                      className={styles.specialOfferEditBtn}
                      data-productid={specialOfferId}
                      name="EditAddProductSet"
                      value={specialOfferId}>{t('specialOffersEdit.AddProductSet')}
                    </Button>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      {specialOfferConditions && specialOfferConditions.length > 0 && renderConditions()}

                      <Button
                        onClick={(e) => { handleSpecialOfferAddCondition(e); }}
                        color="link"
                        className={styles.specialOfferEditBtn}
                        data-productid={specialOfferId}
                        name="EditAddCondition"
                        value={specialOfferId}>{t('specialOffersEdit.AddCondition')}
                      </Button>
                    </Row>
                    <Row className={styles.specialOfferEditRow}>
                      {specialOfferRewards && specialOfferRewards.length > 0 && renderRewards()}

                      <Button
                        onClick={(e) => { handleSpecialOfferAddReward(e); }}
                        color="link"
                        className={styles.specialOfferEditBtn}
                        data-productid={specialOfferId}
                        name="EditAddReward"
                        value={specialOfferId}>{t('specialOffersEdit.AddReward')}
                      </Button>
                    </Row>
                    <Row className={styles.lastSpecialOfferEditRow}>
                      {specialOfferDatesValueCheck && ((specialOfferDatesValid) ? null :
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>{t("specialOffersEdit.validationMessageSpecialOfferDates")}</div>
                      )}
                      {errorProductSetEmpty ?
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>
                          {t("specialOffersEdit.errorProductSetEmpty")}
                        </div>
                        : null
                      }
                      {errorNoCondition ?
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>
                          {t("specialOffersEdit.errorNoCondition")}
                        </div>
                        : null
                      }
                      {errorNoReward ?
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>
                          {t("specialOffersEdit.errorNoReward")}
                        </div>
                        : null
                      }
                      {errorConditionWithoutSet ?
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>
                          {t("specialOffersEdit.errorConditionWithoutSet")}
                        </div>
                        : null
                      }
                      {errorRewardWithoutSet ?
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>
                          {t("specialOffersEdit.errorRewardWithoutSet")}
                        </div>
                        : null
                      }
                      <Col lg="12" sm="12" xs="12">
                        <Button
                          color="link"
                          className={styles.specialOfferEditBtn}
                          data-productid={specialOfferId}
                          name="Edit"
                          value={specialOfferId}
                          disabled={specialOffer == null} //|| productPrice === 0
                          onClick={(e) => { saveSpecialOffer(); }}
                        >{(specialOfferId == 0) ? t('specialOfferEdit.addSpecialOfferToShopBtn') : t('specialOfferEdit.saveSpecialOfferToShopBtn')}</Button>
                      </Col>
                    </Row>
                  </Container>

                  <ErrorMessageCustomPopup show={ordersEditError} title={ordersEditErrorMessage} onClose={(e) => { setOrdersEditError(false); }} />
                  <LoaderAnimation show={showLoader} allowClose={false} />
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}
    </userContext.Consumer>
  )
}
export default SpecialOfferEdit