import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './shippingtime-card.module.scss';
import * as Icon from 'react-bootstrap-icons';

const ShippingTimeCard = (props) => {
  const { shippingTime } = props;
  //console.log(shippingTime);

  const [shippingTimeCardOpen, setShippingTimeCardOpen] = useState(false);

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  const toggleShippingTimeCard = (e) => {
    if (!shippingTimeCardOpen == true) {
      //onHandleMoveShippingTimeCardOpenToTop(productId);
    }
    setShippingTimeCardOpen(!shippingTimeCardOpen);
  }

  return (
    <Card className={styles.shippingTimeCard + " " + (getCurrentLng() === 'en' ? "" : styles.shippingTimeCardRTL)}>
      <CardHeader onClick={(e) => {
        //toggleShippingTimeCard(e)
      }} className={styles.cardHeader}>
        <Row className={styles.cardRow}>
          <Col lg="4" xs="12" className={styles.cardInfoContainer}>
            <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
              <NavLink tag={Link} to={`${path}edit/${shippingTime.ScheduleID}`} className={styles.cardNavLink + " " + styles.cardCollectionLink}>
                <Icon.PencilSquare /> {shippingTime.Name}
              </NavLink>
            </CardTitle>
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {t('shippingTimes.shipAmountPerHourLimit')}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {shippingTime.ShipAmountPerHourLimit}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {t('shippingTimes.deliveryInterval')}
          </Col>
          <Col lg="2" xs="6" className={styles.cardColumn}>
            {shippingTime.DeliveryInterval}
          </Col>
          {/*<Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)}>*/}
          {/*  {(shippingTimeCardOpen) ?*/}
          {/*    <Icon.ChevronUp />*/}
          {/*    :*/}
          {/*    <Icon.ChevronDown />}*/}
          {/*</Col>*/}
        </Row>
      </CardHeader>
      <Collapse isOpen={shippingTimeCardOpen}>
        <CardBody className={styles.cardEditSectionWrapper}>
          <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
            <Row className={styles.cardEditRow}>
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {t('shippingTimes.shipAmountPerHourLimit')}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {shippingTime.ShipAmountPerHourLimit}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {t('shippingTimes.deliveryInterval')}*/}
              {/*</Col>*/}
              {/*<Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>*/}
              {/*  {shippingTime.DeliveryInterval}*/}
              {/*</Col>*/}
            </Row>
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default ShippingTimeCard