import React, { Component, useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';
import { useTranslation } from "react-i18next";
import { getCurrentLng } from '../../i18next_fun';
import { dateAdd, getFullDateInUTC } from '../../dateAdd';
import CustomPopup from '../../Components/CustomPopup';
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';

import * as Icon from 'react-bootstrap-icons';
import 'bootstrap/scss/bootstrap.scss';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import { DatePicker } from 'reactstrap-date-picker'

import styles from './special-work-times.module.scss';

const SpecialWorkTimes = (props) => {
  const [user, setUser] = useState({});
  const [currentSelectedShop, setCurrentSelectedShop] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const [customModalMultipleShopsAlert, setCustomModalMultipleShopsAlert] = useState(false);

  const [editError, setEditError] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState('');

  const [modalImmediate, setModalImmediate] = useState(false);

  const [immediateSpecialWorkTimeHours, setImmediateSpecialWorkTimeHours] = useState(false);
  const [immediateSpecialWorkTimeHoursValue, setImmediateSpecialWorkTimeHoursValue] = useState(0);
  const [immediateSpecialWorkTimeHoursId, setImmediateSpecialWorkTimeHoursId] = useState(null);

  const [modalFuture, setModalFuture] = useState(false);

  const [futureSpecialWorkTimeHoursId, setFutureSpecialWorkTimeHoursId] = useState(null);

  const [futureSpecialWorkTimeHoursFrom, setFutureSpecialWorkTimeHoursFrom] = useState(false);
  const [futureSpecialWorkTimeHoursFromValue, setFutureSpecialWorkTimeHoursFromValue] = useState(0);

  const [futureSpecialWorkTimeHoursTo, setFutureSpecialWorkTimeHoursTo] = useState(false);
  const [futureSpecialWorkTimeHoursToValue, setFutureSpecialWorkTimeHoursToValue] = useState(0);

  const [futureSpecialWorkTimeDateFromValue, setFutureSpecialWorkTimeDateFromValue] = useState(new Date());
  const [futureSpecialWorkTimeDateFromFormattedValue, setFutureSpecialWorkTimeDateFromFormattedValue] = useState(undefined);

  const [futureSpecialWorkTimeDateToValue, setFutureSpecialWorkTimeDateToValue] = useState(new Date());
  const [futureSpecialWorkTimeDateToFormattedValue, setFutureSpecialWorkTimeDateToFormattedValue] = useState(undefined);

  const [futureDatesValid, setFutureDatesValid] = useState(false);
  const [futureDatesValieCheck, setFutureDatesValueCheck] = useState(false);

  const { t, i18n } = useTranslation('common');

  const { path } = useRouteMatch();

  const arrayOfTimesForImmediate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 48, 72, 96, 120, 144, 168];

  useEffect(() => {
    document.title = t('nav.specialWorkTimes');
  }, []);

  useEffect(() => {
    if (editErrorMessage && editErrorMessage.length > 0) {
      setEditError(true);
    }
  }, [editErrorMessage]);

  useEffect(() => {
    //console.log(productShop);
    //debugger;
    if (currentSelectedShop != null) {
      if (Number(currentSelectedShop) == 0) { //layoutDataInitialized
        toggleMultipleShopsCustomModal();
      }
      else {
        getSpecialTimes();
      }
    }

  }, [currentSelectedShop]);

  useEffect(() => {

    
  }, [immediateSpecialWorkTimeHoursId]);


  useEffect(() => {
    if (futureSpecialWorkTimeDateFromValue !== null && futureSpecialWorkTimeDateFromValue !== undefined &&
      futureSpecialWorkTimeDateToValue !== null && futureSpecialWorkTimeDateToValue !== undefined &&
      futureSpecialWorkTimeHoursFromValue !== null && futureSpecialWorkTimeHoursFromValue !== undefined &&
      futureSpecialWorkTimeHoursToValue !== null && futureSpecialWorkTimeHoursToValue !== undefined
      ) {
      //if (String(productSKU) !== '') {
      setFutureDatesValueCheck(true);
      //}

      let startTime = new Date(futureSpecialWorkTimeDateFromValue);
      startTime.setHours(0);
      startTime = dateAdd(startTime, 'hour', futureSpecialWorkTimeHoursFromValue);

      let endTime = new Date(futureSpecialWorkTimeDateToValue);
      endTime.setHours(0);
      endTime = dateAdd(endTime, 'hour', futureSpecialWorkTimeHoursToValue);

      if (startTime > new Date() && endTime > startTime) {
        setFutureDatesValid(true);
      }
      else {
        setFutureDatesValid(false);
      }
    }
  }, [futureSpecialWorkTimeDateFromValue, futureSpecialWorkTimeDateToValue, futureSpecialWorkTimeHoursFromValue, futureSpecialWorkTimeHoursToValue]);

  const toggleMultipleShopsCustomModal = (e) => {
    //document.body.style.overflow = (!customModalMultipleShopsAlert) ? 'hidden' : 'unset';
    setCustomModalMultipleShopsAlert(!customModalMultipleShopsAlert);
  }

  const toggleImmediateSpecialWorkTimeHours = (e) => { setImmediateSpecialWorkTimeHours(!immediateSpecialWorkTimeHours); };

  const toggleImmediateModal = (e) => {
    //document.body.style.overflow = (!modalImmediate) ? 'hidden' : 'unset';
    setModalImmediate(!modalImmediate);
  }

  const toggleFutureSpecialWorkTimeHoursFrom = (e) => { setFutureSpecialWorkTimeHoursFrom(!futureSpecialWorkTimeHoursFrom); };
  const toggleFutureSpecialWorkTimeHoursTo = (e) => { setFutureSpecialWorkTimeHoursTo(!futureSpecialWorkTimeHoursTo); };

  const toggleFutureModal = (e) => {
    //document.body.style.overflow = (!modalFuture) ? 'hidden' : 'unset';
    setModalFuture(!modalFuture);
  }

  const handleEditImmediate = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (currentSelectedShop != null) {
      let currentTime = new Date();
      let endTime = dateAdd(new Date(), 'hour', immediateSpecialWorkTimeHoursValue);
      let saveSpecialTimeObj = {
        id: immediateSpecialWorkTimeHoursId,
        shopId: currentSelectedShop,
        dateFrom: currentTime,
        dateTo: endTime,
        isWorking: false
      };

      saveSpecialWorkTime(saveSpecialTimeObj, true);
    }
  };

  const handleDeleteImmediate = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (currentSelectedShop != null && immediateSpecialWorkTimeHoursId !== null && immediateSpecialWorkTimeHoursId !== 0) {
      let saveSpecialTimeObj = {
        id: immediateSpecialWorkTimeHoursId,
        shopId: currentSelectedShop,
        dateFrom: null,
        dateTo: null,
        isWorking: false
      };

      deleteSpecialWorkTime(saveSpecialTimeObj, true);
    }
  }

  const handleEditFuture = (e) => {
    if (e) {
      e.preventDefault();
    }

    //debugger;
    if (currentSelectedShop != null) {
      let startTime = new Date(futureSpecialWorkTimeDateFromValue);
      startTime.setHours(0);
      startTime = dateAdd(startTime, 'hour', futureSpecialWorkTimeHoursFromValue);

      let endTime = new Date(futureSpecialWorkTimeDateToValue);
      endTime.setHours(0);
      endTime = dateAdd(endTime, 'hour', futureSpecialWorkTimeHoursToValue);

      let saveSpecialTimeObj = {
        id: futureSpecialWorkTimeHoursId,
        shopId: currentSelectedShop,
        dateFrom: startTime,
        dateTo: endTime,
        isWorking: false
      };

      saveSpecialWorkTime(saveSpecialTimeObj, false);
      toggleFutureModal();
    }
  };

  const handleDeleteFuture = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (currentSelectedShop != null && futureSpecialWorkTimeHoursId !== null && futureSpecialWorkTimeHoursId !== 0) {
      let saveSpecialTimeObj = {
        id: futureSpecialWorkTimeHoursId,
        shopId: currentSelectedShop,
        dateFrom: null,
        dateTo: null,
        isWorking: false
      };

      deleteSpecialWorkTime(saveSpecialTimeObj, false);
      toggleFutureModal();
    }
  }

  const handleFutureSpecialWorkTimeDateFromChange = (value, formattedValue) => {
    //debugger;
    setFutureSpecialWorkTimeDateFromValue(value);
    setFutureSpecialWorkTimeDateFromFormattedValue(formattedValue);
  }

  const handleFutureSpecialWorkTimeDateToChange = (value, formattedValue) => {
    //debugger;
    setFutureSpecialWorkTimeDateToValue(value);
    setFutureSpecialWorkTimeDateToFormattedValue(formattedValue);
  }

  async function getSpecialTimes() {
    setShowLoader(true);
    try {
      // const response = await fetch('weatherforecast');

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            id: 0,
            shopId: currentSelectedShop,
            dateFrom: null, 
            dateTo: null,
            isWorking: null
          }
        )
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberShopSpecialShipTimes/GetSpecialTimes`, requestOptions);

        const data = await response.json();

        //debugger;

        //setTotal(data.total);
        //setTotalInStock(data.totalInStock);
        //setTotalOutOfStock(data.totalOutOfStock);

        if (data.data.length === 0) {
          //setProductsReachedEnd(true);
        }

        if (data.immediateSpecialTime == null) {
          setImmediateSpecialWorkTimeHoursId(0);
          setImmediateSpecialWorkTimeHoursValue(0);
        }
        else {
          setImmediateSpecialWorkTimeHoursId(data.immediateSpecialTime.id);
          var dateFrom = new Date(data.immediateSpecialTime.dateFrom);
          var dateTo = new Date(data.immediateSpecialTime.dateTo);
          var span = new Date(dateTo - dateFrom);
          setImmediateSpecialWorkTimeHoursValue(span.getUTCHours());
        }

        if (data.futureSpecialTime == null) {
          setFutureSpecialWorkTimeHoursId(0);
        }
        else {
          setFutureSpecialWorkTimeHoursId(data.futureSpecialTime.id);
          var dateFrom = new Date(data.futureSpecialTime.dateFrom);
          var dateTo = new Date(data.futureSpecialTime.dateTo);

          setFutureSpecialWorkTimeDateFromValue(getFullDateInUTC(dateFrom));
          setFutureSpecialWorkTimeDateToValue(getFullDateInUTC(dateTo));

          setFutureSpecialWorkTimeHoursFromValue(dateFrom.getUTCHours());
          setFutureSpecialWorkTimeHoursToValue(dateTo.getUTCHours());
        }

        //const fullProductsArr = [...products, ...data.data];

        //setProducts(fullProductsArr);
      }
      else {
        //setProducts([]);
      }
      setShowLoader(false);
    }
    catch {
      setShowLoader(false);
    }
  }

  async function saveSpecialWorkTime(specialWorkTime, isImmediate) {

    setShowLoader(true);

    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(specialWorkTime)
      };

      //debugger;
      console.log(immediateSpecialWorkTimeHoursValue);

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberShopSpecialShipTimes/EditSpecialTime`, requestOptions);

        const data = await response.json();

        //if (data.data.length === 0) {

        //}

        if (data.success == true) {
          //setProduct(data.data);
          if (isImmediate) {
            toggleImmediateModal();
          }
          else {
            toggleFutureModal();
          }

          await getSpecialTimes();
        }
        else {
          if (data.error === "SKU_exists") {
            setEditErrorMessage('Error Adding Product');
          }
          else if (data.error === "SKU_short") {
            setEditErrorMessage('Error Adding Product');
          }
          else {
            setEditErrorMessage('Error');
          }
        }
      }
      else {
        //setProductInShop({});
      }
    }
    catch {
      setEditErrorMessage('Error Adding Product');
    }

    setShowLoader(false);
  }

  async function deleteSpecialWorkTime(specialWorkTime, isImmediate) {

    setShowLoader(true);

    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(specialWorkTime)
      };

      //debugger;
      console.log(immediateSpecialWorkTimeHoursValue);

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberShopSpecialShipTimes/DeleteSpecialTime`, requestOptions);

        const data = await response.json();

        //if (data.data.length === 0) {

        //}

        if (data.success == true) {
          //setProduct(data.data);
        }
        else {
          if (data.error === "SKU_exists") {
            setEditErrorMessage('Error Adding Product');
          }
          else if (data.error === "SKU_short") {
            setEditErrorMessage('Error Adding Product');
          }
          else {
            setEditErrorMessage('Error');
          }
        }
      }
      else {
        //setProductInShop({});
      }
    }
    catch {
      setEditErrorMessage('Error Adding Product');
    }

    if (isImmediate) {
      toggleImmediateModal();
      setImmediateSpecialWorkTimeHoursId(0);
      setImmediateSpecialWorkTimeHoursValue(0);
      await getSpecialTimes();
    }
    else {
      toggleFutureModal();
      setFutureSpecialWorkTimeHoursId(0);

      setFutureSpecialWorkTimeDateFromValue(new Date());
      setFutureSpecialWorkTimeDateToValue(new Date());

      setFutureSpecialWorkTimeHoursFromValue(0);
      setFutureSpecialWorkTimeHoursToValue(0);

    }

    setShowLoader(false);
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return(
          <layoutContext.Consumer>
            {({ currentShop }) => {

              setCurrentSelectedShop(currentShop);

              return (

                <div>
                  {/*hi {currentSelectedShop}*/}

                  <Container className={(getCurrentLng() === 'en' ? "" : styles.preferencesRtl)}>

                    <Row className={styles.preferencesRow} onClick={(e) => { toggleImmediateModal(); }}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t('specialWorkTimes.immediateTurnOff')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                        {(getCurrentLng() === 'en') ?
                          <Icon.ChevronRight /> :
                          <Icon.ChevronLeft />
                          }
                        {(immediateSpecialWorkTimeHoursId !== null && immediateSpecialWorkTimeHoursId !== 0 && immediateSpecialWorkTimeHoursValue !== 0) ?
                          <span className={styles.immediateValueInMenu}>{immediateSpecialWorkTimeHoursValue + " " + t('specialWorkTimes.immediateHoursOptionHours')}</span>
                          : null}
                      </Col>
                    </Row>

                    <Row className={styles.preferencesRow} onClick={(e) => { toggleFutureModal(); }}>
                      <Col lg="6" sm="6" xs="6">
                        <Label>
                          {t('specialWorkTimes.futureTurnOff')}
                        </Label>
                      </Col>
                      <Col lg="6" sm="6" xs="6" className={(getCurrentLng() === 'en' ? styles.perferencesControlsCol : styles.perferencesControlsColRtl)}>
                        {(getCurrentLng() === 'en') ?
                          <Icon.ChevronRight /> :
                          <Icon.ChevronLeft />
                          }
                        {(futureSpecialWorkTimeHoursId !== null && futureSpecialWorkTimeHoursId !== 0 && futureSpecialWorkTimeDateFromValue && futureSpecialWorkTimeDateFromValue instanceof Date && futureSpecialWorkTimeDateToValue && futureSpecialWorkTimeDateToValue instanceof Date) ?
                          <span className={styles.immediateValueInMenu}> {futureSpecialWorkTimeDateFromValue.getDate() + "/" + (futureSpecialWorkTimeDateFromValue.getMonth() + 1) + " - " + futureSpecialWorkTimeDateToValue.getDate() + "/" + (futureSpecialWorkTimeDateToValue.getMonth() + 1)}</span>
                        : null }
                      </Col>
                    </Row>

                  </Container>


                  <Modal isOpen={modalImmediate} toggle={toggleImmediateModal} scrollable={true} fullscreen={true} backdrop="static">
                    <ModalHeader toggle={toggleImmediateModal} className={(getCurrentLng() === 'en' ? "" : styles.modalHeaderRTL)}>{t('specialWorkTimes.immediateTurnOffTitle')}</ModalHeader>
                    <ModalBody className={styles.modalBody + " " + (getCurrentLng() === 'en' ? "" : styles.modalBodyRtl)}>
                      <Container className={(getCurrentLng() === 'en' ? "" : styles.preferencesRtl)}>

                        <Row className={styles.editRow}>
                          <Col lg="6" sm="6" xs="6">
                            <Label>
                              {t('specialWorkTimes.selectImmediateSpecialWorkTimeHours')}
                            </Label>
                          </Col>
                          <Col lg="6" sm="6" xs="6" className={(getCurrentLng() === 'en' ? styles.editControlsCol : styles.editControlsColRtl)}>
                            <Dropdown
                              isOpen={immediateSpecialWorkTimeHours}
                              toggle={toggleImmediateSpecialWorkTimeHours}
                              name="immediateSpecialWorkTimeHours"
                              placeholder={t('specialWorkTimes.selectTimeSpan')}
                            >
                              <DropdownToggle caret
                                onClick={toggleImmediateSpecialWorkTimeHours}
                                data-toggle={"toggleImmediateSpecialWorkTimeHours"}
                                aria-expanded={immediateSpecialWorkTimeHours}
                                className={styles.editDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                              >
                                {(immediateSpecialWorkTimeHoursValue === 0) ?
                                  t('specialWorkTimes.selectTimeSpan') :
                                  String(immediateSpecialWorkTimeHoursValue) + " " + t('specialWorkTimes.immediateHoursOptionHours')}
                              </DropdownToggle>
                              {/*<DropdownMenu>*/}
                              {/*  {[...Array(4)].map((x, i) =>*/}
                              {/*    <DropdownItem value={i + 1} key={"immediateHoursOption" + String(i + 1)} className={((immediateSpecialWorkTimeHoursValue == i + 1) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}*/}
                              {/*      onClick={(e) => {*/}
                              {/*        setImmediateSpecialWorkTimeHoursValue(i + 1);*/}
                              {/*      }}>*/}
                              {/*      {String(i + 1) + " " + t('specialWorkTimes.immediateHoursOptionHours')}*/}
                              {/*    </DropdownItem>*/}
                              {/*  )}*/}
                              {/*</DropdownMenu>*/}

                              <DropdownMenu className={styles.dropdownWindow}>
                                {arrayOfTimesForImmediate.map((x, i) =>
                                  <DropdownItem value={x} key={"immediateHoursOption" + String(x)} className={((immediateSpecialWorkTimeHoursValue == x) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                    onClick={(e) => {
                                      setImmediateSpecialWorkTimeHoursValue(x);
                                    }}>
                                    {(x < 24) ? String(x) + " " + t('specialWorkTimes.immediateHoursOptionHours') : String(Number(x / 24)) + " " + t('specialWorkTimes.immediateHoursOptionDays')}
                                  </DropdownItem>
                                )}
                              </DropdownMenu>


                              {/*  */}
                            </Dropdown>
                          </Col>
                        </Row>

                        <Row className={styles.lastEditRow}>
                          <Col lg="12" sm="12" xs="12">
                            <Button
                              color="link"
                              className={styles.editBtn}
                              data-shopspecialworktimeid={immediateSpecialWorkTimeHoursId}
                              name="Edit"
                              value={immediateSpecialWorkTimeHoursId}
                              disabled={currentSelectedShop === null || currentSelectedShop === 0 || immediateSpecialWorkTimeHoursId === null || immediateSpecialWorkTimeHoursValue < 1} 
                              onClick={(e) => { handleEditImmediate(e) }}
                            >{(immediateSpecialWorkTimeHoursId == 0) ? t('specialWorkTimes.saveNewImmediate') : t('specialWorkTimes.saveEditImmediate')}</Button>
                          </Col>
                        </Row>

                        {immediateSpecialWorkTimeHoursId === 0 ? null :
                          <Row className={styles.lastEditRow}>
                            <Col lg="12" sm="12" xs="12">
                              <Button
                                color="link"
                                className={styles.editBtn}
                                data-shopspecialworktimeid={immediateSpecialWorkTimeHoursId}
                                name="Delete"
                                value={immediateSpecialWorkTimeHoursId}
                                disabled={currentSelectedShop === null || currentSelectedShop === 0 || immediateSpecialWorkTimeHoursId === null || immediateSpecialWorkTimeHoursId === 0}
                                onClick={(e) => { handleDeleteImmediate(e) }}
                              >{t('specialWorkTimes.deleteImmediate')}</Button>
                            </Col>
                          </Row>
                        }
                      </Container>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modalFuture} toggle={toggleFutureModal} scrollable={true} fullscreen={true} backdrop="static">
                    <ModalHeader toggle={toggleFutureModal} className={(getCurrentLng() === 'en' ? "" : styles.modalHeaderRTL)}>{t('specialWorkTimes.futureTurnOffTitle')}</ModalHeader>
                    <ModalBody className={styles.modalBody + " " + (getCurrentLng() === 'en' ? "" : styles.modalBodyRtl)}>
                      <Container className={(getCurrentLng() === 'en' ? "" : styles.preferencesRtl)}>
                        <Row>
                          <Col lg="12" sm="12" xs="12">
                            <Label>
                              {t('specialWorkTimes.selectFutureSpecialWorkTimeHoursFrom')}
                            </Label>
                          </Col>
                        </Row>
                        <Row className={styles.editRow}>
                          <Col lg="6" sm="6" xs="6" className={styles.editDatesCol}>
                            <span><Icon.Calendar /></span>
                            <DatePicker
                              id="futureSpecialWorkTimeDateFromValue"
                              dateFormat='DD/MM/YYYY'
                              value={futureSpecialWorkTimeDateFromValue}
                              showClearButton={false}
                              onChange={(v, f) => handleFutureSpecialWorkTimeDateFromChange(v, f)} />
                          </Col>
                          <Col lg="6" sm="6" xs="6" className={styles.editTimeCol}>
                            <span><Icon.Clock /></span>
                            <Label>
                              <Dropdown
                                isOpen={futureSpecialWorkTimeHoursFrom}
                                toggle={toggleFutureSpecialWorkTimeHoursFrom}
                                name="futureSpecialWorkTimeHoursFrom"
                                placeholder={t('specialWorkTimes.selectHour')}
                              >
                                <DropdownToggle caret
                                  onClick={toggleFutureSpecialWorkTimeHoursFrom}
                                  data-toggle={"toggleFutureSpecialWorkTimeHoursFrom"}
                                  aria-expanded={futureSpecialWorkTimeHoursFrom}
                                  className={styles.timeDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                                >
                                  {futureSpecialWorkTimeHoursFromValue + ":00"}
                                </DropdownToggle>
                                <DropdownMenu className={styles.dropdownWindow}>
                                  {[...Array(24)].map((x, i) =>
                                    <DropdownItem value={i} key={"futureSpecialWorkTimeHoursFromOption" + String(i)} className={((futureSpecialWorkTimeHoursFromValue == i) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                      onClick={(e) => {
                                        setFutureSpecialWorkTimeHoursFromValue(i);
                                      }}>
                                      {String(i) + ":00"}
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" sm="12" xs="12">
                            <Label>
                              {t('specialWorkTimes.selectFutureSpecialWorkTimeHoursTo')}
                            </Label>
                          </Col>
                        </Row>
                        <Row className={styles.editRow}>
                          <Col lg="6" sm="6" xs="6" className={styles.editDatesCol}>
                            <span><Icon.Calendar /></span>
                            <DatePicker
                              id="futureSpecialWorkTimeDateToValue"
                              dateFormat='DD/MM/YYYY'
                              value={futureSpecialWorkTimeDateToValue}
                              showClearButton={false}
                              onChange={(v, f) => handleFutureSpecialWorkTimeDateToChange(v, f)} />
                          </Col>
                          <Col lg="6" sm="6" xs="6" className={styles.editTimeCol}>
                            <span><Icon.Clock /></span>
                            <Label>
                              <Dropdown
                                isOpen={futureSpecialWorkTimeHoursTo}
                                toggle={toggleFutureSpecialWorkTimeHoursTo}
                                name="futureSpecialWorkTimeHoursTo"
                                placeholder={t('specialWorkTimes.selectHour')}
                              >
                                <DropdownToggle caret
                                  onClick={toggleFutureSpecialWorkTimeHoursTo}
                                  data-toggle={"toggleFutureSpecialWorkTimeHoursTo"}
                                  aria-expanded={futureSpecialWorkTimeHoursTo}
                                  className={styles.timeDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                                >
                                  {futureSpecialWorkTimeHoursToValue + ":00"}
                                </DropdownToggle>
                                <DropdownMenu className={styles.dropdownWindow}>
                                  {[...Array(24)].map((x, i) =>
                                    <DropdownItem value={i + 1} key={"futureSpecialWorkTimeHoursToOption" + String(i)} className={((futureSpecialWorkTimeHoursToValue == i) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                      onClick={(e) => {
                                        setFutureSpecialWorkTimeHoursToValue(i);
                                      }}>
                                      {String(i) + ":00"}
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Label>
                          </Col>
                        </Row>

                        <Row className={styles.lastEditRow}>
                          {futureDatesValieCheck && ((futureDatesValid) ? null :
                            <div className={styles.futureEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.futureEditInputValidationMessageRtl)}>{t("specialWorkTimes.validationMessageFuture")}</div>
                          )}
                          <Col lg="12" sm="12" xs="12">
                            <Button
                              color="link"
                              className={styles.editBtn}
                              data-shopspecialworktimeid={futureSpecialWorkTimeHoursId}
                              name="Edit"
                              value={futureSpecialWorkTimeHoursId}
                              disabled={currentSelectedShop === null || currentSelectedShop === 0 || futureSpecialWorkTimeHoursId === null || !futureDatesValid}
                              onClick={(e) => { handleEditFuture(e) }}
                            >{(futureSpecialWorkTimeHoursId == 0) ? t('specialWorkTimes.saveNewImmediate') : t('specialWorkTimes.saveEditImmediate')}</Button>
                          </Col>
                        </Row>

                        {(futureSpecialWorkTimeHoursId == 0) ? null : 
                          <Row className={styles.lastEditRow}>
                            <Col lg="12" sm="12" xs="12">
                              <Button
                                color="link"
                                className={styles.editBtn}
                                data-shopspecialworktimeid={immediateSpecialWorkTimeHoursId}
                                name="Delete"
                                value={immediateSpecialWorkTimeHoursId}
                                disabled={currentSelectedShop === null || currentSelectedShop === 0 || futureSpecialWorkTimeHoursId === null || futureSpecialWorkTimeHoursId === 0}
                                onClick={(e) => { handleDeleteFuture(e) }}
                              >{t('specialWorkTimes.deleteFuture')}</Button>
                            </Col>
                          </Row>
                        }
                      </Container>
                    </ModalBody>
                  </Modal>


                  <CustomPopup className={styles.shopsAlertMain} show={customModalMultipleShopsAlert} onClose={(e) => { toggleMultipleShopsCustomModal(); }} title={""} >
                    <div className={styles.shopsAlertBellWrapper}><img src="/assets/products/products_alert_bell.svg" alt="" /></div>
                    <h2>{t('specialWorkTimes.noShopSelected')}</h2>
                    <div></div>
                    <div>
                      {t('specialWorkTimes.noShopSelectedText')}
                    </div>
                    <div className={styles.shopsAlertActionsWrapper}>
                      <button color="link" className={styles.shopsAlertActionBtn} onClick={(e) => { toggleMultipleShopsCustomModal(); }} >{t('specialWorkTimes.alertCancelText')}</button>
                      <NavLink tag={Link} to="/preferences" className={styles.shopsAlertActionBtn}>{t('specialWorkTimes.goToPreferences')}</NavLink>
                    </div>
                  </CustomPopup>

                  <ErrorMessageCustomPopup show={editError} title={editErrorMessage} onClose={(e) => { setEditError(false); }} />

                  <LoaderAnimation show={showLoader} allowClose={false} />
                </div>

              )
            }}

          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );
}

export default SpecialWorkTimes
