import React, { Component, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../../Components/LoaderAnimation";
import { useHistory } from "react-router-dom";

function Home(props) {
  const displayName = props.name;
  const { t, i18n } = useTranslation('common');
  const history = useHistory();

  history.push("/orders");

  useEffect(() => {
    document.title = t('nav.home');
  }, []);

  return (
    <div>
      <h1>Hello, Member!</h1>
      <p>Welcome</p>
      <LoaderAnimation show={true} allowClose={true}/>
    </div>
  );
}
export default Home