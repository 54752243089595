import React, { Component, useCallback, useEffect, useState } from 'react';
import CustomPopup from '../Components/CustomPopup';

import { getCurrentLng } from '../i18next_fun';
import { useTranslation } from "react-i18next";

import errorStyles from "./error-message-custom-popup.module.scss";
import PropTypes from "prop-types";
import * as Icon from 'react-bootstrap-icons';

const ErrorMessageCustomPopup = (props) => {
  const { t, i18n } = useTranslation('common');

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  return (
    <CustomPopup className={errorStyles.errorAlertMain} show={show} onClose={(e) => { closeHandler(e); }} title={""} >
      <div className={errorStyles.errorAlertMainWrapper}><img src="/assets/alert_exclamation.svg" alt="" /></div>
      <div className={errorStyles.errorMessageWrapper + " " + (getCurrentLng() === 'en' ? "" : errorStyles.errorAlertMainActionsWrapperRTL)}>
        {title}
      </div>
      {/*<div className={styles.errorAlertMainActionsWrapper}>*/}
      {/*  <button color="link" className={styles.errorAlertMainActionBtn} onClick={(e) => { closeHandler(e); }} >{t('products.alertCancelText')}</button>*/}
      {/*</div>*/}
    </CustomPopup>
  );
};

ErrorMessageCustomPopup.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ErrorMessageCustomPopup;