import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";

import styles from './product-stockfilters.module.scss';

const ProductStockFilters = ({
  onHandleStockFilterChange, ...props
}) => {
  const [currentStockFilter, setCurrentStockFilter] = useState('2');

  const { total } = props;
  const { totalInStock } = props;
  const { totalOutOfStock } = props;

  const { t, i18n } = useTranslation('common');

  useEffect(() => {

  }, []);

  const handleStockFilterChange = (e) => {
    setCurrentStockFilter(e.target.value);
    onHandleStockFilterChange(e.target.value);
  };

  return (
    <div className={styles.searchWrpperFixed + " " + (getCurrentLng() === 'en' ? "" : styles.searchFormRtl)}>
      <ButtonGroup className={styles.btnGroup}>
        <Button
          data-filtervalue={2}
          name="stockFilter"
          value="2"
          className={styles.btn + " " + (currentStockFilter === '2' ? styles.btnActive : '')}
          onClick={(e) => { handleStockFilterChange(e); }}
        >{t('productStockFilter.inStock')} ({totalInStock})</Button>
        <Button
          data-filtervalue={3}
          name="stockFilter"
          value="3"
          className={styles.btn + " " + (currentStockFilter === '3' ? styles.btnActive : '')}
          onClick={(e) => { handleStockFilterChange(e); }}
        >{t('productStockFilter.notInStock')} ({totalOutOfStock})</Button>
        <Button
          data-filtervalue={1}
          name="stockFilter"
          value="1"
          className={styles.btn + " " + (currentStockFilter === '1' ? styles.btnActive : '')}
          onClick={(e) => { handleStockFilterChange(e); }}
        >{t('productStockFilter.All')} ({total})</Button>
      </ButtonGroup>
    </div>
  )
};

export default ProductStockFilters;