import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { userContext } from '../Context/userContext';

const AppRoutes = ({ component: Component, path, isPrivate, exact, onHandleSearchInputChange, onHandleSearchSubmit, currentSearchString, onHandleShopChange, ...rest }) => {

  return (
    <userContext.Consumer>
      {({ user }) => {
        let exactProp = exact ? exact : "";
        //console.log(path);
        //console.log(exact);
        //console.log(currentSearchString);
        
        return (
          <Route
            {...rest}
            exactProp
            path={path}
            render={(props) =>
              isPrivate && Boolean(!user || (user.token === undefined || user.token === null || user.token === '')) ? (
                <Redirect to={{ pathname: '/login' }} />
              ) : (
                  <Component {...props} />
              )
            }
          />
        )
      }}
    </userContext.Consumer>
  );
};

export default AppRoutes;
