import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Label, Button, Row, Col } from 'reactstrap';
import styles from './product-imagelistitem.module.scss';

const ProductImageListItem = (props) => {

  return (       
    <Row className={styles.productImageListItemRow}>      
      {props.image.type.includes('image') ? <img className={styles.thumbsContainer} src={props.image.preview} alt="profile" /> :
        <video className={styles.thumbsContainer} src={props.image.preview} autoPlay="autoplay" loop="loop" controls></video>}      
      <Label>
        {props.image.name}
      </Label>
      <div>
        <Button
          className={styles.numericInputFieldButton}
          onClick={() => {            
            props.onUp(props.index)
          }}><Icon.Plus /></Button>
        <Button
          className={styles.numericInputFieldButton}
          onClick={() => {
            props.onDown(props.index);
          }}><Icon.Dash /></Button>
      </div>
    </Row>       
  )
}

export default ProductImageListItem