import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';

import { getCurrentLng } from '../../i18next_fun';
import useInfiniteScroll from '../../Components/InfiniteScroll';
import { Accordion } from "../../Components/Accordion";

import OrderStatusFilter from './OrderStatusFilter';
import OrderGroup from './OrderGroup';
//import OrderCard from './OrderCard';

import styles from './orders.module.scss';
import * as Icon from 'react-bootstrap-icons';

const OrdersList = (props) => {
  const [user, setUser] = useState({});
  const [orderShop, setOrderShop] = useState(0);
  const [orderSearchString, setOrderSearchString] = useState('');

  const [orderReachedEnd, setOrderReachedEnd] = useState(false);

  const [orderStatusFilter, setOrderStatusFilter] = useState('1');
  const [totalNew, setTotalNew] = useState(0);
  const [totalPreparation, setTotalPreparation] = useState(0);
  const [totalReady, setTotalReady] = useState(0);

  const [orders, setOrders] = useState([]);
  const [ordersByDay, setOrdersByDay] = useState([]);

  const [lastOrderDate, setLastOrderDate] = useState(null);

  const [loading, setLoading] = useInfiniteScroll(populateOrdersData, null, '#infiniteScrollQueue');
  //const [newPrice, setNewPrice] = useState(0);

  const { t, i18n } = useTranslation('common');
  //const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const [showLoader, setShowLoader] = useState(false);

  const [ordersEditError, setOrdersEditError] = useState(false);
  const [ordersEditErrorMessage, setOrdersEditErrorMessage] = useState('');

  useEffect(() => {
    document.title = t('nav.orders');
    setOrders([]);

    //getLastSeatchFiltersState().then((state) => {
    //  setOrderStatusFilter(status, optimizedGetOrders());
    //});

  }, []);

  useEffect(() => {
    if (ordersEditErrorMessage && ordersEditErrorMessage.length > 0) {
      setOrdersEditError(true);
    }
  }, [ordersEditErrorMessage]);

  useEffect(() => {
    if (orders == null || (loading && !orderReachedEnd)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    //setOrdersByDay([]);

    var tempDaysOrdersList = [];
    var today = new moment(new Date(), 'DD/MM/YYYY');

    for (let i = 0; i < orders.length; i++) {

      var ordersDate = moment(((orders[i].ShipTimeFrom !== null && orders[i].ShipTimeFrom.length > 0) ?
        orders[i].ShipTimeFrom :
        orders[i].PickupTimeFrom), 'DD/MM/YYYY');

      if (orderStatusFilter == '1') {
        if (moment(ordersDate).isBefore(today, 'day')) {
          if (tempDaysOrdersList.length > 0) {
            tempDaysOrdersList[0].orders.push(orders[i]);
          }
          else {
            var day = {
              date: ordersDate,
              orders: [orders[i]]
            };

            tempDaysOrdersList.push(day);
          }
        }
        else {
          var dayExists = tempDaysOrdersList.filter(e => moment(e.date).isSame(ordersDate, 'day'));
          if (dayExists.length > 0) {
            //console.log(dayExists);
            //tempDaysOrdersList.orders.push(orders[i]);
            dayExists[0].orders.push(orders[i]);
          }
          else {
            var day = {
              date: ordersDate,
              orders: [orders[i]]
            };

            tempDaysOrdersList.push(day);
          }
        }
      }
      else {
        var dayExists = tempDaysOrdersList.filter(e => moment(e.date).isSame(ordersDate, 'day'));
        if (dayExists.length > 0) {
          //console.log(dayExists);
          //tempDaysOrdersList.orders.push(orders[i]);
          dayExists[0].orders.push(orders[i]);
        }
        else {
          var day = {
            date: ordersDate,
            orders: [orders[i]]
          };

          tempDaysOrdersList.push(day);
        }
      }
    }

    let sorted = [];

    if (orderStatusFilter === '3') {
      sorted = tempDaysOrdersList.sort(function (item1, item2) {
        if (item1.date < item2.date)
          return 1;
        else if (item1.date == item2.date)
          return 0;
        else return -1;
      });
    }
    else {
      sorted = tempDaysOrdersList.sort(function (item1, item2) {
        if (item1.date > item2.date)
          return 1;
        else if (item1.date == item2.date)
          return 0;
        else return -1;
      });
    }

    //setOrdersByDay(tempDaysOrdersList);
    setOrdersByDay(sorted);

    return function cleanUp() {
      setOrdersByDay([]);
    }
  }, [orders]);

  async function getLastSeatchFiltersState() {
    if (typeof window !== 'undefined' &&
      window.localStorage.getItem('selectedOrderFilters') !== null &&
      window.localStorage.getItem('selectedOrderFilters') !== undefined &&
      window.localStorage.getItem('selectedOrderFilters') !== '' &&
      JSON.parse(window.localStorage.getItem('selectedOrderFilters'))) {
      return JSON.parse(window.localStorage.getItem('selectedOrderFilters'))
    }
    else {
      return '2';
    }
  }

  useEffect(() => {
    setOrderReachedEnd(false);
    optimizedGetOrders();
    return function cleanUp() {
      setOrders([]);
    };
  }, [orderShop, orderSearchString]);

  useEffect(() => {
    optimizedGetOrders();
    return function cleanUp() {
      setOrders([]);
    };
  }, [orderStatusFilter]);

  useEffect(() => {
    let checkNewOrdersInterval;

    checkNewOrdersInterval = setInterval(function () {
      //console.log('Interval has: ' + lastOrderDate + ' as last date');
      if (lastOrderDate !== null) {
        populateOrdersData(true);
      }
    }, 30000);
    
    return function clear() {
      clearTimeout(checkNewOrdersInterval);
    }
  }, [lastOrderDate]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
    if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //const { userName, SKU, orderStatus } = searchState;

    //if (!userName || !SKU) return null;
    //setSearchState(prevState => ({
    //    userName: "",
    //    SKU: ""
    //}));

    //if (!userName && !SKU) return null;

    setOrders([]);
    optimizedGetOrders();
  };

  const handleOrderStatusFilterChange = (value, orderId) => {
    setOrderReachedEnd(false);
    setOrderStatusFilter(value);
  };

  const handleFreezeOrder = (orderId) => {
    updateOrderValue("Status", 2, orderId).then((success) => {
      if (success) {
        let thisOrder = orders.filter(p => p.ID == orderId);
        if (thisOrder.length > 0) {
          thisOrder[0].Status = 2;
          setOrders(
            orders.map(el => (el.ID === orderId ? Object.assign({}, el, { Status: 2 }) : el))
            //orders.filter(el => el.ID !== orderId)
          );
        }
      }
    });
  };

  function getTodayTomorrowOrLater(date) {
    const yesterday = moment().add(-1, 'day').endOf('day');
    const today = moment().endOf('day');
    const tomorrow = moment().add(1, 'day').endOf('day');

    //console.log(date);
    //console.log(today);
    //console.log(tomorrow);

    if (date < yesterday) return 4;
    if (date < today) return 1;
    if (date < tomorrow) return 2;
    return 3
  }

  function getDateTitle(orderDate) {
    const date = moment(orderDate, "DD/MM/YYYY hh:mm");
    let todayOrTomorrow = getTodayTomorrowOrLater(date);
    let dayOfWeek = (date.isoWeekday() + 1);
    if (dayOfWeek === 8) dayOfWeek = 1;

    return (
      <span>
        {((todayOrTomorrow === 4) ? t('orders.relativeToToday' + todayOrTomorrow) + " " : "")}
        {((todayOrTomorrow < 3) ? t('orders.relativeToToday' + todayOrTomorrow) + " " : "")}
        {((todayOrTomorrow !== 3) ? (getCurrentLng() === 'en' ? <Icon.ChevronRight /> : <Icon.ChevronLeft />) : "")}

        {((orderStatusFilter === '1') ?
          ((todayOrTomorrow === 4) ?
            t('orders.monthName' + (date.month() + 1)) :
            t('orders.isoDay' + dayOfWeek) + ", " + (getCurrentLng() === 'en' ? (date.date() + " " + t('orders.monthName' + (date.month() + 1))) : (date.date() + " " + (getCurrentLng() === 'he' ? 'ל' : '') + t('orders.monthName' + (date.month() + 1))))
          )
          :
          t('orders.isoDay' + dayOfWeek) + ", " + (getCurrentLng() === 'en' ? (date.date() + " " + t('orders.monthName' + (date.month() + 1))) : (date.date() + " " + (getCurrentLng() === 'he' ? 'ל' : '') + t('orders.monthName' + (date.month() + 1))))
          
        )}

        {/*(t('orders.monthName' + date.month()) + " " + date.date())*/}
      </span>
    );
  }

  async function populateOrdersData(checkNew: Boolean = false) {
    //let currentCount = 0;
    //if (orders.length > 0) {
    //  currentCount = orders.reduce(function (acc, curr) {
    //    // acc mean the accumulator, 0 was passed as the first value
    //    acc += curr.orders.length;
    //    return acc;
    //  }, 0) // 0 is the initial value
    //}

    if (!orderReachedEnd || checkNew === true) {
      setLoading(true);

      let startIndexValue = orders.length;

      let lastOrderDateValue = null
      if (checkNew === true && lastOrderDate !== null) {
        //console.log("lastOrderDate: " + lastOrderDate);
        startIndexValue = 0;
        lastOrderDateValue = moment(lastOrderDate, "YYYY-MM-DD hh:mm");
      }

      try {
        const requestOptions = {
          //credentials: 'same-origin',
          //credentials: 'include',
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ startIndex: startIndexValue, pageSize: 20, qString: orderSearchString, qShopID: orderShop, qOrderStatus: orderStatusFilter, lastOrderDate: lastOrderDateValue })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

          const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/ListOrders`, requestOptions);

          const data = await response.json();

          if (data.success === true) {

            if (checkNew !== true) {
              setTotalNew(data.totalNew);
              setTotalPreparation(data.totalPreparation);
              setTotalReady(data.totalReady);
            }

            if (data.data.length === 0) {
              setOrderReachedEnd(true);
              setLoading(false);
            }

            if (data.data.length > 0) {
              if (data.data[0].ShipTimeFrom !== null) {
                setLastOrderDate(moment(data.data[0].ShipTimeFrom, "DD/MM/YYYY hh:mm"));
              }
              else {
                setLastOrderDate(moment(data.data[0].PickupTimeFrom, "DD/MM/YYYY hh:mm"));
              }

              const retreivedOrders = data.data.filter(ord => orders.every(s => s.ID !== ord.ID));
              const fullOrdersArr = [...orders, ...retreivedOrders];
              setOrders(fullOrdersArr);
            }
            else if (data.data.length == 0) { // && !checkNew
              //setLastOrderDate(moment(moment(new Date()).add(-1, 'days'), 'DD/MM/YYYY hh:mm'));
              //setLastOrderDate(moment(moment(new Date()).add(-1, 'h'), 'DD/MM/YYYY hh:mm'));
              setLastOrderDate(moment(new Date(), 'DD/MM/YYYY hh:mm'));
            }

          }
          //setProducts(prevState => ([...prevState, ...data.data]));
        }
        else {
          setOrders([]);
        }

        setLoading(false);
      }
      catch {
        setLoading(false);
      }
    }
  }

  async function updateOrderValue(name, value, id) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, orderId: id })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      return fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/SetOrderValue`, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          if (json.success === true) {
            setShowLoader(false);
            return true;
          }
          else {
            setShowLoader(false);
            setOrdersEditErrorMessage(t('orders.error_updating_order'));
            return false;
          }
        });
    }
    catch {
      setShowLoader(false);
      setOrdersEditErrorMessage(t('errors.connection_error'));
      return false;
    }

    return false;
  }

  const optimizedGetOrders = useCallback(debounce(populateOrdersData, 500), []);
  const optimizedFreezeOrders = useCallback(debounce(handleFreezeOrder, 500), [orders]);


  const renderOrdersTable = () => {
    return (
      (ordersByDay.length > 0) ?
        <Accordion open={0}>
          {ordersByDay.map(day => {

            //console.log(day.orders);

            return (
              <Accordion.Item key={String(day.date).replace("/", "_")}>
                <Accordion.Header
                  className={styles.cardHeaderContainer + " " + (getCurrentLng() === 'en' ? "" : styles.orderRtl)}
                  childClass={"mb-0" + " " + styles.headerBlock + " " + (getCurrentLng() === 'en' ? "" : styles.headerBlockRtl)}
                  childCollapseClass={styles.collapse}>
                  <a>{getDateTitle(day.date)}</a>
                  {/*<Icon.ChevronDown className={styles.openHeaderBtn} />*/}
                  <img src="/assets/orders/chevron_down.svg" className={styles.openHeaderBtn} />

                  {/*<Icon.ChevronUp className={styles.closeHeaderBtn} />*/}
                  <img src="/assets/orders/chevron_up.svg" className={styles.closeHeaderBtn} />
                </Accordion.Header>
                <Accordion.Body className={styles.cardBodyContainer}>
                  <OrderGroup onHandleFreezeOrder={(e) => optimizedFreezeOrders(e)} orders={day.orders} />
                </Accordion.Body>
              </Accordion.Item>
            )
          }
          )}
        </Accordion> : ((orderStatusFilter === '1') ? <div className={styles.horrayMessage}>{t('orders.horrayClearedOrdersMessage')}</div> : null));
  }

  let contents = loading && !orderReachedEnd
    ? <p><em>Loading...</em></p>
    : '';

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setOrderShop(currentShop);
              setOrderSearchString(currentSearchString);

              return (
                <div id="infiniteList">
                  <OrderStatusFilter
                    onHandleOrderStatusFilterChange={(e) => {
                      handleOrderStatusFilterChange(e);
                    }}
                    totalNew={totalNew}
                    totalPreparation={totalPreparation}
                    totalReady={totalReady}
                  />
                  {/*<h1 id="tabelLabel" className={(getCurrentLng() === 'en' ? "" : styles.orderRtl)}>{t('orders.title')} ({getCurrentLng()})</h1>*/}
                  <div className="App m-4" className={styles.order + " " + (getCurrentLng() === 'en' ? "" : styles.orderRtl)}>
                    {renderOrdersTable()}
                  </div>
                  {contents}

                  <ErrorMessageCustomPopup show={ordersEditError} title={ordersEditErrorMessage} onClose={(e) => { setOrdersEditError(false); }} />

                  <LoaderAnimation show={showLoader} allowClose={false} />

                  <div className={styles.infiniteScrollQueue} id="infiniteScrollQueue"></div>
                </div>
              )
            }}

          </layoutContext.Consumer>
        )
      }}

    </userContext.Consumer>
  );
}

export default OrdersList