import React, { Component, useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import ProductSetSearch from './ProductSetSearch';
import ProductInShopSearch from '../Products/ProductInShopSearch';
import BrandSearch from '../Products/BrandSearch';

import NumericInput from 'react-numeric-input';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './specialoffer-condition.module.scss';
import * as Icon from 'react-bootstrap-icons';

const SpecialOfferCondition = (props) => {
  const { currentConditionId } = props;

  const { handleConditionRemove } = props;
  const { handleConditionChange } = props;

  const { specialOfferCondition } = props;
  const { specialOfferProductSets } = props;

  const [user, setUser] = useState({});
  const { t, i18n } = useTranslation('common');

  const [currentConditionIdInSpecialOffer, setCurrentConditionIdInSpecialOffer] = useState(0);

  const [currentConditionPropertiesForEdit, setCurrentConditionPropertiesForEdit] = useState({});

  //const [currentConditionType, setCurrentConditionType] = useState(-1);
  const [currentConditionTypeName, setCurrentConditionTypeName] = useState('');

  const [currentConditionProductSets, setCurrentConditionProductSets] = useState([]);
  const [currentConditionProductSetsOpen, setCurrentConditionProductSetsOpen] = useState(false);

  const [specialOfferConditionProductSetId, setSpecialOfferConditionProductSetId] = useState(0);
  const [specialOfferConditionProductSetName, setSpecialOfferConditionProductSetName] = useState('');
  const [specialOfferConditionProductSetDescription, setSpecialOfferConditionProductSetDescription] = useState('');
  //const [specialOfferConditionProductSetSuggestion, setSpecialOfferConditionProductSetSuggestion] = useState([]);

  const [specialOfferConditionAmount, setSpecialOfferConditionAmount] = useState(0);

  const [specialOfferConditionDayOfWeek, setSpecialOfferConditionDayOfWeek] = useState(0);

  const [specialOfferConditionHoursFrom, setSpecialOfferConditionHoursFrom] = useState(-1);
  //const [specialOfferConditionMinutesFrom, setSpecialOfferConditionMinutesFrom] = useState(0);
  const [specialOfferConditionHoursTo, setSpecialOfferConditionHoursTo] = useState(-1);
  //const [specialOfferConditionMinutesTo, setSpecialOfferConditionMinutesTo] = useState(0);

  const [specialOfferConditionBonusCode, setSpecialOfferConditionBonusCode] = useState('');

  const [specialOfferConditionPrice, setSpecialOfferConditionPrice] = useState(0);

  const [currentConditionCardOpen, setCurrentConditionCardOpen] = useState(false);

  const [currentConditionCardShowProductSet, setCurrentConditionCardShowProductSet] = useState(false);
  const [currentConditionCardShowAmount, setCurrentConditionCardShowAmount] = useState(false);
  const [currentConditionCardShowBundle, setCurrentConditionCardShowBundle] = useState(false);
  const [currentConditionCardShowDayOfWeek, setCurrentConditionCardShowDayOfWeek] = useState(false);
  const [currentConditionCardShowHours, setCurrentConditionCardShowHours] = useState(false);
  const [currentConditionCardShowBonusCode, setCurrentConditionCardShowBonusCode] = useState(false);
  const [currentConditionCardShowPrice, setCurrentConditionCardShowPrice] = useState(false);

  const arrayOfDaysAWeek = [1, 2, 3, 4, 5, 6, 7];

  const arrayOfTimesForHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  const [currentConditionDayOfWeekOpen, setCurrentConditionDayOfWeekOpen] = useState(false);

  const [currentConditionHoursFromOpen, setCurrentConditionHoursFromOpen] = useState(false);
  const [currentConditionHoursToOpen, setCurrentConditionHoursToOpen] = useState(false);

  const [allConditionTypes, setAllConditionTypes] = useState([]);
  const [allConditionTypesDropDownOpen, setAllConditionTypesDropDownOpen] = useState(false);

  useEffect(() => {
    //document.title = t('nav.specialOfferEdit');
    getSpecialOfferConditionTypes();

    return function clear() {
      setAllConditionTypes([]);
    }
  }, [user]);

  useEffect(() => {
    if (currentConditionId && currentConditionId !== 0) {
      setCurrentConditionIdInSpecialOffer(currentConditionId);

      setCurrentConditionPropertiesForEdit(specialOfferCondition);
      //console.log(currentConditionId);
    }

    return function clear() {
      setCurrentConditionIdInSpecialOffer(0);
      setCurrentConditionPropertiesForEdit({});
    }
  }, [currentConditionId]);

  useEffect(() => {
    if (specialOfferCondition === undefined ||
      (specialOfferCondition
      && Object.keys(specialOfferCondition).length === 0
      && Object.getPrototypeOf(specialOfferCondition) === Object.prototype)) {
      //return;
      //do nothing;
    }
    else {
      //console.log(specialOfferCondition);
      //let currentType = allConditionTypes.indexOf(specialOfferCondition.type + "ViewModel");
      //setCurrentConditionType(currentType);
      setCurrentConditionTypeName(specialOfferCondition.type);
      setSpecialOfferConditionProductSetId(specialOfferCondition.productSetId);
      setSpecialOfferConditionProductSetName(specialOfferCondition.productSetName);
      setSpecialOfferConditionProductSetDescription(specialOfferCondition.productSetDescription);

      //let currentSet = {
      //  id: specialOfferCondition.productSetId,
      //  name: specialOfferCondition.productSetName,
      //  text: specialOfferCondition.productSetDescription
      //}

      //let currentConditionProductSetSuggestion = [];
      //currentConditionProductSetSuggestion.push(currentSet);
      //setSpecialOfferConditionProductSetSuggestion(currentConditionProductSetSuggestion);

      setSpecialOfferConditionAmount(specialOfferCondition.amount);
      setSpecialOfferConditionDayOfWeek(specialOfferCondition.dayOfWeek);
      setSpecialOfferConditionBonusCode(specialOfferCondition.bonusCode);
      setSpecialOfferConditionHoursFrom(specialOfferCondition.amouhoursFromnt);
      setSpecialOfferConditionHoursTo(specialOfferCondition.hoursTo);
      setSpecialOfferConditionPrice(specialOfferCondition.price);
    }

    return function clear() {
      //setCurrentConditionType(-1);
      setCurrentConditionTypeName('');
    }
  }, [currentConditionPropertiesForEdit, allConditionTypes]);

  useEffect(() => {
    if (specialOfferProductSets === undefined ||
      (specialOfferProductSets && specialOfferProductSets.length === 0)) {
      //return;
      //do nothing;
    }
    else {
      //console.log(specialOfferProductSets);
      setCurrentConditionProductSets(specialOfferProductSets);

      if (specialOfferConditionProductSetId != 0) {
        let currentSet = specialOfferProductSets.find(e => e.productSetId == specialOfferConditionProductSetId);
        //console.log(currentSet);
        if (currentSet) {
          setSpecialOfferConditionProductSetName(currentSet.productSetName);
        }
      }
    }
      
  }, [specialOfferProductSets]);

  useEffect(() => {
    //if (currentConditionType !== null && currentConditionType !== undefined && currentConditionType !== -1) {
    //  switch (currentConditionTypeName) {
    if (currentConditionTypeName !== null && currentConditionTypeName !== undefined && currentConditionTypeName !== '') {
      switch (currentConditionTypeName) {
        case 'ConditionBuyAnyProductFromProductSet':
        default:
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(true);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            setSpecialOfferConditionAmount(0);
            //setSpecialOfferConditionProductSetId(0);
            //setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionBuyAtLeastXFromProductSet':
          {
            setCurrentConditionCardShowAmount(true);
            setCurrentConditionCardShowProductSet(true);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            //setSpecialOfferConditionAmount(0);
            //setSpecialOfferConditionProductSetId(0);
            //setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionBuyExactAmountFromProductSet':
          {
            setCurrentConditionCardShowAmount(true);
            setCurrentConditionCardShowProductSet(true);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            //setSpecialOfferConditionAmount(0);
            //setSpecialOfferConditionProductSetId(0);
            //setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        //case 'ConditionBuyProductBundle':
        //  {
        //    setCurrentConditionCardShowAmount(false);
        //    setCurrentConditionCardShowProductSet(false);
        //    setCurrentConditionCardShowBundle(true);
        //    setCurrentConditionCardShowDayOfWeek(false);
        //    setCurrentConditionCardShowHours(false);
        //    setCurrentConditionCardShowBonusCode(false);
        //    setCurrentConditionCardShowPrice(false);

        //    setSpecialOfferConditionAmount(0);
        //    setSpecialOfferConditionProductSetId(0);
        //    setSpecialOfferConditionProductSetName('');
        //    setSpecialOfferConditionDayOfWeek(0);
        //    setSpecialOfferConditionHoursFrom(-1);
        //    setSpecialOfferConditionHoursTo(-1);
        //    setSpecialOfferConditionBonusCode('');
        //    setSpecialOfferConditionPrice(0);
        //  }
        //  break;
        case 'ConditionMakePurchaseAtSpecifiedDayOfWeek':
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(false);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(true);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            setSpecialOfferConditionAmount(0);
            setSpecialOfferConditionProductSetId(0);
            setSpecialOfferConditionProductSetName('');
            //setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionMakePurchaseAtSpecifiedHours':
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(false);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(true);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            setSpecialOfferConditionAmount(0);
            setSpecialOfferConditionProductSetId(0);
            setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            //setSpecialOfferConditionHoursFrom(-1);
            //setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionMakePurchaseWithSpecifiedBonusCode':
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(false);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(true);
            setCurrentConditionCardShowPrice(false);

            setSpecialOfferConditionAmount(0);
            setSpecialOfferConditionProductSetId(0);
            setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            //setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionPriceInCartAtLeastX':
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(false);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(true);

            setSpecialOfferConditionAmount(0);
            setSpecialOfferConditionProductSetId(0);
            setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            //setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionPriceInCartAtLeastXForProductSet':
          {
            setCurrentConditionCardShowAmount(false);
            setCurrentConditionCardShowProductSet(true);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(true);

            setSpecialOfferConditionAmount(0);
            //setSpecialOfferConditionProductSetId(0);
            //setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            //setSpecialOfferConditionPrice(0);
          }
          break;
        case 'ConditionTheFirstXOrdersOfCustomer':
          {
            setCurrentConditionCardShowAmount(true);
            setCurrentConditionCardShowProductSet(false);
            setCurrentConditionCardShowBundle(false);
            setCurrentConditionCardShowDayOfWeek(false);
            setCurrentConditionCardShowHours(false);
            setCurrentConditionCardShowBonusCode(false);
            setCurrentConditionCardShowPrice(false);

            //setSpecialOfferConditionAmount(0);
            setSpecialOfferConditionProductSetId(0);
            setSpecialOfferConditionProductSetName('');
            setSpecialOfferConditionDayOfWeek(0);
            setSpecialOfferConditionHoursFrom(-1);
            setSpecialOfferConditionHoursTo(-1);
            setSpecialOfferConditionBonusCode('');
            setSpecialOfferConditionPrice(0);
          }
          break;
      }
    }
  }, [currentConditionTypeName]);

  useEffect(() => {
    handleCurrentConditionChange();
  }, [currentConditionIdInSpecialOffer,
    specialOfferConditionProductSetId,
    currentConditionTypeName,
    specialOfferConditionAmount,
    specialOfferConditionDayOfWeek,
    specialOfferConditionHoursFrom,
    specialOfferConditionHoursTo,
    specialOfferConditionBonusCode,
    specialOfferConditionPrice
  ]);

  //const handleProductSetSuggestionSelect = (event, name, id, text) => {
  //  //debugger;
  //  if (id && id !== 0) {
  //    //setSpecialOfferProductSetId(id);
  //    setSpecialOfferConditionProductSetId(id);
  //    setSpecialOfferConditionProductSetName(name);
  //    setSpecialOfferConditionProductSetDescription(text);
  //  }

  //  //if (id === 0) {
  //  //  setProductEnableOverrideGlobal(false);
  //  //}
  //};

  const toggleConditionsOpen = (e) => { setAllConditionTypesDropDownOpen(!allConditionTypesDropDownOpen); };

  const toggleCurrentConditionProductSets = (e) => { setCurrentConditionProductSetsOpen(!currentConditionProductSetsOpen); };
  
  const toggleSpecialOfferConditionCard = (e) => { setCurrentConditionCardOpen(!currentConditionCardOpen); };

  const toggleCurrentConditionDayOfWeek = (e) => { setCurrentConditionDayOfWeekOpen(!currentConditionDayOfWeekOpen); };

  const toggleCurrentConditionHoursFrom = (e) => { setCurrentConditionHoursFromOpen(!currentConditionHoursFromOpen); };
  const toggleCurrentConditionHoursTo = (e) => { setCurrentConditionHoursToOpen(!currentConditionHoursToOpen); };

  const handleCurrentConditionChange = () => {

    let thisCondition = {
      conditionId: currentConditionIdInSpecialOffer,
      productSetId: specialOfferConditionProductSetId,
      productSetName: specialOfferConditionProductSetName,
      productSetDescription: specialOfferConditionProductSetDescription,
      type: currentConditionTypeName,
      amount: specialOfferConditionAmount,
      dayOfWeek: specialOfferConditionDayOfWeek,
      hoursFrom: specialOfferConditionHoursFrom,
      hoursTo: specialOfferConditionHoursTo,
      bonusCode: specialOfferConditionBonusCode,
      price: specialOfferConditionPrice
    };

    handleConditionChange(thisCondition);
  };

  const handleFocus = (event) => {
    if (event && event.target && event.target.value)
      //event.target.select();
      event.target.value = '';
  };

  function priceFormat(num) {
    //var nis = '\U020AA';
    //const nis = '&#8362;';
    //const nis = '\20AA';
    return String(num) + " ₪"; // + " " + { nis };
  }

  async function getSpecialOfferConditionTypes() {
    try {
      //setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/ConditionTypesList`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        //debugger;
        if (data && data.data && data.data.length > 0) {
          setAllConditionTypes(data.data);
        }
      }
      else {
        //setSpecialOffer({});
      }

      //setLoading(false);
    }
    catch {
      //debugger;
      //setSpecialOffer({});
      //setLoading(false);
      //setShowLoader(false);
    }
  }

  const renderAllConditionDropDown = () => {
    return (
      <DropdownMenu>
        {allConditionTypes.map((item, index) => {
          if (item !== 'ConditionEmptyViewModel') {
            return (
              <DropdownItem value={index} key={"conditionType" + index} className={((currentConditionTypeName == String(item).split('ViewModel')[0]) ? "active" : "") + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferConditionsRtl)}
                onClick={(e) => {
                  //setCurrentConditionType(index);
                  let itemWithoutViewModel = item.split('ViewModel')[0]
                  setCurrentConditionTypeName(itemWithoutViewModel);
                }}>
                {t('specialOffersEditCondition.' + String(item).split('ViewModel')[0])}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    )
  }

  const renderCurrentConditionProductSetsDropDown = () => {
    //console.log(currentConditionProductSets);
    return (
      <DropdownMenu>
        {currentConditionProductSets.map((item, index) => {
          if (item !== 'ConditionEmptyViewModel') {
            return (
              <DropdownItem value={index} key={"conditionTProductSet" + index} className={((specialOfferConditionProductSetId == item.productSetId) ? "active" : "") + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferConditionsRtl)}
                onClick={(e) => {
                  setSpecialOfferConditionProductSetId(item.productSetId);
                  setSpecialOfferConditionProductSetName(item.productSetName);
                }}>
                {item.productSetName}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    )
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (

          <Card className={styles.specialOfferCard + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL)}>
            <CardHeader className={styles.cardHeader}>
              <Row className={styles.cardRow}>
                <Col lg="2" xs="2" className={styles.cardRemoveIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardRemoveIconRTL)}>
                  <Button
                    onClick={(e) => {
                      if (e) {
                        handleConditionRemove(currentConditionIdInSpecialOffer);
                      }
                    }}
                    color="link"
                    className={styles.productTrashBtn}
                    name="Edit"
                    value={currentConditionId}>
                    <Icon.Trash />
                  </Button>
                </Col>
                <Col lg="8" xs="8" className={styles.cardColumn + " " + styles.cardInfoContainer} onClick={(e) => { toggleSpecialOfferConditionCard(e) }} >
                  <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
                    {(currentConditionTypeName !== undefined && currentConditionTypeName !== '') ? t('specialOffersEditCondition.' + currentConditionTypeName) : t('specialOffersEditCondition.newCondition')}
                  </CardTitle>
                </Col>
                <Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)} onClick={(e) => { toggleSpecialOfferConditionCard(e) }} >
                  {(currentConditionCardOpen) ?
                    <Icon.ChevronUp />
                    :
                    <Icon.ChevronDown />}
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={currentConditionCardOpen}>
              <CardBody className={styles.cardEditSectionWrapper}>
                <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL)}>
                  <Row className={styles.cardEditRow}>
                    <Col lg="12" xs="12" className={styles.cardEditCol}>

                      <Dropdown
                        isOpen={allConditionTypesDropDownOpen}
                        toggle={toggleConditionsOpen}
                        name={"productEditCategories" + currentConditionId}
                        placeholder={t('productEdit.categories')}
                        className={styles.conditionEditTypes}
                      >
                        <DropdownToggle caret
                          onClick={toggleConditionsOpen}
                          data-toggle={"allConditionTypesDropDownOpen"} aria-expanded={allConditionTypesDropDownOpen}
                          className={styles.specialOfferConditionsDropDown + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferConditionsRtl)}
                        >
                          {(currentConditionTypeName !== undefined && currentConditionTypeName !== '') ? t('specialOffersEditCondition.' + currentConditionTypeName) : t('specialOffersEditCondition.ChooseCondition')}
                        </DropdownToggle>

                        {allConditionTypes && allConditionTypes.length > 0 && renderAllConditionDropDown()}
                      </Dropdown>
                    </Col>
                  </Row>
                  {currentConditionCardShowAmount ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editAmountLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<input name={"specialOfferConditionAmount" + + currentConditionId} value={specialOfferConditionAmount} onChange={(e) => {*/}
                        {/*  setSpecialOfferConditionAmount(e.target.value);*/}
                        {/*}} />*/}

                        <NumericInputWithButtons
                          name={"specialOfferConditionAmount" + + currentConditionId}
                          id={"specialOfferConditionAmount" + + currentConditionId}
                          title={t('specialOffersEditCondition.editAmountLabel')}
                          data-rewardid={currentConditionId}
                          placeholder={t('specialOffersEditCondition.editAmountLabel')}
                          onChange={(value) => {
                            //if (Number(value) !== 0) {
                            //console.log(value);
                            setSpecialOfferConditionAmount(Number(value));
                            //}
                          }}
                          onSubmit={(e) => { }}
                          onFocus={handleFocus}
                          value={specialOfferConditionAmount}
                          pattern='[0-9]{0,5}'
                          itemId={currentConditionId}
                        />
                      </Col>
                    </Row>
                    : null}
                  {currentConditionCardShowProductSet ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editProductSetLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<ProductSetSearch defaultSuggestions={specialOfferConditionProductSetSuggestion} onHandleSelect={handleProductSetSuggestionSelect} controlId={"specialOfferConditionProductSet" + + currentConditionId} />*/}
                        <Dropdown
                          isOpen={currentConditionProductSetsOpen}
                          toggle={toggleCurrentConditionProductSets}
                          name={"currentConditionProductSets" + currentConditionId}
                          placeholder={t('productEdit.categories')}
                          className={styles.conditionEditTypes}
                        >
                          <DropdownToggle caret
                            onClick={toggleCurrentConditionProductSets}
                            data-toggle={"toggleCurrentConditionProductSets"} aria-expanded={currentConditionProductSetsOpen}
                            className={styles.specialOfferConditionsDropDown + ' ' + (getCurrentLng() == "en" ? null : styles.specialOfferConditionsRtl)}
                          >
                            {(specialOfferConditionProductSetId !== undefined && specialOfferConditionProductSetId !== 0) ?
                              specialOfferConditionProductSetName : t('specialOffersEdit.chooseProductSet')}
                          </DropdownToggle>

                          {renderCurrentConditionProductSetsDropDown()}
                        </Dropdown>
                      </Col>
                    </Row>
                    : null}
                  {currentConditionCardShowBundle ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editBundleLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>

                      </Col>
                    </Row>
                    : null}
                  {currentConditionCardShowDayOfWeek ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editDayOfWeekLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        <Dropdown
                          isOpen={currentConditionDayOfWeekOpen}
                          toggle={toggleCurrentConditionDayOfWeek}
                          name="currentConditionDayOfWeekOpen"
                          placeholder={t('specialOffersEditCondition.selectDayOfWeek')}
                        >
                          <DropdownToggle caret
                            onClick={toggleCurrentConditionDayOfWeek}
                            data-toggle={"toggleCurrentConditionDayOfWeek"}
                            aria-expanded={currentConditionDayOfWeekOpen}
                            className={styles.editDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                          >
                            {(specialOfferConditionDayOfWeek === 0) ?
                              t('specialOffersEditCondition.selectDayOfWeek') :
                              t('orders.isoDay' + specialOfferConditionDayOfWeek) }
                          </DropdownToggle>

                          <DropdownMenu className={styles.dropdownWindow}>
                            {arrayOfDaysAWeek.map((x, i) =>
                              <DropdownItem value={x} key={"specialOfferConditionDayOfWeek" + String(x)} className={((specialOfferConditionDayOfWeek == x) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                onClick={(e) => {
                                  setSpecialOfferConditionDayOfWeek(x);
                                }}>
                                {t('orders.isoDay' + x)}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                    : null}
                  {currentConditionCardShowHours ?
                    <React.Fragment>
                      <Row className={styles.cardEditRow}>
                        <Col lg="6" xs="6" className={styles.cardEditCol}>
                          {t('specialOffersEditCondition.editHourFromLabel')}
                        </Col>
                        <Col lg="6" xs="6" className={styles.cardEditCol}>
                          <Dropdown
                            isOpen={currentConditionHoursFromOpen}
                            toggle={toggleCurrentConditionHoursFrom}
                            name="currentConditionHoursFromOpen"
                            placeholder={t('specialOffersEditCondition.selectTimeSpan')}
                          >
                            <DropdownToggle caret
                              onClick={toggleCurrentConditionHoursFrom}
                              data-toggle={"toggleCurrentConditionHoursFrom"}
                              aria-expanded={currentConditionHoursFromOpen}
                              className={styles.editDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                            >
                              {(specialOfferConditionHoursFrom === -1) ?
                                t('specialOffersEditCondition.selectTimeSpan') :
                                String(specialOfferConditionHoursFrom) + ":00"}
                            </DropdownToggle>
                            <DropdownMenu className={styles.dropdownWindow}>
                              {arrayOfTimesForHours.map((x, i) =>
                                <DropdownItem value={x} key={"specialOfferConditionHoursFrom" + String(x)} className={((specialOfferConditionHoursFrom == x) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                  onClick={(e) => {
                                    setSpecialOfferConditionHoursFrom(x);
                                  }}>
                                  {String(x) + ":00"}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row className={styles.cardEditRow}>
                        <Col lg="6" xs="6" className={styles.cardEditCol}>
                          {t('specialOffersEditCondition.editHourToLabel')}
                        </Col>
                        <Col lg="6" xs="6" className={styles.cardEditCol}>
                          <Dropdown
                            isOpen={currentConditionHoursToOpen}
                            toggle={toggleCurrentConditionHoursTo}
                            name="currentConditionHoursToOpen"
                            placeholder={t('specialOffersEditCondition.selectTimeSpan')}
                          >
                            <DropdownToggle caret
                              onClick={toggleCurrentConditionHoursTo}
                              data-toggle={"toggleCurrentConditionHoursTo"}
                              aria-expanded={currentConditionHoursToOpen}
                              className={styles.editDropDown + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtlOnlyAlign))}
                            >
                              {(specialOfferConditionHoursTo === -1) ?
                                t('specialOffersEditCondition.selectTimeSpan') :
                                String(specialOfferConditionHoursTo) + ":00"}
                            </DropdownToggle>
                            <DropdownMenu className={styles.dropdownWindow}>
                              {arrayOfTimesForHours.map((x, i) =>
                                <DropdownItem value={x} key={"specialOfferConditionHoursTo" + String(x)} className={((specialOfferConditionHoursTo == x) ? "active" : "") + " " + ((getCurrentLng() === 'en' ? "" : styles.preferencesRtl))}
                                  onClick={(e) => {
                                    setSpecialOfferConditionHoursTo(x);
                                  }}>
                                  {String(x) + ":00"}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </React.Fragment>
                    : null}
                  {currentConditionCardShowBonusCode ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editBonusCodeLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        <input
                          name={"specialOfferConditionBonusCode" + + currentConditionId}
                          value={specialOfferConditionBonusCode}
                          onChange={(e) => {
                            setSpecialOfferConditionBonusCode(e.target.value);
                          }} />
                      </Col>
                    </Row>
                    : null}
                  {currentConditionCardShowPrice ?
                    <Row className={styles.cardEditRow}>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {t('specialOffersEditCondition.editPriceLabel')}
                      </Col>
                      <Col lg="6" xs="6" className={styles.cardEditCol}>
                        {/*<input*/}
                        {/*  name={"specialOfferConditionPrice" + + currentConditionId}*/}
                        {/*  value={specialOfferConditionPrice}*/}
                        {/*  onChange={(e) => {*/}
                        {/*    setSpecialOfferConditionPrice(e.target.value);*/}
                        {/*  }} />*/}

                        <span className={styles.productEditPriceInputField}>
                          <NumericInput type="number"
                            title={t('specialOffersEditCondition.editPriceLabel')}
                            name={"specialOfferConditionPrice" + + currentConditionId}
                            data-rewardid={currentConditionId}
                            id={"specialOfferConditionPrice" + + currentConditionId}
                            className={styles.productEditFormPriceField}
                            minimum="0.00"
                            step={1}
                            //precision={2}
                            value={specialOfferConditionPrice}
                            format={priceFormat}
                            mobile={"auto"}
                            //onFocus={handlePriceFieldFocus}
                            onChange={(e) => {
                              //if (Number(e) !== 0) {
                              setSpecialOfferConditionPrice(Number(e).toFixed(2));
                              //}
                            }}
                            onFocus={handleFocus}
                          />
                        </span>
                      </Col>
                    </Row>
                    : null}
                </div>
              </CardBody>
            </Collapse>
          </Card>
  
        )
      }}
    </userContext.Consumer >
  );
}

export default SpecialOfferCondition