import React from "react";
import styles from './Camera.module.scss';
import { Button } from 'reactstrap';
import { contains } from "jquery";
//import b64toBlob from '../b642file';

class Camera extends React.Component {
  constructor(props) {
    super(props);

    this.cameraNumber = 1;

    this.state = {
      activated: false,
      imageDataURL: null,
      camOn: false
    };

    this.handleAddPictureFromCanvas = props.addPictureFromCanvas.bind(this);
    this.handleTakingPicturesModeSet = props.takingPicturesModeSet.bind(this);
  }

  handleAddPictureFromCanvas(canvasFile) {

  }

  handleTakingPicturesModeSet() {

  }

  initializeMedia = async () => {

    //const constraints = {
    //  width: { min: 640, ideal: 1920, max: 1920 },
    //  height: { min: 400, ideal: 1080 },
    //  aspectRatio: 1.777777778,
    //  frameRate: { max: 30 },
    //  facingMode: { exact: "user" }
    //};

    //let constraints = {
    //  //audio: true,
    //  video: {
    //    facingMode: { exact: "environment" },
    //    width: { ideal: 1920 },
    //    height: { ideal: 1080 }
    //  }
    //};

    this.setState({ imageDataURL: null });

    if (!("mediaDevices" in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia Not Implemented"));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    const supports = navigator.mediaDevices.getSupportedConstraints();

    //if (
    //  !supports["width"] ||
    //  !supports["height"] ||
    //  !supports["frameRate"] ||
    //  !supports["facingMode"] //||
    //  //!supports["deviceId"]
    //) {
    //  console.log('can\'t handle camera constraints');
    //  constraints = {};

    //} else {

    //}

    //Get the details of video inputs of the device
    const videoInputs = await this.getListOfVideoInputs();

    if (videoInputs.length == 1) {
      this.cameraNumber = 0;
    }

    //if (camId !== null && camId !== undefined) {
    //  constraints = {
    //    video: {
    //      deviceId: {
    //        exact: videoInputs[this.cameraNumber].deviceId,
    //      },
    //    }
    //  }
    //}

    //The device has a camera
    if (videoInputs.length) {
      //navigator.mediaDevices
      //  .getUserMedia(
      //    constraints
      //  )
      //  .then((stream) => {
      //    this.handleTakingPicturesModeSet();
      //    this.setState({ camOn: true });

      //    if (!this.state.activated) {
      //      this.setState({ activated: true });
      //    }
      //    this.player.srcObject = stream;
      //  })
      //  .catch((error) => {
          navigator.mediaDevices
            .getUserMedia(
              {
                video: {
                  deviceId: {
                    exact: videoInputs[this.cameraNumber].deviceId,
                  },
                },
              }
            )
            .then((stream) => {
              this.handleTakingPicturesModeSet();
              this.setState({ camOn: true });

              if (!this.state.activated) {
                this.setState({ activated: true });
              }
              this.player.srcObject = stream;
            })
            .catch((error) => {
              navigator.mediaDevices
                .getUserMedia(
                  {}
                )
                .then((stream) => {
                  this.handleTakingPicturesModeSet();
                  this.setState({ camOn: true });

                  if (!this.state.activated) {
                    this.setState({ activated: true });
                  }
                  this.player.srcObject = stream;
                })
                .catch((error) => {
                  console.error(error);
                });
            });
        //});
    } else {
      alert("The device does not have a camera");
    }
  };

  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  capturePicture = () => {
    var canvas = document.createElement("canvas");
    canvas.width = this.player.videoWidth;
    canvas.height = this.player.videoHeight;
    var contex = canvas.getContext("2d");
    contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
    this.player.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
    });

    //console.log(canvas.toDataURL());
    this.setState({ imageDataURL: canvas.toDataURL() });
    this.setState({ camOn: false });
    //const fileObj = this.b64toBlob(canvas., 'image/jpeg');
    //this.handleAddPictureFromCanvas(fileObj);

    this.handleAddPictureFromCanvas(canvas.toDataURL());
  };

  switchCamera = async () => {
    const listOfVideoInputs = await this.getListOfVideoInputs();

    // The device has more than one camera
    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {
        this.player.srcObject.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }

      // switch to second camera
      if (this.cameraNumber === 0) {
        this.cameraNumber = 1;
      }
      // switch to first camera
      else if (this.cameraNumber === 1) {
        this.cameraNumber = 0;
      }

      // Restart based on camera input
      this.initializeMedia();
    } else if (listOfVideoInputs.length === 1) {
      alert("The device has only one camera");
    } else {
      alert("The device does not have a camera");
    }
  };

  getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };

  render() {
    const playerORImage = Boolean(this.state.imageDataURL) ? (
      <img className={styles.cameraZone + " " + ((this.state.activated) ? styles.cameraZoneActive : null)} src={this.state.imageDataURL} alt="cameraPic" />
    ) : (
      <video
        width="1920"
          height="1080"
          className={styles.cameraZone + " " + ((this.state.activated) ? styles.cameraZoneActive : null)}
        ref={(refrence) => {
          this.player = refrence;
        }}
        autoPlay
      ></video>
    );

    return (
      <React.Fragment>
        <div className={styles.cameraZoneContainer + " " + ((this.state.activated) ? styles.cameraZoneContainerActive : null) + " " + ((this.state.activated) ? styles.cameraZoneEnlarge : null)}>
          {playerORImage}
        </div>
        <div className={styles.cameraBtnContainer}>
          <Button color="link" className={styles.camBtn + " " + ((this.state.camOn) ? styles.hiddenBtn : null)} onClick={this.initializeMedia}>Take Photo</Button>
          <Button color="link" className={styles.camBtn + " " + ((this.state.camOn) ? null : styles.hiddenBtn)} onClick={this.capturePicture}>Capture</Button>
          <Button color="link" className={styles.camBtn + " " + ((this.state.camOn) ? null : styles.hiddenBtn)} onClick={this.switchCamera}>Switch</Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Camera;