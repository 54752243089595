import React, { Component, useCallback, useEffect, useState, useRef } from 'react';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './productset-search.module.scss';
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, Form, FormGroup, Input, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';

const ProductSetSearch = ({ defaultSuggestions, onHandleSelect, controlId }) => {

  const [user, setUser] = useState({});
  const [suggestionsShopId, setSuggestionsShopId] = useState(0);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState('');
  const inputRef = useRef();

  const { t, i18n } = useTranslation('common');

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (String(inputRef.current).trim().length > 3) {
      optimizedGetAutoComplete(input.current);
    }
    else {
      onHandleSelect(null, '', 0, '');
    }
  }, [input]);

  useEffect(() => {
    if (defaultSuggestions && defaultSuggestions.length > 0) {
      setSuggestions(defaultSuggestions);
      inputRef.current = String(defaultSuggestions[0].name);
      setInput(String(defaultSuggestions[0].name));
    }

    return function cleanUp() {
      setSuggestions();
    };
  }, [defaultSuggestions]);

  //useEffect(() => {
  //  if (suggestions && suggestions?.length > 0) {
  //    //// Filter our suggestions that don't contain the user's input
  //    //const unLinked = suggestions.filter(
  //    //  (suggestion) =>
  //    //    suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
  //    //);
  //    //setFilteredSuggestions(unLinked);
  //    setFilteredSuggestions(suggestions);
  //    setActiveSuggestionIndex(0);
  //    setShowSuggestions(true);
  //  }
  //}, [suggestions]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  async function getProductSetAutoComplete(input) {
    try {

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qString: inputRef.current, qShopId: suggestionsShopId })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
        //const ts = Date.now();
        //const response = await fetch(`${process.env.REACT_APP_API_URL}/memberspecialoffers/AutocompleteProductSets?q=${input}&ts=${ts}`, requestOptions);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/memberspecialoffers/AutocompleteProductSets`, requestOptions);
        //console.log('fetching suggestions');

        const data = await response.json();
        //console.log(data.items);

        if (data.data.length === 0) {
          setFilteredSuggestions([]);
          setActiveSuggestionIndex(0);
          setShowSuggestions(false);
        }
        else {
          //setSugg|estions(data.items);
          setFilteredSuggestions(data.data);
          setActiveSuggestionIndex(0);
        }
      }
      else {
        setSuggestions([]);
      }
    }
    catch {

    }
  }

  const onChange = (e) => {
    inputRef.current = e.target.value;
    setInput(e.target.value);
    setShowSuggestions(true);
  };

  const onClick = (e, name, id, text) => {
    setFilteredSuggestions([]);
    inputRef.current = e.target.innerText;
    setInput(e.target.innerText);
    onHandleSelect(e, name, id, text);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {

  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className={styles.suggestions}>
        {filteredSuggestions.map((suggestion, index) => {
          return (
            <li className={((index === activeSuggestionIndex) ? styles.suggestionActive : null)} key={suggestion.id}
              onClick={(e) => {
                onClick(e, String(suggestion.name), suggestion.id, suggestion.text);
              }}>
              {/*<img src={"https://aviram.blob.core.windows.net/product-thumbnails/50x35/" + suggestion.id + ".png"} />*/}
              {suggestion.name} - {suggestion.text}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className={styles.noSuggestions}>
        <em>{t("productSearch.noReasults")}</em>
      </div>
    );
  };

  const optimizedGetAutoComplete = useCallback(debounce(getProductSetAutoComplete, null), [suggestions]);

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);
        return (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              setSuggestionsShopId(currentShop);
              //setSpecialOfferSearchString(currentSearchString);

              return (
              <div style={{ position: 'relative' }}>
                <input
                  type="text"
                  onChange={onChange}
                  //onBlur={setShowSuggestions(false)}
                  onKeyDown={onKeyDown}
                  value={input}
                  name={controlId}
                  id={controlId}
                />
                {showSuggestions && input && input.length > 3 && <SuggestionsListComponent />}
              </div>
            )
          }}
          </layoutContext.Consumer>
        )
      }}
    </userContext.Consumer>
  );
}
export default ProductSetSearch