import React, { Component, useCallback, useEffect, useState, useRef } from 'react';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

import styles from './brand-search.module.scss';
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonGroup, Form, FormGroup, Input, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';

const BrandSearch = ({ defaultBrand, onHandleSelect }) => {
  const [user, setUser] = useState({});

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState('');
  const inputRef = useRef();

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (String(inputRef.current).trim().length > 0) {
      optimizedGeBrandtAutoComplete(inputRef.current);
    }
    else {
      onHandleSelect(null, 0, '');
    }
  }, [input]);

  useEffect(() => {
    if (defaultBrand && defaultBrand.length > 0) {
      setSuggestions(defaultBrand);
      inputRef.current = String(defaultBrand[0].text);
      setInput(String(defaultBrand[0].text));
    }

    return function cleanUp() {
      setSuggestions([]);
    };
  }, [defaultBrand]);

  const debounce = (func, ...args) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      const context = this;
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  async function getBrandAutoComplete(input) {
    try {

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({ })
      };

      if (Boolean(user.token) && input) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const ts = Date.now();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Brand/autocomplete?countonpage=30&q=${input}&ts=${ts}`, requestOptions); //, requestOptions
        //console.log('fetching suggestions');

        const data = await response.json();
        console.log(data.items);

        if (data.items.length === 0) {
          setFilteredSuggestions([]);
          setActiveSuggestionIndex(0);
          setShowSuggestions(false);
        }
        else {
          //setSuggestions(data.items);
          setFilteredSuggestions(data.items);
          setActiveSuggestionIndex(0);
        }
      }
      else {
        setSuggestions([]);
      }
    }
    catch {

    }
  }

  const onChange = (e) => {
    inputRef.current = e.target.value;
    setInput(e.target.value);
    setShowSuggestions(true);
  };

  const onClick = (e, id, text) => {
    setFilteredSuggestions([]);
    inputRef.current = e.target.innerText;
    setInput(e.target.innerText);
    onHandleSelect(e, id, text);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {

  };

  const SuggestionsListComponent = () => {
    console.log(filteredSuggestions);
    return filteredSuggestions.length ? (
      <ul
        className={styles.suggestions}
        id="productEditBrandSuggestions"
        name="productEditBrandSuggestions"
      >
        {filteredSuggestions.map((suggestion, index) => {
          return (
            <li className={((index === activeSuggestionIndex) ? styles.suggestionActive : null)} key={suggestion.id} onClick={(e) => { onClick(e, suggestion.id, suggestion.text); }}>
              {suggestion.text}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className={styles.noSuggestions}>
          <em>{t("productSearch.noReasults")}</em>
      </div>
    );
  };

  const optimizedGeBrandtAutoComplete = useCallback(debounce(getBrandAutoComplete, null), [suggestions]);

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <div style={{ position: 'relative' }}>
            <input
              id="productEditBrand"
              name="productEditBrand"
              type="text"
              onChange={onChange}
              //onBlur={setShowSuggestions(false)}
              onKeyDown={onKeyDown}
              value={input}
            />
            {showSuggestions && input && input.length > 0 && <SuggestionsListComponent />}
          </div>
        )
      }}
    </userContext.Consumer>
  );
}
export default BrandSearch