import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import ProductSetSearch from './ProductSetSearch';
import ProductInShopSearch from '../Products/ProductInShopSearch';
import BrandSearch from '../Products/BrandSearch';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import { userContext } from '../../Context/userContext';

import styles from './specialoffer-productset.module.scss';
import * as Icon from 'react-bootstrap-icons';

const SpecialOfferProductSet = (props) => {
  //const { specialOffer } = props;
  const { specialOfferProductSet } = props;

  const { currentProductSetId } = props;

  const { handleProductSetChange } = props;
  const { handleProductSetRemove } = props;

  const [user, setUser] = useState({});

  const [currentProductSetIdInSpecialOffer, setCurrentProductSetIdInSpecialOffer] = useState(0);


  const [serverProductSetId, setServerProductSetId] = useState(0);
  const [specialOfferProductSetId, setSpecialOfferProductSetId] = useState(0);
  const [specialOfferProductSetSuggestion, setSpecialOfferProductSetSuggestion] = useState([]);

  const [specialOfferProductSetOpen, setSpecialOfferProductSetOpen] = useState(false);

  const [specialOfferProductSetName, setSpecialOfferProductSetName] = useState('');
  const [specialOfferProductSetNameValid, setSpecialOfferProductSetNameValid] = useState(false);
  const [specialOfferProductSetNameCheck, setSpecialOfferProductSetNameCheck] = useState(false);

  const [specialOfferProductSetDescription, setSpecialOfferProductSetDescription] = useState('');

  const [specialOfferProductSetBrandId, setSpecialOfferProductSetBrandId] = useState(0);
  const [specialOfferProductSetBrandName, setSpecialOfferProductSetBrandName] = useState(0);

  const [specialOfferProductSetBrandSuggestion, setSpecialOfferProductSetBrandSuggestion] = useState([]);

  const [productInShopId, setProductInShopId] = useState(0);
  const [productInShopName, setProductInShopName] = useState('');
  const [productInShopSku, setProductInShopSku] = useState('');
  const [productInShopFieldSuggestion, setProductInShopFieldSuggestion] = useState([]);

  const [specialOfferProductSetProductInSohpIds, setspecialOfferProductSetProductInSohpIds] = useState([]);
  const [specialOfferProductSetProductInShopFieldSuggestion, setSpecialOfferProductSetProductInShopFieldSuggestion] = useState([]);

  const [specialOfferProductSetItems, setSpecialOfferProductSetItems] = useState([]);

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    if (specialOfferProductSet && specialOfferProductSet.productSetId && specialOfferProductSet.productSetId !== 0) {
      setSpecialOfferProductSetId(specialOfferProductSet.productSetId);
      setSpecialOfferProductSetName(specialOfferProductSet.productSetName);
      let description = (specialOfferProductSet.productSetDescription) ? specialOfferProductSet.productSetDescription : '';
      setSpecialOfferProductSetDescription(description);
      setSpecialOfferProductSetBrandName(specialOfferProductSet.productSetBrand);

      if (specialOfferProductSet.items && specialOfferProductSet.items.length > 0) {
        setSpecialOfferProductSetItems(specialOfferProductSet.items);
      }
    }

    return function clear() {
      setSpecialOfferProductSetId(0);
    }
  }, [specialOfferProductSet]);

  useEffect(() => {
    if (currentProductSetId && currentProductSetId !== 0) {
      setCurrentProductSetIdInSpecialOffer(currentProductSetId);
      //console.log(currentConditionId);
    }

    return function clear() {
      setCurrentProductSetIdInSpecialOffer(0);
    }
  }, [currentProductSetId]);

  useEffect(() => {
    if (specialOfferProductSetName !== null && specialOfferProductSetName !== undefined) {
      if (String(specialOfferProductSetName) !== '') {
        setSpecialOfferProductSetNameCheck(true);
      }
      if (String(specialOfferProductSetName).trim().length > 3) {
        setSpecialOfferProductSetNameValid(true);
      }
      else {
        setSpecialOfferProductSetNameValid(false);
      }
    }
  }, [specialOfferProductSetName]);

  useEffect(() => {
    //if (!specialOfferProductSet || !specialOfferProductSet.productSetId && specialOfferProductSet.productSetId === 0) {
    //if (specialOfferProductSetId && specialOfferProductSetId != 0) {
    if (serverProductSetId && serverProductSetId != 0) {
      getSpecialOfferProductSet();
    }
    //}
  }, [serverProductSetId]);

  useEffect(() => {
    handleCurrentProductSetChange();
  }, [serverProductSetId, specialOfferProductSetItems, specialOfferProductSetName, specialOfferProductSetDescription, specialOfferProductSetBrandId]);

  //useEffect(() => {

  //  return function clear() {
  //    setSpecialOfferProductSetItems([]);
  //  }
  //}, [specialOfferProductSetItems]);

  const toggleSpecialOfferProductSet = (e) => {
    if (!specialOfferProductSetOpen == true) {
      //onHandleMoveSpecialOfferCardOpenToTop(productId);
    }
    setSpecialOfferProductSetOpen(!specialOfferProductSetOpen);
  }

  const handleSuggestionSelect = (event, name, id) => {
    //debugger;
    if (id && id !== 0) {
      //setSpecialOfferProductSetId(id);
      setServerProductSetId(id);
      setSpecialOfferProductSetName(name);
    }

    //if (id === 0) {
    //  setProductEnableOverrideGlobal(false);
    //}
  };

  const handleBrandSelect = (event, id, text) => {
    setSpecialOfferProductSetBrandId(id);
    setSpecialOfferProductSetBrandName(text);
  };

  const handleProductInShopSuggestionSelect = (event, sku, name, id) => {
    //debugger;
    console.log(id);

    if (id && id !== 0) {
      setProductInShopId(id);
      setProductInShopSku(sku);
      setProductInShopName(name);
    }
  };

  const handleProductSetAddProduct = (e) => {
    //debugger;
    //console.log(specialOfferProductSetItems);

    setSpecialOfferProductSetItems([...specialOfferProductSetItems, {
      productInShopId: productInShopId,
      name: productInShopName,
      sku: productInShopSku
    }]);
  };

  const handleProductSetRemoveProduct = (e) => {
    debugger;
    let productInShopId = 0;
    if (Number(e.target.getAttribute('data-productinshopid')) == 0) {
      productInShopId = Number(e.target.parentElement.getAttribute('data-productinshopid'));
    }
    else {
      productInShopId = Number(e.target.getAttribute('data-productinshopid'));
    }

    //console.log(productInShopId);
    //console.log(specialOfferProductSetItems);
    if (productInShopId !== 0) {
      let newSpecialOfferProductSetsItems = specialOfferProductSetItems.filter(e => e.productInShopId !== productInShopId);
      setSpecialOfferProductSetItems(newSpecialOfferProductSetsItems);
    }
  };

  const handleCurrentProductSetChange = () => {

    let thisProductSet = {
      productSetId: specialOfferProductSetId,
      productSetName: specialOfferProductSetName,
      productSetDescription: specialOfferProductSetDescription,
      productSetBrand: specialOfferProductSetBrandId,
      items: specialOfferProductSetItems
    };

    handleProductSetChange(thisProductSet);
  };

  async function getSpecialOfferProductSet() {
    try {
      //setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ qID: serverProductSetId })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberSpecialOffers/ProductsSet`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        //debugger;
        if (data && data.data && data.data.Items && data.data.Items.length > 0) {
          let productSetItemsFromServer = [];
          data.data.Items.map((e, index) => {
            let item = {
              productInShopId: e.ProductInShopID,
              name: e.Name,
              sku: e.SKU
            }

            productSetItemsFromServer.push(item);
          });
          setSpecialOfferProductSetItems(productSetItemsFromServer);
        }
        console.log(data);
      }
      else {
        //setSpecialOffer({});
      }

      //setLoading(false);
    }
    catch {
      //debugger;
      //setSpecialOffer({});
      //setLoading(false);
      //setShowLoader(false);
    }
  }

  const renderProductItems = () => {
    //debugger;
    return (
        specialOfferProductSetItems.map((item, i) => {
          //let productInShopId = e.productInShopId;
          //console.log(item);
          return (
            <Row className={styles.cardEditRow} key={item.productInShopId}>
              <Col lg="1" xs="2" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                <Button color="link" className={styles.removeButton} data-productinshopid={item.productInShopId} onClick={(e) => {
                  handleProductSetRemoveProduct(e);
                }}>
                  <Icon.X />
                </Button>
              </Col>
              <Col lg="3" xs="5" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                {item.sku}
              </Col>
              <Col lg="8" xs="5" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                {item.name}
              </Col>
            </Row>
          );
        })
    );
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <Card className={styles.specialOfferCard + " " + (getCurrentLng() === 'en' ? "" : styles.specialOfferCardRTL)}>
            <CardHeader className={styles.cardHeader}>
              <Row className={styles.cardRow}>
                <Col lg="2" xs="2" className={styles.cardRemoveIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardRemoveIconRTL)}>
                  <Button
                    onClick={(e) => {
                      if (e) {
                        handleProductSetRemove(currentProductSetIdInSpecialOffer);
                      }
                    }}
                    color="link"
                    className={styles.productTrashBtn}
                    data-productid={productInShopId}
                    name="Edit"
                    value={productInShopId}>
                    <Icon.Trash />
                  </Button>
                </Col>
                <Col lg="8" xs="8" className={styles.cardColumn + " " + styles.cardInfoContainer} onClick={(e) => { toggleSpecialOfferProductSet(e) }}>
                  <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
                    {/*{(specialOffer) ? specialOffer.Title : null}*/}
                  </CardTitle>
                </Col>
                <Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)} onClick={(e) => { toggleSpecialOfferProductSet(e) }}>
                  {(specialOfferProductSetOpen) ?
                    <Icon.ChevronUp />
                    :
                    <Icon.ChevronDown />}
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={specialOfferProductSetOpen}>
              <CardBody className={styles.cardEditSectionWrapper}>
                <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      {t('specialOffersProductSet.searchProductSet')}
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <ProductSetSearch defaultSuggestions={specialOfferProductSetSuggestion} onHandleSelect={handleSuggestionSelect} controlId={'specialOfferProductSetSearch' + currentProductSetIdInSpecialOffer} />
                    </Col>
                  </Row>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      {t('specialOffersProductSet.name')}
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <input className={styles.specialOfferEditInputField} value={specialOfferProductSetName} onChange={(e) => {
                        setSpecialOfferProductSetName(e.target.value);
                      }} />
                      {specialOfferProductSetNameCheck && ((specialOfferProductSetNameValid) ? null :
                        <div className={styles.specialOfferEditInputValidationMessage + " " + (getCurrentLng() === 'en' ? null : styles.specialOfferEditInputValidationMessageRtl)}>{t("specialOffersEdit.validationMessageTitle")}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      {t('specialOffersProductSet.description')}
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <input className={styles.specialOfferEditInputField} value={specialOfferProductSetDescription} onChange={(e) => {
                        setSpecialOfferProductSetDescription(e.target.value);
                      }} />
                    </Col>
                  </Row>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      {t('specialOffersProductSet.brand')}
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <BrandSearch className={styles.productEditPriceInputField} id="productEditBrandSearch" defaultBrand={specialOfferProductSetBrandSuggestion} onHandleSelect={handleBrandSelect} />
                    </Col>
                  </Row>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      {t('specialOffersProductSet.products')}
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <ProductInShopSearch defaultSuggestions={productInShopFieldSuggestion} onHandleSelect={handleProductInShopSuggestionSelect} />
                    </Col>
                  </Row>
                  <Row className={styles.cardEditRow}>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                    </Col>
                    <Col lg="6" xs="6" className={styles.cardColumn + " " + styles.cardPriceContainer}>
                      <Button
                        onClick={(e) => {
                          handleProductSetAddProduct(e);
                        }}
                        color="link"
                        className={styles.productEditBtn + " " + styles.productEditNarrowBtn}
                        data-productid={productInShopId}
                        name="Edit"
                        value={productInShopId}>{t('specialOffersProductSetEdit.AddProduct')}
                      </Button>
                    </Col>
                  </Row>
                  <Container className={styles.productItemsContainer}>

                      {specialOfferProductSetItems && specialOfferProductSetItems.length > 0 && renderProductItems()}

                  </Container>
                </div>
              </CardBody>
            </Collapse>
          </Card>
        )
      }}
    </userContext.Consumer>
  );
}

export default SpecialOfferProductSet