import React, { Component, useCallback, useEffect, useState } from 'react';
import { Container, Card, CardImg, Button, ButtonGroup, CardHeader, CardBody, CardTitle, CardText, Collapse, Row, Col, FormGroup, Input, Label, NavLink } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
//import { userContext } from '../../Context/userContext';

import TimePicker from '../../Components/TimePicker';
import ToggleSwitch from '../../Components/ToggleSwitch';

import styles from './shippingtime-schedule.module.scss';
import * as Icon from 'react-bootstrap-icons';

const ShippingTimeSchdule = (props) => {
  const { shippingTimeSchdule } = props;
  const { currentSchduleId } = props;
  const { handleSchduleChange } = props;

  //const [user, setUser] = useState({});

  //const [, updateState] = React.useState();
  //const forceUpdate = React.useCallback(() => updateState({}), []);

  const [shippingTimeSchduleId, setShippingTimeSchduleId] = useState(0);

  const [shippingTimeSchduleOpen, setShippingTimeSchduleOpen] = useState(true);

  const [shippingTimeScheduleItems, setShippingTimeScheduleItems] = useState([]);

  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    //console.log(shippingTimeSchdule);
    if (shippingTimeSchdule !== null && shippingTimeSchdule !== undefined && shippingTimeSchdule.length > 0) {
      setShippingTimeScheduleItems(shippingTimeSchdule);
    }
    //console.log(shippingTimeScheduleItems);

    return function clear() {
      setShippingTimeScheduleItems([]);
    }
  }, [shippingTimeSchdule]);

  useEffect(() => {
    if (currentSchduleId && currentSchduleId !== 0) {
      setShippingTimeSchduleId(currentSchduleId);
      //console.log(currentConditionId);
    }

    return function clear() {
      setShippingTimeSchduleId(0);
    }
  }, [currentSchduleId]);

  useEffect(() => {
    if (shippingTimeScheduleItems !== null && shippingTimeScheduleItems !== undefined && shippingTimeScheduleItems.length > 0) {
      //handleCurrentScheduleChange();
    }
    //forceUpdate();
  }, [shippingTimeScheduleItems]);

  const toggleShippingTimeSchdule = (e) => {
    setShippingTimeSchduleOpen(!shippingTimeSchduleOpen);
  }

  const handleCurrentScheduleChange = (shippingTimes) => {
    if (shippingTimeScheduleItems !== null && shippingTimeScheduleItems !== undefined && shippingTimeScheduleItems.length > 0) {
      setShippingTimeScheduleItems(shippingTimes);
      handleSchduleChange(shippingTimes);
    }
  };

  const setDayActive = (day, active) => {
    handleCurrentScheduleChange(shippingTimeScheduleItems.map(item => {
      if (item.shippingTimeDay === day) {
        return { ...item, shippingTimeActive: active };
      } else {
        return item;
      }
    }));
  };

  const setDayNoSameDayDelivery= (day, noSameDayDelivery) => {
    handleCurrentScheduleChange(shippingTimeScheduleItems.map(item => {
      if (item.shippingTimeDay === day) {
        return { ...item, shippingTimeNoSameDayDelivery: noSameDayDelivery };
      } else {
        return item;
      }
    }));
  };

  const setTimeFrom = (day, timeFrom) => {
    handleCurrentScheduleChange(shippingTimeScheduleItems.map(item => {
      if (item.shippingTimeDay === day) {
        return { ...item, shippingTimeTimeFrom: timeFrom };
      } else {
        return item;
      }
    }));
  }

  const setTimeTo = (day, timeTo) => {
    handleCurrentScheduleChange(shippingTimeScheduleItems.map(item => {
      if (item.shippingTimeDay === day) {
        return { ...item, shippingTimeTimeTo: timeTo };
      } else {
        return item;
      }
    }));
  }

  const setTimeLimit = (day, timeLimit) => {
    handleCurrentScheduleChange(shippingTimeScheduleItems.map(item => {
      if (item.shippingTimeDay === day) {
        return { ...item, shippingTimeTimeLimit: timeLimit };
      } else {
        return item;
      }
    }));
  }

  const renderSchduleItems = () => {
    //debugger;
    //console.log(shippingTimeScheduleItems);
    return (
      shippingTimeScheduleItems.map((item, i) => {
        //let productInShopId = e.productInShopId;
        //console.log(item);
        return (
          <Row className={styles.cardEditRow} key={shippingTimeSchduleId + '_' + i}>
            <Col lg="2" xs="12" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              { t('orders.isoDay' + Number(item.shippingTimeDay + 1)) }
            </Col>
            <Col lg="0" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer + " " + styles.cardMobileTitle}>
              {t('shippingTimeScheduleEdit.shippingTimeTimeFromTitle')}
            </Col>
            <Col lg="2" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              {/*{item.shippingTimeTimeFrom}*/}
              <TimePicker 
                id={'editShippingTimeTimeFrom' + shippingTimeSchduleId + '_' + i}
                itemId={item.shippingTimeDay}
                time={item.shippingTimeTimeFrom}
                onChange={(e, itemId) => {
                  //console.log(itemId);
                  //console.log(e);
                  setTimeFrom(itemId, e);
                }}
              />
            </Col>
            <Col lg="0" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer + " " + styles.cardMobileTitle}>
              {t('shippingTimeScheduleEdit.shippingTimeTimeToTitle')}
            </Col>
            <Col lg="2" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              {/*{item.shippingTimeTimeTo}*/}
              <TimePicker
                id={'editShippingTimeTimeTo' + shippingTimeSchduleId + '_' + i}
                itemId={item.shippingTimeDay}
                time={item.shippingTimeTimeTo}
                onChange={(e, itemId) => {
                  //console.log(itemId);
                  //console.log(e);
                  setTimeTo(itemId, e);
                }}
              />
            </Col>
            <Col lg="0" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer + " " + styles.cardMobileTitle}>
              {t('shippingTimeScheduleEdit.shippingTimeTimeLimitTitle')}
            </Col>
            <Col lg="3" xs="6" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              {/*{item.shippingTimeTimeLimit}*/}
              <TimePicker
                id={'editShippingTimeTimeLimit' + shippingTimeSchduleId + '_' + i}
                itemId={item.shippingTimeDay}
                time={item.shippingTimeTimeLimit}
                onChange={(e, itemId) => {
                  //console.log(itemId);
                  //console.log(e);
                  setTimeLimit(itemId, e);
                }}
              />
            </Col>
            <Col lg="0" xs="7" className={styles.cardColumn + " " + styles.cardInfoContainer + " " + styles.cardMobileTitle}>
              {t('shippingTimeScheduleEdit.shippingTimeNoSameDayDelivery')}
            </Col>
            <Col lg="2" xs="5" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              {item.shippingTimeNoSameDayDelivery}
              <ToggleSwitch
                id={'editShippingTimeSameDayDelivery' + shippingTimeSchduleId + '_' + i}
                name={'editShippingTimeSameDayDelivery' + shippingTimeSchduleId + '_' + i}
                optionLabels={[]}
                checked={item.shippingTimeNoSameDayDelivery}
                itemId={item.shippingTimeDay}
                data-currentscheduleid={item.shippingTimeDay}
                onChange={(e, itemId) => {
                  setDayNoSameDayDelivery(itemId, e);
                }} />
            </Col>
            <Col lg="0" xs="7" className={styles.cardColumn + " " + styles.cardInfoContainer + " " + styles.cardMobileTitle}>
              {t('shippingTimeScheduleEdit.shippingTimeActive')}
            </Col>
            <Col lg="1" xs="5" className={styles.cardColumn + " " + styles.cardInfoContainer}>
              {item.shippingTimeActive}
              <ToggleSwitch
                id={'editShippingTimeActiveToggle' + shippingTimeSchduleId + '_' + i}
                name={'editShippingTimeActiveToggle' + shippingTimeSchduleId + '_' + i}
                optionLabels={[]}
                checked={item.shippingTimeActive}
                itemId={item.shippingTimeDay}
                data-currentscheduleid={item.shippingTimeDay}
                onChange={(e, itemId) => {
                  setDayActive(itemId, e);
                }} />
            </Col>
          </Row>
        );
      })
    );
  }

  return (
    //<userContext.Consumer>
    //  {({ user }) => {
    //    setUser(user);

    //    return (
    <Card className={styles.shippingTimeCard + " " + (getCurrentLng() === 'en' ? "" : styles.shippingTimeCardRTL)}>
      <CardHeader className={styles.cardHeader}>
        <Row className={styles.cardRow}>
          {/*<Col lg="12" xs="12" className={styles.cardColumn + " " + styles.cardInfoContainer} onClick={(e) => { toggleShippingTimeSchdule(e) }}>*/}
          <Col lg="12" xs="12" className={styles.cardColumn + " " + styles.cardInfoContainer}>
            <CardTitle className={styles.cardTitle + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleRtl)}>
              {/*{(specialOffer) ? specialOffer.Title : null}*/}
            </CardTitle>
          </Col>
          {/*<Col lg="2" xs="2" className={styles.cardTitleIcon + " " + (getCurrentLng() === 'en' ? "" : styles.cardTitleIconRTL)} onClick={(e) => { toggleShippingTimeSchdule(e) }}>*/}
          {/*  {(shippingTimeSchduleOpen) ?*/}
          {/*    <Icon.ChevronUp />*/}
          {/*    :*/}
          {/*    <Icon.ChevronDown />}*/}
          {/*</Col>*/}
        </Row>
      </CardHeader>
      <Collapse isOpen={shippingTimeSchduleOpen}>
        <CardBody className={styles.cardEditSectionWrapper}>
          <div className={styles.cardText + " " + (getCurrentLng() === 'en' ? "" : styles.productCardRTL)}>
            <Container className={styles.productItemsContainer}>
              <Row className={styles.cardTitleRow} key={shippingTimeSchduleId + '_Title'}>
                <Col lg="2" md="2" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                </Col>
                <Col lg="2" xs="2" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                  {t('shippingTimeScheduleEdit.shippingTimeTimeFromTitle') }
                </Col>
                <Col lg="2" xs="2" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                  {t('shippingTimeScheduleEdit.shippingTimeTimeToTitle')}
                </Col>
                <Col lg="3" xs="3" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                  {t('shippingTimeScheduleEdit.shippingTimeTimeLimitTitle')}
                </Col>
                <Col lg="2" xs="1" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                  {t('shippingTimeScheduleEdit.shippingTimeNoSameDayDelivery')}
                </Col>
                <Col lg="1" xs="1" className={styles.cardColumn + " " + styles.cardInfoContainer}>
                  {t('shippingTimeScheduleEdit.shippingTimeActive')}
                </Col>
              </Row>

              {shippingTimeScheduleItems !== null && shippingTimeScheduleItems !== undefined && shippingTimeScheduleItems.length > 0 && renderSchduleItems()}

            </Container>
          </div>
        </CardBody>
      </Collapse>
    </Card>
    //      )
    //    }}
    //  </userContext.Consumer>
  );
}

export default ShippingTimeSchdule