const ROOT_URL = 'https://secret-hamlet-03431.herokuapp.com';

export async function isLoggedIn() {
  if (window !== 'undefined' && window.localStorage.getItem('currentUser')) {
    const userToken = localStorage.getItem('token');
    try {

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: '' })
      };

      if (userToken) {
        requestOptions.headers["Authorization"] = `Bearer ${userToken}`;
      }

      let response = await fetch(`${process.env.REACT_APP_API_URL}/Account/IsLoggedIn`, requestOptions);
      let data = await response.json();

      if (data.success === true) {
        return {
          'currentUser': localStorage.getItem('currentUser'),
          'token': userToken,
          'userRoles': localStorage.getItem('userRoles'),
          'success': true
        }
      }
      else {
        logoutUser();
        return data;
      }
    } catch (error) {

    }

    logoutUser();
  }
  else {
    return { success: false };
  }
}

export async function loginUser(loginPayload) {

  const requestOptions = {
    method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginPayload)
  };
 
  try {
    //console.log(process.env.REACT_APP_API_URL);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/Account/Authenticate`, requestOptions);
    let data = await response.json();

    //debugger;
    if (data.access_token) {
      //localStorage.setItem('currentUser', JSON.stringify(data.fullName));
      localStorage.setItem('currentUser', JSON.stringify(data.userName));
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('userRoles', JSON.stringify(data.userRoles));

      //console.log(data);
      return {
        //'currentUser': data.fullName??'',
        'currentUser': data.userName,
        'token': data.access_token,
        'userRoles': data.userRoles
      }
    }
 
    return data;
  } catch (error) {

  }
}

export async function sendForgotPassword(forgotPayload) {

  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(forgotPayload)
  };

  try {
    //console.log(process.env.REACT_APP_API_URL);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/Account/SendForgotPasswordMail`, requestOptions);
    let data = await response.json();
    //console.log(data);

    return data;
  } catch (error) {

  }
}

export async function resetPassword(resetPayload) {

  const requestOptions = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(resetPayload)
  };

  try {
    //console.log(process.env.REACT_APP_API_URL);
    let response = await fetch(`${process.env.REACT_APP_API_URL}/Account/MemberResetPassword`, requestOptions);
    let data = await response.json();
    //console.log(data);

    return data;
  } catch (error) {

  }
}
 
export async function logoutUser(historyProps) {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
  localStorage.removeItem('userRoles');
}