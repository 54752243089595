import React, { Component, useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import ReactDOM from "react-dom";
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import CustomPopup from '../../Components/CustomPopup';
import { getCurrentLng } from '../../i18next_fun';
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../../Components/LoaderAnimation";
import ErrorMessageCustomPopup from '../../Components/ErrorMessageCustomPopup';
import NumericInputWithButtons from '../../Components/NumericInputWithButtons';
import LeavePageBlocker from '../../Components/LeavePageBlocker';

import KeyboardEventHandler from 'react-keyboard-event-handler';

import { userContext } from '../../Context/userContext';
import { layoutContext } from '../../Context/layoutContext';

//import useInfiniteScroll from '../../Components/InfiniteScroll';
import ProductReplacement from '../Products/productReplacement';
import ProductAddToOrder from '../Products/productAddToOrder';
import OrderCharge from './OrderCharge';

import * as Icon from 'react-bootstrap-icons';
import {
  Button, ButtonGroup, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Container, Row, Col, Card, CardHeader, CardImg, CardTitle, CardText, CardFooter, Modal, ModalFooter,
  ModalHeader, ModalBody, Toast, ToastBody, ToastHeader, NavLink
} from "reactstrap";

import styles from './order-collection.module.scss';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function ClickOutside(ref, onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const OrderCollection = (props) => {
  const [user, setUser] = useState({});
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [orderTimeLeft, setOrderTimeLeft] = useState({});

  const [shopId, setShopId] = useState(0);
  const [chosenShopId, setChosenShopId] = useState(0);

  //const [currentlyPresentedOrderItems, setCurrentlyPresentedOrderItems] = useState([]);
  const [orderInfoCollapse, setOrderInfoCollapse] = useState(false);

  const [modalProdImg, setModalProdImg] = useState(false);
  const [prodImgUrl, setProdImgUrl] = useState('');
  const [prodImgDescription, setProdImgDescription] = useState('');
  const [modalCloseOrder, setModalCloseOrder] = useState(false);
  const [modalCancelOrder, setModalCancelOrder] = useState(false);

  const [modalProdCollect, setModalProdCollect] = useState(false);
  const [modalProdCollectQuantityPicked, setModalProdCollectQuantityPicked] = useState(0);
  const [modalProdCollectDescription, setModalProdCollectDescription] = useState('');
  const [modalProdCollectItemId, setModalProdCollectItemId] = useState(0);
  const [modalProdCollectItemSKU, setModalProdCollectItemSKU] = useState('');
  const [modalProdCollectItemUnitMeasure, setModalProdCollectItemUnitMeasure] = useState(0);
  const [modalProdCollectItemImg, setModalProdCollectItemImg] = useState('');
  const [modalProdCollectItemDiscountDescription, setModalProdCollectItemDiscountDescription] = useState('');
  const [modalProdCollectItemPrice, setModalProdCollectItemPrice] = useState('');
  const [modalProdCollectItemOrderedUnitMeasure, setModalProdCollectItemOrderedUnitMeasure] = useState('');
  const [modalProdCollectItemOrderedUnitMeasureId, setModalProdCollectItemOrderedUnitMeasureId] = useState(0);
  const [modalProdCollectItemQuantityPerUnit, setModalProdCollectItemQuantityPerUnit] = useState(0);
  const [modalProdCollectItemQuantityUnitMeasureID, setModalProdCollectItemQuantityUnitMeasureID] = useState(0);
  const [modalProdCollectItemFirstUnitMeasureID, setModalProdCollectItemFirstUnitMeasureID] = useState(0);
  const [modalProdCollectItemFirstUnitMeasureType, setModalProdCollectItemFirstUnitMeasureType] = useState(0);
  const [modalProdCollectItemFirstUnitMeasureName, setModalProdCollectItemFirstUnitMeasureName] = useState(0);

  const [modalProdCollectItemOrderedQuantity, setModalProdCollectItemOrderedQuantuty] = useState(0);
  const [modalProdCollectItemComment, setModalProdCollectItemComment] = useState('');

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [modalProdCollectCardTop, setModalProdCollectCardTop] = useState(0);
  const [modalProdCollectCardLeft, setModalProdCollectCardLeft] = useState(0);
  const [modalProdCollectCardWidth, setModalProdCollectCardWidth] = useState(0);
  const [modalProdCollectCardHeight, setModalProdCollectCardHeight] = useState(0);
  const [modalProdCollectCardContentMinHeight, setModalProdCollectCardContentMinHeight] = useState(0);
  const [modalProdCollectCardActionsMinHeight, setModalProdCollectCardActionsMinHeight] = useState(0);

  const [modalPreReplacementPreferencesNotificationShown, setModalPreReplacementPreferencesNotificationShown] = useState(false);
  const [modalPreReplacementPreferencesNotification, setModalPreReplacementPreferencesNotification] = useState(false);

  const [modalProdReplacement, setModalProdReplacement] = useState(false);
  const [prodReplacementDescription, setProdReplacementDescription] = useState('');
  const [prodReplacementSKU, setProdReplacementSKU] = useState('');
  const [prodReplacementBrand, setProdReplacementBrand] = useState('');
  const [prodReplacementCapacity, setProdReplacementCapacity] = useState('');
  const [prodReplacementProductId, setProdReplacementProductId] = useState('');
  const [prodReplacementImgURL, setProdReplacementImgURL] = useState('');

  const [modalProdAdd, setModalProdAdd] = useState(false);

  const [customModalQuantityOverOrdered, setCustomModalQuantityOverOrdered] = useState(false);
  const [customModalQuantityOverOrderedId, setCustomModalQuantityOverOrderedId] = useState(0);
  const [customModalQuantityOverOrderedValue, setCustomModalQuantityOverOrderedValue] = useState(0);
  const [customModalQuantityOverOrderedOriginal, setCustomModalQuantityOverOrderedOriginal] = useState(0);

  const [customModalItemWasNotOrdered, setCustomModalItemWasNotOrdered] = useState(false);
  const [customModalItemWasNotOrderedId, setCustomModalItemWasNotOrderedId] = useState(0);

  const [customModalChargeSuccess, setCustomModalChargeSuccess] = useState(false);

  const [customModalResetItem, setCustomModalResetItem] = useState(false);
  const [customModalResetItemId, setCustomModalResetItemId] = useState(0);

  const [customModalDeleteItem, setCustomModalDeleteItem] = useState(false);
  const [customModalDeleteItemId, setCustomModalDeleteItemId] = useState(0);

  const [customModalOrderCharge, setCustomModalOrderCharge] = useState(false);
  const [customModalOrderChargeId, setCustomModalOrderChargeId] = useState(0);

  const [activeSearchOrderItemsType, setActiveSearchOrderItemsType] = useState('1');

  const [toastBarcodeScanned, setToastBarcodeScanned] = useState(false);
  const [toastBarcodeScannedInput, setToastBarcodeScannedInput] = useState('');
  const [toastBarcodeScannedSKU, setToastBarcodeScannedSKU] = useState('');

  const [shopConnectedToScales, setShopConnectedToScales] = useState(false);
  const [shopConnectedToScalesValue, setShopConnectedToScalesValue] = useState('');
  const [shopConnectedToScalesValueWeight, setShopConnectedToScalesValueWeight] = useState(0);

  const [modalOrderCharge, setModalOrderCharge] = useState(false);
  const [orderChargeUpdated, setOrderChargeUpdated] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ordersEditError, setOrdersEditError] = useState(false);
  const [ordersEditErrorMessage, setOrdersEditErrorMessage] = useState('');

  const ordersEditLeaveBlock = useRef(false);
  const ordersEditLeaveURL = useRef('');
  //const [ordersEditLeaveBlock, setOrdersEditLeaveBlock] = useState(false);
  //const [ordersEditLeaveURL, setOrdersEditLeaveURL] = useState('');
  const [ordersEditLeaveShowMessage, setOrdersEditLeaveShowMessage] = useState(false);
  const [ordersEditLeaveWithReset, setOrdersEditLeaveWithReset] = useState(false);
  const [ordersEditLeaveWithSave, setOrdersEditLeaveWithSave] = useState(false);

  const { t, i18n } = useTranslation('common');

  const { id } = useParams();

  let history = useHistory();

  const [offset, setOffset] = useState(0);
  const [windowInitialHeight, setWindowInitialHeight] = useState(0);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const { path } = useRouteMatch();

  const orderInfoRef = useRef("menu");
  ClickOutside(orderInfoRef, () => {
    if (orderInfoCollapse) {
      toggleOrderInfoCollapse();
    }
  });

  useEffect(() => {
    //resetOrder(order.ID);
    leavePage();

    return () => { setOrdersEditLeaveWithReset(false); }
  }, [ordersEditLeaveWithReset]);

  useEffect(() => {
    //debugger;
    //if (ordersEditLeaveURL != '') {
    //  history.push(ordersEditLeaveURL);
    //}
    leavePage();

    return () => { setOrdersEditLeaveWithSave(false); }
  }, [ordersEditLeaveWithSave]);

  //useEffect(() => {
  //  //debugger;
  //  leavePage();

  //  return () => { setOrdersEditLeaveBlock(false); }
  //}, [ordersEditLeaveBlock]);

  useEffect(() => {
    let unblock = history.block((tx) => {
      //debugger;
      if (ordersEditLeaveBlock.current) { //  || ordersEditLeaveURL.current.length == 0
        let url = tx.pathname;
        //setOrdersEditLeaveURL(url);
        ordersEditLeaveURL.current = url;
        setOrdersEditLeaveShowMessage(true);
        return false;
      }
      else {
        // Unblock the navigation.
        unblock();

        // Retry the transition.
        //tx.retry();
      }
    });

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //useEffect(() => {
  //  console.log();
  //}, []);

  useEffect(() => {
    if (offset > 0) {
      if (orderInfoCollapse) {
        setOrderInfoCollapse(false);
      }
    }
  }, [offset]);

  useEffect(() => {
    if (order.ID == undefined || orderItems.length == 0 || (loading)) {
      setShowLoader(true);
    }
    else {
      setShowLoader(false);
    }
  }, [loading]);

  useEffect(() => {
    function handleResize() {
      const currentDimensions = getWindowDimensions();
      setWindowDimensions(currentDimensions);

      let heightWithoutAddressBar = Number(windowInitialHeight) - 80;
      setIsKeyboardOpen(currentDimensions.height < heightWithoutAddressBar);
    }

    const currentDimensions = getWindowDimensions();
    setWindowInitialHeight(currentDimensions.height);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = t('nav.orders');
    getOrder();

    return function clear() {
      setOrder({});
    }
  }, [user]);

  useEffect(() => {
    getOrderItems();
    let dateString = null;
    if (order.ShippingMethod == 1) {
      dateString = String(order.ShipTimeFrom);
    }
    else {
      dateString = String(order.PickupTimeFrom);

    }
    setOrderTimeLeft(moment(dateString, "DD/MM/YYYY hh:mm"));

  }, [order.ID]);

  useEffect(() => {
    //toggleActiveSearchOrderItemsType();
    checkOrderStatusAndUpdate();
    //getOrder();
    //debugger;
    //updateOrder();

    if (firstLoad && orderItems.length > 0) {
      setOrderInfoCollapse(true);
      setFirstLoad(false);
    }

  }, [orderItems]);

  useEffect(() => {
    if (order.Status == 3 && orderItems.length > 0 && orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted).length === 0) {
      toggleOrderChargeModal();
      //setOrdersEditLeaveBlock(false);
      ordersEditLeaveBlock.current = false;
    }
    if (order.Status > 2) {
      ordersEditLeaveBlock.current = false;
    }
  }, [orderChargeUpdated]); //orderItems

  useEffect(() => {
    if (customModalQuantityOverOrderedId != 0 && customModalQuantityOverOrderedValue != 0) {

      //console.log(customModalQuantityOverOrderedId);
      //console.log(customModalQuantityOverOrderedValue);

      let thisOrderItem = orderItems.filter(p => p.ID == customModalQuantityOverOrderedId);
      if (thisOrderItem.length > 0) {
        //console.log(Number(thisOrderItem[0].QuantityOrdered));
        if (thisOrderItem[0].FirstUnitMeasureID != thisOrderItem[0].OrderedUnitMeasureID) {
          if (customModalQuantityOverOrderedValue > Number(thisOrderItem[0].QuantityOrdered * thisOrderItem[0].QuantityPerUnit)) {
            setCustomModalQuantityOverOrderedOriginal(Number(thisOrderItem[0].QuantityOrdered * thisOrderItem[0].QuantityPerUnit));
            toggleQuantityOverOrderedCustomModal();
          }
          else {
            handleCollectedChangeConfirm();
          }
        }
        else {
          if (customModalQuantityOverOrderedValue > Number(thisOrderItem[0].QuantityOrdered)) {
            setCustomModalQuantityOverOrderedOriginal(Number(thisOrderItem[0].QuantityOrdered));
            toggleQuantityOverOrderedCustomModal();
          }
          else {
            handleCollectedChangeConfirm();
          }
        }
      }
    }

    return function clear() {
      //setCustomModalQuantityOverOrderedId(0);
      //setCustomModalQuantityOverOrderedValue(0);
    }
  }, [customModalQuantityOverOrderedId, customModalQuantityOverOrderedValue])

  let keyupTimeout = null;

  useEffect(() => {
    if (keyupTimeout) {
      clearTimeout(keyupTimeout);
    }
    keyupTimeout = setTimeout(scanKeys, 300);
    return function clear() {
      clearTimeout(keyupTimeout);
    }
  }, [toastBarcodeScannedInput]);

  useEffect(() => {
    let toastTimeout;

    if (toastBarcodeScannedSKU != '') {
      console.log('barcode pattern match: ' + toastBarcodeScannedSKU);
      toggleBarcodeScannedToast();

      //ADD/PICK ITEM
      //$(document).trigger('onbarcodescanned', _inputString);
      finBySKU(toastBarcodeScannedSKU);

      toastTimeout = setTimeout(() => {
        //toggleBarcodeScannedToast();
        setToastBarcodeScanned(false);

        setToastBarcodeScannedInput('');
        setToastBarcodeScannedSKU('');

      }, 2000);
    }

    return function clear() {
      clearTimeout(toastTimeout);
    }
  }, [toastBarcodeScannedSKU]);

  useEffect(() => {
    let scaleInterval;
    if (shopConnectedToScales) {
      scaleInterval = setInterval(function () {
        getScalesValue();
      }, 1000);
    }

    return function clear() {
      clearTimeout(scaleInterval);
    }
  }, [shopId]);

  useEffect(() => {
    if (shopConnectedToScales && modalProdCollect && shopConnectedToScalesValueWeight != 0 && modalProdCollectItemOrderedUnitMeasureId === 1) {
      setModalProdCollectQuantityPicked(shopConnectedToScalesValueWeight);
    }
  }, [shopConnectedToScalesValueWeight]);

  function toggleActiveSearchOrderItemsType(event) {
    const newStatus = (event) ? event.target.value : '1';
    if (event)
      event.preventDefault();

    setActiveSearchOrderItemsType(newStatus);
  }

  const toggleOrderInfoCollapse = (e) => setOrderInfoCollapse(!orderInfoCollapse);

  const toggleProdImgModal = (e) => setModalProdImg(!modalProdImg);

  const toggleProdReplacementModal = (e) => {
    //document.body.style.overflow = (!modalProdReplacement) ? 'hidden' : 'unset';
    setModalProdReplacement(!modalProdReplacement);
  }

  const toggleProdAddModal = (e) => {
    //document.body.style.overflow = (!modalProdAdd) ? 'hidden' : 'unset';
    setModalProdAdd(!modalProdAdd);
  }

  const toggleOrderChargeModal = (e) => {
    //document.body.style.overflow = (!modalOrderCharge) ? 'hidden' : 'unset';
    //let deliveryWindowText = '';

    //if (order.ShippingMethod == 1) {
    //  let thisDate = moment(order.ShipTimeFrom, "DD/MM/YYYY hh:mm");
    //  deliveryWindowText = t('orders.isoDay' + (Number(thisDate.day() + 1))) + ', ' + thisDate.format("MMM D") + ' ' + thisDate.format('HH:mm') + ' - ' + moment(order.ShipTimeTo, "DD/MM/YYYY hh:mm").format('HH:mm')
    //}
    //else {
    //  let thisDate = moment(order.PickupTimeFrom, "DD/MM/YYYY hh:mm");
    //  deliveryWindowText = t('orders.isoDay' + (Number(thisDate.day() + 1))) + ', ' + thisDate.format("MMM D") + ' ' + thisDate.format('HH:mm') + ' - ' + moment(order.PickupTimeTo, "DD/MM/YYYY hh:mm").format('HH:mm');
    //}

    //setOrderChargeData({
    //  direction: order.MissingProductAction,
    //  name: order.FullName,
    //  primaryPhone: order.Phone,
    //  secondaryPhone: order.ShipAddressAddressPhoneNumber,
    //  address: order.ShipAddressStreet,
    //  pickupOrDelivery: order.ShippingMethod,
    //  deliveryWindow: deliveryWindowText,
    //  deliveryCost: order.ShipCost,
    //  itemsOrdered: orderItems.filter(oi => !oi.Deleted && !oi.Replaced).length,
    //  itemsCollected: orderItems.filter(oi => oi.QuantityPicked > 0 && !oi.Deleted && !oi.Replaced).length,
    //  subTotal: order.SubTotal,
    //  shipCost: order.ShipCost,
    //  total: order.Total
    //});
    setModalOrderCharge(!modalOrderCharge);
  }

  const toggleProdCollect = (e, itemId) => {
    //document.body.style.overflow = (!modalProdCollect) ? 'hidden' : 'unset';

    if (e) {
      ////console.log(e);
      //console.log(e.target.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect());
      //if (e != undefined && e != null && e.target)
      //  console.log(e, e.natiiveEvent.target.childNodes[0]);
      const rect = e.target.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();

      if (windowDimensions.width > 780) {
        setModalProdCollectCardTop(rect.top - 28);
        setModalProdCollectCardLeft(rect.left);
        setModalProdCollectCardWidth(rect.width);
        setModalProdCollectCardHeight(rect.height + 12);

        setModalProdCollectCardContentMinHeight(176);
        //setModalProdCollectCardActionsMinHeight(170);
      }
      else {
        setModalProdCollectCardTop(rect.top - 7);
        setModalProdCollectCardLeft(rect.left);
        setModalProdCollectCardWidth(rect.width + 4);
        setModalProdCollectCardHeight(rect.height);

        setModalProdCollectCardContentMinHeight(172);
      }
    }

    //if (itemId && itemId !== null) {
    //  //const next = cardRefs.current[itemId];
    //  //if (next) {
    //  //  console.log(next.getBoundingClientRect());
    //  //}
    //}

    setModalProdCollect(!modalProdCollect);
  }

  const toggleCloseOrderModal = (e) => setModalCloseOrder(!modalCloseOrder);

  const toggleCancelOrderModal = (e) => setModalCancelOrder(!modalCancelOrder);

  const toggleQuantityOverOrderedCustomModal = (e) => {
    //document.body.style.overflow = (!customModalQuantityOverOrdered) ? 'hidden' : 'unset';
    setCustomModalQuantityOverOrdered(!customModalQuantityOverOrdered);
  }

  const toggleItemWasNotOrderedCustomModal = (e) => {
    //document.body.style.overflow = (!customModalItemWasNotOrdered) ? 'hidden' : 'unset';
    setCustomModalItemWasNotOrdered(!customModalItemWasNotOrdered);
  }

  const toggleChargeSuccessCustomModal = (e) => {
    //document.body.style.overflow = (!customModalChargeSuccess) ? 'hidden' : 'unset';
    setCustomModalChargeSuccess(!customModalChargeSuccess);
  }

  const toggleDeleteItemCustomModal = (e) => {
    //document.body.style.overflow = (!customModalDeleteItem) ? 'hidden' : 'unset';
    setCustomModalDeleteItem(!customModalDeleteItem);
  }

  const toggleResetItemCustomModal = (e) => {
    //document.body.style.overflow = (!customModalResetItem) ? 'hidden' : 'unset';
    setCustomModalResetItem(!customModalResetItem);
  }

  const toggleConfirmChargeCustomModal = (e) => {
    //document.body.style.overflow = (!customModalOrderCharge) ? 'hidden' : 'unset';
    setCustomModalOrderCharge(!customModalOrderCharge);
  }

  const togglePreReplacementPreferencesNotificationModal = (e) => {
    //document.body.style.overflow = (!modalPreReplacementPreferencesNotification) ? 'hidden' : 'unset';
    setModalPreReplacementPreferencesNotification(!modalPreReplacementPreferencesNotification);
  }

  const toggleBarcodeScannedToast = (e) => {
    setToastBarcodeScanned(!toastBarcodeScanned);
  }

  const handleFocus = (event) => {
    if (event && event.target) { // && event.target.select
      event.target.value = '';
      event.target.select();
    }
  }

  const handleProdCollectQuantity = event => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      if ((order.Status === -1 || order.Status > 3) || modalProdCollectQuantityPicked === NaN || (Number(modalProdCollectQuantityPicked) == 0)) {
        return;
      }
      //console.log(event.target.dataset);
      handleCollectedChange(event);

    }
  };

  function scanKeys() {
    if (toastBarcodeScannedInput !== '') {
      console.log('scanning barcode: ');
      console.log(toastBarcodeScannedInput);

      if (toastBarcodeScannedInput.length <= 3 || !(/^[0-9]{4,20}$/i.test(toastBarcodeScannedInput))) {
        setToastBarcodeScannedInput('');
        return;
      }

      setToastBarcodeScannedSKU(toastBarcodeScannedInput);
      setToastBarcodeScannedInput('');
    }
  }

  async function getScalesValue() {
    try {
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({  })
      };
      const response = await fetch(`http://127.0.0.1:22084/scales_rs232`, requestOptions);
      const data = await response.json();
      if (data) {
        //currentWeight = data.Weight;
        setShopConnectedToScalesValueWeight(Number(data.Weight));
        setShopConnectedToScalesValue(data.Weight + ' ' + data.Unit);
      }
      else {
        //currentWeight = null;
        setShopConnectedToScalesValueWeight(0);
        setShopConnectedToScalesValue('');
      }

      //$.ajax({
      //  url: 'http://127.0.0.1:22084/scales_rs232',
      //  type: 'GET',
      //  crossDomain: true,
      //  dataType: 'json',
      //  success: function (data) {
      //    if (data) {
      //      currentWeight = data.Weight;
      //      $('#scales_weight').text(data.Weight + ' ' + data.Unit);
      //    }
      //    else {
      //      currentWeight = null;
      //      $('#scales_weight').text('');
      //    }
      //    $('#tab_items').removeClass('scales-disconnected').addClass('scales-connected');
      //  },
      //  error: function () {
      //    $('#tab_items').removeClass('scales-connected').addClass('scales-disconnected');
      //    $('#scales_weight').text('');
      //  }
      //});
    }
    catch {
    }
  }

  async function getOrder() {
    try {
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({  })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/Order/${id}`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        setOrder(data);
        if (data.OrderShopShowScale == true) {
          setShopConnectedToScales(true);
        }
        setShopId(data.ShopID);
        setChosenShopId(data.ShoookChosenShop);
      }
      else {
        setOrder({});
      }

      setLoading(false);
    }
    catch {
      setOrder({});
      setLoading(false);
    }
  }

  async function updateOrder() {
    try {
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({  })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/Order/${id}`, requestOptions);

        const data = await response.json();
        //data.MissingProductAction = 2;
        //setOrder(data);

        //debugger;

        if (data.OrderShopShowScale == true) {
          setShopConnectedToScales(true);
        }
        //setShopId(data.ShopID);
        //setChosenShopId(data.ShoookChosenShop);
        order.Total = data.Total;
        order.SubTotal = data.SubTotal;
        order.TotalDiscountAmount = data.TotalDiscountAmount;
        order.TotalDiscountDescription = data.TotalDiscountDescription;
        setOrderChargeUpdated(!orderChargeUpdated);
      }
      else {
        setOrder({});
      }
      setLoading(false);
    }
    catch {
      setOrder({});
      setLoading(false);
    }
  }

  async function getOrderItems() {
    try {
      setLoading(true);

      const ids = order.OrderItemsIds.split("|");
      var idsInInt = ids.map(function (x) {
        return parseInt(x, 10);
      });
      //console.log(idsInInt);
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderItemIds: idsInInt })
      };

      if (user.token) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/OrderItems`, requestOptions);

        const data = await response.json();
        setOrderItems(data);
      }
      else {
        setOrderItems([]);
      }
      setLoading(false);
    }
    catch {
      setOrderItems([]);
      setLoading(false);
    }
  }

  async function updateOrderValue(name, value) {
    try {
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, orderId: order.ID })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/SetOrderValue`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        if (name === 'Status') {
          setOrder(
            Object.assign({}, order, { Status: value })
          );
        }

        //if (name === "Deleted") {
        //  setOrderItems(
        //    orderItems.map(el => (el.ID === id ? Object.assign({}, el, { Deleted: value, Confirmed: false, Replaced: false }) : el))
        //  );
        //}
      }
      setLoading(false);
    }
    catch {
      //setOrders(prevState => ([...prevState, ...data.data]));
      setLoading(false);
    }
  }

  async function closeOrder() {
    try {
      setLoading(true);

      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        //body: JSON.stringify({ propName: name, value: value, orderId: order.ID })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/CloseOrder/${id}`, requestOptions);

      const data = await response.json();

      if (data.success === true) {
        setOrder(
          Object.assign({}, order, { Status: 6 })
        );
        //toggleCloseOrderModal();
        toggleChargeSuccessCustomModal();
      }
      setLoading(false);
    }
    catch {
      //setOrders(prevState => ([...prevState, ...data.data]));
      setLoading(false);
    }
  }

  async function finBySKU(orderItemSKU) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderItemSKU: orderItemSKU, orderId: order.ID })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/FindProductBySKU`, requestOptions);

      const data = await response.json();

      if (data.success === true) {
        //Show Confirmation

        handleAdd(data.productInShopID, null, null);
      }
      else {
        //show SKU not found toast
      }
    }
    catch {

    }
  }

  async function checkOrderStatusAndUpdate() {
    //debugger;
    await updateOrder();

    if (orderItems && orderItems.length > 0 && orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted).length === 0 && order && order.Status < 4 && order.Status > 1) {
      if (order.Status !== 3) {
        updateOrderValue("Status", 3);
      }
      //console.log('Order is ready');
      //toggleOrderChargeModal();
      //setOrdersEditLeaveBlock(false);\
      ordersEditLeaveBlock.current = false;

    }
    else {
      if (orderItems && orderItems.length > 0 && order && order.Status === 1) {
        updateOrderValue("Status", 2);
        //console.log('Order in Preparation');
      }

      //setOrdersEditLeaveBlock(true);
      ordersEditLeaveBlock.current = true;
    }
  }

  async function handleOrderCharge(e) {
    toggleConfirmChargeCustomModal();
  }

  async function handleOrderChargeConfirm(e) {
    toggleConfirmChargeCustomModal();
    closeOrder();
  }

  async function handleCollectedChange(e) {
    toggleProdCollect();
    let newCollectedID = Number(e.target.getAttribute('data-itemid'));
    let newCollectedValue = Number(e.target.value);

    //console.log(newCollectedID);
    //console.log(newCollectedValue);

    setCustomModalQuantityOverOrderedId(newCollectedID);
    setCustomModalQuantityOverOrderedValue(newCollectedValue);

  }

  async function handleCollectedChangeConfirm() {
    let newCollectedID = customModalQuantityOverOrderedId;
    let newCollectedValue = customModalQuantityOverOrderedValue;

    let thisOrderItem = orderItems.filter(p => p.ID == newCollectedID);

    if (thisOrderItem.length > 0) {
      updateOrderItemValue("QuantityPicked", newCollectedValue, newCollectedID);
      //checkOrderStatusAndUpdate();
    }

    setCustomModalQuantityOverOrderedId(0);
    setCustomModalQuantityOverOrderedValue(0);
  }

  async function handleReplacement(replaceItemId, productInShopId, quantity, unitMeasure) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderItemId: replaceItemId, replaceWithProductInShopId: productInShopId, orderedQuantity: quantity, orederedUnitMeasureId: unitMeasure })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/ReplaceOrderItem`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        let orderItemsList = orderItems;
        orderItemsList = orderItemsList.map(el => (el.ID === replaceItemId ? Object.assign({}, el, { Deleted: true, Replaced: true, Confirmed: false, QuantityPicked: 0, SubTotal: '0' }) : el)) //OutOfStock: false, 
        orderItemsList = [...orderItemsList, data.item];

        setOrderItems(orderItemsList);
        //checkOrderStatusAndUpdate();
        //getOrder();
      }

      toggleProdReplacementModal();
    }
    catch {

    }
  }

  async function handleAdd(productInShopId, addedQuantityOrdered, addedUnitMeasureId) {

    let thisOrderItem = orderItems.filter(p => p.ProductInShopID == productInShopId);
    //console.log(thisOrderItem);
    if (thisOrderItem.length > 0) {
      updateOrderItemValue("QuantityPicked", thisOrderItem[0].QuantityPicked + 1, thisOrderItem[0].ID);
    }
    else {
      //console.log(addedQuantityOrdered + " " + Number(addedQuantityOrdered));
      if (addedQuantityOrdered !== null && Number(addedQuantityOrdered) > 0) {
        handleAddConfirmed(productInShopId, addedQuantityOrdered, addedUnitMeasureId);
      }
      else {
        setCustomModalItemWasNotOrderedId(productInShopId);
        toggleItemWasNotOrderedCustomModal();
      }
    }
  }

  async function handleAddConfirmed(addedProductInShopId, addedQuantityOrdered, addedUnitMeasureId) {
    let productInShopId = 0;
    let orderedQuantity = 0;
    let confirmedAdd = false;
    if (addedProductInShopId !== null && addedQuantityOrdered != null) {
      productInShopId = addedProductInShopId;
      orderedQuantity = addedQuantityOrdered
    }
    else {
      productInShopId = customModalItemWasNotOrderedId;
      orderedQuantity = 0;
      confirmedAdd = true;
    }

    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId: id, orderItemId: productInShopId, orderedQuantity: orderedQuantity, orederedUnitMeasureId: addedUnitMeasureId })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/AddOrderItem`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        let orderItemsList = orderItems;
        //orderItemsList = orderItemsList.map(el => (el.ID === replaceItemId ? Object.assign({}, el, { OutOfStock: false, Deleted: true, Replaced: true, Confirmed: false, QuantityPicked: 0 }) : el))
        orderItemsList = [...orderItemsList, data.item];

        setOrderItems(orderItemsList);
        //checkOrderStatusAndUpdate();
        //getOrder();
      }

      //toggleProdAddModal();
      if (confirmedAdd) {
        toggleItemWasNotOrderedCustomModal();
      }
    }
    catch {

    }
  }

  const handleMissingChange = (e) => {
    let newMissingID = Number(e.target.getAttribute('data-orderitemid'));
    let newMissingValue = (e.target.value === "true");
    let thisOrderItem = orderItems.filter(p => p.ID == newMissingID);
    if (thisOrderItem.length > 0) {
      updateOrderItemValue("OutOfStock", newMissingValue, newMissingID);
    }
  };

  const handleDeletedChange = (e) => {
    let newDeletedID = e;
    let thisOrderItem = orderItems.filter(p => p.ID == newDeletedID);
    if (thisOrderItem.length > 0) {
      updateOrderItemValue("Deleted", true, newDeletedID);
      toggleDeleteItemCustomModal();
    }
  };

  async function updateOrderItemValue(name, value, id) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, orderItemId: id })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/SetOrderItemValue`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        let subTotal = data.SubTotal;

        if (name === 'QuantityPicked') {
          let thisOrderItem = orderItems.filter(p => p.ID == id);
          if (thisOrderItem.length > 0) {
            setOrderItems(
              orderItems.map(el => (el.ID === id ? Object.assign({}, el, { OutOfStock: false, Deleted: false, Replaced: false, Confirmed: true, QuantityPicked: value, SubTotal: subTotal }) : el))
            );
          }
        }

        if (name === "OutOfStock") {
          setOrderItems(
            orderItems.map(el => (el.ID === id ? Object.assign({}, el, { OutOfStock: true, Deleted: value, Confirmed: false, Replaced: false, QuantityPicked: 0, SubTotal: subTotal }) : el))
          );
        }

        if (name === "Deleted") {
          setOrderItems(
            orderItems.map(el => (el.ID === id ? Object.assign({}, el, { OutOfStock: false, Deleted: value, Confirmed: false, Replaced: false, QuantityPicked: 0, SubTotal: subTotal }) : el))
          );
        }

        //checkOrderStatusAndUpdate();
        //getOrder();
      }
    }
    catch {
      //setOrders(prevState => ([...prevState, ...data.data]));
      //setLoading(false);
    }
  }

  async function cancelReplacement(name, value, id) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, orderItemId: id })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/CancelReplacement`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        let orderItemsList = orderItems;
        orderItemsList = orderItemsList.map(el => (el.ID === id ? Object.assign({}, el, { Deleted: false, Replaced: false, QuantityPicked: 0 }) : el)); //OutOfStock: false, 
        orderItemsList = orderItemsList.map(el => (el.ReplacesItem === id ? Object.assign({}, el, { Deleted: true, Replaced: false, Confirmed: false, ReplacesItem: null, QuantityPicked: 0 }) : el)) //OutOfStock: false, 

        setOrderItems(orderItemsList);
        //checkOrderStatusAndUpdate();
        //getOrder();
      }
    }
    catch {
      //setOrders(prevState => ([...prevState, ...data.data]));
      //setLoading(false);
    }
  }

  function leavePage() {
    console.log('blocked:' + ordersEditLeaveBlock.current);
    console.log('url: ' + ordersEditLeaveURL.current);
    console.log('save: ' + ordersEditLeaveWithSave);
    console.log('reset: ' + ordersEditLeaveWithReset);

    //debugger;

    if (!ordersEditLeaveBlock.current && ordersEditLeaveURL.current && ordersEditLeaveURL.current !== '' && ordersEditLeaveURL.current.length > 0) {
      if (ordersEditLeaveWithSave) {
        history.push(ordersEditLeaveURL.current);
      }
      else if (ordersEditLeaveWithReset) {
        resetOrder(order.ID);
      }
    }
  }

  async function resetItem(name, value, id) {
    try {
      const requestOptions = {
        //credentials: 'same-origin',
        //credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ propName: name, value: value, orderItemId: id })
      };

      if (Boolean(user.token)) {
        requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/ResetItem`, requestOptions);

      const data = await response.json();

      if (data.success === true) {

        let orderItemsList = orderItems;
        orderItemsList = orderItemsList.map(el => (el.ID === id ? Object.assign({}, el, { OutOfStock: false, Deleted: false, Replaced: false, QuantityPicked: 0 }) : el));
        orderItemsList = orderItemsList.map(el => (el.ReplacesItem === id ? Object.assign({}, el, { OutOfStock: false, Deleted: true, Replaced: false, Confirmed: false, ReplacesItem: null, QuantityPicked: 0 }) : el))

        setOrderItems(orderItemsList);
        //checkOrderStatusAndUpdate();
        //getOrder();
        toggleResetItemCustomModal();
      }
    }
    catch {
      //setOrders(prevState => ([...prevState, ...data.data]));
      //setLoading(false);
    }
  }

  async function resetOrder(id) {
    if (ordersEditLeaveWithReset) {
      try {
        setOrdersEditLeaveShowMessage(false);

        const requestOptions = {
          //credentials: 'same-origin',
          //credentials: 'include',
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderId: id })
        };

        if (Boolean(user.token)) {
          requestOptions.headers["Authorization"] = `Bearer ${user.token}`;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/MemberOrders/ResetOrder`, requestOptions);

        const data = await response.json();

        if (data.success === true) {
          //debugger;
          history.push(ordersEditLeaveURL.current);
        }
      }
      catch {
        //setOrders(prevState => ([...prevState, ...data.data]));
        //setLoading(false);
      }
    }
  }

  function returnCurrentlyPresentedOrderItems(filter) {
    switch (filter) {
      case '1':
      default:
        return orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted);
      case '2':
        return orderItems.filter(oi => oi.QuantityPicked > 0 && !oi.Deleted);
      case '3':
        return orderItems.filter(oi => oi.Deleted);
    }
  }

  function returnReplacementOf(itemId) {
    if (orderItems.filter(oi => oi.ID === itemId).length > 0)
      return orderItems.filter(oi => oi.ID === itemId)[0];
  }

  function returnReplacedBy(itemId) {
    if (orderItems.filter(oi => oi.ReplacesItem === itemId).length > 0)
      return orderItems.filter(oi => oi.ReplacesItem === itemId)[0];
    else
      return null;
  }

  //const groupByMember = function (xs, key) {
  //  return xs.reduce(function (rv, x) {
  //    (rv[x[key]] = rv[x[key]] || []).push(x);
  //    return rv;
  //  }, {});
  //}

  const groupByMember = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const groupByMemberAndParent = (items, key, key2) => items.reduce(
    (result, item) => ({
      ...result,
      [((key2 && item[key2] != '') ? item[key2] + " - " : '') + item[key]]: [
        ...(result[((key2 && item[key2] != '') ? item[key2] + " - " : '') + item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const renderCategories = () => {
    //let categorizedItems = groupByMember(returnCurrentlyPresentedOrderItems(activeSearchOrderItemsType), 'ProductInShopCategoryNameForPicking');
    let categorizedItems = groupByMemberAndParent(returnCurrentlyPresentedOrderItems(activeSearchOrderItemsType), 'ProductInShopCategoryNameForPicking', 'ProductInShopParentCategoryNameForPicking');

    //let categorizedItems = groupByMember(returnCurrentlyPresentedOrderItems(activeSearchOrderItemsType), 'ProductInShopParentCategoryNameForPicking');
    //console.log(categorizedItems);

    //var categorizedItemsArray = Object.entries(categorizedItems).map(([key, value]) => ({ key, value }));
    //console.log(categorizedItemsArray);
    //console.log((Object.keys(categorizedItems).length == 0) ? "empty" : "full");

    return (
      (Object.keys(categorizedItems).length > 0) ?

        (Object.entries(categorizedItems).map(([key, value]) => {
          //console.log(key);
          //console.log(value);

          return (
            <div key={key}>
              <div className={styles.itemsCategoryHeader + " " + (getCurrentLng() === 'en' ? "" : styles.itemsCategoryHeaderRtl)}>{key} ({value.length})</div>
              {renderOrderItmsTable(value)}
            </div>)
        }))

        :
        ((activeSearchOrderItemsType == '1') ? (<div className={styles.horrayMessage}>{t('orderCollection.horrayClearedCollectionMessage')}</div>) : null)
    );
  };

  const renderOrderItmsTable = (categoryItems) => {
    return (categoryItems.map((item) => {

      let replacedByItem = returnReplacedBy(item.ID);
      return (

        <Card key={item.ID} body className={styles.cardMain + " " + ((item.Deleted && !item.OutOfStock) ? styles.cardMainDeleted : null)}>
          {/*<CardTitle className={styles.cardHeaderLink}>{item.ProductInShopName}</CardTitle>*/}
          {/*<CardImg width="100%" src={item.ProductImage} alt="Cat" />*/}
          {/*alt={item.ProductInShopName} title={item.ProductInShopName}*/}
          {((order.Status >= 0 && order.Status < 4) && (!item.Deleted)) ?
            <div className={styles.orederItemCardDelete} disabled={(order.Status === -1 || order.Status > 3) ? true : false} onClick={(e) => {
              if (order.Status >= 0 || order.Status < 4) {
                setCustomModalDeleteItemId(item.ID);
                toggleDeleteItemCustomModal();
              }
            }
            }>
              <Icon.Trash />
            </div>
            :
            null
          }
          <div className={styles.orederItemCardWrapper}>
            {(item.QuantityPicked > 0 && !item.Replaced && !item.Deleted) ? <div className={styles.orederItemCardTotalBadge}>{t('orderCollection.itemSubTotal')}: <br /> <span className={styles.orederItemCardTotalBadgeSum}>{item.SubTotal} &#8362;</span></div> : null}
            <div className={styles.orederItemCardImgWrapper}>
              <div className={styles.orederItemCardImgCircleWrap}>
                <div className={styles.orederItemCardImgCircle} onClick={(e) => { setProdImgUrl(item.ProductImage); setProdImgDescription(item.ProductInShopName); toggleProdImgModal(e) }}>
                  <img className={styles.orederItemCardImgCircleImg} src={item.ProductImage} />
                </div>
              </div>
            </div>
            <div className={styles.orederItemCardData}>
              <div className={styles.orederItemCardDataName}>{item.ProductInShopName}</div>
              {/*<div>{(item.ProductInShopProductBrandName !== null) ? item.ProductInShopProductBrandName : ''} <span>{item.QuantityPerUnit} {item.FirstUnitMeasureName}</span></div>*/}
              <div>{item.ProductInShopSKU}</div>

              {((item.DiscountDescription === null && item.DiscountDescription === "") ? null :
                <div className={styles.orederItemCardDataDiscountDescription}>
                  {item.DiscountDescription}
                </div>)}

              <div className={styles.orederItemCardDataPrice}>{item.Price} &#8362; \ {((item.OrderedUnitMeasureID !== item.FirstUnitMeasureID) ? item.FirstUnitMeasureName : item.OrderedUnitMeasureName)}</div>

              {((item.ReplacesItem === null) ? null :
                <div>
                  {t('orderCollection.replacesItem')} {returnReplacementOf(item.ReplacesItem).ProductInShopName}
                </div>)}

              {((replacedByItem === null) ? null :
                <div>
                  {t('orderCollection.replacedByItem')} {replacedByItem.ProductInShopName}
                </div>)}

            </div>
            {/*<div className={styles.orederItemCardQuantity}>*/}
            {/*  {(item.QuantityOrdered > 0) ? <span>{item.QuantityOrdered} {item.OrderedUnitMeasureName}</span> : ''}*/}
            {/*</div>*/}
          </div>
          {((item.ItemComment === null || item.ItemComment === '') ? null :
            <div className={styles.orederItemCardNotes}>
              <div className={styles.orederItemCardNotesIcon}>
                <img src="/assets/collection/order_collection_item_notes.svg" />
              </div>
              <div className={styles.orederItemCardNotesText}>
                {item.ItemComment}
              </div>
            </div>)}

          {((order.Status >= 0 && order.Status < 4) && (item.QuantityPicked > 0 || item.Deleted || item.Replaced)) ?
            <div className={styles.orederItemCardReset}>
              <Button className={styles.orederItemCardResetBtn} color="link" disabled={(!item || (order.Status === -1 || order.Status > 3))} onClick={(e) => {
                if (order.Status >= 0 || order.Status < 4) {
                  setCustomModalResetItemId(item.ID);
                  toggleResetItemCustomModal();
                }
              }}>
                <img src="/assets/collection/order_collection_reset_item_white.svg" /> {t('orderCollection.resetItemBtnText')}
              </Button>
            </div>
            :
            null
          }

          {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
          <CardFooter className={styles.orederItemCardFooter}>
            <Row className={styles.orderItemActionRow}>
              <Col xs="4" lg="4" className={styles.orderItemActionCol}>
                <Button className={styles.orderItemCardCollectionLink} color="link" disabled={(order.Status === -1 || order.Status > 3)} onClick={(e) => {
                  setModalProdCollectItemId(item.ID);
                  setModalProdCollectItemUnitMeasure(item.OrderedUnitMeasureID);
                  setModalProdCollectQuantityPicked(item.QuantityPicked);
                  //setModalProdCollectQuantityPicked((item.QuantityPicked > 0) ? Number(item.QuantityPicked) : ((item.OrderedUnitMeasureID !== item.FirstUnitMeasureID && (item.QuantityPerUnit && item.QuantityPerUnit !== 0 && item.orderedQuantity && item.orderedQuantity !== 0)) ? Number(item.QuantityPerUnit * item.orderedQuantity) : item.orderedQuantity))
                  setModalProdCollectDescription(item.ProductInShopName);
                  setModalProdCollectItemImg(item.ProductImage);
                  setModalProdCollectItemDiscountDescription(item.DiscountDescription);
                  setModalProdCollectItemSKU(item.ProductInShopSKU);
                  setModalProdCollectItemPrice(item.Price);
                  setModalProdCollectItemOrderedQuantuty(item.QuantityOrdered);
                  setModalProdCollectItemOrderedUnitMeasure(item.OrderedUnitMeasureName);
                  setModalProdCollectItemOrderedUnitMeasureId(item.OrderedUnitMeasureID);
                  setModalProdCollectItemQuantityPerUnit(item.QuantityPerUnit);
                  setModalProdCollectItemQuantityUnitMeasureID(item.QuantityUnitMeasureID);
                  setModalProdCollectItemFirstUnitMeasureID(item.FirstUnitMeasureID);
                  setModalProdCollectItemFirstUnitMeasureType(item.FirstUnitMeasureType);
                  setModalProdCollectItemFirstUnitMeasureName(item.FirstUnitMeasureName);
                  setModalProdCollectItemComment(item.Comment);
                  toggleProdCollect(e, item.ID);
                }}>
                  {/*<Icon.Basket3 /><br />*/}
                  <span className={styles.orderItemCardCollectionLinkSpan}>
                    {((item.OrderedUnitMeasureID !== item.FirstUnitMeasureID) ? item.QuantityPicked + "/" + Number(item.QuantityPerUnit * item.QuantityOrdered).toFixed(2) : item.QuantityPicked + "/" + item.QuantityOrdered)}
                  </span>&#160;
                  {((item.OrderedUnitMeasureID !== item.FirstUnitMeasureID) ? item.FirstUnitMeasureName : item.OrderedUnitMeasureName)}
                  {/*{t('orderCollection.enterQuantity')}*/}
                </Button>
              </Col>
              <Col xs="4" lg="4" className={styles.orderItemActionCol}>
                <Button className={styles.orderItemCardCollectionBtn} color="link" disabled={(!item || ((item.Deleted) || order.Status === -1 || order.Status > 3))} onClick={(e) => { handleMissingChange(e) }} data-orderitemid={item.ID} value="true"><img src="/assets/collection/order_collection_item_missing.svg" /> {t('orderCollection.markAsMissing')}</Button>
              </Col>
              <Col xs="4" lg="4" className={styles.orderItemActionCol}>
                {
                  (replacedByItem) ?
                    /*<Button color="link" onClick={(e) => { setProdReplacementDescription(item.ProductInShopName); setProdReplacementSKU(item.ProductInShopSKU); setProdReplacementProductId(item.ID); setProdReplacementBrand(item.ProductInShopProductBrandName); setProdReplacementCapacity(item.FirstUnitMeasureName); setProdReplacementImgURL(item.ProductImage); toggleProdReplacementModal(e) }}><Icon.ArrowCounterclockwise /><br />{t('orderCollection.cancelReplace')}</Button>*/
                    <Button className={styles.orderItemCardCollectionBtn + " " + styles.orderItemCardCollectionBtnCancel} color="link" disabled={(order.Status === -1 || order.Status > 3)} onClick={(e) => { cancelReplacement(null, null, item.ID) }}><img src="/assets/collection/order_collection_cancel_replacement.svg" /> {t('orderCollection.cancelReplace')}</Button>
                    :
                    (item.ReplacesItem === null || item.ReplacesItem === 0) &&
                    <Button className={styles.orderItemCardCollectionBtn} color="link" disabled={(order.Status === -1 || order.Status > 3)} onClick={(e) => {
                      setProdReplacementDescription(item.ProductInShopName);
                      setProdReplacementSKU(item.ProductInShopSKU);
                      setProdReplacementProductId(item.ID);
                      setProdReplacementBrand(item.ProductInShopProductBrandName);
                      setProdReplacementCapacity(item.FirstUnitMeasureName);
                      setProdReplacementImgURL(item.ProductImage);
                      if (!modalPreReplacementPreferencesNotificationShown) {
                        setModalPreReplacementPreferencesNotificationShown(true);
                        togglePreReplacementPreferencesNotificationModal(e);
                      }
                      else {
                        toggleProdReplacementModal(e)
                      }
                    }}><img src="/assets/collection/order_collection_item_replace.svg" /> {t('orderCollection.replace')}</Button>
                }
              </Col>
            </Row>
          </CardFooter>

        </Card>
      )
    }));
  }

  function renderMissingProductActionSwitch(param) {
    switch (param) {
      case 1:
      default:
        return (
          <div className={styles.missingItemActionNothingCube}>
            {t('orderCollection.missingProductActionNothing')}
          </div>
        );
        break;
      case 2:
        return (
          <div className={styles.missingItemActionReplaceCube}>
            <img src="/assets/collection/missing_item_replace_icon.svg" />
            {t('orderCollection.missingProductActionReplace')}
          </div>
        );
        break;
      case 3:
        return (
          <div className={styles.missingItemActionCallCube}>
            <img src="/assets/collection/missing_item_call_icon.svg" />
            {t('orderCollection.missingProductActionCall')}
          </div>
        );
        break;
    }
  }

  function renderMissingProductActionNotice(param) {
    switch (param) {
      case 1:
      default:
        return (
          t('orderCollection.missingProductActionNothing')
        );
        break;
      case 2:
        return (
          t('orderCollection.missingProductActionReplace')
        );
        break;
      case 3:
        return (
          t('orderCollection.missingProductActionCall')
        );
        break;
    }
  }

  function unitMeasureFormat(num) {
    switch (modalProdCollectItemUnitMeasure) {
      case 4:
      default:
        return String(num) + ' ' + t('orderCollection.collectInputUnitMeasureUnit');
      case 1:
        return String(num) + ' ' + t('orderCollection.collectInputUnitMeasureWeight');

    }
  }

  return (
    <userContext.Consumer>
      {({ user }) => {
        setUser(user);

        return (
          <layoutContext.Consumer>
            {({ currentShop, currentSearchString }) => {

              //setOrderShop(currentShop);
              //setOrderSearchString(currentSearchString);

              return (
                <div className={styles.mainWrapper}>

                  <div ref={orderInfoRef}>
                    <Card className={styles.orderCard + " " + (getCurrentLng() === 'en' ? "" : styles.orderCardRtl)}>
                      <CardHeader className={styles.cardHeader + " " + (getCurrentLng() === 'en' ? "" : styles.orderCardRtl)} onClick={toggleOrderInfoCollapse}>
                        <CardTitle className={styles.cardTitle}>
                          {/*{id} - {t('orderStatus.status')}: {t('orderStatus.' + order.Status)}*/}


                          <div className={styles.cardTitleIcon + " " + ((getCurrentLng() === 'en') ? null : styles.cardTitleIconRTL)}>
                            {(orderInfoCollapse) ? <Icon.XCircle /> : <Icon.InfoCircle />}
                          </div>

                          <div className={styles.cardTitleName}>
                            <div className={styles.cardTitleId}>{order.IDinShop}</div>
                            {order.FullName}
                          </div>

                          <div className={styles.cardTitleHour}>
                            <div className={styles.cardTitleTimeCaption}>{t('orderCard.timeToCollect')}</div>
                            {/*<Moment date={orderTimeLeft} format="hh:mm" durationFromNow />*/}
                            {/*{moment(orderTimeLeft).fromNow(true)}*/}
                            {/*<Moment date={orderTimeLeft} format="hh:mm" durationFromNow  />*/}
                            {Number(moment.duration(moment(orderTimeLeft).diff(moment(new Date()))).asHours()).toFixed(1)}
                          </div>

                          <div className={styles.cardTitleCharge + " " + ((order.Status > 3 || order.isCharged || orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted).length > 0) ? styles.cardTitleChargeDisabled : null)} onClick={(e) => {
                            if (order.Status == 3 && orderItems.length > 0 && orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted).length === 0) {
                              toggleOrderChargeModal();
                            }
                          }}>
                            <img src="/assets/collection/order_collection_charge_stripe.svg" />
                            <div>
                              {t('orderCollection.chargeOrder')}
                            </div>
                          </div>
                        </CardTitle>
                      </CardHeader>
                      <Collapse className={styles.orderCardCollapse} isOpen={orderInfoCollapse}>
                        <div className={styles.cardCollapseWrapper + " " + (getCurrentLng() === 'en' ? "" : styles.orderCardRtl)}>
                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.cloceCollapseWrapper}>
                                {/*<img src="/assets/collection/order_collection_close.svg" alt="Close" title="Close" onClick={toggleOrderInfoCollapse} />*/}
                                <img src="/assets/collection/order_collection_phone.svg" alt="Phone Numbers" title="Phone Numbers" />
                              </div>
                            </Col>
                            <Col lg="8" md="8" xs="8" className={styles.collapseCol}>
                              <a className={styles.phoneLink} href={"tel:" + order.Phone} title={t('orderCollection.call')}><Icon.TelephoneOutbound /> {order.Phone}</a>
                              {(order.ShipAddressAddressPhoneNumber && order.ShipAddressAddressPhoneNumber.length > 0) ? <a className={styles.phoneLinkSecondary} href={"tel:" + order.ShipAddressAddressPhoneNumber} title={t('orderCollection.callSecPhone')}><Icon.TelephoneOutbound /> {order.ShipAddressAddressPhoneNumber}</a> : ""}
                            </Col>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.missingItemsActionCubeInCollapse + " " + (getCurrentLng() === 'en' ? "" : styles.missingItemsActionCubeInCollapseRtl)}>
                                {renderMissingProductActionSwitch(order.MissingProductAction)}
                              </div>
                            </Col>
                          </Row>

                          {/*{(order.PackagesCount !== 0) ?*/}
                          {/*  <Label> {order.PackagesCount} {t('orderCollection.packages')}</Label> :*/}
                          {/*  <Label></Label>*/}
                          {/*  <Label>{order.OrderItemsIds.split('|').length} {t('orderCollection.products')}</Label>*/}
                          {/*}*/}
                          {/*<h2></h2>*/}
                          {/*<Label></Label>*/}

                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.iconCollapseWrapper}>
                                <img src="/assets/collection/order_collection_calendar.svg" alt="" />
                              </div>
                            </Col>
                            <Col lg="10" md="10" xs="10" className={styles.collapseCol}>
                              {(order.ShippingMethod == 1) ? order.ShipTimeFrom : order.PickupTimeFrom}
                            </Col>
                          </Row>
                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.iconCollapseWrapper}>
                                <img src="/assets/collection/order_collection_location.svg" alt="" />
                              </div>
                            </Col>
                            <Col lg="10" md="10" xs="10" className={styles.collapseCol}>
                              {order.ShipAddressStreet}
                            </Col>
                          </Row>
                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.iconCollapseWrapper}>
                                {(order.ShippingMethod == 1) ? <img src="/assets/collection/order_collection_delivery.svg" alt="" /> : <img src="/assets/collection/order_collection_pickup.svg" alt="" />}
                              </div>
                            </Col>
                            <Col lg="10" md="10" xs="10" className={styles.collapseCol}>
                              {/*{t('orderCollection.hoursLeft')} */}
                              {(order.ShippingMethod == 1) ? t('orderCollection.forDelivery') : t('orderCollection.forPickup')} <b>{order.PickupBranchName}</b>
                            </Col>
                          </Row>
                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.iconCollapseWrapper}>
                                <img src="/assets/collection/order_collection_basket.svg" alt="" />
                              </div>
                            </Col>
                            <Col lg="10" md="10" xs="10" className={styles.collapseCol}>
                              {order.ItemsCount} {t('orderCollection.products')}
                            </Col>
                          </Row>
                          <Row className={styles.collapseRow}>
                            <Col lg="2" md="2" xs="2" className={styles.collapseCol}>
                              <div className={styles.iconCollapseWrapper}>
                                <img src="/assets/collection/order_collection_cc.svg" alt="" />
                              </div>
                            </Col>
                            <Col lg="10" md="10" xs="10" className={styles.collapseCol}>
                              <span className={styles.collapseColPrice + " " + ((getCurrentLng() == 'en') ? null : styles.collapseColPriceRtl)}>{(order.Total === 0) ? order.SubTotal : order.Total}</span>
                            </Col>
                          </Row>

                          {/*<div className={styles.cardHeaderBack}>*/}
                          {/*  <Button color="Link" to="/orders">*/}
                          {/*    {(getCurrentLng() === 'en' ? <Icon.ChevronRight /> : <Icon.ChevronLeft />)}*/}
                          {/*  </Button>*/}
                          {/*</div>*/}

                          {/*<Button outline color="success"*/}
                          {/*  disabled={(*/}
                          {/*    (!orderItems || orderItems.length == 0) ||*/}
                          {/*    (orderItems && orderItems.length > 0 && orderItems.filter(oi => oi.QuantityPicked == 0 && !oi.Deleted).length > 0) ||*/}
                          {/*    (order && (order.Status !== 3))*/}
                          {/*  ) ? true : false}*/}
                          {/*  className={styles.cardActionBtn + " " + (getCurrentLng() === 'en' ? "float-right" : "float-left")}*/}
                          {/*  onClick={toggleCloseOrderModal}>*/}
                          {/*  {t('orderCollection.closeOrder')}*/}
                          {/*</Button>*/}
                          {/*<Button outline color="warning" disabled={(order.Status === -1 || order.Status > 3) ? true : false} className={styles.cardActionBtn + " " + (getCurrentLng() === 'en' ? "float-right" : "float-left")}*/}
                          {/*  onClick={toggleCancelOrderModal}>{t('orderCollection.cancelOrder')}</Button>*/}

                          {(order.Comment) ?
                            <div className={styles.cardCollapsePurpleNotes}>
                              <img src="/assets/collection/order_collection_notes.svg" alt="" /> {order.Comment}
                            </div>
                            :
                            <div className={styles.cardCollapsePurpleNoNotes}>
                            </div>
                          }
                        </div>

                      </Collapse>
                    </Card>
                  </div>

                  <div className={styles.searchWrpperFixed + " " + (getCurrentLng() === 'en' ? "" : styles.searchFormRtl)}>
                    <div className={styles.itemsListIcon}>
                      <NavLink tag={Link} to={'/orders'} className={styles.itemsListIconNavLink}>
                        <img src="/assets/collection/order_items_list.svg" />
                      </NavLink>
                    </div>
                    <ButtonGroup className={styles.btnGroup}>
                      <Button outline className={styles.btn + " " + (activeSearchOrderItemsType == '1' ? styles.btnActive : null)} value="1" onClick={toggleActiveSearchOrderItemsType} >
                        {t('orderCollection.productsToCollect')}&#160;
                        ({(orderItems.length > 0 && orderItems.filter(item => item.QuantityPicked === 0 && item.Deleted === false).length > 0) ?
                          orderItems.filter(item => item.QuantityPicked === 0 && item.Deleted === false).length :
                          "0"
                        })
                      </Button>
                      <Button outline className={styles.btn + " " + (activeSearchOrderItemsType == '2' ? styles.btnActive : null)} value="2" onClick={toggleActiveSearchOrderItemsType} >
                        {t('orderCollection.productsCollected')}&#160;
                        ({(orderItems.length > 0 && orderItems.filter(item => item.QuantityPicked > 0 && item.Deleted === false).length > 0) ?
                          orderItems.filter(item => item.QuantityPicked > 0 && item.Deleted === false).length :
                          "0"
                        })
                      </Button>
                      <Button outline className={styles.btn + " " + (activeSearchOrderItemsType == '3' ? styles.btnActive : null)} value="3" onClick={toggleActiveSearchOrderItemsType} >
                        {t('orderCollection.productsMissing')}&#160;
                        ({(orderItems.length > 0 && orderItems.filter(item => item.Deleted === true).length > 0) ?
                          orderItems.filter(item => item.Deleted === true).length :
                          "0"
                        })
                      </Button>
                    </ButtonGroup>
                    <div className={styles.missingItemsActionCube}>
                      {renderMissingProductActionSwitch(order.MissingProductAction)}
                    </div>
                  </div>

                  <Container className={styles.containerMain + " " + (getCurrentLng() === 'en' ? "" : styles.collectionCardRtl)}>

                    <Container className={styles.cardsContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectionCardRtl)}>
                      {orderItems && orderItems.length > 0 && activeSearchOrderItemsType && renderCategories()}

                    </Container>

                    {(order.Status === -1 || order.Status > 3) ? null :
                      <div className={styles.orderCollectionAddItemCircle} disabled={(order.Status === -1 || order.Status > 3)} onClick={(e) => {
                        if (order.Status > -1 || order.Status < 4) {
                          toggleProdAddModal(e);
                        }
                      }}>
                        <div className={styles.orderCollectionAddItemPlus}>
                          <img src="/assets/collection/order_collection_add_item.svg" alt="" />
                        </div>
                      </div>
                    }

                    <Modal isOpen={modalProdImg} toggle={toggleProdImgModal}>
                      <ModalHeader toggle={toggleProdImgModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>{prodImgDescription}</ModalHeader>
                      <ModalBody className={styles.modalProductContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <img src={prodImgUrl} className={styles.modalProductImg} />
                      </ModalBody>
                      {/*<ModalFooter>*/}
                      {/*  <Button color="primary" onClick={toggleProdImgModal}>{t('orderCollection.closeModal')}</Button>*/}
                      {/*</ModalFooter>*/}
                    </Modal>

                    <Modal isOpen={modalProdCollect} toggle={toggleProdCollect} style={{
                      maxWidth: modalProdCollectCardWidth + "px",
                      height: modalProdCollectCardHeight + "px",
                      top: modalProdCollectCardTop + "px",
                      left: 0 + "px"
                    }}>
                      {/*<ModalHeader toggle={toggleProdCollect} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>{modalProdCollectDescription}</ModalHeader>*/}
                      <ModalBody className={styles.modalProductContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <div className={styles.orederItemCollectModalWrapper} style={{
                          minHeight: modalProdCollectCardContentMinHeight + "px"
                        }}>
                          <div className={styles.orederItemCollectModalImgWrapper}>
                            <div className={styles.orederItemCollectModalImgCircleWrap}>
                              <div className={styles.orederItemCollectModalImgCircle}>
                                <img className={styles.orederItemCollectModalImgCircleImg} src={modalProdCollectItemImg} />
                              </div>
                            </div>
                          </div>
                          <div className={styles.orederItemCollectModalData}>
                            <div className={styles.orederItemCollectModalDataName}>
                              {modalProdCollectDescription}
                            </div>
                            {/*<div>{(item.ProductInShopProductBrandName !== null) ? item.ProductInShopProductBrandName : ''} <span>{item.QuantityPerUnit} {item.FirstUnitMeasureName}</span></div>*/}
                            <div>{modalProdCollectItemSKU}</div>

                            {((modalProdCollectItemDiscountDescription === null && modalProdCollectItemDiscountDescription === "") ? null :
                              <div className={styles.orederItemCollectModalDataDiscountDescription}>
                                {modalProdCollectItemDiscountDescription}
                              </div>)}

                            <div className={styles.orederItemCollectModalDataPrice}>
                              {modalProdCollectItemPrice} &#8362; \ {((modalProdCollectItemOrderedUnitMeasureId !== modalProdCollectItemFirstUnitMeasureID) ? modalProdCollectItemFirstUnitMeasureName : modalProdCollectItemOrderedUnitMeasure)}
                            </div>
                          </div>
                          <div className={styles.orederItemCollectModalClose}>
                            <button type="button" className="btn-close" aria-label="Close" onClick={(e) => { toggleProdCollect(e); }}></button>
                          </div>
                        </div>
                        {((modalProdCollectItemComment === undefined || modalProdCollectItemComment === null || modalProdCollectItemComment === '') ? null :
                          <div className={styles.orederItemCollectModalNotes}>
                            <div className={styles.orederItemCollectModalNotesIcon}>
                              <img src="/assets/collection/order_collection_item_notes.svg" />
                            </div>
                            <div className={styles.orederItemCollectModalNotesText}>
                              {modalProdCollectItemComment}
                            </div>
                          </div>)}

                        <div className={styles.orderItemCardCollectionBottomGrey}>
                          <Button
                            className={styles.orderItemCardCollectionLink}
                            color="Link"
                            disabled={(order.Status === -1 || order.Status > 3) || modalProdCollectQuantityPicked === NaN || (Number(modalProdCollectQuantityPicked) == 0)}
                            onClick={(e) => { handleCollectedChange(e) }}
                            data-itemid={modalProdCollectItemId}
                            value={modalProdCollectQuantityPicked}>
                            {t('orderCollection.collect')}
                            {/*<Icon.Basket3 />*/}
                          </Button>
                          {/*<Label>{t('orderCollection.collectInputLabel')}</Label>*/}
                          {/*<Input value={modalProdCollectQuantityPicked} />*/}

                          <span className={styles.orderItemCardCollectionOrderedQuantity}>
                            {((modalProdCollectItemOrderedUnitMeasureId !== modalProdCollectItemFirstUnitMeasureID) ? Number(modalProdCollectItemQuantityPerUnit * modalProdCollectItemOrderedQuantity) + " " + modalProdCollectItemFirstUnitMeasureName : modalProdCollectItemOrderedQuantity + " " + modalProdCollectItemOrderedUnitMeasure)}
                            /
                          </span>
                          <div className={styles.orderItemCardCollectionQuantityPickedWrapper}>
                            {/*<NumericInput type="number"*/}
                            {/*  title={t('orderCollection.collectInputTitle')}*/}
                            {/*  id="quantityPick"*/}
                            {/*  className={styles.orderItemCardCollectionQuantityPicked}*/}
                            {/*  min={0}*/}
                            {/*  step={(modalProdCollectItemUnitMeasure == 1) ? 0.01 : 1}*/}
                            {/*  precision={2}*/}
                            {/*  value={modalProdCollectQuantityPicked}*/}
                            {/*  format={unitMeasureFormat}*/}
                            {/*  //max="1.00"*/}
                            {/*  //presicion={2}*/}
                            {/*  onChange={(e) => {*/}
                            {/*    if (Number(e) !== 0) {*/}
                            {/*      setModalProdCollectQuantityPicked(Number(e).toFixed(2));*/}
                            {/*    }*/}
                            {/*  }}*/}
                            {/*  onFocus={handleFocus}*/}
                            {/*/>*/}

                            {/*<input*/}
                            {/*  title={t('orderCollection.collectInputTitle')}*/}
                            {/*  id={"quantityPick" + modalProdCollectItemId}*/}
                            {/*  type='number'*/}
                            {/*  min={0}*/}
                            {/*  className={styles.orderItemCardCollectionQuantityPicked}*/}
                            {/*  pattern='[0-9]{0,5}'*/}
                            {/*  onInput={(e) => {*/}
                            {/*    //if (Number(e.target.value) !== 0) {*/}
                            {/*    setModalProdCollectQuantityPicked(Number(e.target.value));*/}
                            {/*    //}*/}
                            {/*  }}*/}
                            {/*  onChange={(e) => { }}*/}
                            {/*  onFocus={(e) => { handleFocus(e); }}*/}
                            {/*  value={modalProdCollectQuantityPicked}*/}
                            {/*  onKeyDown={(e) => { handleProdCollectQuantity(e); }}*/}
                            {/*  data-orderitemid={modalProdCollectItemId}*/}
                            {/*/>*/}

                            <NumericInputWithButtons
                              id={"quantityPick"}
                              title={t('orderCollection.collectInputTitle')}
                              onChange={(value) => {
                                //console.log(value);
                                setModalProdCollectQuantityPicked(Number(value));
                              }}
                              onSubmit={(e) => { handleProdCollectQuantity(e); }}
                              value={modalProdCollectQuantityPicked}
                              pattern='[0-9]{0,5}'
                              itemId={modalProdCollectItemId}
                            />
                            { /* value={(modalProdCollectQuantityPicked > 0) ? Number(modalProdCollectQuantityPicked) : ((modalProdCollectItemOrderedUnitMeasureId !== modalProdCollectItemFirstUnitMeasureID) ? Number(modalProdCollectItemQuantityPerUnit * modalProdCollectItemOrderedQuantity) : Number(modalProdCollectItemOrderedQuantity))} */}
                          </div>
                        </div>
                      </ModalBody>
                      {/*<ModalFooter>*/}
                      {/*  <Button color="primary" onClick={toggleProdCollect}>{t('orderCollection.closeModal')}</Button>*/}
                      {/*</ModalFooter>*/}
                    </Modal>

                    <Modal isOpen={modalProdReplacement} toggle={toggleProdReplacementModal} scrollable={true} fullscreen={true} backdrop="static">
                      <ModalHeader toggle={toggleProdReplacementModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>{prodReplacementDescription}</ModalHeader>
                      <ModalBody className={styles.modalProductBody + " " + styles.modalReplaceProductBody + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <ProductReplacement shopId={shopId} chosenShopId={chosenShopId} initialSearchText={prodReplacementDescription} initialReplaceProductId={prodReplacementProductId} onHandleReplacement={(productInShopId, addedQuantityOrdered, addedUnitMeasureId) => { handleReplacement(productInShopId, addedQuantityOrdered, addedUnitMeasureId); }} />
                      </ModalBody>
                    </Modal>

                    <Modal isOpen={modalProdAdd} toggle={toggleProdAddModal} scrollable={true} fullscreen={true} backdrop="static">
                      <ModalHeader toggle={toggleProdAddModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>
                        {t('orderCollection.addProductModalTitle')}
                      </ModalHeader>
                      <ModalBody className={styles.modalProductBody + " " + styles.modalAddProductBody + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <ProductAddToOrder shopId={shopId} chosenShopId={chosenShopId} initialSearchText={""} onHandleAdd={(productInShopId, addedQuantityOrdered, addedUnitMeasureId) => { toggleProdAddModal(); handleAddConfirmed(productInShopId, addedQuantityOrdered, addedUnitMeasureId); }} />
                      </ModalBody>
                    </Modal>

                    <Modal isOpen={modalCloseOrder} toggle={toggleCloseOrderModal}>
                      <ModalHeader toggle={toggleCloseOrderModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>{t('orderCollection.closeOrder')}</ModalHeader>
                      <ModalBody className={styles.modalProductContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <Container>
                          {t('orderCollection.closeOrderConfirm')}
                        </Container>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={toggleCloseOrderModal}>{t('orderCollection.closeModal')}</Button>
                        <Button color="primary" disabled={(order.Status === -1 || order.Status > 3) ? true : false} onClick={() => { closeOrder(); }}>{t('orderCollection.closeOrder')}</Button>
                      </ModalFooter>
                    </Modal>

                    <Modal isOpen={modalCancelOrder} toggle={toggleCancelOrderModal}>
                      <ModalHeader toggle={toggleCancelOrderModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>{t('orderCollection.cancelOrder')}</ModalHeader>
                      <ModalBody className={styles.modalProductContainer + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <Container>
                          {t('orderCollection.cancelOrderConfirm')}
                        </Container>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={toggleCancelOrderModal}>{t('orderCollection.closeModal')}</Button>
                        <Button color="primary" disabled={(order.Status === -1 || order.Status > 3) ? true : false} onClick={() => { updateOrderValue("Status", -1); toggleCloseOrderModal(); }}>{t('orderCollection.cancelOrder')}</Button>
                      </ModalFooter>
                    </Modal>

                    <Modal isOpen={modalOrderCharge} toggle={toggleOrderChargeModal} scrollable={true} fullscreen={true} backdrop="static">
                      <ModalHeader toggle={toggleOrderChargeModal} className={(getCurrentLng() === 'en' ? "" : styles.modalProductsHeaderRTL)}>
                        {t('orderCollection.orderChargeTitle')}
                      </ModalHeader>
                      <ModalBody className={styles.modalProductBody + " " + styles.modalAddProductBody + " " + (getCurrentLng() === 'en' ? "" : styles.collectioCardRtl)}>
                        <OrderCharge shopId={shopId} chosenShopId={chosenShopId} onHandleCharge={(e) => { toggleOrderChargeModal(); handleOrderCharge(e); }} orderData={order} orderItemsData={orderItems} />
                      </ModalBody>
                    </Modal>

                    <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalQuantityOverOrdered} onClose={(e) => { toggleQuantityOverOrderedCustomModal(); }} title={""} >
                      <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                      <h2>{t('orderCollection.quantityOverOrdered')}</h2>
                      <div>{t('orderCollection.quantityOverOrderedOriginal')} <b> {customModalQuantityOverOrderedOriginal}</b></div>
                      <div>{t('orderCollection.quantityOverOrderedCollected')} <b> {customModalQuantityOverOrderedValue}</b></div>
                      <div>
                        <b>{t('orderCollection.quantityOverOrderedConfirmationQuestion')}</b>
                      </div>
                      <div className={styles.orderCollectionActionsWrapper}>
                        <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleQuantityOverOrderedCustomModal(); }} >{t('orderCollection.itemAlertCancelText')}</button>
                        <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleQuantityOverOrderedCustomModal(); handleCollectedChangeConfirm(); }} >{t('orderCollection.itemWasNotOrderedConfirm')}</button>
                      </div>
                    </CustomPopup>

                    <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalItemWasNotOrdered} onClose={(e) => { toggleItemWasNotOrderedCustomModal(); }} title={""} >
                      <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                      <h2>{t('orderCollection.itemWasNotOrdered')}</h2>
                      <div></div>
                      <div>
                        {t('orderCollection.itemWasNotOrderedConfirmationQuestion')}
                      </div>
                      <div className={styles.orderCollectionActionsWrapper}>
                        <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleItemWasNotOrderedCustomModal(); }} >{t('orderCollection.itemAlertCancelText')}</button>
                        <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { handleAddConfirmed(null, null); }} >{t('orderCollection.itemWasNotOrderedConfirm')}</button>
                      </div>
                    </CustomPopup>

                    <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalChargeSuccess} onClose={(e) => { toggleChargeSuccessCustomModal(); }} title={""} >
                      <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_charge_success.svg" alt="" /></div>
                      <h2>{t('orderCharge.chargeSuccess')}</h2>
                      <div></div>
                      <div className={styles.orderCollectionActionsWrapper}>
                        <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleChargeSuccessCustomModal(); }} >{t('orderCharge.chargeSuccessBackToOrders')}</button>
                        {/*<button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { handleAddConfirmed(null, null); }} >{t('orderCollection.itemWasNotOrderedConfirm')}</button>*/}
                      </div>
                    </CustomPopup>
                  </Container>

                  <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalResetItem} onClose={(e) => { toggleResetItemCustomModal(); }} title={""} >
                    <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                    <h2>{t('orderCollection.ResetItem')}</h2>
                    <div></div>
                    <div>
                      {t('orderCollection.ResetItemConfirmQuestion')}
                    </div>
                    <div className={styles.orderCollectionActionsWrapper}>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleResetItemCustomModal(); }} >{t('orderCollection.itemAlertCancelText')}</button>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { resetItem(null, null, customModalResetItemId); }} >{t('orderCollection.ResetItemOK')}</button>
                    </div>
                  </CustomPopup>

                  <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalDeleteItem} onClose={(e) => { toggleDeleteItemCustomModal(); }} title={""} >
                    <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                    <h2>{t('orderCollection.DeleteItem')}</h2>
                    <div></div>
                    <div>
                      {t('orderCollection.DeleteItemConfirmQuestion')}
                    </div>
                    <div className={styles.orderCollectionActionsWrapper}>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleDeleteItemCustomModal(); }} >{t('orderCollection.itemAlertCancelText')}</button>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { handleDeletedChange(customModalDeleteItemId); }} >{t('orderCollection.DeleteItemOK')}</button>
                    </div>
                  </CustomPopup>

                  <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={customModalOrderCharge} onClose={(e) => { toggleConfirmChargeCustomModal(); }} title={""} >
                    <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                    <h2>{t('orderCollection.OrderChargeConfirmTitle')}</h2>
                    <div></div>
                    <div>
                      {t('orderCollection.OrderChargeConfirmText')}
                    </div>
                    <div className={styles.orderCollectionActionsWrapper}>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { toggleConfirmChargeCustomModal(); }} >{t('orderCollection.itemAlertCancelText')}</button>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => { handleOrderChargeConfirm(e); }} >{t('orderCollection.OrderChargeOK')}</button>
                    </div>
                  </CustomPopup>

                  <CustomPopup className={styles.orderCollectionAlertMain + " " + ((getCurrentLng() == 'en') ? null : styles.orderCollectionAlertMainRtl)} show={modalPreReplacementPreferencesNotification} onClose={(e) => { togglePreReplacementPreferencesNotificationModal(); }} title={""} >
                    <div className={styles.orderCollectionAlertBellWrapper}><img src="/assets/collection/order_collection_alert_bell.svg" alt="" /></div>
                    <h2>{t('orderCollection.preRaplacementPerferencesNoticeTitle')}</h2>
                    <div>{t('orderCollection.preRaplacementPerferencesNoticeText')}</div>
                    <div><b>{renderMissingProductActionNotice(order.MissingProductAction)}</b></div>
                    <div className={styles.orderCollectionActionsWrapper}>
                      <button color="link" className={styles.orderCollectionAlertActionBtn} onClick={(e) => {
                        togglePreReplacementPreferencesNotificationModal();
                        toggleProdReplacementModal();
                      }} >{t('orderCollection.preRaplacementPerferencesNoticeOk')}</button>
                    </div>
                  </CustomPopup>

                  <Toast isOpen={toastBarcodeScanned} fade={true} className={styles.toastBarcodeScannedWrapper}>
                    <ToastHeader>
                      BARCODE
                    </ToastHeader>
                    <ToastBody>
                      Scanned {toastBarcodeScannedSKU}
                    </ToastBody>
                  </Toast>

                  <ErrorMessageCustomPopup show={ordersEditError} title={ordersEditErrorMessage} onClose={(e) => { setOrdersEditError(false); }} />

                  <LeavePageBlocker show={ordersEditLeaveShowMessage} title={''} onClose={(e) => { setOrdersEditLeaveShowMessage(false); }}
                    onReset={(e) => {
                      //setOrdersEditLeaveBlock(false);
                      ordersEditLeaveBlock.current = false;

                      setOrdersEditLeaveShowMessage(false);
                      setOrdersEditLeaveWithReset(true);
                    }}
                    onSave={(e) => {
                      //setOrdersEditLeaveBlock(false);
                      ordersEditLeaveBlock.current = false;

                      setOrdersEditLeaveShowMessage(false);
                      setOrdersEditLeaveWithSave(true);
                    }}
                  />

                  <LoaderAnimation show={showLoader} allowClose={false} />

                  <KeyboardEventHandler
                    handleKeys={['alphanumeric']}
                    onKeyEvent={(key, e) => { setToastBarcodeScannedInput(prevState => (prevState + key)); }} />
                </div>
              )
            }}
          </layoutContext.Consumer>
        )
      }}
    </userContext.Consumer>
  )
}
export default OrderCollection